<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
	[ngClass]="{ 'bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10': isDestinationPage }"
>
	<!-- Heading and/or Tag section -->
	<div
		*ngIf="content.introTitle || content.tagTitle"
		class="mb-4 flex flex-wrap justify-between gap-x-3 gap-y-6 xs:mb-6 xs:gap-y-8 sm:mb-8 sm:gap-x-5 sm:gap-y-10 lg:mb-10 lg:flex-nowrap lg:gap-y-0"
		[ngClass]="{ 'mb-3 sm:mb-5': !content.introText }"
	>
		<!-- Headings -->
		<ui-heading-content-block
			*ngIf="content.introTitle || content.introText"
			[heading]="content.introTitle"
			[intro]="content.introText"
			[headingLevel]="content.headingType"
		></ui-heading-content-block>
		<div *ngIf="content.blogTags.length" class="mb-3 max-w-lg sm:mb-5">
			<ui-heading-content-block
				class="mb-3"
				*ngIf="content.tagTitle"
				[subheading]="content.tagTitle"
			></ui-heading-content-block>
			<a
				*ngFor="let tag of content.blogTags"
				class="mb-1 mr-2 inline-block rounded-full bg-pill-blog px-3 text-sm font-semibold uppercase leading-5 text-white"
				[href]="tag.url"
				target="_blank"
			>
				{{ tag.name | uppercase }}
			</a>
		</div>
	</div>
	<ui-collection [title]="content.title">
		<ui-card
			*ngFor="let blog of content.items"
			[elevated]="true"
			[useFullHeight]="true"
			avatarImageUrl="/assets/images/80x80_Rent-a-Smile-SC-Smiley.png"
			[imageAlt]="blog.imageAlt"
			[imageUrl]="blog.imageUrl"
			[link]="{
				href: blog.url,
				rel: '',
				shouldOpenInNewTab: true,
				text: '',
				title: ''
			}"
			[title]="blog.title"
			styleModifiers="shadow-md rounded-large"
		>
		</ui-card>
	</ui-collection>
	<a
		*ngIf="content.link"
		[href]="content.link.href"
		[rel]="content.link.rel"
		[title]="content.link.title"
		class="btn btn-secondary mt-3 block w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold xs:mt-5"
		[attr.target]="content.link.shouldOpenInNewTab ? '_blank' : null"
	>
		{{ content.buttonText }}
	</a>
</section>
