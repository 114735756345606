import { DOCUMENT, isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, tap } from 'rxjs';
import { BaseComponent } from '../../components/base.component';

declare global {
	interface Window {
		_conv_q: unknown[];
		hasLoadedConvertExperiencesTags?: boolean;
	}
}

@Injectable({
	providedIn: 'root',
})
export class ConvertExperiencesService extends BaseComponent {
	private readonly renderer: Renderer2;
	private isCreated = false;

	constructor(
		@Inject(PLATFORM_ID) private platformId: string,
		@Inject(DOCUMENT) private document: Document,
		private readonly router: Router,
		private readonly rendererFactory: RendererFactory2,
	) {
		super();
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	init(convertExperienceId: string) {
		if (this.isCreated) {
			return;
		}
		this.isCreated = true;

		if (isPlatformServer(this.platformId)) {
			// Script to prevent loading scripts multiple times
			const verificationScriptTag = this.renderer.createElement('script');
			verificationScriptTag.innerHTML =
				'window.hasLoadedConvertExperiencesTags = true;';
			this.renderer.appendChild(this.document.head, verificationScriptTag);

			this.createConvertExperiencesScriptTag(convertExperienceId);
		} else if (isPlatformBrowser(this.platformId)) {
			if (!window.hasLoadedConvertExperiencesTags) {
				this.createConvertExperiencesScriptTag(convertExperienceId);
			}
			this.handleNavigationEndEvents();
		}
	}

	private createConvertExperiencesScriptTag(convertExperienceId: string): void {
		const body: HTMLElement = this.document.body;
		const script = this.renderer.createElement('script');
		script.setAttribute('type', 'text/plain');
		script.setAttribute('data-usercentrics', 'Convert');
		script.setAttribute(
			'src',
			`//cdn-4.convertexperiments.com/js/${convertExperienceId}.js`,
		);
		this.renderer.appendChild(body, script);
	}

	private handleNavigationEndEvents(): void {
		this.router.events
			.pipe(
				filter((event) => event instanceof NavigationEnd),
				tap(() => {
					this.pushConvertEvents();
				}),
			)
			.subscribe();
	}

	private pushConvertEvents(): void {
		window._conv_q = window._conv_q || [];
		window._conv_q.push(['run', 'true']);
		window._conv_q.push(['recheck_goals']);
	}
}
