import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CardsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cards.interface';
import { CardComponent } from '../card/card.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-cards',
	templateUrl: './cards.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		CollectionComponent,
		CardComponent,
	],
})
export class CardsComponent {
	// NOTE: When content have isHorizontal true, it should be rendered as a list, and the content of the card should be horizontal
	@Input() content?: CardsComponentInterface;
}
