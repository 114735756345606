import { CommonModule } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	OnInit,
	Renderer2,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { AffiliateFacade } from '@sunny-cars/data-access-affiliate';
import {
	ContactHoursHelper,
	Menu,
	MenuItem,
	MenuTypes,
	OpeningTimes,
} from '@sunny-cars/util-global';
import { AffiliateCustomService } from '@sunny-cars/util-global/lib/helpers/affiliate-custom/affiliate-custom.service';
import { BaseUrlHelper } from '@sunny-cars/util-global/lib/helpers/base-url/base-url.helper';
import { ExpertrecService } from '@sunny-cars/util-global/lib/helpers/expertrec/expertrec.service';
import { SocialMediaInterface } from '@sunny-cars/util-global/lib/interfaces/social-media/social-media.interface';
import { filterUndefined } from '@sunny-cars/util-global/lib/rxjs-operators/undefined-filter/undefined-filter';
import { Observable, combineLatest, map } from 'rxjs';
import { ContrastTextDirective } from '../contrast-text/contrast-text.directive';
import { CustomAffiliateLinksComponent } from '../custom-affiliate-links/custom-affiliate-links.component';
import { IconComponent } from '../icon/icon.component';
import { LogoComponent } from '../logo/logo.component';
import { SocialMediaComponent } from '../social-media/social-media.component';

@Component({
	selector: 'ui-footer',
	templateUrl: './footer.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		LogoComponent,
		IconComponent,
		RouterLink,
		SocialMediaComponent,
		ContrastTextDirective,
		CustomAffiliateLinksComponent,
		TranslateModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class FooterComponent implements OnInit, OnChanges {
	/**
	 * Whether the help bar is visible
	 */
	@Input() hasHelpBar = false;
	@Input() heading = '';
	@Input() content = '';
	@Input() phone = '';
	@Input() showOpeningHours = false;
	@Input() openingTimes$?: Observable<OpeningTimes | undefined>;
	@Input() socials?: SocialMediaInterface;
	@Input() newsletter?: { title: string; url: string; description: string };
	@Input() contact?: { title: string; url: string };
	@Input() menus?: Menu[];
	@Input() expertrecId?: string = '';

	footerMenu?: MenuItem[];
	subFooterMenu?: MenuItem[];

	baseURL = this.baseUrlHelper.getLegacyBaseUrl();
	logo$ = this.affiliateStore.logo$;
	isCustomAffiliate$ = this.affiliateCustomService.customAffiliate$;
	logoRedirectUrl$ = combineLatest([
		this.affiliateStore.logoUrl$.pipe(filterUndefined),
		this.isCustomAffiliate$,
	]).pipe(
		map(([redirectUrl, isCustomAffiliate]) => {
			if (!isCustomAffiliate) {
				return this.baseUrlHelper.getLegacyBaseUrl();
			}
			return redirectUrl;
		}),
	);
	openingTimesLabel$: Observable<string> | undefined;

	constructor(
		private readonly baseUrlHelper: BaseUrlHelper,
		private readonly affiliateStore: AffiliateFacade,
		private readonly affiliateCustomService: AffiliateCustomService,
		private readonly expertrecService: ExpertrecService,
		private renderer2: Renderer2,
		private readonly contactHoursHelper: ContactHoursHelper,
	) {}

	ngOnInit(): void {
		this.subscribeToOpeningTimes();

		if (this.expertrecId) {
			this.expertrecService.setExpertrecScript(
				this.renderer2,
				this.expertrecId,
			);
		}
	}

	ngOnChanges(changes: SimpleChanges) {
		if (changes['menus']) {
			this.footerMenu = this.menus?.find(
				(menu) => menu.type === MenuTypes.FOOTER,
			)?.items;
			this.subFooterMenu = this.menus?.find(
				(menu) => menu.type === MenuTypes.SUBFOOTER,
			)?.items;
		}
	}

	subscribeToOpeningTimes(): void {
		if (!this.openingTimes$) {
			return;
		}
		this.openingTimes$.subscribe((openingTimes) => {
			this.openingTimesLabel$ =
				this.contactHoursHelper.getTodaysContactLabel(openingTimes);
		});
	}

	hasSocials(socials?: SocialMediaInterface): boolean {
		return !!(
			socials &&
			(socials.twitter ||
				socials.facebook ||
				socials.instagram ||
				socials.vimeo ||
				socials.blog)
		);
	}
}
