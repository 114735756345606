<section [id]="anchor" class="{{ width }}">
	<ui-heading-content-block
		*ngIf="header"
		class="mb-4 xs:mb-6"
		[headingLevel]="headingType"
		[subheading]="header"
	></ui-heading-content-block>
	<ul *ngIf="links">
		<li *ngFor="let link of links">
			<a
				*ngIf="
					link.attributes.link && link.attributes.link.href.startsWith('http')
				"
				class="block h-full w-full border-b border-ui-moderate bg-icon-chevron-right bg-2.5 bg-right-4-center bg-no-repeat pb-2 pl-4 pr-11 pt-2.5 focus-within:bg-secondary-high focus-within:bg-icon-chevron-right-white focus-within:text-white hover:bg-secondary-low active:bg-secondary-moderate"
				[href]="link.attributes.link.href"
				[rel]="link.attributes.link"
				[title]="link.attributes.link.title"
				[attr.target]="
					link.attributes.link.shouldOpenInNewTab ? '_blank' : null
				"
			>
				{{ link.attributes.link.text }}
			</a>
			<a
				*ngIf="
					link.attributes.link && !link.attributes.link.href.startsWith('http')
				"
				class="block h-full w-full border-b border-ui-moderate bg-icon-chevron-right bg-2.5 bg-right-4-center bg-no-repeat pb-2 pl-4 pr-11 pt-2.5 focus-within:bg-secondary-high focus-within:bg-icon-chevron-right-white focus-within:text-white hover:bg-secondary-low active:bg-secondary-moderate"
				[routerLink]="link.attributes.link.href"
				[title]="link.attributes.link.title"
			>
				{{ link.attributes.link.text }}
			</a>
		</li>
	</ul>
	<ul *ngIf="pages">
		<li *ngFor="let page of pages">
			<a
				class="block h-full w-full border-b border-ui-moderate bg-icon-chevron-right bg-2.5 bg-right-4-center bg-no-repeat pb-2 pl-4 pr-11 pt-2.5 focus-within:bg-secondary-high focus-within:bg-icon-chevron-right-white focus-within:text-white hover:bg-secondary-low active:bg-secondary-moderate"
				[href]="page.attributes.page.url"
				[title]="page.attributes.page.title"
			>
				{{ page.attributes.text }}
			</a>
		</li>
	</ul>
</section>
