import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { ImageInterface, Sizes } from '@sunny-cars/util-global';
import { LinkInterface } from '@sunny-cars/util-global/lib/interfaces/content/link-type.interface';
import { RoundNumberPipe } from '@sunny-cars/util-global/lib/pipes/round-number/round-number.pipe';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-b2b-card',
	templateUrl: './b2b-card.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, ImageComponent, RoundNumberPipe],
})
export class B2BCardComponent {
	@Input() isHorizontal = false;
	@Input() image?: ImageInterface;
	@Input() showImage = false;
	@Input() link?: LinkInterface;
	@Input() title = '';
	@Input() subtitle = '';
	@Input() text = '';
	sizes = Sizes;
}
