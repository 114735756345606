import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	Affiliate,
	LocalizedTravelArrangement,
	TravelArrangementConfiguration,
	TypographyConfiguration,
} from '@sunny-cars/util-global';
import { AFFILIATE_FEATURE_KEY, State } from './affiliate.reducer';

type LanguageObject = { language?: string };

function matchOnLocaleLanguage(
	language: string,
): (language: LanguageObject) => boolean {
	return (object: LanguageObject) =>
		object.language?.toLowerCase() === language.toLowerCase();
}

export const getAffiliateState = createFeatureSelector<State>(
	AFFILIATE_FEATURE_KEY,
);

export const getAffiliate = createSelector(
	getAffiliateState,
	(state: State) => state?.affiliate,
);

export const getGeneralStyling = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.general;
	},
);

export const getIsDefaultSunnyColours = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.general.default;
	},
);

export const getTypography = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined): TypographyConfiguration | undefined => {
		return affiliate?.configuration?.typography;
	},
);

export const getReviewsAndRewards = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.reviews;
	},
);

export const getPaymentMethods = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.paymentMethods;
	},
);

export const getReviews = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.reviews;
	},
);

export const getHeaderAndFooterStyling = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.header;
	},
);

export const getContactInformation = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.header.contact;
	},
);

export const showCustomContactInfo = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) =>
		!affiliate?.configuration?.header.contact.default,
);

export const getTravelArrangementInformation = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.travelArrangement;
	},
);

export const getBookingWidget = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.widget;
	},
);

export const getTravelArrangementsUrlForLanguage = (localeLanguage: string) =>
	createSelector(getAffiliate, (affiliate): LocalizedTravelArrangement => {
		const arrangement = findLocalizedArrangementForLanguage(
			localeLanguage,
			affiliate,
		);

		return {
			linkLabel: arrangement?.linkLabel || '',
			linkURL: arrangement?.linkURL || '',
			language: arrangement?.language || '',
		};
	});

const findLocalizedArrangementForLanguage = (
	localeLanguage: string,
	affiliate?: Affiliate,
): LocalizedTravelArrangement | undefined =>
	affiliate?.configuration?.travelArrangement?.localized.find(
		matchOnLocaleLanguage(localeLanguage),
	);

export const getTravelArrangementsActive = createSelector(
	getTravelArrangementInformation,
	(travelArrangementConfig: TravelArrangementConfiguration | undefined) => {
		return travelArrangementConfig?.active;
	},
);

export const getAffiliateName = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.travelArrangement?.affiliateName;
	},
);

export const getLogoUrl = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.header.logoRedirectUrl;
	},
);

export const getLogo = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => affiliate?.configuration?.header.logo,
);

export const getPhoneNumbers = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.header.contact.phoneNumbers;
	},
);

export const getPhoneNumberForLanguage = (localeLanguage: string) =>
	createSelector(getPhoneNumbers, (phoneNumbers) => {
		return phoneNumbers?.find(matchOnLocaleLanguage(localeLanguage))?.phone;
	});

export const getPhoneNumberInfoForLanguage = (localeLanguage: string) =>
	createSelector(getPhoneNumbers, (phoneNumbers) => {
		return phoneNumbers?.find(matchOnLocaleLanguage(localeLanguage))
			?.information;
	});

export const getEmails = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => {
		return affiliate?.configuration?.header.contact.emails;
	},
);

export const getEmailAddressForLanguage = (localeLanguage: string) =>
	createSelector(getEmails, (emails) => {
		return emails?.find(matchOnLocaleLanguage(localeLanguage))?.email;
	});

export const getCountryCode = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => affiliate?.countryCode,
);

export const getCurrencyCode = createSelector(
	getAffiliate,
	(affiliate: Affiliate | undefined) => affiliate?.currencyCode,
);

export const getAffiliateLoading = createSelector(
	getAffiliateState,
	(state: State) => state.isLoading,
);

export const getAffiliateLoaded = createSelector(
	getAffiliateState,
	(state: State) => state.isLoaded,
);

export const getAffiliateErrors = createSelector(
	getAffiliateState,
	(state: State) => state.errors,
);

export const getLastAction = createSelector(
	getAffiliateState,
	(state: State) => state.lastAction,
);

export const getHasPendingChanges = createSelector(
	getAffiliateState,
	(state: State) => state.hasPendingChanges,
);
