import { Inject, Injectable, Optional } from '@angular/core';
import Cookies from 'js-cookie';
import { DomainHelper } from '../domain';

@Injectable({
	providedIn: 'root',
})
export class CookieService {
	constructor(
		@Optional() @Inject('isProduction') readonly isProduction: string,
		private readonly domainHelper: DomainHelper,
	) {}

	persistCookieForDuration(name: string, value: unknown, days: number) {
		const stringRepresentation =
			typeof value === 'string' ? value : JSON.stringify(value);
		Cookies.set(name, stringRepresentation, {
			expires: days,
			secure: true,
			sameSite: 'None',
			domain: this.isProduction
				? `.sunnycars.${this.domainHelper.siteDomain}`
				: undefined,
		});
	}

	removeCookie(key: string): void {
		Cookies.remove(key);
	}

	getCookie(name: string): string | undefined {
		return Cookies.get(name);
	}
}
