export const colors = {
	currentcolor: 'currentColor',
	white: '#FFFFFF',
	'white-transparent': '#FFFFFFCC',
	black: '#000000',
	'black-transparent': '#0009',
	'black-transparent-low': '#00000036',
	text: {
		primary: '#000000',
		secondary: '#000000D9',
		tertiary: '#000000BF',
		quaternary: '#000000A6',
		DEFAULT: 'var(--color-text-primary)',
	},
	primary: {
		low: '#FAE6EE',
		moderate: '#EFB0C9',
		high: '#DD548B',
		DEFAULT: '#CC0052',
		important: '#CC0052',
		critical: '#91003A',
	},
	secondary: {
		low: '#E6F2F1',
		moderate: '#B0D6D5',
		high: '#54A6A3',
		DEFAULT: '#007A76',
		important: '#007A76',
		critical: '#004341',
	},
	tertiary: {
		low: '#E6F9ED',
		moderate: '#B0EBC8',
		high: '#00BF4C',
		DEFAULT: '#008836',
		important: '#008836',
		critical: '#00692A',
	},
	highlight: {
		low: '#FFFCE6',
		moderate: '#FFF4B0',
		high: '#FFDD00',
		DEFAULT: '#E8C900',
		important: '#E8C900',
		critical: '#B59D00',
	},
	ui: {
		root: '#FFFFFF',
		'root-half-transparent': '#FFFFFF80',
		'root-transparent': '#FFFFFFE6',
		ground: '#FFFFFF',
		low: '#F6F9FB',
		moderate: '#E2ECF1',
		high: '#A3C3D2',
		'high-transparent': '#A3C3D280',
		DEFAULT: '#748A95',
		important: '#748A95',
		critical: '#5A6B74',
	},
	info: {
		low: '#E6F8FF',
		moderate: '#B2E8FF',
		high: '#06B4FF',
		DEFAULT: '#0480B5',
		important: '#0480B5',
		critical: '#03638C',
	},
	success: {
		low: '#E6F9ED',
		moderate: '#B0EBC8',
		high: '#00BF4C',
		DEFAULT: '#008836',
		important: '#008836',
		critical: '#00692A',
	},
	error: {
		low: '#FEE6E6',
		moderate: '#FBB0B0',
		high: '#F10000',
		DEFAULT: '#AB0000',
		important: '#AB0000',
		critical: '#850000',
	},
	warning: {
		low: '#FFF3E6',
		moderate: '#FFDBB1',
		high: '#FF8A02',
		DEFAULT: '#B56201',
		important: '#B56201',
		critical: '#8C4C01',
	},
	selected: {
		low: '#E6F2F1',
		moderate: '#B0D6D5',
		high: '#54A6A3',
		DEFAULT: '#007A76',
		important: '#007A76',
		critical: '#004341',
	},
	focus: {
		low: '#F6F9FB',
		moderate: '#E2ECF1',
		high: '#A3C3D2',
		DEFAULT: '#748A95',
		important: '#748A95',
		critical: '#5A6B74',
		gray: '#BFBFBF',
	},
	'frame-dominant': {
		50: '#E6F2F1',
		100: '#B0D6D5',
		200: '#8AC2C0',
		300: '#54A6A3',
		400: '#339591',
		500: '#007A76',
		600: '#006F6B',
		700: '#005754',
		800: '#004341',
		900: '#003332',
	},
	heading: 'var(--color-secondary)',
	subheading: 'var(--color-black)',
	'header-footer-background': {
		low: 'var(--color-secondary-low)',
		moderate: 'var(--color-secondary-moderate)',
		high: 'var(--color-secondary-high)',
		DEFAULT: 'var(--color-secondary)',
		important: 'var(--color-secondary-important)',
		critical: 'var(--color-secondary-critical)',
	},
	'header-navigation-background': '#0000001A',
	widget: {
		heading: 'var(--color-heading)',
		subheading: 'var(--color-subheading)',
		text: 'var(--color-text)',
		'submit-background': 'var(--color-primary)',
		background: 'transparent',
	},
	pill: '#ACEAA8',
	'pill-blog': '#A4BA00',
	reviews: '#00BF4C',
	'success-active': '#00AE45',
	'modal-backdrop': '#0033327A',
	facebook: '#3B5998',
	twitter: '#00A0D1',
	linkedin: '#0E76A8',
	youtube: '#C4302B',
	pinterest: '#C8232C',
	instagram: '#D93175',
	vimeo: '#00ADEF',
	anvr: '#419FE1',
};
