<ul class="flex basis-full flex-col">
	<ng-template #treeNode let-item let-level="level">
		<li
			class="w-full border-b border-selected-low last:border-b-0 hover:bg-selected-low"
			[style.padding-left]="level * 18 + 'px'"
		>
			<a
				class="block bg-icon-chevron-right bg-3 bg-right-3-center bg-no-repeat pr-8 pt-3 pb-3"
				[href]="item.path"
			>
				{{ item.title }}
			</a>
		</li>
		<ng-container *ngIf="item.children">
			<ng-container
				*ngFor="let child of item.children"
				[ngTemplateOutlet]="treeNode"
				[ngTemplateOutletContext]="{
					$implicit: child,
					level: level + 1
				}"
			>
			</ng-container>
		</ng-container>
	</ng-template>
	<ng-container
		*ngFor="let item of list"
		[ngTemplateOutlet]="treeNode"
		[ngTemplateOutletContext]="{
			$implicit: item,
			level: 1
		}"
	>
	</ng-container>
</ul>
