// Hard coded for now, later should come from backend
import { APICountryISO } from '../interfaces';

const countriesDefinition: APICountryISO = {
	NL: {
		name: 'The Netherlands',
		iso3: 'NLD',
		iso2: 'NL',
	},
	BE: {
		name: 'Belgium',
		iso3: 'BEL',
		iso2: 'BE',
	},
	DE: {
		name: 'Germany',
		iso3: 'DEU',
		iso2: 'DE',
	},
	LU: {
		name: 'Luxembourg',
		iso3: 'LUX',
		iso2: 'LU',
	},
	AT: {
		name: 'Austria',
		iso3: 'AUT',
		iso2: 'AT',
	},
	CH: {
		name: 'Switzerland',
		iso3: 'CHE',
		iso2: 'CH',
	},
	FR: {
		name: 'France',
		iso3: 'FRA',
		iso2: 'FR',
	},
	GB: {
		name: 'United Kingdom',
		iso3: 'GBR',
		iso2: 'GB',
	},
};

/**
 * International address country mapper
 * @param {string} countryCode
 * @returns {string}
 */
export function internationalAddressCountryMapper(countryCode: string): string {
	if (!countryCode || !countriesDefinition[countryCode]) {
		return '';
	}

	return countriesDefinition[countryCode].iso3;
}
