import { ContentLayoutTypes } from '../page-content-layout-types.interface';

export enum FaqTextPosition {
	LEFT = 'left',
	RIGHT = 'right',
	TOP = 'top',
}

export interface FaqComponentInterface {
	anchor: string;
	content: string;
	heading: string;
	keepQuestionsVisible: boolean;
	layout: ContentLayoutTypes.FAQ;
	questions: Question[];
	showText: boolean;
	textPosition: FaqTextPosition;
}

export interface Question {
	text: string;
	title: string;
}
