/**
 * CacheLabelService
 */
export class CacheLabelService {
	/**
	 * Get current unix timestamp
	 * @param {Date} date
	 * @returns {number}
	 */
	private static getUnixTimestamp(date: Date): number {
		return Math.floor(date.getTime() / 1000);
	}

	/**
	 * Get Date For Every 15
	 * @param {number} minutes
	 * @returns {Date}
	 */
	private static getDateForEvery(minutes: number): Date {
		const currentDate: Date = new Date();
		const timeDifference: number = CacheLabelService.calculateTimeDifference(
			currentDate,
			minutes,
		);
		const date: Date = new Date();
		date.setMinutes(currentDate.getMinutes() - timeDifference);
		date.setSeconds(0);

		return date;
	}

	/**
	 * Calculate Time Difference
	 * @param {Date} currentDate
	 * @param {number} minutes
	 * @returns {number}
	 */
	private static calculateTimeDifference(
		currentDate: Date,
		minutes: number,
	): number {
		return currentDate.getMinutes() % minutes;
	}

	/**
	 * Get timestamp: changes every X min
	 * @returns {number}
	 */
	static getTimestamp(): number {
		const date: Date = CacheLabelService.getDateForEvery(15);
		return CacheLabelService.getUnixTimestamp(date);
	}
}
