<div
	class="z-50 max-sm:fixed max-sm:left-0 max-sm:top-0 max-sm:h-full max-sm:w-full max-sm:bg-modal-backdrop"
	(click)="close()"
	*ngIf="isOpen || openFullScreen"
></div>
<div
	class="autocomplete relative rounded-t-popover-2x"
	data-test-class="autocomplete"
	[ngClass]="{
		'is-open bottom-sheet-like-offset-xs max-h-sheet-like-xs xs:bottom-sheet-like-offset xs:max-h-sheet-like sm:bottom-0 sm:max-h-full':
			isOpen || openFullScreen
	}"
>
	<div class="autocomplete__header relative" #trigger>
		<ui-sheet-header
			[title]="title"
			(closed)="close()"
			[ngClass]="{ hidden: !openFullScreen }"
			class="sm:hidden"
		>
		</ui-sheet-header>
		<!-- Disabling autocomplete is a long running issue. See: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill -->
		<div
			[ngClass]="{
				'px-4 sm:px-0': openFullScreen
			}"
		>
			<div class="relative" [ngClass]="{ 'mt-0.5 sm:mt-0': openFullScreen }">
				<input
					data-test-class="autocomplete-input"
					class="input w-full bg-right-3-center pr-7 text-lg"
					[ngClass]="{
						'input--invalid': isInvalid || errorEmpty,
						'input--valid': isValid,
						'bg-right-9-center !pr-14': searchValue || selectedValue
					}"
					[attr.value]="selectedValue"
					[autocomplete]="idSuffix"
					[attr.aria-expanded]="isOpen ? 'true' : 'false'"
					[attr.id]="controlId + idSuffix || null"
					[attr.name]="controlName"
					[placeholder]="placeholder || ''"
					[disabled]="isDisabled"
					[(ngModel)]="selectedValue"
					(ngModelChange)="handleInput()"
					(click)="open()"
					(keydown.arrowup)="highlightPrevious($event)"
					(keydown.arrowdown)="highlightNext($event)"
					(keydown.enter)="selectHighlighted($event)"
					(keydown.escape)="close()"
					(keydown.shift.tab)="close()"
					(keydown.tab)="close()"
				/>
				<button
					*ngIf="searchValue || selectedValue"
					class="autocomplete__clear-button absolute flex w-10 flex-wrap items-center justify-center rounded-button focus:border-2 focus:border-focus focus:shadow-input-focus focus:outline focus:outline-1 focus:outline-focus-moderate sm:h-full"
					[ngClass]="{
						'pointer-events-none opacity-disabled': isDisabled
					}"
					(click)="clearValue()"
					type="button"
					[attr.aria-label]="'global.clear-action' | translate"
				>
					<ui-icon
						inlined
						name="icon-cross-circle-solid"
						color="ui-critical"
					></ui-icon>
				</button>
			</div>
		</div>
	</div>
	<div
		#results
		class="autocomplete__results z-40 min-w-full overflow-auto bg-white text-black sm:max-h-80 sm:rounded-popover sm:shadow-lg"
		[ngClass]="{
			'h-0': !isOpen
		}"
		data-test-class="autocomplete-results"
		aria-labelledby="autocomplete"
	>
		<ng-container *ngIf="isLoading">
			<div
				*ngFor="let entry of [0, 1, 2, 3, 4, 5, 6, 7, 8]; let index = index"
				data-test-class="autocomplete-row"
			>
				<ng-container
					*ngTemplateOutlet="skeletonTemplate; context: { index: index }"
				>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="!isLoading">
			<div *ngIf="entries.length === 0">
				<ng-container *ngTemplateOutlet="emptyTemplate"> </ng-container>
			</div>
			<div
				*ngFor="let entry of entries | slice: 0 : renderLimit || entries.length"
				class="grid min-h-mobile-input p-0 sm:min-h-0"
				[ngClass]="{
					'hover:bg-selected-low': entry.value !== selectedEntry?.value,
					'selected bg-selected text-white':
						entry.value === selectedEntry?.value
				}"
				data-test-class="autocomplete-row"
				(click)="handleSelect(entry)"
			>
				<ng-container
					*ngTemplateOutlet="
						entryTemplate;
						context: {
							entry: entry,
							isSelected: entry.value === selectedEntry?.value
						}
					"
				>
				</ng-container>
			</div>
			<div
				*ngIf="renderLimit && entries.length > renderLimit"
				class="border-t border-ui-low bg-highlight-low p-3 text-base"
			>
				{{ 'components.picker.more-options-available-label' | translate }}
			</div>
		</ng-container>
		<div id="scrollEnd"></div>
		<div *ngIf="isLoadingMore" class="autocomplete__load-more px-3 py-2">
			<ui-icon
				classModifiers="d-block"
				name="icon-loader"
				inlined="true"
				color="ui-critical"
			></ui-icon>
		</div>
	</div>
</div>
