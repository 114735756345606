import { InjectionToken } from '@angular/core';

export const AFFILIATE_META_REDUCERS = new InjectionToken<string[]>(
	'affiliateStoreMetaReducers',
);
export const AFFILIATE_STORAGE_KEY = new InjectionToken<string[]>(
	'affiliateStoreKey',
);
export const AFFILIATE_STORE_KEYS = new InjectionToken<string[]>(
	'affiliateStoreKeys',
);
