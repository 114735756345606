import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ProfileProviderModule } from '@sunny-cars/provider-account-service';
import { SessionStorageService } from '@sunny-cars/provider-storage';
import { getStorageReducer } from '@sunny-cars/util-global';
import { AccountEffects } from './account.effects';
import { AccountFacade } from './account.facade';
import * as fromAccount from './account.reducer';
import {
	ACCOUNT_META_REDUCERS,
	ACCOUNT_STORAGE_KEY,
	ACCOUNT_STORE_KEYS,
} from './account.tokens';

/**
 * Account store module
 */
@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromAccount.ACCOUNT_FEATURE_KEY,
			fromAccount.reducer,
			ACCOUNT_META_REDUCERS,
		),
		EffectsModule.forFeature([AccountEffects]),
		ProfileProviderModule,
	],
	providers: [
		AccountFacade,
		{
			provide: ACCOUNT_STORAGE_KEY,
			useValue: '__account_storage__',
		},
		{
			provide: ACCOUNT_STORE_KEYS,
			useValue: undefined,
		},
		{
			deps: [ACCOUNT_STORAGE_KEY, SessionStorageService, ACCOUNT_STORE_KEYS],
			provide: ACCOUNT_META_REDUCERS,
			useFactory: getStorageReducer,
		},
	],
})
export class AccountStoreModule {}
