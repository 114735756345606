import { Action, createReducer, on } from '@ngrx/store';
import { SessionType } from '@sunny-cars/util-global';
import * as SessionActions from './session.actions';

/**
 * Storage key
 */
export const SESSION_FEATURE_KEY = 'session';

/**
 * Store interface
 */
export interface State {
	/**
	 * Session identifier
	 */
	sessionID: string | undefined;
	/**
	 * Token type f.e. ADMIN
	 */
	tokenType: SessionType | undefined;
	/**
	 * Session id
	 */
	id: string | undefined;
	/**
	 * Session username
	 */
	username: string | undefined;
	/**
	 * Token expiration
	 */
	expiresAt?: string;
	/**
	 * is the Session been loading
	 */
	isLoading: boolean;
	/**
	 * has the Session been loaded
	 */
	isLoaded: boolean;
	/**
	 * Reason for logout
	 */
	logoutReason: string | undefined;
	/**
	 * last known error (if any)
	 */
	error: string | undefined;
}

/**
 * Initial store state
 */
export const initialState: State = {
	id: undefined,
	username: undefined,
	sessionID: undefined,
	tokenType: undefined,
	expiresAt: undefined,
	isLoading: false,
	isLoaded: false,
	logoutReason: undefined,
	error: undefined,
};

/**
 * Reducer handler
 */
const sessionReducer = createReducer(
	initialState,
	on(SessionActions.init, (state) => ({
		...state,
		isLoading: false,
		isLoaded: !!state.sessionID,
		logoutReason: undefined,
		error: undefined,
	})),
	on(SessionActions.loadSession, (state) => ({
		...state,
		id: undefined,
		username: undefined,
		sessionID: undefined,
		tokenType: undefined,
		expiresAt: undefined,
		isLoading: true,
		isLoaded: false,
		error: undefined,
	})),
	on(
		SessionActions.loadSessionSuccess,
		(state, { sessionID, tokenType, expiresAt, id, username }) => ({
			...state,
			id,
			username,
			sessionID,
			tokenType,
			expiresAt,
			isLoading: false,
			isLoaded: true,
		}),
	),
	on(SessionActions.loadSessionFailure, (state, { error }) => ({
		...state,
		id: undefined,
		username: undefined,
		sessionID: undefined,
		tokenType: undefined,
		expiresAt: undefined,
		isLoading: false,
		isLoaded: false,
		error,
	})),
	on(SessionActions.clearError, (state) => ({
		...state,
		error: undefined,
	})),
	on(SessionActions.logout, (state, { reason }) => ({
		...state,
		id: undefined,
		sessionID: undefined,
		username: undefined,
		tokenType: undefined,
		expiresAt: undefined,
		isLoading: false,
		isLoaded: false,
		logoutReason: reason,
		error: undefined,
	})),
);

/**
 * Session reducer
 * @param {State|undefined} state
 * @param {Action} action
 * @returns {State}
 */
export function reducer(state: State | undefined, action: Action): State {
	return sessionReducer(state, action);
}
