import { CommonModule } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnInit,
	Optional,
	Output,
	Renderer2,
	SimpleChanges,
	ViewChild,
	ViewEncapsulation,
	isDevMode,
} from '@angular/core';
import { Params, Router, RouterLink } from '@angular/router';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import {
	LangChangeEvent,
	TranslateModule,
	TranslateService,
} from '@ngx-translate/core';
import { AccountFacade } from '@sunny-cars/data-access-account';
import { AffiliateFacade } from '@sunny-cars/data-access-affiliate';
import { SessionFacade } from '@sunny-cars/data-access-session';
import {
	ApplicationUrlEnvironment,
	HeaderOmitButton,
	Menu,
	MenuItem,
	MenuTypes,
	SupportedDomainsCapitalized,
	UrlHelper,
} from '@sunny-cars/util-global';
import { BaseComponent } from '@sunny-cars/util-global/lib/components/base.component';
import { LazyImageLoadingDirective } from '@sunny-cars/util-global/lib/directives/lazy-image/lazy-image.directive';
import { AffiliateCustomService } from '@sunny-cars/util-global/lib/helpers/affiliate-custom/affiliate-custom.service';
import { BaseUrlHelper } from '@sunny-cars/util-global/lib/helpers/base-url/base-url.helper';
import { ContactHoursHelper } from '@sunny-cars/util-global/lib/helpers/contact-hours/contact-hours.helper';
import { ExpertrecService } from '@sunny-cars/util-global/lib/helpers/expertrec/expertrec.service';
import { LocaleHelper } from '@sunny-cars/util-global/lib/helpers/locale/locale.helper';
import { PhoneNumberUrlHelper } from '@sunny-cars/util-global/lib/helpers/phone-number-url/phone-number-url.helper';
import { OpeningTimes } from '@sunny-cars/util-global/lib/interfaces/opening-hours/opening-hours.interface';
import { filterUndefined } from '@sunny-cars/util-global/lib/rxjs-operators/undefined-filter/undefined-filter';
import {
	Observable,
	Subject,
	combineLatest,
	filter,
	map,
	switchMap,
} from 'rxjs';
import { ContrastTextDirective } from '../contrast-text/contrast-text.directive';
import { IconComponent } from '../icon/icon.component';
import { LogoComponent } from '../logo/logo.component';
import { MainMenuComponent } from '../main-menu/main-menu.component';
import { PickerEntry } from '../picker/picker.component';
import { PopoverComponent } from '../popover/popover.component';

marker('languages.de-label');
marker('languages.en-label');
marker('languages.fr-label');
marker('languages.nl-label');
marker('languages.it-label');

@Component({
	selector: 'ui-header',
	templateUrl: './header.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		LogoComponent,
		ContrastTextDirective,
		RouterLink,
		PopoverComponent,
		LazyImageLoadingDirective,
		IconComponent,
		MainMenuComponent,
		TranslateModule,
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class HeaderComponent
	extends BaseComponent
	implements OnInit, OnChanges
{
	@ViewChild('languagePopover')
	languagePopover?: PopoverComponent;

	@Input() omitButtons: HeaderOmitButton[] = [];
	@Input() showUpActionButton = true;
	@Input() upActionRoute: string | unknown[] | undefined;
	@Input() upActionParams: Params | undefined;
	@Input() upActionLabel = '';
	@Input() displayServiceCode = false;
	@Input() hasNoRateLinkError$: Observable<boolean> | undefined;
	@Input() isLoadingRateLink$: Observable<boolean> | undefined;
	@Input() serviceCode$: Observable<string> | undefined;
	@Input() openingTimesLoaded$: Observable<boolean | undefined> | undefined;
	@Input() openingTimes$: Observable<OpeningTimes | undefined> | undefined;
	@Input() expertrecId?: string = '';
	@Input() useLanguageRoutes = false;
	@Input() languageFromRoute = '';
	@Input() languageRoutes?: { [key: string]: string }[];
	@Input() logoRedirectUrl$;

	@Output() isContactPopoverVisible: EventEmitter<boolean> =
		new EventEmitter<boolean>();
	@Output() shouldLoadServiceCode: EventEmitter<void> =
		new EventEmitter<void>();
	@Output() userLoggedOut: EventEmitter<void> = new EventEmitter<void>();

	headerMenu?: MenuItem[];
	useLegacyMySunny = !isDevMode();
	serviceCodeString = '';
	isMobileMenuOpen = false;
	languageEntries: PickerEntry[] = [
		{
			label: 'En',
			value: 'en',
			labelShort: 'EN',
			path: this.getLanguagePageUrl('en'),
		},
		{
			label: 'Nl',
			value: 'nl',
			labelShort: 'NL',
			path: this.getLanguagePageUrl('nl'),
		},
	];
	selectedLanguage: PickerEntry | undefined;
	legacyContactUrl = this.baseUrlHelper.getLegacyBaseUrl();
	phoneHelper = PhoneNumberUrlHelper;
	mySunnyUrl = this.urlHelper.getApplicationUrlForDomainAndLanguage(
		this.applicationUrls.mySunnyUrl,
		this.domain,
		this.translate.currentLang,
		this.envLanguages?.default || this.domain.toLowerCase(),
		undefined,
		undefined,
		false,
	);
	baseUrl = '';

	openingTimesLabel$: Observable<string> | undefined;
	isCustomAffiliate$ = this.affiliateCustomService.customAffiliate$;
	session$ = this.sessionStore.sessionID$;
	account$ = this.accountStore.account$;
	phoneNumber$: Observable<string> =
		this.affiliateStore.phoneNumberForLocaleLanguage();
	phoneNumberInfo$: Observable<string> =
		this.affiliateStore.phoneNumberInfoForLocaleLanguage();
	email$: Observable<string> =
		this.affiliateStore.emailAddressForLocaleLanguage();
	logo$ = this.affiliateStore.logo$;
	displayServiceCode$ = this.affiliateStore.displayServiceCode();
	shouldLoadServiceCode$ = new Subject<boolean>();
	showAffiliateContactDetails$ = combineLatest([
		this.affiliateStore.showCustomContactInfo$,
		this.isCustomAffiliate$,
	]).pipe(
		map(
			([showCustomContactInfo, isCustomAffiliate]) =>
				isCustomAffiliate && showCustomContactInfo,
		),
	);

	@Input()
	set menus(menus: Menu[] | undefined) {
		this.assignHeaderMenu(menus);
	}

	/**
	 * Set language entries based on given languages
	 */
	@Input()
	set availableLanguages(value: string[]) {
		this.languageEntries = value.map((language: string) => ({
			label: language.toUpperCase(),
			value: language,
			labelShort: language.toUpperCase(),
			path: this.getLanguagePageUrl(language),
		}));
		this.languageEntries.forEach((languageEntry: PickerEntry) => {
			if (languageEntry.value === this.localeHelper.language) {
				this.selectedLanguage = languageEntry;
			}
		});
	}

	/**
	 * Get languages from available entries
	 */
	get availableLanguages(): string[] {
		return this.languageEntries.map(
			(entry: PickerEntry) => entry.value as string,
		);
	}

	constructor(
		private readonly translate: TranslateService,
		private readonly localeHelper: LocaleHelper,
		private readonly baseUrlHelper: BaseUrlHelper,
		private readonly contactHoursHelper: ContactHoursHelper,
		private readonly affiliateStore: AffiliateFacade,
		private readonly sessionStore: SessionFacade,
		private readonly accountStore: AccountFacade,
		private readonly affiliateCustomService: AffiliateCustomService,
		private readonly expertrecService: ExpertrecService,
		private readonly router: Router,
		private renderer2: Renderer2,
		private readonly urlHelper: UrlHelper,
		@Inject('applicationUrls')
		private readonly applicationUrls: ApplicationUrlEnvironment,
		@Inject('countryCode')
		private readonly domain: SupportedDomainsCapitalized,
		@Optional()
		@Inject('envLanguages')
		private readonly envLanguages?: {
			availableLanguages: string[];
			default: string;
		},
		@Optional()
		@Inject('applicationBaseUrl')
		private readonly applicationBaseUrl?: string,
	) {
		super();

		this.translate
			.stream('components.header.legacy-contact-uri')
			.subscribe((contactUri: string) => {
				this.legacyContactUrl = this.baseUrlHelper.getLegacyBaseUrl(contactUri);
			});

		this.translate.onLangChange.subscribe(() => {
			this.mySunnyUrl = this.urlHelper.getApplicationUrlForDomainAndLanguage(
				this.applicationUrls.mySunnyUrl,
				this.domain,
				this.translate.currentLang,
				this.envLanguages?.default || this.domain.toLowerCase(),
				undefined,
				undefined,
				false,
			);
		});

		this.logoRedirectUrl$ = combineLatest([
			this.affiliateStore.logoUrl$.pipe(filterUndefined),
			this.isCustomAffiliate$,
		]).pipe(
			map(([redirectUrl, isCustomAffiliate]) => {
				if (!isCustomAffiliate) {
					return this.baseUrlHelper.getLegacyBaseUrl();
				}
				return redirectUrl;
			}),
		);
		this.detectActiveLanguage();
		this.setupShouldLoadServiceCodeEmitter();
		this.baseUrl = this.applicationBaseUrl || '';
	}

	ngOnInit(): void {
		this.subscribeToServiceCode();
		this.subscribeToOpeningTimes();

		if (this.expertrecId) {
			this.expertrecService.setExpertrecScript(
				this.renderer2,
				this.expertrecId,
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['languageRoutes']) {
			this.languageEntries.forEach((entry) => {
				entry['path'] =
					this.baseUrl + this.getLanguagePageUrl(`${entry.value}`);
			});
		}

		if (this.useLanguageRoutes && changes['languageFromRoute']?.currentValue) {
			const language = changes['languageFromRoute'].currentValue;
			this.selectedLanguage = {
				label: language.charAt(0).toUpperCase() + language.slice(1),
				value: language.toLowerCase(),
				labelShort: language.toUpperCase(),
			};
		}
	}

	logout() {
		this.sessionStore.logout('user logout');
		this.userLoggedOut.emit();
	}

	private assignHeaderMenu(menus: Menu[] | undefined): void {
		if (!menus) {
			return;
		}

		this.headerMenu = menus.find(
			(menu) => menu.type === MenuTypes.MULTILEVEL,
		)?.items;

		if (!this.headerMenu) {
			this.headerMenu = menus.find(
				(menu) => menu.type === MenuTypes.MAIN,
			)?.items;
		}
	}

	/**
	 * project isCustomAffiliate upon isContactPopoverVisible
	 */
	private setupShouldLoadServiceCodeEmitter(): void {
		const isNonAffiliate$ = this.showAffiliateContactDetails$.pipe(
			filter((isCustomAffiliate) => isCustomAffiliate === false),
		);

		this.addSubscription(
			this.shouldLoadServiceCode$
				.pipe(
					filter((isVisible) => isVisible === true),
					switchMap(() => isNonAffiliate$),
				)
				.subscribe(() => {
					this.shouldLoadServiceCode.emit();
				}),
		);
	}

	subscribeToServiceCode(): void {
		if (!this.serviceCode$ || !this.openingTimes$) {
			return;
		}
		this.addSubscription(
			combineLatest([
				this.serviceCode$,
				this.openingTimes$,
				this.email$,
				this.isCustomAffiliate$,
			]).subscribe(
				([serviceCode, openingTimes, emailAddress, isCustomAffiliate]) => {
					this.buildServiceCodeString(
						serviceCode,
						openingTimes,
						emailAddress,
						isCustomAffiliate,
					);
				},
			),
		);
	}

	subscribeToOpeningTimes(): void {
		if (!this.openingTimes$) {
			return;
		}
		this.openingTimes$.subscribe((openingTimes) => {
			this.openingTimesLabel$ =
				this.contactHoursHelper.getTodaysContactLabel(openingTimes);
		});
	}

	triggerContactPopoverVisible(isVisible: boolean): void {
		this.shouldLoadServiceCode$.next(isVisible);
		this.isContactPopoverVisible.emit(isVisible);
	}

	/**
	 * Detect active language
	 */
	detectActiveLanguage(): void {
		this.languageEntries.forEach((languageEntry: PickerEntry) => {
			if (languageEntry.value === this.localeHelper.language) {
				this.selectedLanguage = languageEntry;
			}
		});
		this.addSubscription(
			this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
				this.languageEntries.forEach((languageEntry: PickerEntry) => {
					if (languageEntry.value === event.lang) {
						this.selectedLanguage = languageEntry;
					}
				});
			}),
		);
	}

	selectLanguage(entry: PickerEntry): void {
		if (!this.useLanguageRoutes) {
			this.localeHelper.language = entry.value as string;
			this.languagePopover?.close();
		}
	}

	getLanguagePageUrl(language: string): string {
		const entryValue = `${language}`.toLowerCase();
		const matchedRoute = this.languageRoutes?.find((route) => {
			return route[entryValue] !== undefined;
		});

		const route = matchedRoute ? matchedRoute[entryValue] : '/';
		return route;
	}

	/**
	 * Builds the service code string based on the customer service contact hours
	 */
	buildServiceCodeString(
		serviceCode: string,
		openingTimes: OpeningTimes | undefined,
		emailAddress: string,
		isCustomAffiliate: boolean,
	): void {
		const withinHours =
			this.contactHoursHelper.isCustomerServiceOpen(openingTimes);
		const emailAddressString = `<a href="mailto:${emailAddress}" class="underline">${emailAddress}</a>`;
		const serviceCodeTranslation = isCustomAffiliate
			? this.translate.instant(
					'components.header.service-code-lowercase-affiliate',
				)
			: this.translate.instant('components.header.service-code-lowercase');
		const serviceCodeStringSnippet = `<strong>${serviceCodeTranslation}: ${serviceCode}</strong>`;

		if (withinHours && openingTimes) {
			if (isCustomAffiliate) {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-within-hours-affiliate',
					{
						untilTime: openingTimes.endTime,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			} else {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-within-hours',
					{
						untilTime: openingTimes.endTime,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			}
		} else if (openingTimes) {
			if (isCustomAffiliate) {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-outside-hours-affiliate',
					{
						fromTime: openingTimes.startTime,
						emailAddress: emailAddressString,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			} else {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-outside-hours',
					{
						fromTime: openingTimes.startTime,
						emailAddress: emailAddressString,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			}
		} else {
			if (isCustomAffiliate) {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-closed-affiliate',
					{
						emailAddress: emailAddressString,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			} else {
				this.serviceCodeString = this.translate.instant(
					'components.header.service-code-closed',
					{
						emailAddress: emailAddressString,
						serviceCode: serviceCodeStringSnippet,
					},
				);
			}
		}
	}

	mobileClosedHandler(): void {
		this.isMobileMenuOpen = false;
	}
}
