import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, TransferState, makeStateKey } from '@angular/core';
import { BaseComponent } from '@sunny-cars/util-global/lib/components/base.component';
import { SourceType } from '@sunny-cars/util-global/lib/interfaces/source/source.interface';
import { AffiliateVersion } from '@sunny-cars/util-global/lib/types/affiliate/version.type';
import { BehaviorSubject, Observable, of, tap } from 'rxjs';
import { APIAffiliateResponse } from './affiliate.interface';

export interface AffiliateCache {
	[key: string]: APIAffiliateResponse;
}
export const AFFILIATE_CACHE_KEY = makeStateKey<AffiliateCache | null>(
	'affiliateState',
);

/**
 * @deprecated because of the affiliatesService
 */
@Injectable({
	providedIn: 'root',
})
export class AffiliateService extends BaseComponent {
	isRequestInProgress = false;
	affiliateResponse$ = new BehaviorSubject<APIAffiliateResponse | null>(null);
	affiliateError$ = new BehaviorSubject<HttpErrorResponse | null>(null);

	constructor(
		private readonly http: HttpClient,
		private readonly transferState: TransferState,
		@Inject('apiBaseUrl')
		private readonly apiBaseUrl: string,
	) {
		super();
	}

	/**
	 * Get affiliate data from API
	 */
	tryGetAffiliateUsingApi(
		affiliateKey: number,
		styleProfile: number | undefined,
		contentProfile: number | undefined,
		affiliateVersion: AffiliateVersion | undefined,
		source: SourceType,
	): void | boolean {
		if (this.isRequestInProgress) {
			return false;
		}
		if (this.affiliateResponse$.getValue()) {
			return false;
		}
		this.affiliateError$.next(null);
		this.isRequestInProgress = true;
		this.addSubscription(
			this.getRequest(
				affiliateKey,
				styleProfile,
				contentProfile,
				affiliateVersion,
				source,
			).subscribe({
				/**
				 * handle success
				 * @param {APIAffiliateResponse} affiliateResponse
				 * @returns {void}
				 */
				next: (affiliateResponse: APIAffiliateResponse) => {
					this.handleAffiliateResponse(affiliateResponse);
				},
				/**
				 * handle error
				 * @param {HttpErrorResponse} errorResponse
				 * @returns {void}
				 */
				error: (errorResponse: HttpErrorResponse): void => {
					this.handleAffiliateError(errorResponse);
				},
			}),
		);
	}

	/**
	 * Gets affiliate request
	 */
	private getRequest(
		affiliateKey: number,
		styleProfile: number | undefined,
		contentProfile: number | undefined,
		version: string | undefined,
		source: SourceType,
	): Observable<APIAffiliateResponse> {
		const cache = this.transferState.get(AFFILIATE_CACHE_KEY, null);
		const cacheKey = affiliateKey;

		if (cache && cache[cacheKey]) {
			return of(cache[cacheKey]);
		}

		return this.http
			.get<APIAffiliateResponse>(
				`${this.apiBaseUrl}v1/affiliates/${affiliateKey}`,
				{
					params: {
						source,
						...(styleProfile ? { styleprofile: styleProfile } : {}),
						...(contentProfile ? { contentprofile: contentProfile } : {}),
						...(version ? { version } : {}),
					},
				},
			)
			.pipe(
				tap((response: APIAffiliateResponse) => {
					this.transferState.set(AFFILIATE_CACHE_KEY, {
						...cache,
						[cacheKey]: response,
					});
				}),
			);
	}

	/**
	 * Handles Affiliate Response
	 * @param {APIAffiliateResponse} affiliateResponse
	 * @returns {void}
	 */
	private handleAffiliateResponse(
		affiliateResponse: APIAffiliateResponse,
	): void {
		this.isRequestInProgress = false;
		this.affiliateResponse$.next(affiliateResponse);
		this.affiliateError$.next(null);
	}

	/**
	 * Handles Affiliate Error
	 * @param {HttpErrorResponse} errorResponse
	 * @returns {void}
	 */
	private handleAffiliateError(errorResponse: HttpErrorResponse): void {
		this.isRequestInProgress = false;
		this.affiliateResponse$.next(null);
		this.affiliateError$.next(errorResponse);
	}
}
