import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Inject,
	Input,
	OnDestroy,
	Output,
	PLATFORM_ID,
	ViewEncapsulation,
} from '@angular/core';
import { MenuItem } from '@sunny-cars/util-global';
import {
	fromEvent,
	map,
	NEVER,
	Observable,
	Subject,
	takeUntil,
	throttleTime,
} from 'rxjs';
import { MainMenuDesktopComponent } from './desktop/main-menu-desktop.component';
import { MainMenuMobileComponent } from './mobile/main-menu-mobile.component';

@Component({
	selector: 'ui-main-menu',
	templateUrl: './main-menu.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, MainMenuDesktopComponent, MainMenuMobileComponent],
})
export class MainMenuComponent implements OnDestroy {
	@Input() isMobile = false;
	@Input() items?: MenuItem[] = [];
	@Input() wrapperStyleModifiers = '';
	@Output() closed: EventEmitter<void> = new EventEmitter();

	private readonly onDestroy$: Subject<void> = new Subject();
	readonly onScroll$: Observable<void> = this.constructOnScrollObservable();

	constructor(@Inject(PLATFORM_ID) public platformId: string) {
		this.onScroll$ = this.constructOnScrollObservable();
	}

	ngOnDestroy(): void {
		this.onDestroy$.next(void 0);
		this.onDestroy$.complete();
	}

	private constructOnScrollObservable(): Observable<void> {
		if (isPlatformBrowser(this.platformId)) {
			return fromEvent(window, 'scroll').pipe(
				throttleTime(1000),
				map(() => void 0),
				takeUntil(this.onDestroy$),
			);
		} else {
			return NEVER;
		}
	}

	closeHandler(): void {
		this.closed.emit();
	}
}
