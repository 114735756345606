import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { APICountry } from '../interfaces';
import { APICountriesResponse } from './countries.interface';

@Injectable({
	providedIn: 'root',
})
export class CountryService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	fetchCountries(hasRegions = false): Observable<APICountriesResponse> {
		return this.http.get<APICountriesResponse>(`${this.endpoint}v1/countries`, {
			params: { ...(hasRegions ? { hasregions: hasRegions } : {}) },
		});
	}

	getCountries(hasRegions = false): Observable<APICountry[]> {
		return this.fetchCountries(hasRegions).pipe(
			map(
				(countriesResponse: APICountriesResponse): APICountry[] =>
					countriesResponse.data || [],
			),
		);
	}
}
