import { InjectionToken } from '@angular/core';

/**
 * Token for the storage reducers
 */
export const RECEIPT_META_REDUCERS = new InjectionToken<string[]>(
	'receiptStoreMetaReducers',
);
/**
 * Token for the storage key
 */
export const RECEIPT_STORAGE_KEY = new InjectionToken<string[]>(
	'receiptStoreKey',
);
/**
 * Token for the storage keys
 */
export const RECEIPT_STORE_KEYS = new InjectionToken<string[]>(
	'receiptStoreKeys',
);
