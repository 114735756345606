import { Injectable } from '@angular/core';
import { Actions, OnInitEffects, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { SessionFacade } from '@sunny-cars/data-access-session';
import {
	APIGetProfileRequestParams,
	ProfileService,
} from '@sunny-cars/provider-account-service';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import { of } from 'rxjs';
import { catchError, map, switchMap, withLatestFrom } from 'rxjs/operators';
import * as AccountActions from './account.actions';

@Injectable()
export class AccountEffects implements OnInitEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.loadAccount),
			withLatestFrom(this.sessionStore.sessionID$),
			switchMap(
				([action, token]: [
					{
						params: APIGetProfileRequestParams;
					},
					string | undefined,
				]) => {
					return this.profileService
						.getProfile(action.params, token || '')
						.pipe(
							map((response: AccountData) => {
								if (response) {
									return AccountActions.loadAccountSuccess({
										account: response,
									});
								}
								return AccountActions.loadAccountFailure({
									error: 'No data returned',
								});
							}),
							catchError(() => {
								return of(
									AccountActions.loadAccountFailure({
										error: 'Request failed',
									}),
								);
							}),
						);
				},
			),
		),
	);

	updateAccount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AccountActions.updateAccount),
			switchMap(({ account, affiliateKey, source }) =>
				this.profileService.editProfile(account, affiliateKey, source).pipe(
					map((response) => {
						if (response) {
							return AccountActions.updateAccountSuccess({
								account: response,
							});
						}
						return AccountActions.updateAccountFailure({
							error: 'No data returned',
						});
					}),
					catchError(() =>
						of(
							AccountActions.updateAccountFailure({
								error: 'Request failed',
							}),
						),
					),
				),
			),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly profileService: ProfileService,
		private readonly sessionStore: SessionFacade,
	) {}

	ngrxOnInitEffects(): Action {
		return AccountActions.init();
	}
}
