import { InjectionToken } from '@angular/core';

/**
 * Token for the storage reducers
 */
export const NAVIGATION_META_REDUCERS = new InjectionToken<string[]>(
	'navigationStoreMetaReducers',
);
/**
 * Token for the storage key
 */
export const NAVIGATION_STORAGE_KEY = new InjectionToken<string[]>(
	'navigationStoreKey',
);
/**
 * Token for the storage keys
 */
export const NAVIGATION_STORE_KEYS = new InjectionToken<string[]>(
	'navigationStoreKeys',
);
