import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Sizes } from '@sunny-cars/util-global';
import { ImageInterface } from '@sunny-cars/util-global/lib/interfaces/content/image-type.interface';
import { LinkInterface } from '@sunny-cars/util-global/lib/interfaces/content/link-type.interface';
import { UspInterface } from '@sunny-cars/util-global/lib/interfaces/content/usp.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { IconComponent } from '../icon/icon.component';
import { ImageComponent } from '../image/image.component';
import { Rating, RatingsComponent } from '../ratings/ratings.component';

interface Promotion {
	title: string;
	subtitle: string;
	description: string;
}

@Component({
	selector: 'ui-hero',
	templateUrl: 'hero.component.html',
	standalone: true,
	imports: [
		CommonModule,
		ImageComponent,
		HeadingContentBlockComponent,
		IconComponent,
		RouterLink,
		RatingsComponent,
	],
})
export class HeroComponent {
	@Input() backgroundImage?: ImageInterface;
	@Input() hasEmphasizedHeading = false;
	@Input() title?: string;
	@Input() subtitle?: string;
	@Input() link?: LinkInterface;
	@Input() promotion?: Promotion;
	@Input() usps?: UspInterface[];
	@Input() reviews?: Rating[];
	@Input() reviewsCenterAligned?: boolean;
	@Input() headingType: HeadingLevels = HeadingLevels.H1;

	readonly sizes = Sizes;

	sortUsps(usps: UspInterface[]) {
		return usps.sort((a, b) => a.sortOrder - b.sortOrder);
	}
}
