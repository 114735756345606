import {
	CommonModule,
	CurrencyPipe,
	registerLocaleData,
} from '@angular/common';
import localeBE from '@angular/common/locales/be';
import localeDE from '@angular/common/locales/de';
import localeAT from '@angular/common/locales/de-AT';
import localeCH from '@angular/common/locales/de-CH';
import localeEN from '@angular/common/locales/en';
import localeFR from '@angular/common/locales/fr';
import localeIT from '@angular/common/locales/de-IT'
import localeNL from '@angular/common/locales/nl';
import { NgModule, ɵLocaleDataIndex } from '@angular/core';
import { SunnyCurrencyPipe } from './pipes/currency/sunny-currency.pipe';
import { DateTimePipe } from './pipes/date-time/date-time.pipe';
import { ErrorExistsPipe } from './pipes/error/error.pipe';
import { PropertyDescriptionPipe } from './pipes/property-description/property-description.pipe';
import { PropertyIconPipe } from './pipes/property-icon/property-icon.pipe';
import { PropertyLabelPipe } from './pipes/property-label/property-label.pipe';
import { PropertyTooltipPipe } from './pipes/property-tooltip/property-tooltip.pipe';
import { RoundNumberPipe } from './pipes/round-number/round-number.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { SafeUrlPipe } from './pipes/safe-url/safe-url.pipe';

/* Ugly fix to set the currency code to symbol */
/* eslint-disable @typescript-eslint/no-explicit-any */
(localeNL as any)[ɵLocaleDataIndex.Currencies].USD = ['$'];
(localeFR as any)[ɵLocaleDataIndex.Currencies].USD = ['$'];
(localeCH as any)[ɵLocaleDataIndex.Currencies].EUR = ['€'];
/* eslint-enable @typescript-eslint/no-explicit-any */

registerLocaleData(localeNL);
registerLocaleData(localeDE);
registerLocaleData(localeBE);
registerLocaleData(localeFR);
registerLocaleData(localeEN);
registerLocaleData(localeAT, 'at');
registerLocaleData(localeCH, 'ch');
registerLocaleData(localeIT, 'it');

/**
 * Global utilities module
 */
@NgModule({
	imports: [
		CommonModule,
		DateTimePipe,
		ErrorExistsPipe,
		PropertyDescriptionPipe,
		PropertyIconPipe,
		PropertyLabelPipe,
		PropertyTooltipPipe,
		RoundNumberPipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		SunnyCurrencyPipe,
	],
	exports: [
		DateTimePipe,
		ErrorExistsPipe,
		PropertyDescriptionPipe,
		PropertyIconPipe,
		PropertyLabelPipe,
		PropertyTooltipPipe,
		RoundNumberPipe,
		SafeHtmlPipe,
		SafeUrlPipe,
		SunnyCurrencyPipe,
	],
	providers: [CurrencyPipe],
})
export class UtilGlobalModule {}
