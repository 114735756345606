import { createAction, props } from '@ngrx/store';
import { APIReceiptRequestBody } from '@sunny-cars/provider-receipt-service';
import { ErrorMessageResponse } from '@sunny-cars/util-global';
import { OrderReceiptWithToken } from './receipt.interface';

/**
 * Init
 */
export const init = createAction('[Receipt] Init Receipt');

/**
 * Load
 */
export const loadReceipt = createAction(
	'[Receipt/API] Load Receipt',
	props<{
		body: APIReceiptRequestBody;
	}>(),
);

/**
 * Load success
 */
export const loadReceiptSuccess = createAction(
	'[Receipt/API] Load Receipt Success',
	props<OrderReceiptWithToken>(),
);

/**
 * Load failure
 */
export const loadReceiptFailure = createAction(
	'[Receipt/API] Load Products Failure',
	props<{ errors: ErrorMessageResponse[] }>(),
);

export const setFilterLocationId = createAction(
	'[Receipt] Set filter location id',
	props<{ locationId: number | undefined }>(),
);

/**
 * Clear receipt error
 */
export const clearError = createAction('[Receipt] Clear error');

/**
 * Clear receipt
 */
export const clear = createAction('[Receipt] Clear');

/**
 * Clear token
 */
export const clearToken = createAction('[Receipt] Clear token');

export const hidePriceChangeDialog = createAction(
	'[Receipt] Hide Price Change Dialog',
);

export const showPriceChangeDialog = createAction(
	'[Receipt] Show Price Change Dialog',
);
