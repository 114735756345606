import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, TransferState, makeStateKey } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { APILocationsRequestParams } from '../locations/locations.interface';
import { APILocationsResponse } from './locations.interface';

export interface LocationsCache {
	[key: string]: {
		params: APILocationsRequestParams;
		response: APILocationsResponse;
	};
}
export const LOCATIONS_CACHE_KEY = makeStateKey<LocationsCache | null>(
	'locationsState',
);

/**
 * Locations service
 */
@Injectable({
	providedIn: 'root',
})
export class LocationsService {
	constructor(
		private readonly http: HttpClient,
		private readonly transferState: TransferState,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	/**
	 * Fetch locations from API
	 * @param {APILocationsRequestParams} params
	 * @returns {Observable<APILocationsResponse>}
	 */
	fetchLocations(
		params: APILocationsRequestParams,
	): Observable<APILocationsResponse> {
		const cache = this.transferState.get(LOCATIONS_CACHE_KEY, null);
		const cacheKey = JSON.stringify(params);
		if (
			cache &&
			cache[cacheKey] &&
			JSON.stringify(cache[cacheKey].params) === JSON.stringify(params)
		) {
			return of(cache[cacheKey].response);
		}

		return this.http
			.get<APILocationsResponse>(`${this.endpoint}v1/locations`, {
				params: {
					...params,
				},
			})
			.pipe(
				tap((response: APILocationsResponse) =>
					this.transferState.set(LOCATIONS_CACHE_KEY, {
						...cache,
						[cacheKey]: {
							params,
							response,
						},
					}),
				),
			);
	}
}
