<article
	[ngClass]="{
		'sm:flex-row': isHorizontal,
		'max-w-card': useMaxWidth,
		'bg-ui-root shadow-md': elevated,
		'bg-ui-low': !elevated,
		'h-full': useFullHeight
	}"
	class="flex flex-col overflow-hidden rounded"
>
	<!-- link -->
	<ng-container *ngIf="link && link.href && !showButton; else cardContent">
		<a
			*ngIf="link.href && link.href.startsWith('http')"
			[class.sm:flex-row]="isHorizontal"
			class="flex w-full flex-col"
			[href]="link.href"
			[rel]="link.rel"
			[title]="link.title"
			[attr.target]="link.shouldOpenInNewTab ? '_blank' : null"
		>
			<ng-container *ngTemplateOutlet="cardContent"></ng-container>
		</a>
		<a
			*ngIf="link.href && !link.href.startsWith('http')"
			[class.sm:flex-row]="isHorizontal"
			class="flex w-full flex-col"
			[routerLink]="link.href"
			[rel]="link.rel"
			[title]="link.title"
		>
			<ng-container *ngTemplateOutlet="cardContent"></ng-container>
		</a>
	</ng-container>
	<ng-template #cardContent>
		<!-- media -->
		<picture
			[ngClass]="{
				'sm:w-1/3 sm:pb-0': isHorizontal
			}"
			class="relative w-full overflow-hidden pb-aspect-ratio-16/9"
			*ngIf="imageUrl"
		>
			<ui-image
				className="absolute top-0 left-0 h-full w-full object-cover"
				[sources]="[
					{
						min: null,
						max: sizes.LG,
						width: 440,
						height: 248
					},
					{
						min: sizes.LG,
						max: sizes.XL,
						width: 382,
						height: 215
					},
					{
						min: sizes.XL,
						max: null,
						width: 435,
						height: 245
					}
				]"
				[image]="{
					src: imageUrl,
					altText: imageAlt,
					width: 440,
					height: 248
				}"
			></ui-image>
		</picture>
		<!-- avatar or icon and text -->
		<div
			class="relative w-full p-4"
			[ngClass]="{
				'pr-10': link && !showButton
			}"
		>
			<div class="flex">
				<!-- avatar or icon -->
				<ui-image
					*ngIf="avatarImageUrl"
					class="mr-2 shrink-0"
					className="h-8 w-8 rounded-full object-cover"
					[image]="{
						src: avatarImageUrl,
						altText: '',
						width: 32,
						height: 32
					}"
				></ui-image>
				<ui-icon
					class="mr-3 h-5 w-5 shrink-0"
					classModifiers="h-5 w-5"
					*ngIf="!avatarImageUrl && icon"
					[name]="icon"
					customSize
				></ui-icon>
				<div class="max-w-lg">
					<!-- title & subtitle -->
					<p
						class="flex flex-col hyphens-auto [overflow-wrap:anywhere]"
						[class.mb-1]="text || (link && showButton)"
						*ngIf="title || subtitle"
					>
						<strong class="text-xl leading-5 text-secondary" *ngIf="title">
							{{ title }}
						</strong>
						<span class="text-sm leading-5 sm:text-base" *ngIf="subtitle">
							{{ subtitle }}
						</span>
					</p>
					<!-- text -->
					<div class="flex flex-col gap-3" *ngIf="text || (link && showButton)">
						<div
							*ngIf="text"
							[innerHtml]="text"
							class="gap-1 text-lg leading-tight"
						></div>
						<!-- button -->
						<a
							class="btn btn-secondary block w-fit max-w-fit bg-icon-chevron-right-white bg-icon bg-right-3-center bg-no-repeat pr-9 text-base font-semibold"
							*ngIf="link && link.href && showButton"
							[href]="link.href"
							[rel]="link.rel"
							[title]="link.title"
							[attr.target]="link.shouldOpenInNewTab ? '_blank' : null"
						>
							{{ link.text }}
						</a>
					</div>
				</div>
			</div>

			<!-- chevron -->
			<ui-icon
				[ngClass]="{
					'sm:top-1/2 sm:-translate-y-1/2': isHorizontal
				}"
				class="absolute right-4 top-5"
				*ngIf="link?.href && !showButton"
				color="secondary"
				inlined
				name="icon-chevron-right"
				sm
			>
			</ui-icon>
		</div>
	</ng-template>
</article>
