<form
	[formGroup]="radioGroupForm"
	class="flex flex-col sm:gap-3"
	data-test-class="radio-group"
>
	<div *ngFor="let option of options">
		<label class="flex h-6 items-center max-sm:min-h-mobile-input">
			<input
				[checked]="option.value === selectedValue"
				[value]="option.value"
				class="radio mr-1.5"
				data-test-class="radio-group-option"
				formControlName="value"
				type="radio"
			/>
			<span class="text-base">{{ option.label }}</span>
		</label>
	</div>
</form>
