<div class="flex flex-col gap-3 sm:flex-row sm:gap-4">
	<div
		class="flex flex-col gap-4"
		[ngClass]="{
			'sm:w-form-control': !isHiddenLabel && !isFullWidth,
			'w-full': isHiddenLabel || isFullWidth
		}"
	>
		<div class="flex flex-col gap-1">
			<div class="text-sm">
				<div
					class="flex-col justify-center text-base font-bold empty:hidden sm:flex"
					[ngClass]="{
						hidden: isHiddenLabel
					}"
				>
					<ng-content select="[ui-form-control-label]"></ng-content>
				</div>
				<ng-content select="[ui-form-control-context]"></ng-content>
			</div>
			<ng-content select="[ui-form-control-control]"></ng-content>
			<ng-content select="[ui-form-control-feedback]"></ng-content>
		</div>
	</div>
</div>
