import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { ImageInterface } from '@sunny-cars/util-global';
import { QuickInfo } from '@sunny-cars/util-global/lib/interfaces/content/destination-info.interface';
import { SafeHtmlPipe } from '@sunny-cars/util-global/lib/pipes/safe-html/safe-html.pipe';
import { QuickInfoComponent } from '../quick-info/quick-info.component';

@Component({
	selector: 'ui-destination-info',
	templateUrl: './destination-info.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [QuickInfoComponent, SafeHtmlPipe],
})
export class DestinationInfoComponent {
	@Input() runningHead = '';
	@Input() content = '';
	@Input() items: QuickInfo[] = [];
	@Input() image: ImageInterface | undefined;
	@Input() titleQuickInfo = '';
}
