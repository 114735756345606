import { InjectionToken } from '@angular/core';
import {
	CookieStorageService,
	LocalStorageService,
	SessionStorageService,
} from '@sunny-cars/provider-storage';

/**
 * Token for the storage reducers
 */
export const SESSION_META_REDUCERS = new InjectionToken<string[]>(
	'sessionStoreMetaReducers',
);
/**
 * Token for the storage key
 */
export const SESSION_STORAGE_KEY = new InjectionToken<string[]>(
	'sessionStoreKey',
);
/**
 * Token for the storage keys
 */
export const SESSION_STORE_KEYS = new InjectionToken<string[]>(
	'sessionStoreKeys',
);

/**
 * Token for the local or session storage type
 */
export const SESSION_STORE_TYPE_SERVICE = new InjectionToken<
	SessionStorageService | LocalStorageService | CookieStorageService
>('sessionStoreTypeService');
