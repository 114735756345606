import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {
	Inject,
	Injectable,
	Injector,
	Optional,
	PLATFORM_ID,
} from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { RESPONSE } from '../../../express.tokens';
import { PageEntityInterface } from '@sunny-cars/provider-cms-service/lib/page/interfaces/page.interface';
import { PageService } from '@sunny-cars/provider-cms-service/lib/page/page.service';
import { LocaleHelper, PageTypes } from '@sunny-cars/util-global';
import { filterUndefined } from '@sunny-cars/util-global/lib/rxjs-operators/undefined-filter/undefined-filter';
import { catchError, combineLatest, map, of, tap } from 'rxjs';
import { PageDataService } from '../../services/page-data/page-data.service';

@Injectable()
export class PageGuard implements CanActivate {
	constructor(
		private readonly pageService: PageService,
		private readonly pageDataService: PageDataService,
		private readonly localeHelper: LocaleHelper,
		@Inject(Injector) private readonly injector: Injector,
		@Inject(PLATFORM_ID) private platformId: string,
		@Optional()
		@Inject('isB2B')
		private readonly envIsB2B?: boolean,
	) {}

	canActivate(route: ActivatedRouteSnapshot) {
		const params = this.pageDataService.parseParams(route);

		this.localeHelper.language = params.language;

		this.pageDataService.fetchSiteData(params.language);
		if (!this.envIsB2B) {
			this.pageDataService.fetchMenuData(params.language);
			this.pageDataService.fetchContactData(params.language);
		}

		const siteData$ = this.pageDataService.siteData$.pipe(filterUndefined);
		const pageData$ = this.pageService
			.getPageByPath(params.slug, params.previewKey)
			.pipe(
				catchError((data) => {
					let errorPage: string = data.error?.redirect_to || '?error=500';
					const errorCode: number = data.error?.code || 500;

					if (isPlatformServer(this.platformId)) {
						const response = this.injector.get(RESPONSE);
						response.status(errorCode);
						if (errorCode >= 300 && errorCode < 400) {
							response.set('location', errorPage);
							return of(undefined);
						}
					} else if (isPlatformBrowser(this.platformId)) {
						if (errorCode >= 300 && errorCode < 400) {
							window.location.pathname = errorPage;
							return of(undefined);
						}
					}

					if (!errorPage.includes('error=')) {
						errorPage = `${errorPage}?error=${errorCode}`;
					}

					return this.pageService.getPageByPath(errorPage, params.previewKey);
				}),
				tap((response: PageEntityInterface | undefined) => {
					if (response?.type === PageTypes.DESTINATION_PAGE) {
						this.pageDataService.parseParams(route, true);
					}
					this.pageDataService.setPageData(response);
				}),
			);

		return combineLatest([siteData$, pageData$]).pipe(
			map(([siteData, pageData]) => !!siteData && !!pageData),
		);
	}
}
