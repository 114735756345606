import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ImageInterface } from '@sunny-cars/util-global/lib/interfaces/content/collection.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-image-collection',
	templateUrl: './image-collection.component.html',
	standalone: true,
	imports: [CommonModule, HeadingContentBlockComponent, ImageComponent],
})
export class ImageCollectionComponent implements OnInit {
	@Input() id = '';
	@Input() heading = '';
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() subheading = '';
	@Input() images: ImageInterface[] = [];

	height = 0;
	width = 0;
	// maxContentWidth equals to tailwind max-w-7.5xl
	maxContentWidth = 1344;

	ngOnInit(): void {
		this.width = Math.round(
			this.maxContentWidth / Math.max(this.images.length, 3),
		);
		let smallestImageHeight = Number.MAX_SAFE_INTEGER;

		this.images.forEach((image) => {
			const { height, width } = image.attributes.image;
			const ratio = height / width;
			if (height < smallestImageHeight) {
				smallestImageHeight = height;
				this.height = Math.round(this.width * ratio);
			}
		});
	}
}
