import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'formly-wrapper-label-w-full',
	template: `
		<div class="flex flex-auto gap-1">
			<label [attr.for]="field.key" *ngIf="props['label']">
				{{ props['translate'] ? (props['label'] | translate) : props['label'] }}
			</label>
			<label
				class="self-end text-base text-text-tertiary"
				[attr.for]="field.key"
				*ngIf="props['description']"
			>
				{{
					props['translate']
						? (props['description'] | translate)
						: props['description']
				}}
			</label>
		</div>
	`,
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class LabelDesriptionAppendWrapperComponent extends FieldWrapper {}
