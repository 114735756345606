import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { LayoutWidthClasses } from '@sunny-cars/util-global/lib/interfaces/content/layout-width-classes.interface';
import { LinkComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/navigation.interface';
import { PageLinkComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/page-links.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';

@Component({
	selector: 'ui-page-links',
	templateUrl: './page-links.component.html',
	standalone: true,
	imports: [CommonModule, HeadingContentBlockComponent, RouterLink],
})
export class PageLinksComponent {
	@Input() anchor = '';
	@Input() header = '';
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() links?: LinkComponentInterface[];
	@Input() pages?: PageLinkComponentInterface[];
	@Input() width: LayoutWidthClasses = 'w-full';
}
