<div class="flex">
	<button
		data-test-class="autocomplete-button"
		class="input min-w-fit text-lg min-h-mobile-input sm:min-h-input {{
			styleModifiers
		}}"
		type="button"
		(click)="open()"
		[disabled]="isDisabled"
	>
		<div class="p-0">
			<ng-container *ngIf="isLoading">
				<div data-test-class="loading">
					<ng-container *ngTemplateOutlet="skeletonTemplate"> </ng-container>
				</div>
			</ng-container>
			<ng-container *ngIf="!isLoading">
				<ng-container
					*ngTemplateOutlet="
						selectedTemplate;
						context: {
							entry: selectedEntry
						}
					"
				>
				</ng-container>
			</ng-container>
		</div>
	</button>
	<ng-content></ng-content>
</div>
<div
	class="z-50 max-sm:fixed max-sm:left-0 max-sm:top-0 max-sm:h-full max-sm:w-full max-sm:bg-modal-backdrop"
	(click)="close()"
	*ngIf="isOpen || openFullScreen"
></div>
<div
	class="autocomplete relative rounded-t-popover-2x"
	[ngClass]="{
		'is-open bottom-sheet-like-offset-xs max-h-sheet-like-xs xs:bottom-sheet-like-offset xs:max-h-sheet-like sm:bottom-0 sm:max-h-full':
			isOpen || openFullScreen
	}"
>
	<div class="autocomplete__header sticky top-0" #trigger>
		<ui-sheet-header
			[title]="title"
			(closed)="close()"
			[ngClass]="{ hidden: !openFullScreen }"
			class="sm:hidden"
		>
		</ui-sheet-header>
		<div
			[ngClass]="{
				'px-4 sm:px-0': openFullScreen
			}"
		>
			<!-- Disabling autocomplete is a long running issue. See: https://stackoverflow.com/questions/15738259/disabling-chrome-autofill -->
			<div class="relative sm:hidden">
				<input
					data-test-class="autocomplete-button-mobile-input"
					class="input w-full bg-right-3-center pr-7 text-lg"
					[ngClass]="{
						'input--invalid': isInvalid || errorEmpty,
						'input--valid': isValid,
						'bg-right-9-center !pr-14': searchValue || selectedValue,
						hidden: !isOpen
					}"
					[attr.value]="selectedValue"
					[autocomplete]="idSuffix"
					[attr.aria-expanded]="isOpen ? 'true' : 'false'"
					[attr.id]="controlId + idSuffix || null"
					[attr.name]="controlName"
					[placeholder]="placeholder || 'global.search-action' | translate"
					[disabled]="isDisabled"
					[(ngModel)]="selectedValue"
					(ngModelChange)="handleInput()"
					(click)="open()"
					(keydown.arrowup)="highlightPrevious($event)"
					(keydown.arrowdown)="highlightNext($event)"
					(keydown.enter)="selectHighlighted($event)"
					(keydown.escape)="close()"
					(keydown.shift.tab)="close()"
					(keydown.tab)="close()"
				/>
			</div>
		</div>
	</div>
	<div
		#results
		class="autocomplete__results z-40 min-w-full overflow-auto bg-white text-black sm:max-h-80 sm:rounded-popover sm:shadow-lg"
		[ngClass]="{
			'h-0': !isOpen
		}"
		data-test-class="autocomplete-button-results"
		aria-labelledby="autocomplete"
	>
		<ng-container *ngIf="!isLoading">
			<div class="sticky top-0 z-10 bg-white p-4 max-sm:hidden">
				<input
					data-test-class="autocomplete-button-input"
					class="input w-full bg-right-3-center pr-7 text-lg"
					[ngClass]="{
						'input--invalid': isInvalid || errorEmpty,
						'input--valid': isValid,
						'bg-right-9-center !pr-14': searchValue || selectedValue,
						hidden: !isOpen
					}"
					[attr.value]="selectedValue"
					[autocomplete]="idSuffix"
					[attr.aria-expanded]="isOpen ? 'true' : 'false'"
					[attr.id]="controlId + idSuffix || null"
					[attr.name]="controlName"
					[placeholder]="placeholder || 'global.search-action' | translate"
					[disabled]="isDisabled"
					[(ngModel)]="selectedValue"
					(ngModelChange)="handleInput()"
					(keydown.arrowup)="highlightPrevious($event)"
					(keydown.arrowdown)="highlightNext($event)"
					(keydown.enter)="selectHighlighted($event)"
					(keydown.escape)="close()"
					(keydown.shift.tab)="close()"
					(keydown.tab)="close()"
				/>
			</div>
			<div *ngIf="entries.length === 0">
				<ng-container *ngTemplateOutlet="emptyTemplate"> </ng-container>
			</div>
			<div
				*ngFor="let entry of entries | slice: 0 : renderLimit || entries.length"
				class="p-0"
				[ngClass]="{
					'hover:bg-selected-low': entry.value !== selectedEntry?.value,
					'selected bg-selected text-white':
						entry.value === selectedEntry?.value
				}"
				data-test-class="autocomplete-button-row"
				(click)="handleSelect(entry)"
			>
				<ng-container
					*ngTemplateOutlet="
						entryTemplate;
						context: {
							entry: entry,
							isSelected: entry.value === selectedEntry?.value
						}
					"
				>
				</ng-container>
			</div>
			<div
				*ngIf="renderLimit && entries.length > renderLimit"
				class="border-t border-ui-low bg-highlight-low p-3 text-base"
			>
				{{ 'components.picker.more-options-available-label' | translate }}
			</div>
		</ng-container>
		<div id="scrollEnd"></div>
		<div *ngIf="isLoadingMore" class="autocomplete__load-more px-3 py-2">
			<ui-icon
				classModifiers="d-block"
				name="icon-loader"
				inlined="true"
				color="ui-critical"
			></ui-icon>
		</div>
	</div>
</div>
