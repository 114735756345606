import { Injectable } from '@angular/core';
import { SupportedDomains } from '../../interfaces/domain/domain.interface';
import { EnvironmentService } from '../../services/environment/environment.service';

@Injectable({
	providedIn: 'root',
})
export class DomainHelper {
	private _siteDomain!: SupportedDomains;

	constructor(private readonly environmentService: EnvironmentService) {
		this.siteDomain =
			this.environmentService.config.domain.active.toLowerCase();
	}

	/**
	 * Get current site domain
	 * @returns {string}
	 */
	get siteDomain(): SupportedDomains {
		return this._siteDomain;
	}

	/**
	 * Switch site domain on demand, f.e. in the widget.
	 * @param {string} domain
	 * @returns {void}
	 */
	set siteDomain(domain: SupportedDomains | string | undefined) {
		this._siteDomain = (domain?.toLowerCase() ||
			this._siteDomain.toLowerCase()) as SupportedDomains;
	}
}
