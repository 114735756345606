<section>
	<div
		class="relative grid grid-cols-hero grid-rows-hero gap-x-3 xs:grid-cols-hero-xs sm:grid-cols-hero-sm"
	>
		<!-- Background color/image -->
		<div
			class="relative col-start-1 col-end-hero-full row-start-1 row-end-[span_4] bg-hero-gradient xs:col-end-hero-background hero-layout-desktop:row-end-[span_2] hero-layout-desktop:bg-hero-gradient-desktop"
			[ngClass]="{
				'after:absolute after:inset-0 after:h-full after:w-full after:bg-hero-image-overlay-gradient max-hero-layout-desktop:mb-32':
					backgroundImage
			}"
		>
			<ui-image
				*ngIf="backgroundImage"
				className="absolute w-full h-full object-cover"
				[sources]="[
					{
						min: null,
						max: sizes.HERO_LAYOUT_DESKTOP,
						width: 858,
						height: 825
					},
					{
						min: sizes.HERO_LAYOUT_DESKTOP,
						max: sizes.LG,
						width: 1040,
						height: 825
					},
					{
						min: sizes.LG,
						max: null,
						width: 1536,
						height: 536
					}
				]"
				[image]="{
					src: backgroundImage.src,
					altText: backgroundImage.alt,
					width: 1536,
					height: 536
				}"
			></ui-image>
		</div>
		<!-- Hero main (widget) -->
		<div
			class="z-10 col-start-1 col-end-hero-full row-start-2 row-end-[span_1] max-hero-layout-desktop:mb-3 xs:col-start-2 hero-layout-desktop:col-start-2 hero-layout-desktop:col-end-hero-2/3 hero-layout-desktop:row-end-[span_2]"
		>
			<div
				class="h-full rounded-small bg-ui-root p-5 shadow-md hero-layout-desktop:p-8"
			>
				<header *ngIf="title || subtitle" class="mb-3">
					<ui-heading-content-block
						[heading]="title"
						[subheading]="subtitle"
						[headingLevel]="headingType"
						[customHeadingClasses]="
							hasEmphasizedHeading
								? 'text-3xl text-secondary sm:text-5xl'
								: 'text-2xl sm:text-3xl'
						"
						[customSubHeadingClasses]="
							hasEmphasizedHeading
								? 'hidden sm:block'
								: 'font-normal prose mt-2 sm:prose-lg hidden sm:block'
						"
					></ui-heading-content-block>
				</header>
				<!-- Promotion Banner  -->
				<div
					*ngIf="
						promotion &&
						(promotion.title || promotion.subtitle || promotion.description)
					"
					class="my-3 hidden rounded-large border border-highlight-critical bg-highlight-high p-3 text-center sm:block"
				>
					<p
						class="text-2xl font-semibold uppercase leading-6 tracking-custom-promotion sm:text-3xl"
					>
						{{ promotion.title }}
					</p>
					<p *ngIf="promotion.subtitle" class="text-xl font-semibold">
						{{ promotion.subtitle }}
					</p>
					<div
						class="prose-text-black-sm leading-5"
						[innerHtml]="promotion.description"
					></div>
				</div>
				<ng-content></ng-content>
			</div>
		</div>
		<!-- Hero aside (usps) -->
		<div
			class="col-start-1 col-end-hero-full row-start-4 row-end-5 xs:col-start-2 hero-layout-desktop:col-start-hero-2/3 hero-layout-desktop:col-end-hero-1/3 hero-layout-desktop:row-start-2 hero-layout-desktop:row-end-2 hero-layout-desktop:-ml-3 hero-layout-desktop:min-h-hero"
		>
			<aside
				*ngIf="usps && usps.length"
				class="relative p-6 text-white hero-layout-desktop:mb-8"
				[ngClass]="{
					'rounded-none bg-black-transparent xs:rounded-large hero-layout-desktop:mt-8 hero-layout-desktop:rounded-l-none hero-layout-desktop:pb-6':
						backgroundImage
				}"
			>
				<div
					class="relative"
					*ngFor="let usp of sortUsps(usps)"
					[ngClass]="usp.sortOrder ? 'order-[' + usp.sortOrder + ']' : ''"
				>
					<ui-icon
						class="absolute left-0 top-0.5"
						name="icon-check-circle-solid"
						color="white"
					></ui-icon>
					<div class="mb-4 pl-6">
						<h2 class="text-xl font-bold leading-none text-currentcolor">
							{{ usp.title }}
						</h2>
						<p class="text-base leading-none">{{ usp.subtitle }}</p>
					</div>
				</div>
				<div *ngIf="link">
					<a
						*ngIf="link.href.startsWith('http')"
						class="underline"
						[href]="link.href"
						[rel]="link.rel"
						[title]="link.title"
						[attr.target]="link.shouldOpenInNewTab ? '_blank' : null"
					>
						{{ link.title }}
					</a>
					<a
						*ngIf="!link.href.startsWith('http')"
						class="underline"
						[routerLink]="link.href"
						[title]="link.title"
					>
						{{ link.title }}
					</a>
				</div>
			</aside>
		</div>
	</div>
	<ui-ratings
		*ngIf="reviews"
		[reviews]="reviews"
		[centerAligned]="reviewsCenterAligned"
	></ui-ratings>
</section>
