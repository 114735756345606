<article class="flex overflow-hidden rounded bg-ui-root shadow-md">
	<div class="relative w-full p-3 md:p-4">
		<div class="flex items-center">
			<ui-image
				*ngIf="image?.src"
				className="inline-block mr-3 md:mr-4 md:h-12 md:w-12 rounded-full object-cover w-8 h-8"
				[sources]="[
					{
						min: null,
						max: sizes.MD,
						height: 32,
						width: 32
					},
					{
						min: sizes.MD,
						max: null,
						height: 48,
						width: 48
					}
				]"
				[image]="{
					src: image?.src || '',
					altText: image?.alt,
					width: 48,
					height: 48
				}"
			></ui-image>
			<div class="w-full">
				<div *ngIf="content" class="grid gap-1">
					<div
						*ngIf="content"
						[innerHtml]="content"
						class="prose gap-1 text-lg"
					></div>
				</div>
				<div
					class="text-base leading-4"
					*ngIf="showOpeningHours && openingHours && openingHours.length"
				>
					<div
						*ngFor="
							let openingTimes of openingHours | slice : 0 : 3;
							let index = index
						"
					>
						<span *ngIf="index === 0">
							{{ 'global.today' | translate | titlecase }}:
						</span>
						<span *ngIf="index > 0">
							{{
								openingTimes.startDateTime
									| datetime : dateTimeFormats.DATE_WEEKDAY
									| titlecase
							}}:
						</span>
						{{
							'global.time'
								| translate
									: {
											time:
												openingTimes.startDateTime
												| datetime : dateTimeFormats.TIME
									  }
						}}
						-
						{{
							'global.time'
								| translate
									: {
											time:
												openingTimes.endDateTime
												| datetime : dateTimeFormats.TIME
									  }
						}}
					</div>
				</div>
			</div>
		</div>
	</div>
</article>
