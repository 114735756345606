<!-- Recursive list rendering -->
<ng-template #nestedListItem let-item let-level="level">
	<li>
		<a
			[ngClass]="{
				uppercase: level === 1 && item.children.length,
				'font-bold text-secondary-high':
					level < 3 && item.children && item.children.length > 0
			}"
			[routerLink]="path + slugify(item.name)"
			[style.padding-left]="level * 18 + 'px'"
			class="block cursor-pointer break-words border-b border-ui-moderate bg-icon-chevron-right bg-2.5 bg-right-4-center bg-no-repeat pb-2 pl-4 pr-11 pt-2.5 focus-within:bg-secondary-high focus-within:bg-icon-chevron-right-white focus-within:text-white hover:bg-secondary-low active:bg-secondary-moderate"
		>
			{{ item.name }}
		</a>
	</li>
	<ng-container *ngIf="item.children">
		<ng-container
			*ngFor="let child of item.children"
			[ngTemplateOutletContext]="{
				$implicit: child,
				level: level + 1
			}"
			[ngTemplateOutlet]="nestedListItem"
		>
		</ng-container>
	</ng-container>
</ng-template>

<!-- Display simple list if data is not nested -->
<ng-template #simpleRegionsList>
	<ul *ngIf="region.children" class="border-t border-ui-moderate py-4">
		<ng-container
			*ngFor="let item of region.children.slice(0, 5)"
			[ngTemplateOutletContext]="{
				$implicit: item,
				level: 1
			}"
			[ngTemplateOutlet]="nestedListItem"
		>
		</ng-container>
		<ng-container *ngIf="region.children.length > 5">
			<details class="[&[open]>summary]:hidden">
				<summary
					class="w-[calc(100% - 20px)] mx-2.5 mt-4 flex items-center justify-between rounded-button bg-ui-moderate px-4 py-2.5"
				>
					<span class="text-base font-bold">{{
						'components.region-map.show-all-locations-in'
							| translate: { location: region.name }
					}}</span>
					<span
						class="inline-block rounded-full bg-white px-2.5 py-0.5 text-xs"
						>{{ region.children.length }}</span
					>
				</summary>
				<ng-container
					*ngFor="let item of region.children.slice(5)"
					[ngTemplateOutletContext]="{
						$implicit: item,
						level: 1
					}"
					[ngTemplateOutlet]="nestedListItem"
				></ng-container>
			</details>
		</ng-container>
	</ul>
</ng-template>

<ng-container *ngIf="region.children?.length">
	<p class="px-6 py-4 text-base font-bold sm:text-2xl">
		{{ title }}
	</p>
	<div class="shadow-on-scroll">
		<!-- Display nested list -->
		<ng-container *ngIf="regionsAreNested; else simpleRegionsList">
			<details
				*ngFor="let link of region.children.slice(0, 5); index as index"
				class="border-t border-ui-critical bg-icon-point-down-ui-critical bg-3 bg-left-5-center bg-no-repeat last:border-b open:bg-icon-point-up-ui-critical open:bg-left-5-top"
			>
				<summary class="py-4 pl-12">
					<span class="text-xl">{{ link.name }}</span> <br />
					<span *ngIf="regionsCount" class="text-base leading-none">
						{{ regionsCount[index] }}
						{{ 'components.region-map.destinations' | translate | lowercase }}
					</span>
				</summary>
				<ul
					*ngIf="link.children as childLinks"
					class="border-t border-ui-moderate px-6 py-4"
				>
					<ng-container
						*ngFor="let link of [{ name: link.name, children: childLinks }]"
						[ngTemplateOutletContext]="{
							$implicit: link,
							level: 1
						}"
						[ngTemplateOutlet]="nestedListItem"
					>
					</ng-container>
				</ul>
			</details>
			<ng-container *ngIf="region.children.length > 5">
				<details class="[&[open]>summary]:hidden">
					<summary
						class="w-[calc(100% - 20px)] mx-2.5 mb-4 flex items-center justify-between rounded-button bg-ui-moderate px-4 py-2.5"
					>
						<span class="text-base font-bold">{{
							'components.region-map.show-all-locations-in'
								| translate: { location: region.name }
						}}</span>
						<span
							class="inline-block rounded-full bg-white px-2.5 py-0.5 text-xs"
							>{{ totalCount }}</span
						>
					</summary>
					<details
						*ngFor="let link of region.children.slice(5); index as index"
						class="border-t border-ui-critical bg-icon-point-down-ui-critical bg-3 bg-left-5-center bg-no-repeat last:border-b open:bg-icon-point-up-ui-critical open:bg-left-5-top"
					>
						<summary class="py-4 pl-12">
							<span class="text-xl">{{ link.name }}</span> <br />
							<span *ngIf="regionsCount" class="text-base leading-none">
								{{ regionsCount[index + 5] }}
								{{
									'components.region-map.destinations' | translate | lowercase
								}}
							</span>
						</summary>
						<ul
							*ngIf="link.children as childLinks"
							class="border-t border-ui-moderate px-6 py-4"
						>
							<ng-container
								*ngFor="let link of [{ name: link.name, children: childLinks }]"
								[ngTemplateOutletContext]="{
									$implicit: link,
									level: 1
								}"
								[ngTemplateOutlet]="nestedListItem"
							>
							</ng-container>
						</ul>
					</details>
				</details>
			</ng-container>
		</ng-container>
	</div>
</ng-container>
