<section class="max-w-3xl">
	<div
		*ngIf="image"
		class="relative h-fit-content w-full max-w-md before:block before:pb-aspect-ratio-1/1"
	>
		<ui-image
			className="absolute top-0 left-0 h-full w-full object-cover"
			[image]="{
				src: image.src,
				altText: image.alt,
				width: 448,
				height: 448
			}"
		></ui-image>
	</div>
	<div
		*ngIf="heading || subheading || usps"
		class="-top-28 -mt-28 min-h-28 px-3 xs:-top-36 xs:-mt-36 xs:min-h-36 xs:pl-1/10 xs:pr-1/6"
	>
		<div class="relative z-20 rounded bg-secondary p-6 text-white shadow-sm">
			<p *ngIf="heading" class="text-2xl font-bold leading-none">
				{{ heading }}
			</p>
			<p *ngIf="subheading" class="text-xl">{{ subheading }}</p>
			<ul *ngIf="usps" [ngClass]="{ 'pt-6': heading || subheading }">
				<li
					class="relative mb-4 text-lg last:mb-0"
					[ngClass]="{ 'pl-7': hasIcon }"
					*ngFor="let usp of usps"
				>
					<ui-icon
						customSize
						classModifiers="w-4.5 h-4.5"
						*ngIf="hasIcon"
						class="absolute left-0 top-1"
						name="icon-check-circle-solid"
						color="white"
					></ui-icon>
					{{ usp.attributes.text }}
				</li>
			</ul>
		</div>
	</div>
</section>
