import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Available dialog types
 */
export enum DialogTypes {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
}

@Component({
	selector: 'ui-dialog',
	templateUrl: './dialog.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class DialogComponent {
	/**
	 * Dialog type
	 */
	@Input() type: DialogTypes = DialogTypes.INFO;
	/**
	 * Whether to show an icon
	 */
	@Input() showIcon = true;
	/**
	 * Tailwind class for custom icon
	 */
	@Input() iconName = '';
	/**
	 * Dialog title
	 */
	@Input() title = '';
	/**
	 * Whether the dialog can be dismissed
	 */
	@Input() isDismissable = false;
	/**
	 * Style modifiers
	 */
	@Input() styleModifiers = '';
	/**
	 * Whether margin should be applied
	 */
	@Input() isPadded = true;

	/**
	 * Emit event on dismiss
	 */
	@Output() dismissed: EventEmitter<void> = new EventEmitter<void>();

	/**
	 * Exposes dialog types to template
	 */
	types = DialogTypes;

	constructor(private readonly element: ElementRef) {}

	/**
	 * Remove the dialog element completely
	 * @returns {void}
	 */
	removeDialog(): void {
		this.dismissed.emit();

		// It's not easy to mock elementRef, therefore we ignore this
		/* istanbul ignore next */
		const element: HTMLElement = this.element.nativeElement;
		/* istanbul ignore next */
		element.parentNode?.removeChild(element);
	}
}
