import { Component, Input } from '@angular/core';

@Component({
	selector: 'ui-wrapper',
	templateUrl: './full-width-wrapper.component.html',
	standalone: true,
})
export class WrapperComponent {
	@Input() backgroundColor = 'bg-secondary-low';
}
