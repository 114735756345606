import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Inject,
	Input,
	OnInit,
	Output,
	PLATFORM_ID,
	ViewChild,
} from '@angular/core';
import {
	FormsModule,
	NG_VALUE_ACCESSOR,
	NgForm,
	ReactiveFormsModule,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { AccountFacade } from '@sunny-cars/data-access-account';
import { ReceiptFacade } from '@sunny-cars/data-access-receipt';
import {
	APIPostalAddressRequest,
	APIPostalAddressResponse,
	InternationalAddressService,
	mapInternationalAddressRequest,
} from '@sunny-cars/provider-address';
import {
	APICountry,
	CountryService,
} from '@sunny-cars/provider-rental-office-service';
import {
	OrderReceipt,
	SupportedDomainsCapitalized,
} from '@sunny-cars/util-global';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import { BehaviorSubject, Observable, distinctUntilChanged, of } from 'rxjs';
import { debounceTime, filter, map, tap, withLatestFrom } from 'rxjs/operators';
import {
	AutocompleteComponent,
	AutocompleteEntry,
} from '../autocomplete/autocomplete.component';
import { BaseControlComponent } from '../base.control';
import { FormControlComponent } from '../form-control/form-control.component';
import { IconComponent } from '../icon/icon.component';
import { PostalAddressFormOrder } from './interfaces';
import { CountryAutocompleteEntry } from './interfaces/country-autocomplete.interface';

@Component({
	selector: 'ui-international-address-form',
	templateUrl: 'international-address-form.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: InternationalAddressFormComponent,
		},
	],
	standalone: true,
	imports: [
		CommonModule,
		IconComponent,
		FormsModule,
		ReactiveFormsModule,
		FormControlComponent,
		AutocompleteComponent,
		TranslateModule,
	],
})
export class InternationalAddressFormComponent
	extends BaseControlComponent
	implements OnInit, AfterViewInit
{
	postalCodeHouseNumberCountries = ['NL'];
	supportedAutocompleteCountries = [
		'NL',
		'BE',
		'DE',
		'LU',
		'AT',
		'CH',
		'FR',
		'GB',
	];

	@Input() isSubmitted = false;
	@Input() hasBoldLabels = false;
	@Input() isFullWidth = true;
	@Input() receiptStore?: ReceiptFacade;

	@Output()
	internationalAddressUpdated: EventEmitter<APIPostalAddressResponse> =
		new EventEmitter<APIPostalAddressResponse>();

	@ViewChild('internationalAddressForm')
	internationalAddressForm?: NgForm;

	@ViewChild('internationalAddressFormElement')
	internationalAddressFormElement?: ElementRef;

	@ViewChild('countryAutoCompleteElement')
	countryAutoCompleteElement?: AutocompleteComponent;

	isDefaultAddressFormOrder = true;
	countryOptions: CountryAutocompleteEntry[] = [];
	filteredCountryOptions: CountryAutocompleteEntry[] = [];
	hasFoundCountries = false;
	isLoggedIn$: Observable<boolean> = this.accountStore.loaded$;
	isVisibleLoading = false;
	postalAddressResponse: APIPostalAddressResponse = {
		city: '',
		country: this.domain,
		houseNumber: '',
		houseNumberAddition: '',
		postalCode: '',
		street: '',
	};

	defaultAddressFormOrder: PostalAddressFormOrder = {
		street: 1,
		houseNumberId: 2,
		postalId: 3,
		city: 4,
	};

	specialAddressFormOrder: PostalAddressFormOrder = {
		postalId: 1,
		houseNumberId: 2,
		street: 3,
		city: 4,
	};

	private _addressFormOrder$ = new BehaviorSubject<PostalAddressFormOrder>(
		this.defaultAddressFormOrder,
	);

	addressFormOrder$ = this._addressFormOrder$.asObservable().pipe(
		distinctUntilChanged(),
		tap(() => this.reorderAddressFormFields()),
	);
	isLoading = false;
	lastLoadedData?: APIPostalAddressRequest;

	constructor(
		private readonly accountStore: AccountFacade,
		private readonly countryService: CountryService,
		private readonly internationalAddressService: InternationalAddressService,
		@Inject('countryCode') private readonly domain: SupportedDomainsCapitalized,
		@Inject(PLATFORM_ID)
		private readonly platformId: string,
	) {
		super();
	}

	ngOnInit() {
		this.getCountries();
	}

	ngAfterViewInit() {
		this.listenToAddressFormValueChanges();
	}

	private updateAddressFormOrder(country?: string): void {
		if (!country) {
			return;
		}
		this.isDefaultAddressFormOrder = this.isDefaultAddressForm(country);
		this._addressFormOrder$.next(
			this.isDefaultAddressFormOrder
				? this.defaultAddressFormOrder
				: this.specialAddressFormOrder,
		);
	}

	private isDefaultAddressForm(country?: string): boolean {
		return !this.postalCodeHouseNumberCountries.includes(country || '');
	}

	override writeValue(postalAddressResponse: APIPostalAddressResponse): void {
		if (postalAddressResponse) {
			this.postalAddressResponse = postalAddressResponse;
		}
	}

	updateFilteredEntries(searchValue: string): void {
		if (!searchValue) {
			this.clearCountry();
			return;
		}
		this.postalAddressResponse.country = '';
		this.filteredCountryOptions = this.countryOptions.filter(
			(country: CountryAutocompleteEntry) => {
				const label = country.label.toLowerCase();
				return label.includes(searchValue.toLowerCase());
			},
		);

		if (this.countryAutoCompleteElement && this.filteredCountryOptions.length) {
			this.countryAutoCompleteElement.selectedEntry =
				this.filteredCountryOptions[0];
		}
	}

	clearCountry(): void {
		this.postalAddressResponse.country = '';
		this.filteredCountryOptions = this.countryOptions;
	}

	resetInternationalAddressForm(countryCode?: string): void {
		this.postalAddressResponse = {
			city: '',
			country: countryCode || this.domain,
			houseNumber: '',
			houseNumberAddition: '',
			postalCode: '',
			street: '',
		};
		this.internationalAddressUpdated.emit(this.postalAddressResponse);
	}

	mapNumberToString(
		autocompleteEntry: AutocompleteEntry,
	): CountryAutocompleteEntry {
		autocompleteEntry.value = String(autocompleteEntry.value);
		return {
			...autocompleteEntry,
			...(<CountryAutocompleteEntry>autocompleteEntry),
		};
	}

	selectedCountryEntryChanged(selectedCountry: CountryAutocompleteEntry): void {
		if (this.postalAddressResponse.country === selectedCountry.value) {
			return;
		}

		const selectedCountryHasDefaultAddressForm = this.isDefaultAddressForm(
			selectedCountry.value,
		);

		if (selectedCountryHasDefaultAddressForm) {
			if (this.isDefaultAddressFormOrder) {
				this.postalAddressResponse = {
					...this.postalAddressResponse,
					country: selectedCountry.value,
				};
				this.internationalAddressUpdated.emit(this.postalAddressResponse);
			} else {
				this.isDefaultAddressFormOrder = true;
				this.convertToDefaultAddressForm(selectedCountry.value);
			}
		} else {
			if (this.isDefaultAddressFormOrder) {
				this.isDefaultAddressFormOrder = false;
				this.resetInternationalAddressForm(selectedCountry.value);
			} else {
				this.postalAddressResponse = {
					...this.postalAddressResponse,
					country: selectedCountry.value,
				};
				this.internationalAddressUpdated.emit(this.postalAddressResponse);
			}
		}
	}

	orderFormFields(
		htmlElementA: HTMLElement,
		htmlElementB: HTMLElement,
	): number {
		if (
			!(htmlElementA && htmlElementA.id) ||
			!(htmlElementB && htmlElementB.id)
		) {
			return 0;
		}

		const HTMLElementAId: number = +htmlElementA.id;
		const HTMLElementBId: number = +htmlElementB.id;
		return HTMLElementAId - HTMLElementBId;
	}

	private getCountries(): void {
		this.addSubscription(
			this.countryService
				.getCountries()
				.pipe(
					map((countries: APICountry[]) =>
						this.createCountryAutocompleteList(countries),
					),
					tap((countriesAutocompleteEntries: CountryAutocompleteEntry[]) =>
						this.setFoundAndDefaultCountry(countriesAutocompleteEntries),
					),
				)
				.subscribe(
					(entries: CountryAutocompleteEntry[]) =>
						(this.filteredCountryOptions = entries),
				),
		);
	}

	/**
	 * Set found countries and default country
	 * @param {CountryAutocompleteEntry[]} countryOptions
	 * @returns {void}
	 */
	private setFoundAndDefaultCountry(
		countryOptions: CountryAutocompleteEntry[],
	): void {
		if (countryOptions.length > 0) {
			this.countryOptions = countryOptions;
			this.hasFoundCountries = true;
		}

		if (this.domain && !this.postalAddressResponse.country) {
			this.postalAddressResponse.country = this.domain;
		}
	}

	/**
	 * Create Country Autocomplete List
	 * @param {Country[]} countries
	 * @returns {CountryAutocompleteEntry[]}
	 */
	private createCountryAutocompleteList(
		countries: APICountry[],
	): CountryAutocompleteEntry[] {
		return countries
			.map(
				(entry: APICountry): CountryAutocompleteEntry => ({
					label: entry.name,
					value: entry.code,
				}),
			)
			.sort(
				(
					optionA: CountryAutocompleteEntry,
					optionB: CountryAutocompleteEntry,
				) => optionA.label.localeCompare(optionB.label),
			);
	}

	private isAutoCompleteCountrySupported(country?: string): boolean {
		return this.supportedAutocompleteCountries.includes(country || '');
	}

	private listenToAddressFormValueChanges(): void {
		if (!this.internationalAddressForm?.valueChanges) {
			return;
		}

		this.addSubscription(
			this.internationalAddressForm.valueChanges
				.pipe(
					tap((postalAddressRequest: APIPostalAddressRequest) =>
						this.updateAddressFormOrder(postalAddressRequest.country),
					),
					tap((data) => {
						if (this.internationalAddressForm?.valid) {
							const addressData: APIPostalAddressResponse = {
								city: data.city,
								country: data.country,
								houseNumber: data.houseNumber,
								houseNumberAddition: data.houseNumberAddition,
								postalCode: data.postalCode,
								street: data.street,
							};
							this.internationalAddressUpdated.emit(addressData);
						}
					}),
					debounceTime(1000),
					withLatestFrom(
						this.accountStore.account$,
						this.receiptStore?.receipt$ || of(undefined),
					),
					filter(
						([formData, accountData, orderReceipt]: [
							APIPostalAddressRequest,
							AccountData | null,
							OrderReceipt | undefined,
						]) => {
							if (
								(!this.isDefaultAddressFormOrder &&
									!formData.houseNumber?.length) ||
								!formData.postalCode?.length
							) {
								return false;
							}

							/* istanbul ignore next */
							const formHasAccountData =
								formData.city === accountData?.city &&
								formData.street === accountData?.street &&
								formData.postalCode === accountData?.postalcode;

							const streetData = [
								orderReceipt?.customer?.streetName,
								orderReceipt?.customer?.streetNumber,
								orderReceipt?.customer?.streetNumberAddition,
							]
								.filter(Boolean)
								.join(' ');

							/* istanbul ignore next */
							const formHasReceiptData =
								formData.city === orderReceipt?.customer?.city &&
								formData.street === streetData &&
								formData.postalCode === orderReceipt?.customer?.postalCode;

							return !(formHasAccountData || formHasReceiptData);
						},
					),
				)
				.subscribe(
					([postalAddressRequest]: [
						APIPostalAddressRequest,
						AccountData | null,
						OrderReceipt | undefined,
					]) => this.handleAddressFormValueChange(postalAddressRequest),
				),
		);
	}

	private handleAddressFormValueChange(
		postalAddressRequest: APIPostalAddressRequest,
	): void {
		if (
			JSON.stringify(this.lastLoadedData) ===
				JSON.stringify(postalAddressRequest) ||
			!this.isAutoCompleteCountrySupported(postalAddressRequest.country)
		) {
			return;
		}
		this.isLoading = true;
		this.lastLoadedData = postalAddressRequest;

		this.addSubscription(
			this.internationalAddressService
				.getInternationalPostAddress(
					mapInternationalAddressRequest(
						postalAddressRequest,
						this.isDefaultAddressFormOrder,
					),
				)
				.subscribe((postalAddressResponse: APIPostalAddressResponse) =>
					this.setAndEmitPostalAddressResponse(postalAddressResponse),
				),
		);
	}

	private setAndEmitPostalAddressResponse(
		postalAddressResponse: APIPostalAddressResponse,
	): void {
		this.isLoading = false;
		if (
			!postalAddressResponse ||
			Object.keys(postalAddressResponse).length === 0
		) {
			return;
		}

		// Ignore autocomplete for control if it was changed by user or is focussed
		// istanbul ignore next
		Object.keys(postalAddressResponse).forEach((key: string) => {
			if (
				this.internationalAddressForm?.controls &&
				(this.internationalAddressForm?.controls[key]?.value ||
					document.activeElement?.getAttribute('name') === key)
			) {
				postalAddressResponse[key as keyof APIPostalAddressResponse] =
					this.internationalAddressForm?.controls[key]?.value;
			}
		});

		postalAddressResponse.country = this.postalAddressResponse.country;
		this.postalAddressResponse = {
			...this.postalAddressResponse,
			...postalAddressResponse,
		};

		this.internationalAddressUpdated.emit(postalAddressResponse);
	}

	private reorderAddressFormFields(): void {
		if (isPlatformBrowser(this.platformId)) {
			// @NOTE: wait one tick to render
			setTimeout(() => {
				const form = this.internationalAddressFormElement?.nativeElement;
				if (!form) {
					return;
				}

				const arrayFormFields: HTMLElement[] = [...form.children];
				arrayFormFields
					.sort(this.orderFormFields)
					.forEach((htmlElement: HTMLElement) => form.appendChild(htmlElement));
			});
		}
	}

	private convertToDefaultAddressForm(countryCode: string): void {
		let street = this.postalAddressResponse.street;

		if (this.postalAddressResponse.houseNumber) {
			street +=
				' ' +
				this.postalAddressResponse.houseNumber +
				this.postalAddressResponse.houseNumberAddition;
		}

		this.postalAddressResponse = {
			...this.postalAddressResponse,
			country: countryCode,
			street,
			houseNumber: '',
			houseNumberAddition: '',
		};
		this.internationalAddressUpdated.emit(this.postalAddressResponse);
	}
}
