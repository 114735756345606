<ng-template #monthEntryTemplate let-data="entry">
	<div
		class="picker-entry--selected-checked min-w-max cursor-pointer bg-ui-root px-4 py-3 capitalize text-text-secondary hover:bg-selected-low"
		[attr.data-value]="data.value"
	>
		{{ (data.label$ | async) || data.label }}
	</div>
</ng-template>

<section class="max-w-optimized-reading text-xl">
	<ui-heading-content-block
		class="mb-3"
		*ngIf="heading"
		[heading]="heading"
		[headingLevel]="headingType"
	></ui-heading-content-block>
	@if (isServer) {
		<div class="text-center">
			<ui-icon inlined name="icon-loader"></ui-icon>
			<p class="text-sm">
				{{ 'components.register.form-loading-label' | translate }}
			</p>
		</div>
	} @else {
		<form
			class="text-base"
			id="register-form"
			[formGroup]="formGroup"
			#registerForm="ngForm"
			(ngSubmit)="submit($event)"
		>
			<formly-form
				[form]="formGroup"
				[fields]="fieldsBeforeAddress"
				[model]="model"
			></formly-form>
			<ui-international-address-form
				[isSubmitted]="registerForm.submitted"
				(internationalAddressUpdated)="addressUpdated($event)"
			></ui-international-address-form>
			<formly-form
				[form]="formGroup"
				[fields]="fieldsAfterAddress"
				[model]="model"
			></formly-form>
			<ui-dialog
				*ngIf="hasRegisterError"
				[type]="dialogTypes.ERROR"
				[title]="'components.register.register-failed-dialog-title' | translate"
			>
				{{ 'components.register.register-failed-dialog-text' | translate }}
			</ui-dialog>
			<ui-button
				[styleType]="buttonStyleTypes.PRIMARY_SOLID"
				[size]="buttonSizes.L"
				[isSubmit]="true"
				[isDisabled]="isLoading"
				[isWidthFull]="true"
			>
				{{ 'global.register' | translate }}
			</ui-button>
		</form>
	}
</section>
