import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
	providedIn: 'root',
})
export class SessionStorageService {
	constructor(@Inject(PLATFORM_ID) public platformId: string) {}

	setSavedState(state: any, storageKey: string): void {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.setItem(storageKey, JSON.stringify(state));
		}
	}

	getSavedState(storageKey: string): any {
		if (isPlatformBrowser(this.platformId)) {
			return JSON.parse(sessionStorage.getItem(storageKey) || '{}');
		}
		return {};
	}

	removeSavedState(storageKey: string): void {
		if (isPlatformBrowser(this.platformId)) {
			sessionStorage.removeItem(storageKey);
		}
	}
}
