import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocationsProviderModule } from '@sunny-cars/provider-rental-office-service';
import { CookieStorageService } from '@sunny-cars/provider-storage';
import { getStorageReducer } from '@sunny-cars/util-global';
import { SessionEffects } from './session.effects';
import { SessionFacade } from './session.facade';
import * as fromSession from './session.reducer';
import {
	SESSION_META_REDUCERS,
	SESSION_STORAGE_KEY,
	SESSION_STORE_KEYS,
	SESSION_STORE_TYPE_SERVICE,
} from './session.tokens';

/**
 * Session store module
 */
@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromSession.SESSION_FEATURE_KEY,
			fromSession.reducer,
			SESSION_META_REDUCERS,
		),
		EffectsModule.forFeature([SessionEffects]),
		LocationsProviderModule,
	],
	providers: [
		SessionFacade,
		{
			provide: SESSION_STORAGE_KEY,
			useValue: '__session_storage__',
		},
		{
			provide: SESSION_STORE_KEYS,
			useValue: ['sessionID', 'tokenType', 'id', 'expiresAt'],
		},
		{
			provide: SESSION_STORE_TYPE_SERVICE,
			useClass: CookieStorageService,
		},
		{
			deps: [
				SESSION_STORAGE_KEY,
				SESSION_STORE_TYPE_SERVICE,
				SESSION_STORE_KEYS,
			],
			provide: SESSION_META_REDUCERS,
			useFactory: getStorageReducer,
		},
	],
})
export class SessionStoreModule {}
