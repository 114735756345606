import { createAction, props } from '@ngrx/store';
import { NavigationEntity } from './navigation.interface';

/**
 * Init action
 */
export const init = createAction('[Navigation] Init');

/**
 * Set
 */
export const set = createAction(
	'[Navigation] Set',
	props<{
		entities: NavigationEntity[];
	}>(),
);

/**
 * Update current
 */
export const updateCurrent = createAction(
	'[Navigation] Update Current',
	props<{
		updates: Partial<NavigationEntity>;
	}>(),
);

/**
 * Push
 */
export const push = createAction(
	'[Navigation] Push',
	props<{
		entity: NavigationEntity;
	}>(),
);

/**
 * Back to key
 */
export const backToKey = createAction(
	'[Navigation] Back To Key',
	props<{ key: string }>(),
);
