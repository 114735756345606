import { ClusterIconStyle, MarkerClustererOptions } from '@angular/google-maps';

/* istanbul ignore file */
export const clusterIconStyle: ClusterIconStyle = {
	className: 'flex items-center text-base font-bold text-white font-default',
	height: 52,
	width: 52,
	url: '/assets/ui/images/map-markers/marker-cluster.svg',
};

export const clusterOptions: MarkerClustererOptions = {
	maxZoom: 12,
};

export const mapOptions: google.maps.MapOptions = {
	gestureHandling: 'cooperative',
	center: {
		lat: 51,
		lng: 6,
	},
	mapTypeControl: false,
	streetViewControl: false,
	fullscreenControl: false,
	scaleControl: true,
	zoom: 4,
	maxZoom: 17,
	styles: [
		{
			featureType: 'administrative',
			elementType: 'all',
			stylers: [
				{
					visibility: 'simplified',
				},
			],
		},
		{
			featureType: 'administrative.country',
			elementType: 'geometry.stroke',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'administrative.locality',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'administrative.neighborhood',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'landscape',
			elementType: 'all',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'landscape.man_made',
			elementType: 'labels.icon',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi',
			elementType: 'geometry.fill',
			stylers: [
				{
					visibility: 'on',
				},
			],
		},
		{
			featureType: 'poi.attraction',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.business',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.government',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.medical',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.place_of_worship',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.school',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'poi.sports_complex',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
		{
			featureType: 'transit',
			elementType: 'all',
			stylers: [
				{
					visibility: 'off',
				},
			],
		},
	],
};
