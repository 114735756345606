import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

marker('contact.phone-number');
marker('contact.email');

@Injectable({
	providedIn: 'root',
})
export class ContactDetailsHelper {
	constructor(private readonly translate: TranslateService) {}

	/**
	 * Try fetching the contact phone number from translations.
	 */
	getPhoneNumber(): Observable<string> {
		return this.translate.stream('contact.phone-number');
	}

	/**
	 * Try fetching the contact email address from translations.
	 */
	getEmailAddress(): Observable<string> {
		return this.translate.stream('contact.email');
	}
}
