import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { EnvironmentService } from '../../services/environment/environment.service';

@Injectable({
	providedIn: 'root',
})
export class LocaleHelper {
	/**
	 * Map locale to language
	 */
	private _localeMap: { [key: string]: string } = {};
	/**
	 * Map language to locale
	 */
	private _languageMap: { [key: string]: string } = {};
	/**
	 * language (lowercase)
	 */
	private _language = this.getLangFromLocale(this.acceptLanguage);
	/**
	 * Locale
	 */
	private _locale = this.acceptLanguage;

	/**
	 * Get (lowercase) language
	 */
	get language(): string {
		return this._language;
	}
	/**
	 * Set (lowercase) language
	 */
	set language(language: string) {
		this._locale = this.getLocaleFromLang(language);
		this._language = language;

		this.onLanguageChange.next(this._language);
		this.onLocaleChange.next(this._locale);
	}

	get locale(): string {
		return this._locale;
	}
	set locale(locale: string) {
		this._locale = locale;
		this._language = this.getLangFromLocale(locale);

		this.onLanguageChange.next(this._language);
		this.onLocaleChange.next(this._locale);
	}
	onLanguageChange = new BehaviorSubject(this._language);
	onLocaleChange = new BehaviorSubject(this._locale);

	constructor(
		private readonly environmentService: EnvironmentService,
		@Inject('acceptLanguage')
		private readonly acceptLanguage: string,
	) {
		this.updateLocaleMaps();
	}

	updateLocaleMaps() {
		this._localeMap = {
			[`nl-${this.environmentService.countryCode}`]: 'nl', // nl-NL, nl-BE
			[`en-${this.environmentService.countryCode}`]: 'en',
			[`de-${this.environmentService.countryCode}`]: 'de', // de-DE, de-CH, de-AT
			[`fr-${this.environmentService.countryCode}`]: 'fr', // fr-FR, fr-CH, fr-BE
			[`it-${this.environmentService.countryCode}`]: 'it',
		};
		this._languageMap = {
			nl: `nl-${this.environmentService.countryCode}`,
			en: `en-${this.environmentService.countryCode}`,
			de: `de-${this.environmentService.countryCode}`,
			fr: `fr-${this.environmentService.countryCode}`,
			it: `it-${this.environmentService.countryCode}`,
		};
	}

	private getLangFromLocale(locale: string): string {
		let fallback = this.acceptLanguage;
		if (this.acceptLanguage.length > 2) {
			fallback = this.acceptLanguage.slice(-2);
		}
		return this._localeMap[locale] || fallback.toLowerCase();
	}

	private getLocaleFromLang(lang: string): string {
		return this._languageMap[lang] || this.acceptLanguage.substring(0, 2);
	}
}
