import {
	CommonModule,
	DOCUMENT,
	isPlatformBrowser,
	isPlatformServer,
} from '@angular/common';
import {
	Component,
	Inject,
	Optional,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
	ViewEncapsulation,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AffiliateFacade } from '@sunny-cars/data-access-affiliate';
import { ContactDetailsFacade } from '@sunny-cars/data-access-contact-details';
import { PaymentOptionsService } from '@sunny-cars/provider-affiliate-service';
import {
	PageEntityInterface,
	PageNotificationTypeInterface,
	SeoSettingsInterface,
} from '@sunny-cars/provider-cms-service/lib/page/interfaces/page.interface';
import { PageHelper } from '@sunny-cars/provider-cms-service/lib/page/page.helper';
import { AccordionComponent } from '@sunny-cars/ui/lib/accordion/accordion.component';
import { AwardsComponent } from '@sunny-cars/ui/lib/awards/awards.component';
import { B2BCardsComponent } from '@sunny-cars/ui/lib/b2b-cards/b2b-cards.component';
import { BlogComponent } from '@sunny-cars/ui/lib/blog/blog.component';
import { BreadcrumbsComponent } from '@sunny-cars/ui/lib/breadcrumbs/breadcrumbs.component';
import { Positions } from '@sunny-cars/ui/lib/breadcrumbs/breadcrumbs.data';
import { CardsComponent } from '@sunny-cars/ui/lib/cards/cards.component';
import { ContactPersonComponent } from '@sunny-cars/ui/lib/contact-person/contact-person.component';
import { ContactComponent } from '@sunny-cars/ui/lib/contact/contact.component';
import { CoverComponent } from '@sunny-cars/ui/lib/cover/cover.component';
import { CtaButtonComponent } from '@sunny-cars/ui/lib/cta-button/cta-button.component';
import { DestinationInfoComponent } from '@sunny-cars/ui/lib/destination-info/destination-info.component';
import { WrapperComponent } from '@sunny-cars/ui/lib/full-width-wrapper/full-width-wrapper.component';
import { HeadingContentBlockComponent } from '@sunny-cars/ui/lib/heading-content-block/heading-content-block.component';
import { HeroComponent } from '@sunny-cars/ui/lib/hero/hero.component';
import { IconComponent } from '@sunny-cars/ui/lib/icon/icon.component';
import { ImageCollectionComponent } from '@sunny-cars/ui/lib/image-collection/image-collection.component';
import { ImageComponent } from '@sunny-cars/ui/lib/image/image.component';
import { InfoItemsComponent } from '@sunny-cars/ui/lib/info-items/info-items.component';
import { DoubleColumnComponent } from '@sunny-cars/ui/lib/layouts/double-column/double-column.component';
import { NewsComponent } from '@sunny-cars/ui/lib/news/news.component';
import { NewsletterComponent } from '@sunny-cars/ui/lib/newsletter/newsletter.component';
import {
	NotificationComponent,
	NotificationTypes,
} from '@sunny-cars/ui/lib/notification/notification.component';
import { PageLinksComponent } from '@sunny-cars/ui/lib/page-links/page-links.component';
import { PopularCountriesComponent } from '@sunny-cars/ui/lib/popular-countries/popular-countries.component';
import { Rating } from '@sunny-cars/ui/lib/ratings/ratings.component';
import { RegionMapComponent } from '@sunny-cars/ui/lib/region-map/region-map.component';
import { RegisterComponent } from '@sunny-cars/ui/lib/register/register.component';
import { ReviewsComponent } from '@sunny-cars/ui/lib/reviews/reviews.component';
import { SitemapComponent } from '@sunny-cars/ui/lib/sitemap/sitemap.component';
import { TextBlockComponent } from '@sunny-cars/ui/lib/text-block/text-block.component';
import { VideoComponent } from '@sunny-cars/ui/lib/video/video.component';
import { VisualUspsComponent } from '@sunny-cars/ui/lib/visual-usps/visual-usps.component';
import {
	AffiliateKeyService,
	AffiliateReviews,
	CardsComponentInterface,
	Menu,
	PageTypes,
	PaymentMethodKey,
	Sizes,
	UsercentricsService,
	filterUndefined,
} from '@sunny-cars/util-global';
import { BaseComponent } from '@sunny-cars/util-global/lib/components/base.component';
import { LazyImageLoadingDirective } from '@sunny-cars/util-global/lib/directives/lazy-image/lazy-image.directive';
import { CookieService } from '@sunny-cars/util-global/lib/helpers/cookie/cookie.service';
import { DeepLinkParams } from '@sunny-cars/util-global/lib/helpers/deep-link-params/deep-link-params.helper';
import { LocaleHelper } from '@sunny-cars/util-global/lib/helpers/locale/locale.helper';
import { B2BPortalItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/b2b-portal-items.interface';
import { CoverComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cover.interface';
import { FormTypes } from '@sunny-cars/util-global/lib/interfaces/content/form.interface';
import { HeroComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/hero.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { ContentLayoutTypes } from '@sunny-cars/util-global/lib/interfaces/page-content-layout-types.interface';
import { RoundNumberPipe } from '@sunny-cars/util-global/lib/pipes/round-number/round-number.pipe';
import { SafeHtmlPipe } from '@sunny-cars/util-global/lib/pipes/safe-html/safe-html.pipe';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';
import {
	AffiliatedWith,
	AffiliatedWithTypes,
} from '@sunny-cars/util-global/lib/types/affiliate/affiliated-with.type';
import dayjs from 'dayjs';
import { Observable, first, map, of } from 'rxjs';
import { LinkService } from '../services/link/link.service';
import { MetadataService } from '../services/metadata/metadata.service';
import { PageDataService } from '../services/page-data/page-data.service';
import { ScriptService } from '../services/script/script.service';
import { SSRServerUrlToken } from '../ssr/server.token';
import { PaymentOptionsComponent } from './payment-options/payment-options.component';
import { SearchTravelAgenciesComponent } from './search-travel-agencies/search-travel-agencies.component';

export interface QueryParamData extends DeepLinkParams {
	slug: string;
	language: string;
	previewKey?: string;
}

@Component({
	selector: 'content-template',
	templateUrl: './root.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		AccordionComponent,
		AwardsComponent,
		B2BCardsComponent,
		BlogComponent,
		BreadcrumbsComponent,
		CardsComponent,
		CommonModule,
		ContactComponent,
		ContactPersonComponent,
		CoverComponent,
		CtaButtonComponent,
		DestinationInfoComponent,
		DoubleColumnComponent,
		HeadingContentBlockComponent,
		HeroComponent,
		IconComponent,
		ImageCollectionComponent,
		ImageComponent,
		InfoItemsComponent,
		LazyImageLoadingDirective,
		NewsComponent,
		NewsletterComponent,
		NotificationComponent,
		PageLinksComponent,
		PaymentOptionsComponent,
		PopularCountriesComponent,
		RegionMapComponent,
		RegisterComponent,
		ReviewsComponent,
		RoundNumberPipe,
		SafeHtmlPipe,
		SearchTravelAgenciesComponent,
		SitemapComponent,
		TextBlockComponent,
		TranslateModule,
		VideoComponent,
		VisualUspsComponent,
		WrapperComponent,
	],
})
export class RootComponent extends BaseComponent {
	readonly affiliatedWithTypes = AffiliatedWithTypes;
	readonly componentType = ContentLayoutTypes;
	readonly formTypes = FormTypes;
	readonly positions = Positions;
	readonly headingLevels = HeadingLevels;
	readonly pageTypes = PageTypes;
	readonly sizes = Sizes;
	readonly availableLanguages: string[] =
		this.environmentService.getDomainConfig().language.supported;
	readonly expertrecId =
		this.environmentService.getDomainConfig().expertrec?.apiKey;
	readonly affiliatedWith?: AffiliatedWith[] =
		this.environmentService.getDomainConfig().affiliatedWith;
	private readonly renderer: Renderer2;
	readonly logoRedirectPath$ = of('/');
	readonly siteData$ = this.pageDataService.siteData$;
	readonly pageData$ = this.pageDataService.pageData$;
	readonly openingHours$ = this.contactDetailsFacade.openingHours$;
	readonly source = this.environmentService.getDomainConfig().source;
	readonly affiliateKey =
		this.environmentService.getDomainConfig().affiliateKey;

	paymentMethodItems$ = this.getAffiliatePaymentMethods();
	reviews$: Observable<undefined | Rating[]> = this.getAffiliateReviews();

	menus: Menu[] = [];
	coverComponent?: CoverComponentInterface;
	heroComponent?: HeroComponentInterface;
	notification?: {
		guid: string;
		title: string;
		description: string;
		type: NotificationTypes;
	};
	widgetHtml?: SafeHtml;
	language = this.environmentService.getDomainConfig().language.default;
	currentUrl = '';
	isB2B = false;

	constructor(
		private readonly contactDetailsFacade: ContactDetailsFacade,
		private readonly usercentricsService: UsercentricsService,
		private readonly metadataService: MetadataService,
		private readonly linkService: LinkService,
		private readonly scriptService: ScriptService,
		private readonly pageDataService: PageDataService,
		private readonly rendererFactory: RendererFactory2,
		private readonly domSanitizer: DomSanitizer,
		private readonly translate: TranslateService,
		private readonly localeHelper: LocaleHelper,
		private readonly affiliateKeyService: AffiliateKeyService,
		private readonly affiliateStore: AffiliateFacade,
		private readonly environmentService: EnvironmentService,
		private readonly cookieService: CookieService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(PLATFORM_ID) public platformId: string,
		@Optional() @Inject(SSRServerUrlToken) private serverUrl: string,
		@Optional() @Inject('isB2B') private readonly envIsB2B?: boolean,
	) {
		super();
		this.isB2B = !!this.envIsB2B;
		this.renderer = this.rendererFactory.createRenderer(null, null);

		this.initializeUserCentrics(this.pageDataService.params.language);
		this.metadataService.setGTM();
		this.handlePageData(this.pageDataService.params);
		this.handleLanguageChanges(this.pageDataService.params.language);

		this.setCurrentUrl(serverUrl);
	}

	setCurrentUrl(url: string): void {
		if (isPlatformServer(this.platformId)) {
			this.currentUrl = url;
		} else {
			this.currentUrl = window.location.href;
		}
	}

	convertCardsToB2bCards(
		cards: CardsComponentInterface,
	): B2BPortalItemsComponentInterface {
		return PageHelper.convertCardsToB2bCards(cards);
	}

	private getAffiliatePaymentMethods() {
		if (isPlatformBrowser(this.platformId)) {
			return this.affiliateStore.paymentMethods$.pipe(
				map((paymentMethods) =>
					PaymentOptionsService.mapPaymentMethodKeysToPaymentMethodItems(
						paymentMethods as PaymentMethodKey[],
					),
				),
			);
		}
		return of();
	}

	private getAffiliateReviews() {
		return this.affiliateStore.reviews$.pipe(
			map(
				(reviews) =>
					reviews &&
					Object.keys(reviews)
						.map((key) => ({
							review: reviews[key as keyof AffiliateReviews],
							name: key,
						}))
						.sort((reviewA, reviewB) => {
							/* istanbul ignore next */
							if (reviewA.name < reviewB.name) {
								return -1;
							} else if (reviewA.name > reviewB.name) {
								return 1;
							}
							/* istanbul ignore next */
							return 0;
						}),
			),
		);
	}

	private initializeUserCentrics(language?: string) {
		const envConfig = this.environmentService.getDomainConfig();
		if (envConfig.usercentrics?.apiKey) {
			this.usercentricsService.initUsercentrics(
				this.environmentService.config.production,
				envConfig.countryCode,
				envConfig.usercentrics.apiKey,
				envConfig.usercentrics?.dataProtectorEnabled || false,
				language,
			);
		}
	}

	private handleLanguageChanges(language: string): void {
		this.localeHelper.language = language;
		this.addSubscription(
			this.localeHelper.onLanguageChange.subscribe((lang: string) => {
				this.translate.use(lang);
				dayjs.locale(lang);
				this.language = lang;
				this.document.documentElement.lang = lang;
			}),
		);
	}

	private handlePageData(params: QueryParamData): void {
		this.addSubscription(
			this.pageData$
				.pipe(filterUndefined, first())
				.subscribe((pageData: PageEntityInterface) => {
					if (isPlatformBrowser(this.platformId)) {
						pageData.hideUsercentrics
							? this.usercentricsService.hide()
							: this.usercentricsService.showIfConsentRequired();
					}

					this.setPageMetaData(pageData);

					this.setNotification(pageData);

					this.coverComponent =
						(pageData.content.find(
							(content) => content.layout === ContentLayoutTypes.COVER,
						) as CoverComponentInterface) || undefined;
					this.heroComponent =
						(pageData.content.find(
							(content) => content.layout === ContentLayoutTypes.HERO,
						) as HeroComponentInterface) || undefined;

					if (this.heroComponent) {
						this.loadWidgetScripts();

						if (isPlatformBrowser(this.platformId)) {
							if (pageData.destination) {
								params.pickupRegionPrefill = pageData.destination.title;
								params.iataCode = undefined;
								params.pickupRegionId = undefined;
							}
							this.loadWidgetDom(params);
						}
					}

					this.affiliateStore.load(
						this.affiliateKeyService.getAffiliateKey(),
						undefined,
						undefined,
						this.source,
					);
				}),
		);
	}

	setNotification(pageData: PageEntityInterface): void {
		const { notification } = pageData;

		if (!notification) {
			return;
		}

		this.notification = {
			guid: notification.guid,
			title: notification.title,
			description: notification.description,
			type: this.mapNotificationType(notification.type),
		};
	}

	private mapNotificationType(
		type: PageNotificationTypeInterface | string,
	): NotificationTypes {
		switch (type) {
			case 'info':
				return NotificationTypes.INFO;
			case 'success':
				return NotificationTypes.SUCCESS;
			case 'warning':
				return NotificationTypes.WARNING;
			case 'error':
				return NotificationTypes.ERROR;
			case 'highlight':
				return NotificationTypes.HIGHLIGHT;
			default:
				return NotificationTypes.INFO;
		}
	}

	private setPageMetaData(pageData: PageEntityInterface): void {
		if (!pageData.seoSettings) {
			return;
		}
		this.metadataService.updateMetadata(
			pageData.seoSettings as SeoSettingsInterface,
		);
		this.metadataService.addMetaData(
			this.environmentService.getDomainConfig().metaData,
		);

		if (isPlatformServer(this.platformId)) {
			if (pageData.seoSettings.linkDataJson) {
				this.scriptService.addScript(
					{
						type: 'application/ld+json',
					},
					pageData.seoSettings.linkDataJson,
				);
			}

			this.linkService.addTag({
				rel: 'canonical',
				href: pageData.seoSettings.canonicalUrl || this.serverUrl,
			});

			// Href lang other domains
			pageData.hrefLangs.forEach((hrefLang) => {
				this.linkService.addTag({
					rel: 'alternate',
					hreflang: hrefLang.lang.toLowerCase(),
					href: `${hrefLang.href}`,
				});
			});
		}
	}

	getNotificationCookieName(): string {
		return `notification-${this.notification?.guid}`;
	}

	isNotificationVisible(): boolean {
		if (!this.notification || !isPlatformBrowser(this.platformId)) {
			return false;
		}

		return !this.cookieService.getCookie(this.getNotificationCookieName());
	}

	dismissNotification(): void {
		this.cookieService.persistCookieForDuration(
			this.getNotificationCookieName(),
			1,
			90,
		);
	}

	private loadWidgetScripts(): void {
		// Script to prevent loading scripts multiple times
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		if (isPlatformBrowser(this.platformId) && (window as any).hasLoadedTags) {
			return;
		}
		const verificationScriptTag = this.renderer.createElement('script');
		verificationScriptTag.innerHTML = 'window.hasLoadedTags = true;';
		this.renderer.appendChild(this.document.head, verificationScriptTag);

		// Load widget webcomponent script
		const scriptTag = this.renderer.createElement('script');
		scriptTag.src = `${
			this.environmentService.getDomainConfig().applicationUrls.widgetUrl
		}sc-widget.js`;
		scriptTag.type = 'text/javascript';
		this.renderer.setAttribute(scriptTag, 'rel', 'preload');
		this.renderer.appendChild(this.document.body, scriptTag);

		// Load widget webcomponent style
		const styleTag = this.renderer.createElement('link');
		styleTag.href = `${
			this.environmentService.getDomainConfig().applicationUrls.widgetUrl
		}sc-widget.css`;
		styleTag.rel = 'stylesheet';
		this.renderer.appendChild(this.document.head, styleTag);
	}

	private loadWidgetDom(params: QueryParamData): void {
		this.widgetHtml = this.domSanitizer.bypassSecurityTrustHtml(`<sc-widget
			affiliatekey="${
				params.affiliateKey || this.affiliateKeyService.getAffiliateKey()
			}"
			isaffiliate="false"
			openinnewtab="false"
			source="${this.environmentService.getDomainConfig().source}"
			domain="${this.environmentService.getDomainConfig().countryCode}"
			lang="${params.language}"
			${params.promocode ? `promocode="${params.promocode}"` : ''}
			${
				params.pickupRegionPrefill
					? `pickupregionprefill="${params.pickupRegionPrefill}"`
					: ''
			}
			${params.pickupRegionId ? `pickupregionid="${params.pickupRegionId}"` : ''}
			${
				params.pickupLocationId
					? `pickuplocationid="${params.pickupLocationId}"`
					: ''
			}
			${params.iataCode ? `iatacode="${params.iataCode}"` : ''}
			${params.dropoffRegionId ? `dropoffregionid="${params.dropoffRegionId}"` : ''}
			${
				params.dropoffLocationId
					? `dropofflocationid="${params.dropoffLocationId}"`
					: ''
			}
			${params.pickupDate ? `pickupdate="${params.pickupDate}"` : ''}
			${params.dropoffDate ? `dropoffdate="${params.dropoffDate}"` : ''}
			${params.pickupTime ? `pickuptime="${params.pickupTime}"` : ''}
			${params.dropoffTime ? `dropofftime="${params.dropoffTime}"` : ''}
			${params.styleProfile ? `styleprofile="${params.styleProfile}"` : ''}
			${params.contentProfile ? `contentprofile="${params.contentProfile}"` : ''}
			${
				params.affiliateReference
					? `affiliatereference="${params.affiliateReference}"`
					: ''
			}
			${
				params.affiliateReferenceSystem
					? `affiliatereferencesystem="${params.affiliateReferenceSystem}"`
					: ''
			}
			${
				params.affiliateVersion
					? `affiliateversion="${params.affiliateVersion}"`
					: ''
			}
		></sc-widget>`);
	}
}
