import { ElementRef, Injectable } from '@angular/core';
import { ColorHelper } from '../color/color.helper';

@Injectable({
	providedIn: 'root',
})
export class ContrastTextHelper {
	static shouldApplyContrast = false;

	static getContrastText(element: ElementRef): string | undefined {
		if (!ContrastTextHelper.shouldApplyContrast) {
			return undefined;
		}
		const backgroundColor = ColorHelper.getBackgroundColor(
			element.nativeElement,
		);
		return this.getContrastColor(backgroundColor);
	}

	private static getContrastColor(color: string): string {
		const backgroundColorHex = ColorHelper.rgbToHex(color);
		return ColorHelper.getContrastColor(backgroundColorHex);
	}
}
