<ui-text-block
	class="mb-3 block md:mb-4"
	[heading]="heading"
	[headingType]="headingType"
	[subheading]="subheading"
	[content]="content"
>
</ui-text-block>
<div *ngIf="contactPerson" class="flex gap-4 rounded-medium bg-ui-low p-4">
	<ui-image
		[className]="
			contactPerson.function
				? 'rounded-full object-cover h-20 w-20'
				: 'rounded-full object-cover h-16 w-16'
		"
		[image]="{
			src: contactPerson.image.src,
			altText: contactPerson.image.alt,
			width: 80,
			height: 80
		}"
	></ui-image>
	<div>
		<div class="font-bold">{{ contactPerson.name }}</div>
		<div *ngIf="contactPerson.function">{{ contactPerson.function }}</div>
		<div class="flex flex-col sm:flex-row sm:gap-4">
			<a
				*ngIf="contactPerson.email"
				class="underline"
				href="mailto:{{ contactPerson.email }}"
				target="_blank"
				rel="noopener noreferrer"
				>E-mail</a
			>
			<a
				*ngIf="contactPerson.phone"
				class="underline"
				href="tel:{{ contactPerson.phone }}"
				>{{ contactPerson.phone }}</a
			>
			<a
				*ngIf="contactPerson.linkedin"
				class="underline"
				href="{{ contactPerson.linkedin }}"
				target="_blank"
				rel="noopener noreferrer"
				>LinkedIn</a
			>
			<a
				*ngIf="contactPerson.twitter"
				class="underline"
				href="{{ contactPerson.twitter }}"
				target="_blank"
				rel="noopener noreferrer"
				>Twitter</a
			>
		</div>
	</div>
</div>
