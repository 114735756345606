<div
	*ngIf="content.items.length"
	class="grid grid-cols-1 gap-4"
	[ngClass]="{
		'md:grid-cols-2': content.twoColumns
	}"
>
	<ui-b2b-card
		*ngFor="let item of content.items"
		[title]="item.title"
		[subtitle]="
			item.publishDate
				? (item.publishDate | datetime : dateTimeFormats.DATE_SHORT)
				: ''
		"
		[text]="item.content"
		[image]="item.image"
		[showImage]="!!content.showImage"
		[link]="
			item.button
				? {
						href: item.button.link,
						rel: '',
						shouldOpenInNewTab: item.button.openInNewTab,
						text: item.button.title,
						title: item.button.title
				  }
				: undefined
		"
		[isHorizontal]="isHorizontal"
	>
	</ui-b2b-card>
</div>
