<ui-main-menu-desktop
	*ngIf="!isMobile"
	[items]="items"
	[wrapperStyleModifiers]="wrapperStyleModifiers"
	[onScroll$]="onScroll$"
>
</ui-main-menu-desktop>
<ui-main-menu-mobile
	*ngIf="isMobile"
	[items]="items"
	(closed)="closeHandler()"
></ui-main-menu-mobile>
