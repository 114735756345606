<article
	class="flex flex-col overflow-hidden rounded border border-ui-moderate bg-ui-low"
	[ngClass]="{
		'xs:flex-row': isHorizontal
	}"
>
	<div
		*ngIf="showImage"
		class="relative h-0 w-full basis-1/4 pb-aspect-ratio-16/9"
		[ngClass]="{
			'min-w-[100px] xs:h-auto xs:w-1/4 xs:max-w-[320px] xs:pb-0': isHorizontal
		}"
	>
		<div
			class="absolute inset-0"
			[ngClass]="{
				'xs:relative xs:inset-auto': isHorizontal
			}"
		>
			<ui-image
				*ngIf="!image?.src"
				class="mx-auto block h-full w-full max-w-[200px]"
				className="w-full h-full object-contain"
				[image]="{
					src: '/assets/ui/images/sunny-smile-button.png',
					altText: 'Sunny Cars',
					width: 400,
					height: 400
				}"
			></ui-image>
			<ui-image
				*ngIf="image && image.src"
				class="h-full w-full"
				className="w-full h-full object-cover"
				[sources]="[
					{
						min: null,
						max: sizes.LG,
						width: 440,
						height: 440 * (image.height / image.width) | roundNumber
					},
					{
						min: sizes.LG,
						max: sizes.XL,
						width: 382,
						height: 382 * (image.height / image.width) | roundNumber
					},
					{
						min: sizes.XL,
						max: null,
						width: 435,
						height: 435 * (image.height / image.width) | roundNumber
					}
				]"
				[image]="{
					src: image.src,
					altText: image.alt,
					width: 440,
					height: 440 * (image.height / image.width) | roundNumber
				}"
			></ui-image>
		</div>
	</div>
	<div class="basis-3/4 p-4">
		<p
			class="flex flex-col hyphens-auto [overflow-wrap:anywhere]"
			[class.mb-1]="text || link?.href"
			*ngIf="title || subtitle"
		>
			<strong class="text-xl leading-5 text-secondary" *ngIf="title">
				{{ title }}
			</strong>
			<span class="text-sm leading-5 sm:text-base" *ngIf="subtitle">
				{{ subtitle }}
			</span>
		</p>
		<div class="flex flex-col gap-3" *ngIf="text || link?.href">
			<div
				*ngIf="text"
				class="gap-1 text-lg leading-tight"
				[innerHtml]="text"
			></div>
			<a
				class="btn btn-secondary block w-fit max-w-fit bg-icon-chevron-right-white bg-icon bg-right-3-center bg-no-repeat pr-9 text-base font-semibold"
				*ngIf="link && link.href"
				[href]="link.href"
				[rel]="link.rel"
				[title]="link.title"
				[attr.target]="link.shouldOpenInNewTab ? '_blank' : null"
			>
				{{ link.text }}
			</a>
		</div>
	</div>
</article>
