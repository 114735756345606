export class ColorHelper {
	private static style: CSSStyleDeclaration;

	static getComputedPropertyValue(
		property: string,
		element?: HTMLElement,
	): string {
		if (!this.style) {
			this.style = getComputedStyle(element || document.documentElement);
		}

		return this.style.getPropertyValue(property).trim();
	}

	static getContrastColor(color: string): string {
		if (!color) {
			return '';
		}
		if (ColorHelper.getColorExceedsTresholdSimple(color)) {
			return '#000000';
		} else {
			return '#ffffff';
		}
	}

	/**
	 * getColorExceedsTreshold
	 * Method copied from https://stackoverflow.com/a/41491220
	 * Will be implemented in SCR-9443
	 */
	static getColorExceedsTreshold(backgroundColor: string): boolean {
		const wcagThreshold = 0.179;
		const colorHex =
			backgroundColor.charAt(0) === '#'
				? backgroundColor.substring(1, 7)
				: backgroundColor;
		const red = parseInt(colorHex.substring(0, 2), 16);
		const green = parseInt(colorHex.substring(2, 4), 16);
		const blue = parseInt(colorHex.substring(4, 6), 16);
		const uiColors = [red / 255, green / 255, blue / 255];
		const colors = uiColors.map((color) => {
			if (color <= 0.03928) {
				return color / 12.92;
			}
			return Math.pow((color + 0.055) / 1.055, 2.4);
		});
		const contrastLevel =
			0.2126 * colors[0] + 0.7152 * colors[1] + 0.0722 * colors[2];
		return contrastLevel > wcagThreshold;
	}

	/**
	 * getColorExceedsTresholdSimple
	 * Method copied from https://stackoverflow.com/a/41491220
	 */
	static getColorExceedsTresholdSimple(backgroundColor: string): boolean {
		const threshold = 128;
		const color =
			backgroundColor.charAt(0) === '#'
				? backgroundColor.substring(1, 7)
				: backgroundColor;
		const red = parseInt(color.substring(0, 2), 16);
		const green = parseInt(color.substring(2, 4), 16);
		const blue = parseInt(color.substring(4, 6), 16);
		const contrastLevel = red * 0.299 + green * 0.587 + blue * 0.114;
		return contrastLevel >= threshold;
	}

	static rgbToHex(color: string): string {
		color = '' + color;
		if (!color || color.indexOf('rgb') < 0) {
			return '';
		}

		if (color.charAt(0) == '#') {
			return color;
		}

		const nums = /(.*?)rgb\((\d+),\s*(\d+),\s*(\d+)\)/i.exec(color);
		if (!nums) {
			return '';
		}
		const r = parseInt(nums[2], 10).toString(16);
		const g = parseInt(nums[3], 10).toString(16);
		const b = parseInt(nums[4], 10).toString(16);

		return (
			'#' +
			((r.length == 1 ? '0' + r : r) +
				(g.length == 1 ? '0' + g : g) +
				(b.length == 1 ? '0' + b : b))
		);
	}

	static hslToHex(hsl: string): string {
		const regex = new RegExp('hsl\\((\\d+)deg\\s*([\\d.]+)% \\s*([\\d.]+)%\\)');
		const result = hsl.match(regex) || [];
		if (result.length <= 3) {
			return '#000';
		}

		const h = parseInt(result[1]);
		const s = parseInt(result[2]);
		let l = parseInt(result[3]);
		l /= 100;

		const a = (s * Math.min(l, 1 - l)) / 100;
		const f = (n: number) => {
			const k = (n + h / 30) % 12;
			const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
			return Math.round(255 * color)
				.toString(16)
				.padStart(2, '0');
		};

		return `#${f(0)}${f(8)}${f(4)}`;
	}

	static tint(hexColor: string, percent: number): string {
		return this.mixHexColors('#fff', hexColor, percent / 100);
	}

	static shade(hexColor: string, percent: number): string {
		return this.mixHexColors('#000', hexColor, -percent / 100);
	}

	private static normalizeHexColor(hexColor: string): string {
		hexColor = hexColor.replace(/^#/, '');
		if (hexColor.length === 3) {
			return [
				hexColor[0],
				hexColor[0],
				hexColor[1],
				hexColor[1],
				hexColor[2],
				hexColor[2],
			].join('');
		}
		return hexColor;
	}

	private static isHexColor(hexColor: string): boolean {
		const regex = new RegExp(/^#([0-9a-f]{3}){1,2}$/i);
		return regex.test(hexColor);
	}

	private static decimalToHex(decimalValue: number): string {
		return decimalValue.toString(16);
	}

	private static hexToDecimal(hexValue: string): number {
		return parseInt(hexValue, 16);
	}

	private static mixHexColors(
		originalBaseHexColor: string,
		originalHexColorToAdjust: string,
		weight: number,
	) {
		const color = [];

		if (
			!this.isHexColor(originalBaseHexColor) ||
			!this.isHexColor(originalHexColorToAdjust)
		) {
			// just return a fallback black color
			return '#000000';
		}

		const baseColor = this.normalizeHexColor(originalBaseHexColor);
		const colorToAdjust = this.normalizeHexColor(originalHexColorToAdjust);
		const colorCharacters = colorToAdjust.length - 1;

		for (let i = 0; i <= colorCharacters; i += 2) {
			const baseColorDecimal = this.hexToDecimal(baseColor.slice(i, i + 2));
			const colorToAdjustDecimal = this.hexToDecimal(
				colorToAdjust.slice(i, i + 2),
			);

			const value = this.decimalToHex(
				Math.round(
					colorToAdjustDecimal +
						(baseColorDecimal - colorToAdjustDecimal) * ((weight * 100) / 100),
				),
			).padStart(2, '0');

			color.push(value);
		}
		const hexColor = color.join('');
		return `#${hexColor}`;
	}

	static getBackgroundColor(element: Element | null) {
		if (!element) {
			return '';
		}

		const style = getComputedStyle(element);
		let backgroundColor = style.backgroundColor;
		let backgroundAlpha: number | undefined = undefined;

		if (backgroundColor && backgroundColor.substring(0, 4) === 'rgba') {
			const colorSplit = backgroundColor.split(',');
			backgroundAlpha = parseFloat(colorSplit[3]) || undefined;
		}

		if (
			backgroundColor !== 'rgb(0, 0, 0)' &&
			(backgroundColor === 'rgba(0, 0, 0, 0)' ||
				(backgroundAlpha && backgroundAlpha < 0.5)) &&
			element.parentElement
		) {
			backgroundColor = this.getBackgroundColor(element.parentElement);
		} else if (
			backgroundColor === 'rgba(0, 0, 0, 0)' &&
			!element.parentElement
		) {
			backgroundColor = 'rgb(255, 255, 255)';
		}

		return backgroundColor;
	}
}
