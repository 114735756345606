import {
	LinkTypes,
	Menu,
	MenuItem,
	MenuItemExtra,
	MenuItemTypes,
} from '@sunny-cars/util-global';
import { SiteEntityInterface } from './interfaces/parsed/site.interface';
import {
	SiteEntityResponseInterface,
	SiteMenuResponse,
	SiteMenuResponseItem,
	SiteMenuResponseItemExtra,
} from './interfaces/response/site-response.interface';

/**
 * Helper for parsing site responses
 */
export class SiteHelper {
	static parseSiteResponse(
		site: SiteEntityResponseInterface,
		language?: string,
	): SiteEntityInterface {
		const locales = language
			? site.locales.find(
					(locale) =>
						locale.language_uri === language ||
						locale.language_uri.slice(-2) === language,
			  )
			: site.locales[0];

		return {
			contactInfo: site.contact_info
				? {
						email: site.contact_info.email || '',
						phoneNumber: site.contact_info.phone_number_emergency || '',
						translations: {
							aboutHeading: site.contact_info.translations.about_heading,
							aboutContent: site.contact_info.translations.about_content,
						},
				  }
				: undefined,
			footer: site.contact_info?.translations?.footer
				? {
						heading: site.contact_info.translations.footer.heading,
						content: site.contact_info.translations.footer.content,
						phone: site.contact_info.translations.footer.phone_number,
						showOpeningHours:
							site.contact_info.translations.footer.show_opening_hours,
						contactLink:
							site.contact_info.translations.footer.contact_link?.url,
						contactTitle: site.contact_info.translations.footer.contact_title,
						newsletterLink:
							site.contact_info.translations.footer.newsletter_link?.url,
						newsletterTitle:
							site.contact_info.translations.footer.newsletter_title,
						newsletterDescription:
							site.contact_info.translations.footer.newsletter_description,
						showSocialMediaIcons:
							site.contact_info.translations.footer.show_social_media_icons,
				  }
				: undefined,
			domains: site.domains.map((domain) => domain.name),
			locales: site.locales.map((locale) => locale.iso_code),
			menus:
				(locales?.menus
					?.map((menu) => this.parseMenuResponse(menu))
					.filter((menu) => !!menu) as Menu[]) || [],
			siteLocales: site.locales.map((locale) => {
				return {
					name: locale.name,
					dateFormats: {
						short: locale.date_format_short,
						long: locale.date_format_long,
					},
					isoCode: locale.iso_code,
					decimals: locale.num_decimals,
					separators: {
						decimals: locale.num_decimals,
						thousands: locale.sep_thousands,
					},
				};
			}),
			name: site.name,
			settings: {
				blogUrl: site.settings.blog_url,
				currency: site.settings.currency,
				currencySymbol: site.settings.currency_symbol,
				googleTagManagerCode: site.settings.gtm_code,
				locale: site.default_locale.iso_code,
				newsletter: {
					gdprUrl: site.settings.newsletter_gdpr_url,
					parameterZ: site.settings.newsletter_parameter_z,
					parameterMidrid: site.settings.newsletter_parameter_midrid,
				},
			},
			seoSettings: site.seo_settings.length
				? [
						{
							guid: site.seo_settings[0].guid,
							title: site.seo_settings[0].title,
							metaDescription: site.seo_settings[0].meta_description,
							indexable: site.seo_settings[0].indexable,
							ogImage: site.seo_settings[0].og_image
								? {
										guid: site.seo_settings[0].og_image.guid,
										fileName: site.seo_settings[0].og_image.file_name,
										fileSize: site.seo_settings[0].og_image.file_size,
										fileType: site.seo_settings[0].og_image.file_type,
										src: site.seo_settings[0].og_image.src,
										url: site.seo_settings[0].og_image.url,
										width: site.seo_settings[0].og_image.width,
										height: site.seo_settings[0].og_image.height,
								  }
								: null,
							locale: {
								guid: site.seo_settings[0].locale.guid,
								name: site.seo_settings[0].locale.name,
								isoCode: site.seo_settings[0].locale.iso_code,
								languageUri: site.seo_settings[0].locale.language_uri,
							},
						},
				  ]
				: [],
			socialMedia: {
				twitter: site.social_media?.twitter || '',
				facebook: site.social_media?.facebook || '',
				youtube: site.social_media?.youtube || '',
				blog: site.social_media?.blog || '',
				vimeo: site.social_media?.vimeo || '',
				pinterest: site.social_media?.pinterest || '',
				instagram: site.social_media?.instagram || '',
				newsletter: site.social_media?.newsletter || '',
			},
		};
	}

	static parseMenuResponse(
		menuResponse: SiteMenuResponse,
		baseUrl?: string,
	): Menu | undefined {
		try {
			return {
				type: menuResponse.type,
				items: this.recursiveMenuItemParser(menuResponse.items, baseUrl),
			};
		} catch (e) {
			console.error(e);
			return undefined;
		}
	}

	private static recursiveMenuItemParser(
		menuItems: SiteMenuResponseItem[],
		baseUrl?: string,
	): MenuItem[] {
		return menuItems.map((menuItem) => {
			let value: string = menuItem.value || '';
			let type = menuItem.children?.length
				? MenuItemTypes.DROPDOWN
				: menuItem.type;
			if (type === MenuItemTypes.PAGE && baseUrl) {
				type = MenuItemTypes.STATIC;
				value = `${baseUrl}${value}`;
			}
			return {
				name: menuItem.name,
				type,
				value: value,
				target: menuItem.target,
				extra: menuItem.extra
					? this.parseExtra(menuItem.extra, baseUrl)
					: undefined,
				children: menuItem.children?.length
					? this.recursiveMenuItemParser(menuItem.children, baseUrl)
					: undefined,
			};
		});
	}

	private static parseExtra(
		extra: SiteMenuResponseItemExtra,
		baseUrl?: string,
	): MenuItemExtra {
		let ctaValue: string = extra.callToAction?.value || '';
		let ctaType: LinkTypes = extra.callToAction?.type || LinkTypes.TEXT;
		if (extra.callToAction && ctaType === LinkTypes.RELATIVE && baseUrl) {
			ctaType = LinkTypes.ABSOLUTE;
			ctaValue = `${baseUrl}${ctaValue}`;
		}
		return {
			callToAction: extra.callToAction
				? {
						name: extra.callToAction.name,
						url: ctaValue,
						type: ctaType,
				  }
				: undefined,
			header: extra.title
				? {
						title: extra.title,
						intro: extra.description,
						icon: extra.icon,
						image: extra.image,
				  }
				: undefined,
			categories: extra.categories?.map((category) => {
				return {
					id: category.id.toString(),
					name: category.name,
					title: category.title,
					icon: category.icon,
					description: category.description,
				};
			}),
			parentCategoryId: extra.categoryID?.toString(),
		};
	}
}
