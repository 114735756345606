import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { OpeningTimes } from '../../interfaces/opening-hours/opening-hours.interface';
import { LocaleHelper } from '../locale/locale.helper';

marker('global.closed');
marker('global.contact-hours-label');

@Injectable({
	providedIn: 'root',
})
export class ContactHoursHelper {
	constructor(
		private readonly translate: TranslateService,
		private readonly localeHelper: LocaleHelper,
	) {}

	getTodaysContactLabel(
		openingTimes: OpeningTimes | undefined,
	): Observable<string> {
		if (!openingTimes) {
			return this.translate.stream('global.closed');
		}

		return this.isCustomerServiceOpen(openingTimes)
			? this.translate.stream('global.contact-hours-label', {
					end: openingTimes.endTime,
				})
			: this.translate.stream('global.outside-contact-hours-label', {
					start: openingTimes.startTime,
				});
	}

	isCustomerServiceOpen(openingTimes: OpeningTimes | undefined): boolean {
		if (!openingTimes) {
			return true;
		}
		const now = new Date().toLocaleString(this.localeHelper.locale, {
			timeStyle: 'short',
			hour12: false,
		});
		return openingTimes.startTime <= now && now < openingTimes.endTime;
	}
}
