import { isPlatformBrowser } from '@angular/common';
import {
	Inject,
	Injectable,
	Optional,
	PLATFORM_ID,
	SecurityContext,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, combineLatest, first } from 'rxjs';
import { SupportedDomainValues } from '../../interfaces/domain/domain.interface';
import { ApplicationUrlEnvironment } from '../../interfaces/environment/environment.interface';
import { SupportedDomains } from '../../services/environment/environment.interface';
import { EnvironmentService } from '../../services/environment/environment.service';
import {
	AffiliateVersion,
	AffiliateVersions,
} from '../../types/affiliate/version.type';
import { InputValidators } from '../input-validators/input-validators';
import { LocaleHelper } from '../locale/locale.helper';
import { UrlHelper } from '../url/url.helper';

export interface DeepLinkParams {
	affiliateKey?: number;
	language?: string;
	promocode?: string;
	pickupRegionPrefill?: string;
	iataCode?: string;
	pickupRegionId?: number;
	pickupLocationId?: number;
	pickupType?: string;
	pickupDate?: string;
	pickupTime?: string;
	dropoffRegionId?: number;
	dropoffLocationId?: number;
	dropoffType?: string;
	dropoffDate?: string;
	dropoffTime?: string;
	salesSeasonId?: number;
	vehicleId?: number;
	skipRedirect: boolean;
	isNonAffiliate: boolean;
	styleProfile?: number;
	contentProfile?: number;
	affiliateReference?: string;
	affiliateReferenceSystem?: string;
	affiliateVersion?: AffiliateVersion;
	driverAge?: string;
}

export interface CustomAffiliateStorageData {
	status: boolean;
	styleProfile?: number;
	contentProfile?: number;
	affiliateVersion?: string;
}

export const customAffiliateStorageKey = 'customAffiliate';

@Injectable({
	providedIn: 'root',
})
export class DeepLinkParamsService {
	private url?: URL;

	constructor(
		private readonly localeHelper: LocaleHelper,
		private readonly urlHelper: UrlHelper,
		private readonly sanitizer: DomSanitizer,
		private readonly translate: TranslateService,
		private readonly validators: InputValidators,
		private readonly environmentService: EnvironmentService,
		@Inject('applicationUrls')
		private readonly applicationUrls: ApplicationUrlEnvironment,
		@Inject('countryCode')
		private readonly countryCode: SupportedDomains,
		@Inject(PLATFORM_ID) public platformId: string,
		@Optional()
		@Inject('envLanguages')
		private readonly envLanguages?: {
			availableLanguages: string[];
			default: string;
		},
	) {}

	getParams(): Params {
		if (!isPlatformBrowser(this.platformId)) {
			return {};
		}

		return UrlHelper.getParamsWithLowerCasedKeys();
	}

	parseParams(params: Params, isDestinationPage?: boolean): DeepLinkParams {
		let affiliateKey = this.sanitizeNumberParam(
			params['affiliatekey'] || params['ak'],
		);
		if (isDestinationPage) {
			affiliateKey = this.customAkParsing(params);
		}

		return {
			affiliateKey,
			language: this.sanitizeParam(
				(params['language'] || params['lang'] || params['l'])?.toLowerCase(),
			),
			promocode: this.sanitizeParam(params['promocode'] || params['pc']),
			pickupRegionPrefill: this.sanitizeParam(
				params['pickupregionprefill'] || params['pl'],
			),
			iataCode: this.sanitizeParam(
				params['iata'] || params['airport'] || params['apt'],
			),
			pickupRegionId: this.sanitizeNumberParam(
				params['pickupregion'] || params['prid'],
			),
			pickupLocationId: this.sanitizeNumberParam(params['pickuplocation']),
			pickupType: this.sanitizeParam(params['pickuptype']),
			pickupDate: this.sanitizeParam(params['pickupdate'] || params['pd']),
			pickupTime: this.sanitizeParam(
				(params['pickuptime'] || params['pt'])?.replace(':', ''),
			),
			dropoffRegionId: this.sanitizeNumberParam(
				params['dropoffregion'] || params['drid'],
			),
			dropoffLocationId: this.sanitizeNumberParam(params['dropofflocation']),
			dropoffType: this.sanitizeParam(params['dropofftype']),
			dropoffDate: this.sanitizeParam(params['dropoffdate'] || params['dd']),
			dropoffTime: this.sanitizeParam(
				(params['dropofftime'] || params['dt'])?.replace(':', ''),
			),
			salesSeasonId: this.sanitizeNumberParam(
				params['salesseasonid'] || params['sseid'],
			),
			vehicleId: this.sanitizeNumberParam(
				params['vehicleid'] || params['pvid'],
			),
			skipRedirect: !!this.sanitizeParam(params['skip'] || params['sk']),
			isNonAffiliate: !!this.sanitizeParam(params['nonaffiliate']),
			styleProfile: this.sanitizeNumberParam(
				params['styleprofile'] || params['sp'],
			),
			contentProfile: this.sanitizeNumberParam(
				params['contentprofile'] || params['cp'],
			),
			affiliateReference: this.sanitizeParam(
				params['affiliatereference'] || params['ar'],
			),
			affiliateReferenceSystem: this.sanitizeParam(
				params['affiliatereferencesystem'] || params['ars'],
			),
			affiliateVersion:
				this.sanitizeParam(params['affiliateversion']) ===
				AffiliateVersions.CONCEPT
					? AffiliateVersions.CONCEPT
					: undefined,
			driverAge: this.sanitizeParam(params['driverage']),
		};
	}

	handleParams(
		params: DeepLinkParams,
		supportedLanguages: string[],
		isPromoCodeValid$: Observable<boolean>,
		isNonAffiliateUrl?: boolean,
	): void {
		this.url = new URL(location.href);

		if (params.isNonAffiliate) {
			sessionStorage.removeItem(customAffiliateStorageKey);
		} else if (params.affiliateKey) {
			supportedLanguages = this.setSupportedLanguages(supportedLanguages);
			this.handleCustomAffiliateData(
				params.styleProfile,
				params.contentProfile,
				params.affiliateVersion,
			);
		}

		combineLatest([
			this.translate.get('ROUTES_BOOKING.carselection').pipe(first()),
			isPromoCodeValid$.pipe(first()),
		]).subscribe(([carSelectionRoutePath, isPromoCodeValid]) => {
			if (
				!this.shouldRedirectToCarSelection(
					params,
					carSelectionRoutePath,
					isPromoCodeValid,
				) &&
				params.language
			) {
				this.handleLanguage(params.language, supportedLanguages);
			}
			this.handleRedirect(
				params,
				carSelectionRoutePath,
				supportedLanguages,
				isPromoCodeValid,
				isNonAffiliateUrl,
			);
		});
	}

	private customAkParsing(params: Params): number {
		const mapping: {
			[key: string]: {
				[key: string]: number;
			};
		} = {
			default: {
				[SupportedDomainValues.DE]: 55,
				[SupportedDomainValues.CH]: 58,
				[SupportedDomainValues.AT]: 57,
			},
			gclid: {
				[SupportedDomainValues.DE]: 51,
				[SupportedDomainValues.CH]: 53,
				[SupportedDomainValues.AT]: 52,
			},
			channel: {
				[SupportedDomainValues.DE]: 46,
				[SupportedDomainValues.CH]: 1907628,
				[SupportedDomainValues.AT]: 1907627,
			},
		};

		let affiliateKey = mapping['default'][this.countryCode];
		if (params['gclid'] && mapping['gclid'][this.countryCode]) {
			affiliateKey = mapping['gclid'][this.countryCode];
		}
		if (
			params['channel'] === 'display' &&
			mapping['channel'][this.countryCode]
		) {
			affiliateKey = mapping['channel'][this.countryCode];
		}
		const affiliateKeyParam = this.sanitizeNumberParam(
			params['affiliatekey'] || params['ak'],
		);
		if (affiliateKeyParam) {
			affiliateKey = affiliateKeyParam;
		}

		return affiliateKey;
	}

	private setSupportedLanguages(supportedLanguages: string[]): string[] {
		if (
			[SupportedDomains.DE, SupportedDomains.AT].includes(this.countryCode) &&
			!supportedLanguages.includes('fr')
		) {
			supportedLanguages.push('fr');
			this.environmentService.addSupportedLanguage('fr');
		}

		if (
			[SupportedDomains.BE].includes(this.countryCode) &&
			!supportedLanguages.includes('en')
		) {
			supportedLanguages.push('en');
			this.environmentService.addSupportedLanguage('en');
		}

		if (
			[SupportedDomains.DE].includes(this.countryCode) &&
			!supportedLanguages.includes('it')
		) {
			supportedLanguages.push('it');
			this.environmentService.addSupportedLanguage('it');
		}
		return supportedLanguages;
	}

	private sanitizeParam(parameter: string | undefined): string | undefined {
		if (parameter) {
			return (
				this.sanitizer.sanitize(SecurityContext.URL, parameter) || undefined
			);
		}
		return undefined;
	}

	private sanitizeNumberParam(
		parameter: string | undefined,
	): number | undefined {
		const sanitized = this.sanitizeParam(parameter);
		return sanitized ? parseInt(sanitized) : undefined;
	}

	private handleLanguage(language: string, supportedLanguages: string[]): void {
		if (supportedLanguages.includes(language)) {
			// Wait until translated router is completely set up
			setTimeout(() => {
				this.localeHelper.language = language;
			}, 1000);
			this.url?.searchParams.delete('languageNotSupported');
		} else {
			this.url?.searchParams.set('languageNotSupported', '1');
		}
	}

	private handleCustomAffiliateData(
		styleProfile: number | undefined,
		contentProfile: number | undefined,
		affiliateVersion: string | undefined,
	): void {
		const data: CustomAffiliateStorageData = {
			status: true,
			styleProfile,
			contentProfile,
			affiliateVersion,
		};
		sessionStorage.setItem(customAffiliateStorageKey, JSON.stringify(data));
	}

	private shouldRedirectToCarSelection(
		params: DeepLinkParams,
		carSelectionRoutePath: string,
		isPromoCodeValid: boolean,
	): boolean {
		if (this.url?.pathname.includes(carSelectionRoutePath)) {
			return false;
		}

		return !!(
			!params.skipRedirect &&
			((params.pickupRegionId && params.pickupDate) ||
				(params.iataCode && params.pickupDate)) &&
			(!params.promocode || (params.promocode && isPromoCodeValid))
		);
	}

	private handleRedirect(
		params: DeepLinkParams,
		carSelectionRoutePath: string,
		supportedLanguages: string[],
		isPromoCodeValid: boolean,
		isNonAffiliateUrl?: boolean,
	): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		if (
			this.shouldRedirectToCarSelection(
				params,
				carSelectionRoutePath,
				isPromoCodeValid,
			)
		) {
			if (isNonAffiliateUrl) {
				params.isNonAffiliate = true;
			}
			const language =
				params.language && supportedLanguages.includes(params.language)
					? params.language
					: undefined;
			const baseUrl = this.urlHelper.getApplicationUrlForDomainAndLanguage(
				this.applicationUrls.bookingUrl,
				this.countryCode as unknown as SupportedDomainValues,
				language === this.translate.currentLang ? language : undefined,
				this.envLanguages?.default || this.countryCode.toLowerCase(),
				carSelectionRoutePath,
				undefined,
				false,
			);
			const routeParams = this.getRedirectParams(params);
			const urlParams = new URLSearchParams(routeParams);

			window.location.replace(`${baseUrl}?${urlParams}`);
		} else if (this.url?.toString() !== new URL(location.href).toString()) {
			window.history.replaceState({}, '', this.url);
		}
	}

	private getRedirectParams(params: DeepLinkParams): Params {
		const data: Params = {
			ak: params.affiliateKey,
			iata: params.iataCode,
			pickupRegion: params.pickupRegionId,
			pickupLocation: params.pickupLocationId,
			pickupType: params.pickupType,
			pickupDate: params.pickupDate,
			pickupTime: params.pickupTime,
			dropoffRegion: params.dropoffRegionId,
			dropoffLocation: params.dropoffLocationId,
			dropoffType: params.dropoffType,
			dropoffDate: params.dropoffDate,
			dropoffTime: params.dropoffTime,
			styleProfile: params.styleProfile,
			contentProfile: params.contentProfile,
			affiliateReference: params.affiliateReference,
			affiliateReferenceSystem: params.affiliateReferenceSystem,
			affiliateVersion: params.affiliateVersion,
			promocode: params.promocode,
			driverAge: params.driverAge,
			nonAffiliate: params.isNonAffiliate || undefined,
			vehicleId: params.vehicleId,
			salesSeasonId: params.salesSeasonId,
			language:
				params.language !== this.translate.currentLang
					? params.language
					: undefined,
		};

		const validatedPickupDate = this.validators.getValidatedPickupDate(
			params.pickupDate || '',
		);
		const validatedDropoffDate = this.validators.getValidatedDropoffDate(
			params.dropoffDate || '',
			validatedPickupDate,
		);
		data['pickupDate'] = validatedPickupDate.substring(0, 10);
		data['dropoffDate'] = validatedDropoffDate.substring(0, 10);
		if (!data['dropoffRegionId']) {
			data['dropoffRegionId'] = params.pickupRegionId;
		}
		if (!data['pickupTime']) {
			data['pickupTime'] = '10:00';
		}
		if (!data['dropoffTime']) {
			data['dropoffTime'] = '10:00';
		}
		Object.keys(data).forEach((key) => {
			if (data[key] === undefined) {
				delete data[key];
			}
		});

		return data;
	}
}
