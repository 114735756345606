import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { LazyImageLoadingDirective } from '@sunny-cars/util-global/lib/directives/lazy-image/lazy-image.directive';
import { EasterHelper } from '@sunny-cars/util-global/lib/helpers/easter/easter.helper';
import { HeaderConfigurationLogo } from '@sunny-cars/util-global/lib/interfaces/affiliates/affiliates.interface';
import { LogoTypes } from '@sunny-cars/util-global/lib/types/affiliate/logo.type';

@Component({
	selector: 'ui-logo',
	templateUrl: './logo.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, LazyImageLoadingDirective, TranslateModule],
})
export class LogoComponent {
	@Input() isCustomAffiliate: boolean | null = false;
	@Input() logo?: HeaderConfigurationLogo | null;

	logoTypes = LogoTypes;
	isEaster = false;

	constructor(private readonly easterHelper: EasterHelper) {
		this.isEaster = this.easterHelper.isEaster();
	}
}
