<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
>
	<ui-heading-content-block
		*ngIf="content.heading || content.content"
		class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
		[heading]="content.heading"
		[intro]="content.content"
		[headingLevel]="content.headingType"
	></ui-heading-content-block>
	<ui-collection [isList]="content.isHorizontal">
		<ui-card
			*ngFor="let card of content.cards"
			[elevated]="content.useShadow"
			[useFullHeight]="true"
			[imageUrl]="card.attributes.image?.src ?? ''"
			[imageAlt]="card.attributes.image?.alt ?? ''"
			[imageWidth]="card.attributes.image?.width ?? undefined"
			[imageHeight]="card.attributes.image?.height ?? undefined"
			[link]="card.attributes.link"
			[showButton]="!!card.attributes.link?.text"
			[isHorizontal]="content.isHorizontal"
			[useMaxWidth]="!content.isHorizontal && !!card.attributes.image"
			[title]="card.attributes.title"
			[text]="card.attributes.content"
		></ui-card>
	</ui-collection>
</section>
