import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { DateTimeFormats } from '@sunny-cars/util-global';
import { B2BPortalItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/b2b-portal-items.interface';
import { DateTimePipe } from '@sunny-cars/util-global/lib/pipes/date-time/date-time.pipe';
import { B2BCardComponent } from '../b2b-card/b2b-card.component';

@Component({
	selector: 'ui-b2b-cards',
	templateUrl: './b2b-cards.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, B2BCardComponent, DateTimePipe],
})
export class B2BCardsComponent {
	@Input() content!: B2BPortalItemsComponentInterface;
	@Input() isHorizontal = false;

	readonly dateTimeFormats = DateTimeFormats;
}
