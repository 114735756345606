<article class="h-full w-full rounded-large bg-ui-low p-4 text-lg">
	<strong class="hyphens-auto text-xl [overflow-wrap:anywhere]">
		<ui-icon
			class="mr-1 align-text-bottom"
			classModifiers="h-5 w-5"
			*ngIf="icon"
			[name]="icon"
			inlined
			customSize
		></ui-icon>
		{{ title }}
	</strong>
	<div class="leading-tight" *ngIf="content" [innerHtml]="content"></div>
	<a
		*ngIf="link && link.startsWith('http')"
		[href]="link"
		class="text-secondary underline"
	>
		{{ 'global.read-more-action' | translate }}
	</a>
	<a
		*ngIf="link && !link.startsWith('http')"
		[routerLink]="link"
		class="text-secondary underline"
	>
		{{ 'global.read-more-action' | translate }}
	</a>
</article>
