export type Font =
	| 'CALIBRI'
	| 'VERDANA'
	| 'HELVETICA_NEUE'
	| 'GEORGIA'
	| 'OPEN_SANS'
	| 'MONTSERRAT';

export enum FontTypes {
	CALIBRI = 'Calibri',
	VERDANA = 'Verdana',
	HELVETICA_NEUE = 'Helvetica Neue',
	GEORGIA = 'Georgia',
	OPEN_SANS = 'Open Sans',
	MONTSERRAT = 'Montserrat',
}
