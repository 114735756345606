import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Sizes } from '@sunny-cars/util-global';
import { CoverComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cover.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-cover',
	templateUrl: 'cover.component.html',
	standalone: true,
	imports: [CommonModule, ImageComponent, HeadingContentBlockComponent],
})
export class CoverComponent {
	@Input() content?: CoverComponentInterface;
	sizes = Sizes;
}
