<div class="mb-3">
	<ui-password-input
		ngDefaultControl
		[formControl]="formControl"
		[formlyAttributes]="field"
		[attr.name]="field.id || ''"
		[controlId]="field.id || ''"
		[isInvalid]="showError"
		[attr.value]="formControl.value"
	></ui-password-input>
</div>
<ui-password-strength-indicator
	ngDefaultControl
	[value]="formControl.value"
	[isValid]="!formControl.errors"
	[userData]="getFormValues()"
>
</ui-password-strength-indicator>
