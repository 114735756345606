<form [formGroup]="form" (ngSubmit)="searchTravelAgencies()">
	<div class="flex">
		<ui-form-control class="w-full" [isHiddenLabel]="true">
			<input
				ui-form-control-control
				class="input rounded-br-none rounded-tr-none"
				formControlName="search"
				(ngModelChange)="isFormSubmitted = false"
			/>
		</ui-form-control>
		<ui-button
			class="mt-1 w-full max-w-2xs"
			[isDisabled]="isLoading || form.controls['search'].invalid"
			[isSubmit]="true"
			[styleType]="buttonStyleTypes.SECONDARY_SOLID"
			[size]="buttonSizes.L"
			[isWidthFull]="true"
			styleModifiers="rounded-bl-none rounded-tl-none"
		>
			{{ 'global.search-action' | translate }}
		</ui-button>
	</div>
</form>
<section
	class="mt-6 flex flex-col gap-6"
	*ngIf="travelAgencies$ | async as travelAgencies"
>
	<ui-dialog
		*ngIf="isFormSubmitted && !travelAgencies.length && !isLoading"
		[type]="dialogTypes.ERROR"
		[title]="'components.travel-agencies.no-results-text' | translate"
	></ui-dialog>
	<ng-container *ngIf="isFormSubmitted && form.valid">
		<ui-card
			*ngFor="let agency of travelAgencies | slice: 0 : travelAgencyCounter * 6"
			[title]="agency.name"
			[subtitle]="
				agency.street +
				', ' +
				agency.postalCode +
				' ' +
				agency.city +
				', ' +
				agency.phoneNumber
			"
			[link]="
				agency.url
					? {
							href: agency.url,
							rel: '',
							shouldOpenInNewTab: true,
							text: '',
							title: ''
						}
					: undefined
			"
			[elevated]="true"
			[useFullHeight]="true"
			[useMaxWidth]="false"
		>
		</ui-card>
		<ui-button
			*ngIf="travelAgencyCounter * 6 < travelAgencies.length"
			[isWidthFull]="true"
			[styleType]="buttonStyleTypes.SECONDARY_SOLID"
			(click)="travelAgencyCounter = travelAgencyCounter + 1"
		>
			{{ 'components.travel-agencies.show-more-action' | translate }}
		</ui-button>
	</ng-container>
</section>
