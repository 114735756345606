import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SiteEntityInterface } from './interfaces/parsed/site.interface';
import { SiteResponseInterface } from './interfaces/response/site-response.interface';
import { SiteHelper } from './site.helper';

@Injectable({
	providedIn: 'root',
})
export class SiteService {
	constructor(
		private readonly http: HttpClient,
		private readonly environmentService: EnvironmentService,
	) {}

	getSite(language: string): Observable<SiteEntityInterface | undefined> {
		const baseUrl = this.environmentService.getDomainConfig().api.cms.baseUrl;
		const siteDomain =
			this.environmentService.getDomainConfig().api.cms.settings.siteDomain;
		const headers: HttpHeaders = new HttpHeaders({
			sitedomain: siteDomain,
		});

		return this.http
			.get<SiteResponseInterface>(`${baseUrl}v1/site`, {
				headers,
			})
			.pipe(
				map((site: SiteResponseInterface) => {
					return SiteHelper.parseSiteResponse(site.data, language);
				}),
			);
	}
}
