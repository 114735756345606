<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
>
	<ui-heading-content-block
		*ngIf="content.heading || content.content"
		class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
		[heading]="content.heading"
		[subheading]="content.subheading"
		[intro]="content.content"
		[headingLevel]="content.headingType"
	></ui-heading-content-block>
	<ui-collection>
		<ng-container *ngIf="!isElevated">
			<ui-box
				*ngFor="let item of content.items"
				[content]="item.attributes.content"
				[icon]="item.attributes.icon"
				[title]="item.attributes.title"
				[link]="item.attributes.link?.href || ''"
			></ui-box>
		</ng-container>
		<ng-container *ngIf="isElevated">
			<ui-card
				*ngFor="let item of content.items"
				[text]="item.attributes.content"
				[icon]="item.attributes.icon"
				[title]="item.attributes.title"
				[link]="{
					href: item.attributes.link?.href || '',
					rel: '',
					shouldOpenInNewTab: item.attributes.link?.shouldOpenInNewTab || false,
					text: '',
					title: ''
				}"
				[elevated]="true"
				[useFullHeight]="true"
				[useMaxWidth]="false"
			>
			</ui-card>
		</ng-container>
	</ui-collection>
</section>
