<ng-template #logoTemplate let-url="url" let-urlMobile="urlMobile">
	<picture>
		<source media="(max-width: 639px)" [srcset]="urlMobile" />
		<source media="(min-width: 640px)" [srcset]="url" />
		<img
			class="h-7 w-auto sm:h-9"
			[src]="url"
			[alt]="'global.logo-alt-text' | translate"
			width="auto"
			height="auto"
			loading="eager"
			data-test-class="logo"
		/>
	</picture>
</ng-template>
<ng-template #defaultLogoTemplate>
	<ng-container
		*ngTemplateOutlet="
			logoTemplate;
			context: {
				urlMobile: isEaster
					? '/assets/ui/images/forest-logo.png'
					: '/assets/images/logo.svg',
				url: isEaster
					? '/assets/ui/images/forest-logo.png'
					: '/assets/images/logo-text.svg'
			}
		"
	>
	</ng-container>
</ng-template>

<ng-container *ngIf="!isCustomAffiliate || !logo">
	<ng-container *ngTemplateOutlet="defaultLogoTemplate"> </ng-container>
</ng-container>
<ng-container *ngIf="logo && isCustomAffiliate" [ngSwitch]="logo.type">
	<ng-container *ngSwitchCase="logoTypes.SUNNY">
		<ng-container *ngTemplateOutlet="defaultLogoTemplate"> </ng-container>
	</ng-container>
	<ng-container *ngSwitchCase="logoTypes.CUSTOM">
		<ng-container
			*ngTemplateOutlet="
				logoTemplate;
				context: {
					urlMobile: logo.mobileURL,
					url: logo.desktopURL
				}
			"
		>
		</ng-container>
	</ng-container>
</ng-container>
