import { Action, createReducer, on } from '@ngrx/store';
import { ContactDetails } from '@sunny-cars/util-global';
import * as ContactDetailsActions from './contact-details.actions';

export const CONTACT_DETAILS_FEATURE_KEY = 'contact-details';

export interface State {
	contactDetails?: ContactDetails;
	expiresDateTime?: string;
	isLoaded?: boolean;
	isLoading: boolean;
	error: string | null;
}

export const initialState: State = {
	isLoading: false,
	error: null,
};

const contactDetailsReducer = createReducer(
	initialState,
	on(ContactDetailsActions.init, (state) => ({
		...state,
		isLoading: false,
		error: null,
	})),
	on(ContactDetailsActions.loadContactDetails, (state) => ({
		...state,
		isLoading: true,
		isLoaded: false,
		error: null,
	})),
	on(
		ContactDetailsActions.loadContactDetailsSuccess,
		(state, { contactDetails, expires }) => ({
			...state,
			contactDetails,
			expiresDateTime: expires,
			isLoading: false,
			isLoaded: true,
		}),
	),
	on(ContactDetailsActions.loadContactDetailsFailure, (state, { error }) => ({
		...state,
		contactDetails: undefined,
		expiresDateTime: undefined,
		isLoading: false,
		isLoaded: false,
		error,
	})),
	on(ContactDetailsActions.clearError, (state) => ({
		...state,
		error: null,
	})),
);

export function reducer(state: State | undefined, action: Action): State {
	return contactDetailsReducer(state, action);
}
