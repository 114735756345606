<section
	class="flex w-full flex-col items-start gap-0 md:gap-10"
	[ngClass]="{
		'md:flex-row': headingContentPosition === textPosition.LEFT,
		'md:flex-col': headingContentPosition === textPosition.TOP,
		'md:flex-row-reverse': headingContentPosition === textPosition.RIGHT
	}"
>
	<ui-heading-content-block
		*ngIf="title || text"
		class="w-full"
		[ngClass]="{
			'md:w-full': headingContentPosition === textPosition.TOP,
			'md:w-1/3':
				headingContentPosition === textPosition.LEFT ||
				headingContentPosition === textPosition.RIGHT
		}"
		[heading]="title"
		[headingLevel]="headingLevels.H2"
		[intro]="text"
	></ui-heading-content-block>
	<section
		class="flex w-full flex-wrap"
		[ngClass]="{
			'md:w-full':
				(!title && !text) || headingContentPosition === textPosition.TOP,
			'md:w-2/3': headingContentPosition !== textPosition.TOP
		}"
		*ngIf="questions && questions?.length"
	>
		<ui-accordion-item
			*ngFor="let question of questions; let index = index"
			[title]="question.title"
			[isOpen]="!!question.isVisible"
			(toggle)="setVisible(undefined, index)"
			detailStyleModifiers="border-b-1 border-ui-high"
			summaryStyleModifiers="p-2.5 md:px-6 md:py-5"
			class="w-full"
		>
			<div
				class="prose mx-2.5 ml-5 pb-2.5 text-base md:mx-6 md:pb-5 md:pl-6 md:text-lg"
				[innerHTML]="question.text | SafeHtml"
			></div>
		</ui-accordion-item>
	</section>
</section>
