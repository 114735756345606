import { RecursivePartial } from '../../types/recursive-partial/recursive-partial.type';
/* eslint-disable  @typescript-eslint/no-explicit-any */

/**
 * Merge the given object current with the given partial object update , recursively.
 */
export const mergeObjects = <T>(current: T, update: RecursivePartial<T>): T => {
	for (const _key of Object.keys(update)) {
		const key = _key as keyof typeof update;
		if (isObject(update[key]) && isObject(current[key])) {
			current[key] = mergeObjects(current[key], update[key] as any);
		} else if (update[key] !== undefined && current[key] !== undefined) {
			current[key] = update[key] as any;
		}
	}

	return current;
};

const isObject = (value: unknown): boolean => {
	return typeof value === 'object' && !Array.isArray(value) && value !== null;
};
