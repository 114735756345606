import { Injectable } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import {
	PaymentMethodItem,
	PaymentMethodKey,
	PaymentMethodsIcons,
} from '@sunny-cars/util-global/lib/interfaces/payment-method/payment-method';

marker('payment-methods.ideal');
marker('payment-methods.paypal');
marker('payment-methods.amazonpay');
marker('payment-methods.bancontact');
marker('payment-methods.giropay');
marker('payment-methods.cc3dsecure');
marker('payment-methods.debitcash');
marker('payment-methods.sepa');

@Injectable({
	providedIn: 'root',
})
export class PaymentOptionsService {
	static mapPaymentMethodKeysToPaymentMethodItems(
		paymentMethodKeys?: PaymentMethodKey[],
	): PaymentMethodItem[] {
		if (!paymentMethodKeys) {
			return [];
		}
		return paymentMethodKeys.map((paymentMethodKey: PaymentMethodKey) => ({
			label: `payment-methods.${paymentMethodKey}`,
			icon: PaymentMethodsIcons[paymentMethodKey],
		}));
	}
}
