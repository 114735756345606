import {
	APIInternationalAddressDetails,
	APIPostalAddressRequest,
	APIPostalAddressResponse,
} from '../interfaces';

const NO_DEFAULT_ADDRESS_COUNTRY = 'The Netherlands';

/**
 * Map international address request
 * @param {APIPostalAddressRequest} postalAddressRequest
 * @param {boolean} isDefaultAddressForm
 * @returns {APIPostalAddressRequest}
 */
export function mapInternationalAddressRequest(
	postalAddressRequest: APIPostalAddressRequest,
	isDefaultAddressForm: boolean,
): APIPostalAddressRequest {
	if (!isDefaultAddressForm) {
		return {
			postalCode: postalAddressRequest.postalCode,
			houseNumber: postalAddressRequest.houseNumber,
			houseNumberAddition: postalAddressRequest.houseNumberAddition,
			country: postalAddressRequest.country,
		};
	}

	return postalAddressRequest;
}

/**
 * MapAddressHouseNumberFields
 * @param {APIInternationalAddressDetails} internationalAddressDetails
 * @returns {APIInternationalAddressDetails}
 */
function mapAddressHouseNumberFields(
	internationalAddressDetails: APIInternationalAddressDetails,
): APIInternationalAddressDetails {
	if (
		internationalAddressDetails.country !== NO_DEFAULT_ADDRESS_COUNTRY &&
		internationalAddressDetails.street
	) {
		internationalAddressDetails.street +=
			internationalAddressDetails.buildingnumber
				? ` ${internationalAddressDetails.buildingnumber}`
				: '';
		internationalAddressDetails.street +=
			internationalAddressDetails.buildingnumberaddition
				? ` ${internationalAddressDetails.buildingnumberaddition}`
				: '';
	}
	return internationalAddressDetails;
}

/**
 * Map international address details
 * @param {APIInternationalAddressDetails} internationalAddressDetails
 * @returns {APIPostalAddressResponse}
 */
export function mapInternationalAddressDetailsResponse(
	internationalAddressDetails: APIInternationalAddressDetails,
): APIPostalAddressResponse {
	// Some default countries street and address form mappings
	internationalAddressDetails = mapAddressHouseNumberFields(
		internationalAddressDetails,
	);

	return {
		city: internationalAddressDetails.locality ?? '',
		houseNumber: !internationalAddressDetails.buildingnumber
			? ''
			: internationalAddressDetails.buildingnumber + '',
		houseNumberAddition:
			internationalAddressDetails.buildingnumberaddition ?? '',
		postalCode: internationalAddressDetails.postcode ?? '',
		street: internationalAddressDetails.street ?? '',
	};
}
