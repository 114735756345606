<div
	class="sticky left-0 top-0 z-10 flex items-center justify-between bg-ui-root-transparent p-3 px-4"
>
	<strong>{{ title }}</strong>
	<ui-button
		[size]="buttonSizes.S"
		[styleType]="buttonStyleTypes.SECONDARY_TEXT"
		(buttonClick)="close()"
	>
		{{ 'global.close-action' | translate }}
	</ui-button>
</div>
