import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class LinkService {
	private renderer: Renderer2;

	constructor(
		private readonly rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	addTag(tag: LinkDefinition) {
		const link = this.renderer.createElement('link');
		const head = this.document.head;

		Object.keys(tag).forEach((prop: string) => {
			return this.renderer.setAttribute(link, prop, tag[prop]);
		});

		this.renderer.appendChild(head, link);
	}
}

export declare type LinkDefinition = {
	charset?: string;
	crossorigin?: string;
	href?: string;
	hreflang?: string;
	media?: string;
	rel?: string;
	rev?: string;
	sizes?: string;
	target?: string;
	type?: string;
} & {
	[prop: string]: string;
};
