import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FaqTextPosition } from '@sunny-cars/util-global/lib/interfaces/content/faq.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { SafeHtmlPipe } from '@sunny-cars/util-global/lib/pipes/safe-html/safe-html.pipe';
import { AccordionItemComponent } from '../accordion-item/accordion-item.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';

export interface Faq {
	text: string;
	title: string;
	isVisible?: boolean;
	category?: string;
}

@Component({
	selector: 'ui-accordion',
	templateUrl: './accordion.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		AccordionItemComponent,
		SafeHtmlPipe,
	],
})
export class AccordionComponent implements OnInit {
	@Input() keepQuestionsVisible = true;
	@Input() title = '';
	@Input() text = '';
	@Input() questions?: Faq[] = [];
	@Input() headingContentPosition: FaqTextPosition = FaqTextPosition.TOP;

	textPosition = FaqTextPosition;
	headingLevels = HeadingLevels;

	ngOnInit(): void {
		if (this.questions?.length) {
			this.questions = this.setQuestionVisibility(this.questions);
		}
	}

	setVisible(event: Event | undefined, index: number): void {
		event?.preventDefault();

		if (!this.questions || index + 1 > this.questions.length) {
			return;
		}

		if (this.keepQuestionsVisible) {
			this.questions[index].isVisible = !this.questions[index].isVisible;
		} else {
			this.questions = this.setQuestionVisibility(this.questions, index);
		}
	}

	private setQuestionVisibility(questions: Faq[], visibleIndex = 0): Faq[] {
		return questions.map((question, index) => ({
			title: question.title,
			text: question.text,
			isVisible: index === visibleIndex ? !questions[index].isVisible : false,
		}));
	}
}
