<section [id]="anchor" class="{{ widthClass }}">
	<ui-heading-content-block
		*ngIf="title || content"
		[headingLevel]="headingLevels.H2"
		[heading]="countries ? title : ''"
		[intro]="countries ? content : ''"
		class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
		customHeadingClasses="text-xl font-semibold text-subheading sm:text-2xl"
	></ui-heading-content-block>
	<div
		[ngClass]="{
			'before:bg-ui-low sm:flex-row sm:gap-0 sm:before:absolute sm:before:-left-8 sm:before:-right-8 sm:before:bottom-8 sm:before:top-8 lg:before:-left-10 lg:before:-right-10 lg:before:bottom-20 lg:before:top-20':
				isDestinationPage
		}"
		class="relative flex flex-col justify-end gap-3"
	>
		<!-- Side menu if destination page, bottom menu on other page types -->
		<div
			*ngIf="countries || subRegions"
			[ngClass]="{
				'left-0 z-10 shadow-md sm:absolute sm:bottom-4 sm:top-4 sm:w-[calc(33.33%+2rem)] lg:bottom-10 lg:top-10 lg:w-[calc(33%+2.5rem)]':
					isDestinationPage,
				'order-2 w-full': !isDestinationPage
			}"
			class="flex flex-col overflow-y-auto rounded bg-ui-root"
		>
			<ng-container *ngIf="countries && !isDestinationPage">
				<nav class="columns-2xs gap-8 border-t border-ui-moderate py-4">
					<a
						*ngFor="let country of countries"
						[routerLink]="path + slugify(country.name)"
						class="block border-b border-ui-moderate bg-icon-chevron-right bg-2.5 bg-right-4-center bg-no-repeat pb-2 pl-4 pr-11 pt-2.5 focus-within:bg-secondary-high focus-within:bg-icon-chevron-right-white focus-within:text-white hover:bg-secondary-low active:bg-secondary-moderate"
					>
						{{ country.name }}
					</a>
				</nav>
			</ng-container>
			<ui-region-map-list
				*ngIf="region"
				[path]="path"
				[region]="region"
				[title]="title"
			></ui-region-map-list>
			<ui-region-map-list
				*ngIf="countryRegion"
				[path]="path"
				[region]="countryRegion"
				[title]="
					'region-map.all-destinations.title'
						| translate: { country: countryName }
				"
			></ui-region-map-list>
		</div>
		<div
			[ngClass]="{
				'sm:w-2/3': isDestinationPage,
				'sm:w-full': !isDestinationPage
			}"
		>
			<div
				class="relative w-full overflow-hidden rounded"
				data-smart-data-protector
				[ngClass]="{
					'pb-3/4 shadow-md': isDestinationPage,
					'pb-[41.67%]': !isDestinationPage
				}"
				class="relative w-full overflow-hidden rounded"
			>
				<ng-container *ngIf="isMapLoaded$ | async">
					<google-map
						[id]="controlId"
						[options]="mapOptions"
						class="absolute h-full w-full"
					>
						<map-marker-clusterer [styles]="[markerClusterIconStyle]">
							<map-marker
								#markerElem="mapMarker"
								(mapClick)="openInfo(markerElem, marker.title)"
								*ngFor="let marker of markers"
								[icon]="marker.icon"
								[position]="marker.position"
							>
							</map-marker>
							<map-info-window
								[innerHTML]="infoContent"
								[routerLink]="infoWindowRouterLink"
								class="cursor-pointer"
							></map-info-window>
						</map-marker-clusterer>
					</google-map>
				</ng-container>
			</div>
		</div>
	</div>
</section>
