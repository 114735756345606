import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { BlogComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/blog.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { CardComponent } from '../card/card.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-blog',
	templateUrl: './blog.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		CollectionComponent,
		CardComponent,
	],
})
export class BlogComponent {
	@Input() content?: BlogComponentInterface;
	@Input() isDestinationPage = false;
	readonly headingLevels = HeadingLevels;
}
