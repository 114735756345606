<section>
	<ui-heading-content-block
		class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
		[heading]="heading"
		[headingLevel]="headingType"
		[intro]="content"
	>
	</ui-heading-content-block>
	<div
		class="[&>*]:max-w-full"
		*ngIf="html"
		[innerHtml]="html | SafeHtml"
	></div>
</section>
