<span
	class="relative block {{ classModifiers }}"
	[ngClass]="{
		'inline-block h-4 w-4 align-middle': inlined !== undefined,
		'h-2 w-2': xs !== undefined,
		'h-3 w-3': sm !== undefined,
		'h-4 w-4':
			xs === undefined &&
			sm === undefined &&
			lg === undefined &&
			xl === undefined &&
			customSize === undefined,
		'h-8 w-8': lg !== undefined,
		'h-10 w-10': xl !== undefined
	}"
	[innerHTML]="sanitizedIcon"
	aria-hidden="true"
>
</span>
