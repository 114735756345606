import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	ControlValueAccessor,
	FormsModule,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BaseControlComponent } from '../base.control';
import { SegmentedControlInterface } from './segmented-control.interface';

@Component({
	selector: 'ui-segmented-control[name]',
	templateUrl: './segmented-control.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: SegmentedControlComponent,
			multi: true,
		},
	],
	standalone: true,
	imports: [CommonModule, FormsModule],
})
export class SegmentedControlComponent
	extends BaseControlComponent
	implements ControlValueAccessor
{
	@Input() controlOptions: SegmentedControlInterface[] | undefined;
	@Input() name!: string;
	@Input() sm: string | undefined;

	value = '';

	inputChange(value: string): void {
		this.onChange(value);
		this.value = value;
	}

	/**
	 * Handle value set by form
	 */
	override writeValue(value: string): void {
		this.value = value;
	}
}
