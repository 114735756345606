import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	RegionDestinationAPI,
	RegionDestinationParentAPI,
	RegionDestinationsResponseAPI,
} from '@sunny-cars/util-api-interfaces';
import {
	RegionDestination,
	RegionType,
	SourceType,
} from '@sunny-cars/util-global';
import { Observable, map } from 'rxjs';
import {
	CoordinatesParams,
	RegionDestinationsByCoordsResponseAPI,
} from './region.interfaces';

@Injectable({
	providedIn: 'root',
})
export class RegionsService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl')
		private readonly apiBaseUrl: string,
	) {}

	fetchRegionWithSubregions(
		regionId: number,
	): Observable<RegionDestination | undefined> {
		return this.http
			.get<RegionDestinationsResponseAPI>(
				`${this.apiBaseUrl}v1/regions/${regionId}/subregions`,
			)
			.pipe(map((response) => this.parseRegionWithSubregions(response.data)));
	}

	fetchRegionsByCoordinates(
		latitude: number,
		longitude: number,
		source: SourceType,
		affiliatekey: number,
		radius?: number,
	): Observable<RegionDestination[] | undefined> {
		const params: CoordinatesParams = {
			latitude,
			longitude,
			source,
			affiliatekey,
		};

		if (radius) {
			params.radius = radius;
		}

		return this.http
			.get<RegionDestinationsByCoordsResponseAPI>(
				`${this.apiBaseUrl}v1/regions`,
				{
					params: { ...params },
				},
			)
			.pipe(map((response) => this.parseRegionRegionsByCoords(response.data)));
	}

	private parseRegionRegionsByCoords(
		regions: RegionDestinationAPI[] | undefined,
	): RegionDestination[] | undefined {
		if (!regions || !regions.length) {
			return undefined;
		}

		return regions.map((region) => ({
			id: region.id,
			name: region.name,
			type: region.type as RegionType,
			geo: { lat: region.geo?.lat || 0, lng: region.geo?.lng || 0 },
			children: [],
		}));
	}

	private parseRegionWithSubregions(
		region: RegionDestinationParentAPI | undefined,
	): RegionDestination | undefined {
		if (!region) {
			return undefined;
		}
		return {
			id: region.id,
			name: region.name,
			type: region.type as RegionType,
			geo: { lat: region.geo?.lat || 0, lng: region.geo?.lng || 0 },
			children: this.parseChildren(region.children),
			countryRegionID: region.countryRegionID,
			countryRegionName: region.countryRegionName,
		};
	}

	private parseChildren(
		children: RegionDestinationAPI[] | undefined,
	): RegionDestination[] {
		if (!children) {
			return [];
		}

		const parsedChildren: RegionDestination[] = [];

		children.forEach((child) => {
			parsedChildren.push({
				id: child.id,
				name: child.name,
				type: child.type as RegionType,
				geo: { lat: child.geo?.lat || 0, lng: child.geo?.lng || 0 },
				children: this.parseChildren(child.children),
			});
		});

		return parsedChildren;
	}
}
