<section class="rounded-medium bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10">
	<form [formGroup]="formGroup" #subscribeForm="ngForm">
		<div
			class="flex"
			[ngClass]="{
				'flex-wrap lg:flex-nowrap': image
			}"
		>
			<ui-image
				*ngIf="image?.src"
				class="mx-auto w-full max-w-sm self-center pb-4 xs:pb-6 sm:pb-8 lg:w-1/2 lg:max-w-none lg:pb-0"
				className="w-full inline-block"
				[sources]="[
					{
						min: null,
						max: sizes.SM,
						width: 480,
						height: 480
					},
					{
						min: sizes.SM,
						max: sizes.LG,
						width: 610,
						height: 610
					},
					{
						min: sizes.LG,
						max: null,
						width: 390,
						height: 390
					}
				]"
				[image]="{
					src: image?.src || '',
					altText: image?.alt,
					height: 610,
					width: 610
				}"
			></ui-image>
			<div
				class="self-center"
				[ngClass]="{
					'md:pl-8 lg:pl-10': image
				}"
			>
				<ui-heading-content-block
					[heading]="title"
					[subheading]="subtitle"
					[customHeadingClasses]="
						'mb-2 font-semibold text-subheading text-xl sm:text-2xl'
					"
					[customSubHeadingClasses]="
						'mb-2 font-semibold text-subheading text-lg sm:text-xl'
					"
					[headingLevel]="headingLevels.H2"
				></ui-heading-content-block>
				<ng-container [ngSwitch]="subscribed$ | async">
					<ng-container *ngSwitchCase="'success'">
						<ui-dialog
							*ngIf="!isFormVisible"
							[type]="dialogTypes.SUCCESS"
							[title]="
								'components.newsletter.success-dialog-title'
									| translate : { email: model.email }
							"
						>
							<a
								(click)="resetForm(subscribeForm)"
								class="cursor-pointer underline"
							>
								{{ 'components.newsletter.success-dialog-text' | translate }}
							</a>
						</ui-dialog>
					</ng-container>
					<ui-dialog
						*ngSwitchCase="'error'"
						[type]="dialogTypes.ERROR"
						[title]="'components.newsletter.error-dialog-title' | translate"
					>
						{{ 'components.newsletter.error-dialog-text' | translate }}
					</ui-dialog>
				</ng-container>
				<div
					*ngIf="isFormVisible"
					[ngClass]="{
						'pb-4': socials && isShowingSocialMediaIcons
					}"
				>
					<div class="flex flex-wrap gap-2">
						<formly-form
							class="flex-auto"
							[form]="formGroup"
							[fields]="fields"
							[model]="model"
						></formly-form>
						<button
							class="btn w-full p-2.5 font-bold"
							type="submit"
							[ngClass]="[
								buttonPosition === 'right' ? 'sm:w-auto' : 'w-full',
								buttonPosition === 'right' &&
								!formGroup.valid &&
								(formGroup.touched || formSubmitted)
									? 'self-center'
									: 'self-end',
								buttonClass === 'primary' ? 'btn-primary' : 'btn-secondary'
							]"
						>
							{{ buttonText }}
						</button>
					</div>
					<p
						class="prose prose-lg mt-2 text-base text-text-tertiary"
						[innerHTML]="privacyStatement"
					></p>
				</div>
				<ui-social-media
					*ngIf="isShowingSocialMediaIcons && socials"
					color="secondary"
					[twitter]="socials.twitter"
					[facebook]="socials.facebook"
					[instagram]="socials.instagram"
					[vimeo]="socials.vimeo"
					[blog]="socials.blog"
				>
				</ui-social-media>
			</div>
		</div>
	</form>
</section>
