import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import {
	FormsModule,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormBuilder,
	UntypedFormGroup,
} from '@angular/forms';
import { BaseControlComponent } from './../base.control';
import { RadioButton } from './radio-group.interface';

@Component({
	selector: 'ui-radio-group',
	templateUrl: './radio-group.component.html',
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: RadioGroupComponent,
			multi: true,
		},
	],
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule],
})
export class RadioGroupComponent
	extends BaseControlComponent
	implements OnInit
{
	@Input() options: RadioButton[] = [];
	@Input() selectedValue?: string;

	radioGroupForm: UntypedFormGroup;

	constructor(private readonly formBuilder: UntypedFormBuilder) {
		super();

		this.radioGroupForm = this.formBuilder.group({
			value: [''],
		});
	}

	ngOnInit(): void {
		if (this.selectedValue) {
			this.radioGroupForm.patchValue({ value: this.selectedValue });
		}

		this.addSubscription(
			this.radioGroupForm.valueChanges.subscribe(() => {
				this.onChange(this.radioGroupForm.value['value']);
				this.onTouched();
			}),
		);
	}
}
