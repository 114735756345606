export enum ButtonSizes {
	S = 'S',
	M = 'M',
	L = 'L',
}

export enum ButtonSizeClasses {
	S = 'text-sm leading-none px-3 py-2.5 font-bold',
	M = 'text-base leading-none px-4 py-2.5 font-bold',
	L = 'text-lg px-4 py-2.5 font-bold',
}

export enum ButtonStyleTypes {
	PRIMARY_SOLID = 'PRIMARY_SOLID',
	PRIMARY_OUTLINE = 'PRIMARY_OUTLINE',
	PRIMARY_TEXT = 'PRIMARY_TEXT',

	SECONDARY_SOLID = 'SECONDARY_SOLID',
	SECONDARY_OUTLINE = 'SECONDARY_OUTLINE',
	SECONDARY_TEXT = 'SECONDARY_TEXT',

	UI_SOLID = 'UI_SOLID',
	UI_OUTLINE = 'UI_OUTLINE',
}

export enum StyleClasses {
	PRIMARY_SOLID = 'text-white bg-primary rounded-medium hover:bg-primary-high active:bg-primary-critical disabled:opacity-40 active:outline-0',
	PRIMARY_OUTLINE = 'text-primary border-2 border-primary rounded-medium hover:border-primary-high hover:text-primary-high active:border-primary-critical active:text-primary-critical disabled:opacity-40 active:outline-0',
	PRIMARY_TEXT = 'text-primary rounded-medium hover:bg-primary-low hover:text-primary-high active:text-primary-critical active:bg-transparent disabled:opacity-40 active:outline-0',

	SECONDARY_SOLID = 'text-white bg-secondary rounded-medium hover:bg-secondary-high active:bg-secondary-critical disabled:opacity-40 active:outline-0',
	SECONDARY_OUTLINE = 'text-secondary border-2 border-secondary rounded-medium hover:border-secondary-high hover:text-secondary-high active:border-secondary-critical active:text-secondary-critical disabled:opacity-40 active:outline-0',
	SECONDARY_TEXT = 'text-secondary rounded-medium hover:bg-secondary-low hover:text-secondary-high active:text-secondary-critical active:bg-transparent disabled:opacity-40 active:outline-0',

	UI_SOLID = 'bg-ui-moderate rounded-medium hover:bg-ui-high active:bg-ui-critical disabled:opacity-40 active:outline-0 focus:outline-gray',
	UI_OUTLINE = 'bg-white border-2 border-ui-critical rounded-medium hover:border-ui-important active:border-ui-high disabled:opacity-40 active:outline-0 focus:outline-gray',
}

export enum ButtonTypes {
	BUTTON = 'button',
	SUBMIT = 'submit',
}
