import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { SegmentedControlComponent as UiSegmentedControlComponent } from '@sunny-cars/ui/lib/segmented-control/segmented-control.component';

export interface SegmentedControlOption {
	label$?: Observable<string>;
	label: string;
	value: string;
}

@Component({
	selector: 'formly-field-segmented-control',
	templateUrl: 'segmented-control.component.html',
	standalone: true,
	imports: [
		UiSegmentedControlComponent,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
	],
})
export class SegmentedControlComponent extends FieldType<FieldTypeConfig> {
	constructor(private readonly translate: TranslateService) {
		super();
	}

	getControlOptions() {
		if (this.props['translate']) {
			this.props['controlOptions'].forEach((option: SegmentedControlOption) => {
				option.label$ = this.translate.stream(option.label);
			});
		}

		return this.props['controlOptions'];
	}
}
