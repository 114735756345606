import { TemplateRef } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { FormlyFieldConfig } from '@ngx-formly/core';
import dayjs from 'dayjs';
import { Observable } from 'rxjs';
import { ExistingEmailValidator } from './form-validators/existing-email/existing-email.form-validator';

marker('global.email-label');
marker('global.salutation-label');
marker('global.salutation-m-label');
marker('global.salutation-f-label');
marker('global.title-label');
marker('global.given-name-label');
marker('global.family-name-label');
marker('global.phone-number-label');
marker('global.day-label');
marker('global.month-label');
marker('global.year-label');
marker('global.day-month-year-label');
marker('global.password-label');
marker('global.birthdate');
marker('global.email-exists-dialog-title');
marker('global.email-exists-dialog-text');

export interface Entry {
	modifiedLabel?: string;
	label$?: Observable<string>;
	label: string;
	value: string | number;
	[key: string]: unknown;
}

export function getRegisterFormFormlyConfigBeforeAddress(
	existingEmailValidator: ExistingEmailValidator,
	monthOptions: Entry[],
	monthEntryTemplate: TemplateRef<Entry>,
): FormlyFieldConfig[] {
	return [
		{
			fieldGroupClassName: 'grid grid-row gap-y-2',
			fieldGroup: [
				{
					id: 'username',
					key: 'username',
					props: {
						label: 'global.email-label',
						required: true,
						translate: true,
					},
					type: 'email',
					asyncValidators: {
						'existing-email': {
							expression: existingEmailValidator.existingEmailValidator(),
							message: 'global.email-exists-dialog-title',
							supportingText: 'global.email-exists-dialog-text',
							translate: true,
							type: 'dialog',
						},
					},
				},
				{
					id: 'salutation',
					key: 'salutation',
					props: {
						label: 'global.salutation-label',
						controlOptions: [
							{ label: 'global.salutation-m-label', value: 'm' },
							{ label: 'global.salutation-f-label', value: 'f' },
						],
						translate: true,
						required: true,
					},
					type: 'segmentedControl',
				},
				{
					id: 'title',
					key: 'title',
					props: {
						label: 'global.title-label',
						translate: true,
						hideValid: true,
					},
					expressions: {
						hide: '!model.isGermanDomain',
					},
					resetOnHide: true,
					type: 'input',
				},
				{
					id: 'givenName',
					key: 'givenName',
					props: {
						label: 'global.given-name-label',
						required: true,
						translate: true,
					},
					type: 'input',
				},
				{
					id: 'familyName',
					key: 'familyName',
					props: {
						label: 'global.family-name-label',
						required: true,
						translate: true,
					},
					type: 'input',
				},
				{
					id: 'phoneNumber',
					key: 'phoneNumber',
					props: {
						label: 'global.phone-number-label',
						required: true,
						translate: true,
					},
					type: 'input',
				},
				{
					fieldGroupClassName: ' gap-y-0',
					fieldGroup: [
						{
							className: 'w-full',
							id: 'birthdaylabel',
							key: 'birthdaylabel',
							props: {
								label: 'global.birthdate',
								description: 'global.day-month-year-label',
								translate: true,
							},
							wrappers: ['label-description-append'],
						},
						{
							fieldGroupClassName: 'flex flex-auto gap-2',
							fieldGroup: [
								{
									className: 'w-1/4',
									id: 'day',
									key: 'day',
									props: {
										placeholder: 'global.day-label',
										required: true,
										translate: true,
										min: 1,
										max: 31,
										type: 'number',
									},
									type: 'input',
								},
								{
									className: 'w-1/2',
									id: 'month',
									key: 'month',
									props: {
										entries: monthOptions,
										entryTemplate: monthEntryTemplate,
										required: true,
										translate: true,
									},
									type: 'picker',
								},
								{
									className: 'w-1/3',
									id: 'year',
									key: 'year',
									props: {
										placeholder: 'global.year-label',
										required: true,
										translate: true,
										min: 1900,
										max: dayjs().year(),
										type: 'number',
									},
									type: 'input',
								},
							],
						},
					],
				},
			],
		},
		{
			wrappers: ['hr'],
		},
	];
}

export function getRegisterFormFormlyConfigAfterAddress(): FormlyFieldConfig[] {
	return [
		{
			wrappers: ['hr'],
		},
		{
			id: 'password',
			key: 'password',
			props: {
				label: 'global.password-label',
				required: true,
				translate: true,
			},
			type: 'new-password',
			validators: {
				validation: ['password'],
			},
		},
		{
			wrappers: ['hr'],
		},
	];
}
