import { Component, Input } from '@angular/core';
import { ControlValueAccessor } from '@angular/forms';
import { BaseComponent } from '@sunny-cars/util-global';

/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * Base control component
 * @augments {BaseComponent}
 * @implements {ControlValueAccessor}
 */
@Component({
	selector: 'ui-base-control',
	template: 'no-ui',
})
export class BaseControlComponent
	extends BaseComponent
	implements ControlValueAccessor
{
	/**
	 * Name of the control
	 */
	controlName = 'control' + this.getIdSuffix();
	/**
	 * Identifier of the control
	 */
	@Input() controlId = '';
	/** Whether to apply 'valid' styling */
	@Input() isValid?: boolean = false;
	/** Whether to apply 'invalid' styling */
	@Input() isInvalid?: boolean = false;
	/**
	 * Whether the control is disabled
	 */
	@Input()
	set isDisabled(value: boolean) {
		this._isDisabled = value;
	}
	/**
	 * Returns local disabled state
	 * @returns {boolean}
	 */
	get isDisabled(): boolean {
		return this._isDisabled;
	}
	/**
	 * Whether the control is readonly
	 */
	@Input()
	set isReadonly(value: boolean) {
		this._isReadonly = value;
	}
	/**
	 * Returns local readonly state
	 * @returns {boolean}
	 */
	get isReadonly(): boolean {
		return this._isReadonly;
	}

	/**
	 * Local disabled value
	 */
	_isDisabled = false;
	/**
	 * Local readonly value
	 */
	_isReadonly = false;
	/**
	 * Local value whether the control is touched
	 */
	_touched = false;
	/**
	 * Function to call on control change
	 * @param {string|string[]} _
	 * @returns {void}
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	onChange = (_: any): void => undefined;
	/**
	 * Function to call on control touch
	 * @returns {void}
	 */
	onTouched = (): void => undefined;

	/**
	 * Handle value set by form
	 * @param {any} _value
	 * @returns {void}
	 */
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	writeValue(_value: any): void {
		return;
	}

	/**
	 * Registers function to call on control change
	 * @param {Function} callback
	 * @returns {void}
	 */
	registerOnChange(callback: (_: any) => unknown): void {
		this.onChange = callback;
	}

	/**
	 * Registers function to call on control touch
	 * @param {Function} callback
	 * @returns {void}
	 */
	registerOnTouched(callback: () => unknown): void {
		this.onTouched = callback;
	}

	/**
	 * Mark control touched
	 * @returns {void}
	 */
	markAsTouched(): void {
		if (!this._touched) {
			this.onTouched();
			this._touched = true;
		}
	}

	/**
	 * Sets the control disabled state
	 * @param {boolean} isDisabled
	 * @returns {void}
	 */
	// @TODO: [SCR-9037] Improve disabled binding for custom controls. This method is only called in combination with [ngModel].
	// setDisabledState(isDisabled: boolean): void {
	// 	this._isDisabled = isDisabled;
	// }

	/**
	 * Return a randomized ID suffix
	 * @returns {string}
	 */
	getIdSuffix(): string {
		return `-${Math.round(Math.random() * 999999)}`;
	}
}
