import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { FormlyFieldConfig } from '@ngx-formly/core';

marker('global.email-label');

export const form: FormlyFieldConfig[] = [
	{
		className: '',
		id: 'email',
		key: 'email',
		props: {
			label: 'global.email-label',
			required: true,
			translate: true,
		},
		type: 'email',
	},
];
