export type SupportedDomains = 'nl' | 'de' | 'at' | 'ch' | 'fr' | 'be';

export enum SupportedDomainValues {
	AT = 'AT',
	BE = 'BE',
	CH = 'CH',
	DE = 'DE',
	FR = 'FR',
	NL = 'NL',
}
export type SupportedDomainsCapitalized =
	| SupportedDomainValues.NL
	| SupportedDomainValues.DE
	| SupportedDomainValues.AT
	| SupportedDomainValues.CH
	| SupportedDomainValues.FR
	| SupportedDomainValues.BE;
