import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';

@Component({
	selector: 'formly-wrapper-hr',
	template: `
		<div class="{{ to['classname'] }}">
			<hr class="mb-6 mt-6 border-dashed border-ui-moderate" />
		</div>
	`,
})
export class HrWrapperComponent extends FieldWrapper {}
