import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { BreadcrumbInterface } from '@sunny-cars/util-global/lib/interfaces/content/breadcrumbs.interface';
import { Positions } from './breadcrumbs.data';

@Component({
	selector: 'ui-breadcrumbs',
	templateUrl: './breadcrumbs.component.html',
	standalone: true,
	imports: [CommonModule, RouterLink, TranslateModule],
})
export class BreadcrumbsComponent {
	@Input() breadcrumbs?: BreadcrumbInterface[];
	@Input() positionOnPage: Positions = Positions.TOP;

	positions = Positions;

	get routerLink(): string {
		if (this.breadcrumbs) {
			const breadcrumb = this.breadcrumbs.slice(-2, -1);
			if (breadcrumb?.length > 0) {
				return breadcrumb[0].path;
			}
		}
		return '..';
	}
}
