import { Directive, ElementRef } from '@angular/core';

@Directive({
	selector: 'img',
	standalone: true,
})
export class LazyImageLoadingDirective {
	constructor({ nativeElement }: ElementRef<HTMLImageElement>) {
		nativeElement.setAttribute('loading', 'lazy');
	}
}
