import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
// eslint-disable-next-line @nx/enforce-module-boundaries
import {
	AutocompleteEntry,
	AutocompleteComponent as UiAutocompleteComponent,
} from '@sunny-cars/ui/lib/autocomplete/autocomplete.component';

@Component({
	selector: 'formly-field-autocomplete',
	templateUrl: 'autocomplete.component.html',
	standalone: true,
	imports: [
		UiAutocompleteComponent,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
	],
})
export class AutocompleteComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	@ViewChild('autoCompleteElement')
	autoCompleteElement?: UiAutocompleteComponent;
	filteredEntries = [];

	ngOnInit(): void {
		this.filteredEntries = this.props['entries'] || [];
	}

	updateFilteredEntries(searchValue: string): void {
		if (!searchValue) {
			this.clearCountry();
			return;
		}
		this.model.countryCode = '';
		this.filteredEntries = this.props['entries'].filter(
			(country: AutocompleteEntry) => {
				const label = country.label.toLowerCase();
				return label.includes(searchValue.toLowerCase());
			},
		);

		if (this.autoCompleteElement && this.filteredEntries.length) {
			this.autoCompleteElement.selectedEntry = this.filteredEntries[0];
		}
	}

	clearCountry(): void {
		this.model.countryCode = '';
		this.filteredEntries = this.props['entries'];
	}
}
