<div
	class="relative picker grid {{ wrapperStyleModifiers }}"
	data-test-class="picker"
>
	<!-- autocomplete="new-password" is set to prevent Chrome from autocompleting input -->
	<input
		#trigger
		type="button"
		class="{{
			mainButtonTextSizeClass
		}} bg-no-repeat text-left cursor-pointer {{ styleModifiers }}"
		[ngClass]="{
			'bg-ui-low bg-icon-loader-ui-critical bg-icon bg-left-3-center':
				isLoading,
			'bg-ui-low': isDisabled,
			'input--invalid': isInvalid,
			'input--valid': isValid,
			'bg-right-9-center pr-14':
				(isValid || isInvalid) &&
				!isLoading &&
				hasStandardSuffixIcon &&
				hasEntries,
			'bg-right-3-center pr-7':
				!isValid &&
				!isInvalid &&
				!isLoading &&
				hasStandardSuffixIcon &&
				hasEntries
		}"
		[attr.aria-expanded]="isOpen ? 'true' : 'false'"
		autocomplete="new-password"
		[attr.id]="controlId || null"
		[attr.name]="controlName"
		[disabled]="isDisabled"
		[readonly]="isInputReadonly"
		[value]="isLoading ? '' : selectedValue"
		(click)="toggle()"
		(keydown.arrowup)="highlightPrevious($event)"
		(keydown.arrowdown)="highlightNext($event)"
		(keydown.enter)="selectHighlighted($event)"
		(keydown.escape)="close()"
		(keydown.shift.tab)="close()"
		(keydown.tab)="close()"
		data-test-id="picker-input"
	/>
	<ui-icon
		*ngIf="hasStandardSuffixIcon && hasEntries"
		class="pointer-events-none absolute right-0 top-0 h-full w-7"
		[ngClass]="{
			'opacity-disabled': isDisabled
		}"
		sm
		name="icon-point-down"
		color="ui-critical"
	></ui-icon>
	<div
		#results
		class="
			shadow-lg
			popover
			sm:rounded-popover
			bg-ui-root
			min-w-full
			overflow-auto
			z-40
			text-black
			bottom-sheet-like-offset-xs 
			xs:bottom-sheet-like-offset 
			sm:bottom-0 
			max-h-sheet-like-xs
			xs:max-h-sheet-like 
			sm:max-h-80
			{{ isOpen && !isLoading && resultsStyleModifiers }}
		"
		[ngClass]="{
			'pointer-events-none h-0 shadow-none': !isOpen || isLoading
		}"
		data-test-class="picker-results"
		aria-labelledby="picker"
	>
		<ui-sheet-header [title]="title" (closed)="close()" class="sm:hidden">
		</ui-sheet-header>
		<ng-container *ngIf="isLoading">
			<div
				*ngFor="let entry of [0, 1, 2, 3, 4, 5, 6, 7, 8]; let index = index"
				data-test-class="autocomplete-row"
			>
				<ng-container
					*ngTemplateOutlet="skeletonTemplate; context: { index: index }"
				>
				</ng-container>
			</div>
		</ng-container>
		<ng-container *ngIf="!isLoading">
			<div *ngIf="entries.length === 0">
				<ng-container *ngTemplateOutlet="emptyTemplate"> </ng-container>
			</div>
			<div
				*ngFor="let entry of entries; let first = first"
				class="grid min-h-mobile-input p-0 sm:min-h-0"
				[ngClass]="{
					'hover:bg-selected-low': entry.value !== selectedEntry?.value,
					'selected bg-selected text-white':
						entry.value === selectedEntry?.value,
					'border-ui-low': !first
				}"
				data-test-class="picker-row"
				(click)="handleSelect(entry)"
			>
				<ng-container
					*ngTemplateOutlet="
						entryTemplate;
						context: {
							entry: entry,
							isSelected: entry.value === selectedEntry?.value,
							first
						}
					"
				>
				</ng-container>
			</div>
		</ng-container>
		<div id="scrollEnd"></div>
		<div *ngIf="isLoadingMore" class="picker__load-more px-3 py-2">
			<ui-icon
				classModifiers="block"
				name="icon-loader"
				inlined="true"
				color="base-critical"
			></ui-icon>
		</div>
	</div>
</div>
<!-- Backdrop (only shows when picker is open on screen < 640px) -->
<div
	class="fixed inset-0 z-30 max-h-sheet-like-xs bg-modal-backdrop xs:max-h-sheet-like sm:hidden"
	[ngClass]="{ hidden: !isOpen }"
	(click)="close()"
></div>
