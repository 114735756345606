import { CommonModule, SlicePipe, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnChanges,
	Output,
	PLATFORM_ID,
	SimpleChanges,
	TemplateRef,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Instance as PopperInstance, createPopper } from '@popperjs/core';
import { PageScrollHelper } from '@sunny-cars/util-global/lib/helpers/page-scroll/page-scroll.helper';
import { BaseControlComponent } from '../base.control';
import { IconComponent } from '../icon/icon.component';
import { SheetHeaderComponent } from '../sheet-header/sheet-header.component';

export interface AutocompleteEntry {
	modifiedLabel?: string;
	label: string;
	value: string | number;
	[key: string]: unknown;
}

@Component({
	selector: 'ui-autocomplete',
	templateUrl: './autocomplete.component.html',
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: AutocompleteComponent,
		},
	],
	standalone: true,
	imports: [
		CommonModule,
		SheetHeaderComponent,
		FormsModule,
		IconComponent,
		SlicePipe,
		TranslateModule,
	],
})
export class AutocompleteComponent
	extends BaseControlComponent
	implements AfterViewInit, OnChanges
{
	@Input() placeholder = '';
	/* Template used for displaying skeleton entries */
	@Input() skeletonTemplate: TemplateRef<{ index: number }> | null = null;
	/* Template used for displaying entries */
	@Input() entryTemplate: TemplateRef<{
		entry: AutocompleteEntry;
		isSelected: boolean;
	}> | null = null;
	/* Template used for displaying empty results */
	@Input() emptyTemplate: TemplateRef<void> | null = null;
	@Input() entries: AutocompleteEntry[] = [];
	@Input() isLoading = false;
	@Input() isLoadingMore = false;
	@Input() title = '';
	/**
	 * Amount of characters required before opening
	 */
	@Input() requireValueLength = 0;
	/**
	 * Initially selected value
	 */
	@Input()
	set selected(value: string | number) {
		const selectedEntry = this.entries.find(
			(entry: AutocompleteEntry) => `${entry.value}` === `${value}`,
		);
		if (selectedEntry) {
			this.handleSelect(selectedEntry);
		}
	}
	@Input() renderLimit = 0;
	/**
	 * Attribute for when form is submitted but autocomplete is empty
	 */
	@Input() errorEmpty = false;

	@Output() searchChange: EventEmitter<string> = new EventEmitter();
	@Output() selectedEntryChange: EventEmitter<AutocompleteEntry> =
		new EventEmitter();
	@Output() value: EventEmitter<string | number> = new EventEmitter();
	@Output() clear: EventEmitter<void> = new EventEmitter();
	@Output() scrolledBottom: EventEmitter<void> = new EventEmitter();

	@ViewChild('trigger') triggerElement: ElementRef | undefined;
	@ViewChild('results') resultsElement: ElementRef | undefined;

	openFullScreen = false;
	instance: PopperInstance | undefined;
	isOpen = false;
	/**
	 * A random suffix string to prevent Chrome from unwanted autocompleting
	 */
	idSuffix = '';
	selectedEntry: AutocompleteEntry | undefined;
	searchValue = '';
	selectedValue = '';
	private preventClose = false;
	private formValue?: string | number;

	constructor(
		private readonly pageScrollHelper: PageScrollHelper,
		@Inject(PLATFORM_ID) public platformId: string,
	) {
		super();
		this.idSuffix = this.getIdSuffix();
	}

	/**
	 * Handle click on element
	 */
	@HostListener('click')
	clickInside() {
		this.preventClose = true;
	}

	/**
	 * Handle click outside of element
	 */
	@HostListener('document:click')
	clickOutside() {
		if (!this.preventClose && this.isOpen) {
			this.close();
		}
		this.preventClose = false;
	}

	ngAfterViewInit(): void {
		this.instance = createPopper(
			this.triggerElement?.nativeElement,
			this.resultsElement?.nativeElement,
			{
				placement: 'bottom',
				modifiers: [
					{
						name: 'preventOverflow',
						options: {
							padding: 8,
						},
					},
				],
			},
		);

		if (isPlatformBrowser(this.platformId)) {
			// Detect and emit when bottom of list is reached
			const options: IntersectionObserverInit = {
				root: this.resultsElement?.nativeElement,
				threshold: 0,
			};
			/* istanbul ignore next */
			const observer = new IntersectionObserver(
				(event: IntersectionObserverEntry[]) => {
					if (event[0].isIntersecting) {
						this.scrolledBottom.emit();
					}
				},
				options,
			);
			observer.observe(
				this.resultsElement?.nativeElement.querySelector(
					'#scrollEnd',
				) as HTMLElement,
			);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['entries']) {
			this.entries.forEach((entry: AutocompleteEntry) => {
				entry.modifiedLabel = this.getHighlightedLabel(entry.label);
			});
			const selectedEntry = this.entries.find(
				(entry: AutocompleteEntry) => `${entry.value}` === `${this.formValue}`,
			);
			if (selectedEntry) {
				this.selectedEntry = selectedEntry;
				this.selectedValue = selectedEntry.label;
			}
			if (!this.selectedEntry) {
				this.highlightNext();
			}
		}
		if (changes['requireValueLength']?.currentValue && this.searchValue) {
			this.handleInput();
		}
	}

	highlightPrevious(event: Event): void {
		event.preventDefault();
		const selectedIndex = this.entries.findIndex(
			(entry: AutocompleteEntry) =>
				`${entry.value}` === `${this.selectedEntry?.value}`,
		);
		if (selectedIndex === 0 || selectedIndex === -1) {
			this.selectedEntry = this.entries[0];
			this.scrollToHighlighted(true);
			return;
		}
		this.selectedEntry = this.entries[selectedIndex - 1];
		this.scrollToHighlighted(true);
	}

	highlightNext(event: Event | undefined = undefined): void {
		if (event) {
			event.preventDefault();
		}
		const selectedIndex = this.entries.findIndex(
			(entry: AutocompleteEntry) =>
				`${entry.value}` === `${this.selectedEntry?.value}`,
		);
		if (selectedIndex === this.entries.length - 1) {
			this.selectedEntry = this.entries[0];
			this.scrollToHighlighted(true);
			return;
		}
		this.selectedEntry = this.entries[selectedIndex + 1];
		this.scrollToHighlighted(true);
	}

	selectHighlighted(event: Event): void {
		event.preventDefault();
		if (!this.isOpen) {
			setTimeout(() => {
				this.open();
			});
		}
		if (this.selectedEntry) {
			this.handleSelect(this.selectedEntry);
		}
	}

	scrollToHighlighted(isSmooth = false): void {
		if (isPlatformBrowser(this.platformId)) {
			// @NOTE: Wait until selected has been updated
			setTimeout(() => {
				if (this.resultsElement?.nativeElement) {
					const elem =
						this.resultsElement.nativeElement.querySelector('.selected');
					if (elem) {
						this.resultsElement.nativeElement.scroll({
							left: 0,
							top:
								elem.offsetTop +
								elem.clientHeight / 2 -
								this.resultsElement.nativeElement.clientHeight / 2,
							behavior: isSmooth ? 'smooth' : 'auto',
						});
					}
				}
			});
		}
	}

	handleInput(): void {
		this.searchValue = this.selectedValue;
		this.searchChange.emit(this.searchValue);
		this.onTouched();

		if (this.requireValueLength > 0) {
			if (this.searchValue.length >= this.requireValueLength) {
				this.open();
				this.scrollToHighlighted();
			} else {
				this.close(true);
			}
		} else {
			this.open();
		}

		this.entries.forEach((entry: AutocompleteEntry) => {
			entry.modifiedLabel = this.getHighlightedLabel(entry.label);
		});
	}

	close(preventMobileClose = false): void {
		this.isOpen = false;
		if (!preventMobileClose) {
			this.openFullScreen = false;
			this.disableBackgroundScroll(false);
		}
	}

	open(): void {
		if (
			!this.requireValueLength ||
			this.searchValue.length >= this.requireValueLength ||
			this.selectedValue.length >= this.requireValueLength
		) {
			this.instance?.update();
			this.isOpen = true;
			this.scrollToHighlighted();
		}
		this.disableBackgroundScroll(true);
		this.openFullScreen = true;
	}

	toggle(): void {
		if (this.isOpen) {
			this.close();
		} else {
			this.open();
		}
	}

	handleSelect(entry: AutocompleteEntry, omitTouched = false): void {
		this.selectedEntry = entry;
		this.selectedValue = entry.label;
		this.selectedEntryChange.emit(entry);
		this.value.emit(entry.value);
		this.onChange(entry.value);
		if (!omitTouched) {
			this.onTouched();
		}
		this.close();
	}

	/**
	 * Highlights string match with search value
	 */
	getHighlightedLabel(label: string): string {
		if (!this.searchValue) {
			return label;
		}
		return label.replace(
			new RegExp(`(${this.searchValue})`, 'i'),
			'<strong>$1</strong>',
		);
	}

	clearValue(): void {
		this.selectedEntry = undefined;
		this.selectedValue = '';
		this.handleInput();
		this.clear.emit();
	}

	/**
	 * Handle value set by form
	 */
	override writeValue(value: string | number): void {
		this.formValue = value;
		if (!value) {
			this.selectedEntry = undefined;
			return;
		}
		this.entries.forEach((entry: AutocompleteEntry) => {
			if (`${entry.value}` === `${value}`) {
				this.handleSelect(entry, true);
			}
		});
	}

	private disableBackgroundScroll(state: boolean): void {
		this.pageScrollHelper.toggleBackgroundScroll(state);
	}
}
