<button
	class="{{ styleModifiers }} {{ styleClassesComputed() }} {{
		sizeClassesComputed()
	}} focus:outline focus:outline-offset-2 focus:outline-2 focus:outline-focus-gray"
	[ngClass]="[
		isRoundedFull ? '!rounded-full !p-3' : '',
		isWidthFull ? 'w-full' : ''
	]"
	[disabled]="isDisabled"
	[type]="buttonTypeComputed()"
	[attr.form]="form ? form : null"
	(click)="handleClick()"
>
	<ng-content></ng-content>
</button>
