<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
	[ngClass]="{
		'rounded-medium bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10':
			content.hasDarkBackground
	}"
>
	<!-- Headings and average scores -->
	<div
		*ngIf="content.heading || content.subheading"
		class="mb-6 flex flex-wrap items-center justify-between gap-3 sm:flex-nowrap"
	>
		<ui-heading-content-block
			[heading]="content.heading"
			[subheading]="content.subheading"
			[headingLevel]="content.headingType"
		></ui-heading-content-block>
		<!-- Average scores -->
		<div
			*ngIf="content.averageScores.showAverageScores"
			class="flex flex-col gap-4 sm:flex-row"
		>
			<div
				*ngFor="let platform of content.averageScores.platforms"
				class="flex items-center gap-3 rounded-input bg-secondary p-3 text-center text-xs uppercase text-white sm:flex-grow-0 sm:flex-col sm:gap-1"
			>
				<span>
					{{ 'components.reviews.average' | translate }}
				</span>
				<span
					class="flex h-6 items-center justify-center text-xl font-semibold"
				>
					<span
						*ngIf="platform.name === 'Trustpilot'"
						class="-mt-1 mr-1 inline-block h-6 w-6 bg-icon-social-official-trustpilot-white"
					></span>
					<span
						*ngIf="platform.name === 'Google'"
						class="mr-1 inline-block h-6 w-6 bg-icon-social-circle-solid-google-reviews-colored"
					></span>
					<ui-ekomi-logo
						*ngIf="platform.name === 'Ekomi'"
						class="mr-1 h-6 w-6"
						aria-hidden="true"
						[score]="platform.stars"
					></ui-ekomi-logo>
					{{ platform.stars }}
				</span>
				<span>
					{{ platform.reviews }}
					{{ 'components.reviews.reviews' | translate }}
				</span>
			</div>
		</div>
	</div>

	<!-- Customer reviews  -->
	<ui-collection>
		<section *ngFor="let review of content.reviews">
			<a
				class="flex h-full flex-col rounded-input active:shadow-input-focus"
				[href]="review.url"
				target="_blank"
			>
				<header
					class="align-center flex gap-2 rounded-t-button bg-secondary px-3 py-2 font-bold text-ui-root"
				>
					<div
						*ngIf="review.platform === 'Trustpilot'"
						class="align-center flex gap-2 before:inline-block before:h-5 before:w-5 before:bg-icon-social-official-trustpilot-white"
					>
						{{ review.reviewBy }}
					</div>
					<div
						*ngIf="review.platform === 'Google'"
						class="align-center flex gap-2 before:inline-block before:h-5 before:w-5 before:bg-icon-social-circle-solid-google-reviews-colored"
					>
						{{ review.reviewBy }}
					</div>
					<ng-container *ngIf="review.platform === 'Ekomi'">
						<ui-ekomi-logo
							class="mr-1 h-6 w-6"
							aria-hidden="true"
							[score]="ekomiScore"
						></ui-ekomi-logo>
						{{ 'components.reviews.ekomi-title' | translate }}
					</ng-container>
				</header>
				<div
					class="grow rounded-b-button border-b border-l border-r border-secondary-low bg-ui-root p-3"
				>
					<div class="flex justify-between">
						<div class="flex gap-1">
							<ui-icon
								name="icon-star-full"
								color="secondary"
								*ngFor="let _ of [].constructor(review.stars)"
							></ui-icon>
							<ng-container *ngIf="review.stars > 0 && review.stars < 5">
								<ui-icon
									name="icon-star-empty"
									color="secondary"
									*ngFor="let _ of [].constructor(5 - review.stars)"
								></ui-icon>
							</ng-container>
						</div>
						<div class="text-xs text-text-quaternary">
							{{
								review.createdAt
									| datetime: dateTimeFormats.DATE_SHORT
									| uppercase
							}}
						</div>
					</div>
					<p class="mt-1 text-base leading-4">
						<strong class="text-secondary">{{ review.title }}</strong>
						<span class="line-clamp-1 sm:line-clamp-6">
							{{ review.text }}
						</span>
					</p>
				</div>
			</a>
		</section>
	</ui-collection>
</section>
