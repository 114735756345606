<div class="page">
	<main class="page__main">
		<router-outlet></router-outlet>
	</main>

	<ui-header
		class="sticky bottom-0 z-50 h-fit w-full self-start sm:order-first"
		*ngIf="!isB2B"
		[availableLanguages]="availableLanguages"
		[menus]="menus"
		[omitButtons]="headerOmitButtons"
		[expertrecId]="expertrecId"
		[useLanguageRoutes]="true"
		[languageFromRoute]="language"
		[languageRoutes]="(pageData$ | async)?.altURLs"
		[openingTimesLoaded$]="openingHoursLoaded$"
		[openingTimes$]="todaysOpeningTimes$"
		[logoRedirectUrl$]="logoRedirectPath$"
	></ui-header>

	<ui-footer
		class="order-last"
		*ngIf="!isB2B && (siteData$ | async) as siteData"
		[hasHelpBar]="true"
		[heading]="siteData.footer?.heading || ''"
		[content]="siteData.footer?.content || ''"
		[phone]="siteData.footer?.phone || ''"
		[showOpeningHours]="siteData.footer?.showOpeningHours || false"
		[openingTimes$]="todaysOpeningTimes$"
		[newsletter]="{
			title: siteData.footer?.newsletterTitle || '',
			url: siteData.footer?.newsletterLink || '',
			description: siteData.footer?.newsletterDescription || ''
		}"
		[contact]="{
			title: siteData.footer?.contactTitle || '',
			url: siteData.footer?.contactLink || ''
		}"
		[socials]="siteData.socialMedia"
		[menus]="siteData.menus"
		[expertrecId]="expertrecId"
	></ui-footer>
</div>
