/**
 * Helper to convert phone number string to url
 */
export class PhoneNumberUrlHelper {
	/**
	 * Transform phonenumber to url
	 * @param {string} phoneNumber
	 * @returns {string}
	 */
	static phoneNumberToURL(phoneNumber: string): string {
		return `tel:${phoneNumber.replace(/[()\- |]/g, '')}`;
	}
}
