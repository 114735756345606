import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AffiliatesService } from '@sunny-cars/provider-affiliate-service';
import {
	Affiliate,
	ErrorMessageResponse,
	ErrorTypes,
} from '@sunny-cars/util-global';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as AffiliateActions from './affiliate.actions';

@Injectable()
export class AffiliateEffects implements OnInitEffects {
	private readonly requestFailedMessage = 'Request failed';

	update$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AffiliateActions.updateAffiliate),
			switchMap((action) => {
				return this.affiliatesService
					.patchAffiliateDetails(action.source, action.deploy, action.affiliate)
					.pipe(
						map((response) => {
							return AffiliateActions.updateAffiliateSuccess({
								affiliate: response,
								deploy: action.deploy,
							});
						}),
						catchError(() => {
							return of(
								AffiliateActions.updateAffiliateFailure({
									...this.getAffiliateErrorRequestFailed(
										this.requestFailedMessage,
									),
									deploy: action.deploy,
								}),
							);
						}),
					);
			}),
		),
	);

	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(AffiliateActions.loadAffiliate),
			switchMap((action) => {
				return this.affiliatesService
					.getAffiliateDetails(
						action.affiliateKey,
						action.styleProfile,
						action.contentProfile,
						action.source,
						action.version,
					)
					.pipe(
						map((response: Affiliate) => {
							return AffiliateActions.loadAffiliateSuccess({
								affiliate: response,
							});
						}),
						catchError((error) => {
							return of(AffiliateActions.loadAffiliateFailure(error));
						}),
					);
			}),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly affiliatesService: AffiliatesService,
	) {}

	ngrxOnInitEffects(): Action {
		return AffiliateActions.init();
	}

	private getAffiliateErrorRequestFailed(errorMessage?: string): {
		errors: ErrorMessageResponse[];
	} {
		return {
			errors: [
				{
					status: 500,
					code: ErrorTypes.ERROR_REQUEST_FAILED,
					detail: errorMessage || this.requestFailedMessage,
				},
			],
		};
	}
}
