import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';

/**
 * Heading Content Block component
 */
@Component({
	selector: 'ui-heading-content-block',
	templateUrl: './heading-content-block.component.html',
	styleUrls: ['./heading-content-block.scss'],
	standalone: true,
	imports: [CommonModule],
})
export class HeadingContentBlockComponent implements OnChanges {
	@Input() heading?: string;
	@Input() subheading?: string;
	@Input() intro?: string;
	@Input() headingLevel: HeadingLevels = HeadingLevels.H2;
	@Input() customHeadingClasses?: string;
	@Input() customSubHeadingClasses?: string;

	headingClasses = 'text-3xl text-heading sm:text-5xl';
	subHeadingClasses = 'text-lg font-semibold text-subheading sm:text-xl';
	readonly headingLevels = HeadingLevels;

	ngOnChanges(): void {
		// Apply intro text optimized reading width
		if (this.intro) {
			if (!this.headingClasses.includes('max-w-optimized-reading-heading')) {
				this.headingClasses += ' max-w-optimized-reading-heading';
			}
			if (!this.subHeadingClasses.includes('max-w-optimized-reading-heading')) {
				this.subHeadingClasses += ' max-w-optimized-reading-heading';
			}
		} else {
			if (this.headingClasses.includes('max-w-optimized-reading-heading')) {
				this.headingClasses = this.headingClasses.replace(
					' max-w-optimized-reading-heading',
					'',
				);
			}
			if (this.subHeadingClasses.includes('max-w-optimized-reading-heading')) {
				this.subHeadingClasses = this.subHeadingClasses.replace(
					' max-w-optimized-reading-heading',
					'',
				);
			}
		}
	}
}
