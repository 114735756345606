<aside
	class="mb-auto flex shrink-0 flex-col overflow-hidden rounded-large bg-white drop-shadow-lg sm:max-w-card"
>
	<ui-image
		*ngIf="image && image.src"
		[image]="image"
		class="w-full overflow-hidden"
		className="w-full object-cover aspect-video"
	></ui-image>
	<div
		*ngIf="image && image.src"
		class="-mt-5 ml-6 flex h-10 w-10 flex-col justify-center rounded-full bg-white"
	>
		<div class="mx-auto h-6 w-6 bg-secondary icon-document-badge-check"></div>
	</div>
	<div class="p-6 pt-4">
		<h2 class="text-3xl font-bold text-secondary">
			{{ titleQuickInfo || 'components.quick-info.quick-info' | translate }}
		</h2>
		<dl>
			<div *ngFor="let item of items" class="mt-2">
				<dt class="inline font-bold">{{ item.title }}:&#32;</dt>
				<dd [innerHTML]="item.content" class="prose inline prose-p:inline"></dd>
			</div>
		</dl>
	</div>
</aside>
