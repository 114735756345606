import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import Cookies from 'js-cookie';

/* eslint-disable @typescript-eslint/no-explicit-any */
@Injectable({
	providedIn: 'root',
})
export class CookieStorageService {
	private domain = '';

	constructor(
		@Inject(PLATFORM_ID) public platformId: string,
		@Optional() @Inject('isProduction') public isProduction: boolean,
		@Optional() @Inject('countryCode') public countryCode: string,
	) {
		this.updateCookieDomain();
	}

	private updateCookieDomain() {
		if (isPlatformBrowser(this.platformId)) {
			if (this.isProduction && this.countryCode) {
				this.domain = `.sunnycars.${this.countryCode.toLowerCase()}`;
			} else if (
				location.hostname === 'localhost' ||
				location.hostname === '127.0.0.1'
			) {
				this.domain = 'localhost';
			} else {
				this.domain = '.sunnycars.dev';
			}
		}
	}

	setSavedState(state: any, storageKey: string): void {
		if (isPlatformBrowser(this.platformId)) {
			Cookies.set(storageKey, JSON.stringify(state), {
				secure: true,
				sameSite: 'strict',
				...(state.expiresAt
					? { expires: new Date(state.expiresAt * 1000) }
					: {}),
				domain: this.domain,
				path: '',
			});
		}
	}

	getSavedState(storageKey: string): any {
		if (isPlatformBrowser(this.platformId)) {
			return JSON.parse(Cookies.get(storageKey) || '{}');
		}
		return {};
	}

	removeSavedState(storageKey: string): void {
		if (isPlatformBrowser(this.platformId)) {
			Cookies.remove(storageKey, {
				secure: true,
				sameSite: 'strict',
				domain: this.domain,
				path: '',
			});
		}
	}
}
