import { Inject, Injectable } from '@angular/core';
import {
	AbstractControl,
	AsyncValidatorFn,
	ValidationErrors,
} from '@angular/forms';
import { AuthenticateService } from '@sunny-cars/provider-account-service/lib/authenticate/authenticate.service';
import { SourceType } from '@sunny-cars/util-global';
import { Observable, first, map } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class ExistingEmailValidator {
	constructor(
		private readonly authenticationService: AuthenticateService,
		@Inject('affiliateKey') private readonly affiliateKey: number,
		@Inject('domainSource') private readonly domainSource: SourceType,
	) {}

	existingEmailValidator(): AsyncValidatorFn {
		return (control: AbstractControl): Observable<ValidationErrors | null> => {
			const emailValue = control.value;

			return this.authenticationService
				.validateEmail({
					username: emailValue,
					affiliateKey: this.affiliateKey,
					source: this.domainSource,
				})
				.pipe(
					first(),
					map((response) => {
						return emailValue && response.data.success
							? null
							: { 'existing-email': true };
					}),
				);
		};
	}
}
