import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Sizes } from '@sunny-cars/util-global';
import { DateTimeFormats } from '@sunny-cars/util-global/lib/helpers/date-time/date-time.helper';
import { AwardItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/award-items.interface';
import { DateTimePipe } from '@sunny-cars/util-global/lib/pipes/date-time/date-time.pipe';
import { ImageComponent } from '../image/image.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-awards',
	templateUrl: './awards.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		CollectionComponent,
		RouterLink,
		ImageComponent,
		DateTimePipe,
	],
})
export class AwardsComponent {
	@Input() content?: AwardItemsComponentInterface;

	dateTimeFormats = DateTimeFormats;
	sizes = Sizes;
}
