import { Injectable } from '@angular/core';
import { DefaultUrlSerializer, UrlTree } from '@angular/router';

@Injectable()
export class TrailingSlashUrlSerializer extends DefaultUrlSerializer {
	override serialize(tree: UrlTree): string {
		return this._withTrailingSlash(super.serialize(tree));
	}

	private _withTrailingSlash(url: string): string {
		const splitOn = url.indexOf('?') > -1 ? '?' : '#';
		const pathArray = url.split(splitOn);

		if (!pathArray[0].endsWith('/')) {
			const fileName: string = url.substring(url.lastIndexOf('/') + 1);
			if (fileName.indexOf('.') === -1) {
				pathArray[0] += '/';
			}
		}
		return pathArray.join(splitOn);
	}
}
