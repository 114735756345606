import { Observable } from 'rxjs';

/** Filters undefined types and narrows typing down the stream. */
export function filterUndefined<T>(
	source: Observable<T | undefined>,
): Observable<T> {
	return new Observable((subscriber) => {
		source.subscribe({
			next(value) {
				if (value !== undefined) {
					subscriber.next(value);
				}
			},
			error(error) {
				subscriber.error(error);
			},
			complete() {
				subscriber.complete();
			},
		});
	});
}
