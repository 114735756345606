import { CommonModule } from '@angular/common';
import {
	CUSTOM_ELEMENTS_SCHEMA,
	NgModule,
	importProvidersFrom,
} from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FORMLY_CONFIG, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { emailValidator } from '@sunny-cars/util-global/lib/form-validators/email/email.form-validator';
import { newPasswordValidator } from '@sunny-cars/util-global/lib/form-validators/password/password.form-validator';
import { AutocompleteComponent } from './fields/autocomplete/autocomplete.component';
import { InputComponent } from './fields/input/input.component';
import { NewPasswordComponent } from './fields/new-password/new-password.component';
import { PasswordComponent } from './fields/password/password.component';
import { PhoneComponent } from './fields/phone/phone.component';
import { PickerComponent } from './fields/picker/picker.component';
import { RadioComponent } from './fields/radio/radio.component';
import { SegmentedControlComponent } from './fields/segmented-control/segmented-control.component';
import { registerTranslateExtension } from './formly.config';
import { HeadingWrapperComponent } from './wrappers/heading/heading.component';
import { HrWrapperComponent } from './wrappers/hr/hr.component';
import { LabelDesriptionAppendWrapperComponent } from './wrappers/label-description-append/label-description-append.component';
import { LabelWrapperComponent } from './wrappers/label/label.component';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { AutocompleteComponent as UiAutocompleteComponent } from '@sunny-cars/ui/lib/autocomplete/autocomplete.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { DialogComponent as UiDialogComponent } from '@sunny-cars/ui/lib/dialog/dialog.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { IconComponent as UiIconComponent } from '@sunny-cars/ui/lib/icon/icon.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PasswordInputComponent as UiPasswordInputComponent } from '@sunny-cars/ui/lib/password-input/password-input.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PasswordStrengthIndicatorComponent as UiPasswordStrengthIndicatorComponent } from '@sunny-cars/ui/lib/password-strength-indicator/password-strength-indicator.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PhonenumberInputComponent as UiPhonenumberInputComponent } from '@sunny-cars/ui/lib/phonenumber-input/phonenumber-input.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PickerComponent as UiPickerComponent } from '@sunny-cars/ui/lib/picker/picker.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { RadioGroupComponent as UiRadioGroupComponent } from '@sunny-cars/ui/lib/radio-group/radio-group.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { SegmentedControlComponent as UiSegmentedControlComponent } from '@sunny-cars/ui/lib/segmented-control/segmented-control.component';

@NgModule({
	exports: [FormlyModule, FormsModule, ReactiveFormsModule],
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		UiAutocompleteComponent,
		UiDialogComponent,
		UiIconComponent,
		UiPasswordInputComponent,
		UiPasswordStrengthIndicatorComponent,
		UiPhonenumberInputComponent,
		UiPickerComponent,
		UiRadioGroupComponent,
		UiSegmentedControlComponent,
		TranslateModule.forChild({}),
		AutocompleteComponent,
		HeadingWrapperComponent,
		InputComponent,
		LabelDesriptionAppendWrapperComponent,
		LabelWrapperComponent,
		NewPasswordComponent,
		PasswordComponent,
		PhoneComponent,
		PickerComponent,
		RadioComponent,
		SegmentedControlComponent,
	],
	providers: [
		importProvidersFrom(
			FormlyModule.forRoot({
				validators: [
					{ name: 'email', validation: emailValidator() },
					{ name: 'password', validation: newPasswordValidator() },
				],
				types: [
					{
						name: 'autoComplete',
						component: AutocompleteComponent,
						wrappers: ['label'],
					},
					{
						name: 'email',
						component: InputComponent,
						wrappers: ['label'],
						defaultOptions: {
							validators: {
								validation: ['email'],
							},
						},
					},
					{
						name: 'input',
						component: InputComponent,
						wrappers: ['label'],
					},
					{
						name: 'segmentedControl',
						component: SegmentedControlComponent,
						wrappers: ['label'],
					},
					{
						name: 'password',
						component: PasswordComponent,
						wrappers: ['label'],
					},
					{
						name: 'phone',
						component: PhoneComponent,
						wrappers: ['label'],
					},
					{
						name: 'new-password',
						component: NewPasswordComponent,
						wrappers: ['label'],
					},
					{
						name: 'picker',
						component: PickerComponent,
						wrappers: ['label'],
					},
					{
						name: 'radio',
						component: RadioComponent,
						wrappers: ['label'],
					},
				],
				wrappers: [
					{ name: 'heading', component: HeadingWrapperComponent },
					{ name: 'hr', component: HrWrapperComponent },
					{ name: 'label', component: LabelWrapperComponent },
					{
						name: 'label-description-append',
						component: LabelDesriptionAppendWrapperComponent,
					},
				],
			}),
		),
		{
			deps: [TranslateService],
			multi: true,
			provide: FORMLY_CONFIG,
			useFactory: registerTranslateExtension,
		},
	],
	schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SharedFormlyModule {}
