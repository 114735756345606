<article
	class="flex flex-col gap-10 py-8 xs:gap-6 sm:grid sm:grid-cols-3 sm:flex-row sm:gap-8 sm:py-20 lg:gap-x-9"
>
	<main class="flex flex-grow flex-col sm:col-span-2">
		<div
			class="mr-auto flex items-center rounded-full bg-ui-high px-3 py-1 text-sm"
		>
			<span
				class="mr-1 inline-block h-3 w-3 shrink-0 bg-text icon-lightbulb"
			></span
			>{{ runningHead }}
		</div>
		<div
			[innerHTML]="content | SafeHtml"
			class="prose prose-base mt-7 md:prose-lg prose-h1:text-black prose-lead:text-xl prose-lead:text-black"
		></div>
	</main>
	<ui-quick-info
		[image]="image"
		[items]="items"
		[titleQuickInfo]="titleQuickInfo"
	></ui-quick-info>
</article>
