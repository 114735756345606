import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	AuthenticationRequestAPI,
	AuthenticationResponseAllOfAPI,
} from '@sunny-cars/util-api-interfaces';
import { Session, SessionType } from '@sunny-cars/util-global';
import { first, map, Observable } from 'rxjs';
import {
	APIValidateUserRequestBody,
	APIValidateUserResponse,
} from './interfaces/validate.interface';

@Injectable({
	providedIn: 'root',
})
export class AuthenticateService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	authenticate(
		body: AuthenticationRequestAPI,
	): Observable<Session | undefined> {
		return this.http
			.post<AuthenticationResponseAllOfAPI>(
				`${this.endpoint}v1/authenticate`,
				body,
			)
			.pipe(first(), map(this.parseAuthenticateResponse));
	}

	validateEmail(
		body: APIValidateUserRequestBody,
	): Observable<APIValidateUserResponse> {
		return this.http.post<APIValidateUserResponse>(
			`${this.endpoint}v1/account/validate`,
			body,
		);
	}

	private parseAuthenticateResponse(
		response: AuthenticationResponseAllOfAPI,
	): Session | undefined {
		const data = response?.data;
		if (!data) {
			return undefined;
		}
		return {
			id: String(data.id),
			token: data.token,
			type: SessionType[data.type],
			expiresAt: data.expiresAt,
		};
	}
}
