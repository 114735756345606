<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
	[ngClass]="{
		'rounded-medium bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10':
			content.hasDarkBackground
	}"
>
	<ui-heading-content-block
		*ngIf="content.heading || content.subheading || content.content"
		class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
		[heading]="content.heading"
		[subheading]="content.subheading"
		[intro]="content.content"
		[headingLevel]="content.headingType"
	></ui-heading-content-block>
	<ui-collection>
		<ng-template #popularCountry let-country="country">
			<ui-image
				className="absolute top-0 left-0 h-full w-full scale-100 rounded-large object-cover opacity-80 transition-all duration-200 hover:scale-105 hover:opacity-100"
				[sources]="[
					{
						min: null,
						max: sizes.SM,
						width: 287,
						height: 161
					},
					{
						min: sizes.SM,
						max: sizes.MD,
						width: 610,
						height: 344
					},
					{
						min: sizes.MD,
						max: null,
						width: 430,
						height: 242
					}
				]"
				[image]="{
					src: country.image.src,
					altText: country.image.alt,
					width: 610,
					height: 344
				}"
			></ui-image>
			<div
				class="pointer-events-none absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-center text-3xl font-bold leading-none text-white"
			>
				<h2>{{ country.name }}</h2>
				<p
					*ngIf="country.subtitle"
					class="inline-block w-full text-lg font-normal"
				>
					{{ country.subtitle }}
				</p>
			</div>
		</ng-template>
		<ng-container *ngFor="let country of content.items">
			<a
				*ngIf="country.urlType === urlTypes.CUSTOM_URL"
				[href]="country.customUrl"
				class="relative block overflow-hidden rounded-large bg-image-underlay-gradient pb-aspect-ratio-16/9"
			>
				<ng-container
					*ngTemplateOutlet="popularCountry; context: { country: country }"
				></ng-container>
			</a>
			<a
				*ngIf="country.urlType !== urlTypes.CUSTOM_URL"
				[routerLink]="['/' + country.path]"
				class="relative block overflow-hidden rounded-large bg-image-underlay-gradient pb-aspect-ratio-16/9"
			>
				<ng-container
					*ngTemplateOutlet="popularCountry; context: { country: country }"
				></ng-container
			></a>
		</ng-container>
	</ui-collection>
	<a
		*ngIf="content.link && !content.link.href.startsWith('http')"
		[routerLink]="content.link.href"
		class="btn btn-secondary mt-3 block w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold xs:mt-5"
	>
		{{ content.link.text }}
	</a>
	<a
		*ngIf="content.link && content.link.href.startsWith('http')"
		[href]="content.link.href"
		[target]="content.link.shouldOpenInNewTab ? '_blank' : null"
		class="btn btn-secondary mt-3 block w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold xs:mt-5"
	>
		{{ content.link.text }}
	</a>
</section>
