import { createAction, props } from '@ngrx/store';
import { APIGetProfileRequestParams } from '@sunny-cars/provider-account-service';
import { SourceType } from '@sunny-cars/util-global';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';

export const init = createAction('[Account] Init');

export const loadAccount = createAction(
	'[Account] Load Account',
	props<{
		params: APIGetProfileRequestParams;
	}>(),
);

export const loadAccountSuccess = createAction(
	'[Account] Load Account Success',
	props<{ account: AccountData }>(),
);

export const loadAccountFailure = createAction(
	'[Account] Load Account Failure',
	props<{ error: string }>(),
);

export const updateAccount = createAction(
	'[Account] Update Account',
	props<{
		account: AccountData;
		affiliateKey: number;
		source: SourceType;
	}>(),
);

export const updateAccountSuccess = createAction(
	'[Account] Update Account Success',
	props<{ account: AccountData }>(),
);

export const updateAccountFailure = createAction(
	'[Account] Update Account Failure',
	props<{ error: string }>(),
);

export const clearError = createAction('[Account] Clear Error');

export const clear = createAction('[Account] Clear');
