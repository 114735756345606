import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { LinkTypes, MenuItem, MenuItemTypes } from '@sunny-cars/util-global';
import { IconComponent } from '../../icon/icon.component';
import { SheetHeaderComponent } from '../../sheet-header/sheet-header.component';

@Component({
	selector: 'ui-main-menu-mobile',
	templateUrl: './main-menu-mobile.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		IconComponent,
		SheetHeaderComponent,
		RouterLink,
		TranslateModule,
	],
})
export class MainMenuMobileComponent {
	@Input() items?: MenuItem[] = [];
	@Output() closed: EventEmitter<true> = new EventEmitter();

	activeLevel1Index = -1;
	activeLevel2Index = -1;
	level = 1;
	linkClasses =
		'text-base flex justify-between px-2.5 py-3 hover:bg-selected-low cursor-pointer whitespace-normal';
	callToActionClasses =
		'btn w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold btn-secondary divide-none';
	noDividerClasses = 'border-t-0 divide-none border-none';
	zeroHeightClasses = 'p-0 h-0';

	linkTypes = LinkTypes;
	menuItemTypes = MenuItemTypes;

	showItems(level: number, index: number): void {
		this.level = level;

		if (level === 2) {
			this.activeLevel1Index = index;
		} else if (level === 3) {
			this.activeLevel2Index = index;
		}
	}

	backAction(): void {
		this.level--;
	}

	close(): void {
		this.reset();
		this.closed.emit(true);
	}

	reset(): void {
		this.activeLevel1Index = -1;
		this.activeLevel2Index = -1;
		this.level = 1;
	}
}
