import { createAction, props } from '@ngrx/store';
import { APIContactDetailsRequestParams } from '@sunny-cars/provider-contact-details-service';
import { ContactDetails } from '@sunny-cars/util-global';

export const init = createAction('[Contact Details] Init');

export const loadContactDetails = createAction(
	'[Contact Details] Load Contact Details',
	props<{
		params: APIContactDetailsRequestParams;
	}>(),
);

export const loadContactDetailsSuccess = createAction(
	'[Contact Details] Load Contact Details Success',
	props<{
		contactDetails: ContactDetails;
		expires: string;
	}>(),
);

export const loadContactDetailsFailure = createAction(
	'[Contact Details] Load Contact Details Failure',
	props<{ error: string }>(),
);

export const clearError = createAction('[Contact Details] Clear Error');
