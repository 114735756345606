import { Pipe, PipeTransform } from '@angular/core';
import { Category } from '@sunny-cars/util-global';
import { SegmentedControlInterface } from '../../segmented-control/segmented-control.interface';

@Pipe({
	name: 'segmentedControl',
	standalone: true,
})
export class SegmentedControlPipe implements PipeTransform {
	transform(categories?: Category[]): SegmentedControlInterface[] {
		if (!categories) {
			return [];
		}

		return categories.map((category) => {
			return {
				label: category.title,
				value: category.id,
			};
		});
	}
}
