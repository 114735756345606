import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { RegionDestination } from '@sunny-cars/util-global';
import { RegionMapHelper } from '../region-map/region-map.helper';

@Component({
	selector: 'ui-region-map-list[region]',
	templateUrl: './region-map-list.component.html',
	standalone: true,
	imports: [CommonModule, RouterLink, TranslateModule],
})
export class RegionMapListComponent implements OnInit {
	@Input() title = '';
	@Input() region!: RegionDestination;
	@Input() path = '';

	regionsAreNested = false;
	regionsCount: number[] = [];
	totalCount = 0;
	slugify = RegionMapHelper.slugify;

	ngOnInit(): void {
		this.regionsAreNested = this.isSomeRegionNested(this.region);
		this.regionsCount = this.countValidNestedDestinations(this.region);
		this.totalCount = this.regionsCount.reduce((total, current) => {
			total += current;
			return total;
		}, 0);
	}

	private countValidNestedDestinations(region: RegionDestination): number[] {
		return region.children.map((destination) =>
			this.countChildren(destination),
		);
	}

	private countChildren(
		regionDestination: RegionDestination,
		recursiveDepth = 1,
	): number {
		if (
			!regionDestination.children ||
			regionDestination.children.length === 0 ||
			recursiveDepth >= 100
		) {
			return 1;
		}
		return regionDestination.children
			.filter(RegionMapHelper.isRegionWithGeo)
			.reduce(
				(accumulator, child) =>
					accumulator + this.countChildren(child, recursiveDepth + 1),
				0,
			);
	}

	private isSomeRegionNested(region: RegionDestination): boolean {
		return !!region.children?.some(
			(item) => item.children && item.children.length,
		);
	}
}
