import { Injectable } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Injectable({
	providedIn: 'root',
})
export class IconCacheService {
	private cache: { [key: string]: string | SafeHtml } = {};

	setCache(key: string, value: string | SafeHtml): void {
		this.cache[key] = value;
	}

	getCache(key: string): string | SafeHtml | undefined {
		return this.cache[key];
	}

	clearCache(key: string): void {
		delete this.cache[key];
	}
}
