<ui-heading-content-block
	class="mb-3"
	*ngIf="title"
	[subheading]="title"
></ui-heading-content-block>
<div
	class="gap-3 md:gap-6"
	[ngClass]="{
		'grid grid-cols-collection md:grid-cols-collection-desktop': !isList,
		'flex flex-col': isList
	}"
>
	<ng-content></ng-content>
</div>
