import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
	Affiliate,
	AffiliateConfiguration,
	AffiliateCustomService,
	AffiliateReviews,
	Contact,
	ContactDetailsHelper,
	ContactEmail,
	ContactPhoneNumber,
	ErrorMessageResponse,
	filterUndefined,
	GeneralConfiguration,
	HeaderConfiguration,
	HeaderConfigurationLogo,
	LocaleHelper,
	LocalizedTravelArrangement,
	LogoTypes,
	RecursivePartial,
	ReviewConfiguration,
	SourceType,
	SupportedDomainsCapitalized,
	TravelArrangementConfiguration,
	TypographyConfiguration,
	WidgetConfiguration,
} from '@sunny-cars/util-global';
import {
	combineLatest,
	filter,
	map,
	Observable,
	of,
	startWith,
	switchMap,
} from 'rxjs';
import * as AffiliateActions from './affiliate.actions';
import * as AffiliateSelectors from './affiliate.selectors';

@Injectable({
	providedIn: 'root',
})
export class AffiliateFacade {
	affiliate$: Observable<Affiliate | undefined> = this.store.pipe(
		select(AffiliateSelectors.getAffiliate),
	);

	isDefaultSunnyColours$: Observable<boolean | undefined> = this.store.pipe(
		select(AffiliateSelectors.getIsDefaultSunnyColours),
	);

	generalStyling$: Observable<GeneralConfiguration | undefined> =
		this.store.pipe(select(AffiliateSelectors.getGeneralStyling));

	typography$: Observable<TypographyConfiguration | undefined> =
		this.store.pipe(select(AffiliateSelectors.getTypography));

	reviewsAndRewards$: Observable<ReviewConfiguration | undefined> =
		this.store.pipe(select(AffiliateSelectors.getReviewsAndRewards));

	reviews$: Observable<AffiliateReviews | undefined> = this.store.pipe(
		select(AffiliateSelectors.getReviews),
	);

	paymentMethods$: Observable<string[] | undefined> = this.store.pipe(
		select(AffiliateSelectors.getPaymentMethods),
	);

	headerAndFooterStyling$: Observable<HeaderConfiguration | undefined> =
		this.store.pipe(select(AffiliateSelectors.getHeaderAndFooterStyling));

	contactInformation$: Observable<Contact | undefined> = this.store.pipe(
		select(AffiliateSelectors.getContactInformation),
	);

	travelArrangements$: Observable<TravelArrangementConfiguration> =
		this.store.pipe(
			select(AffiliateSelectors.getTravelArrangementInformation),
			filterUndefined,
		);

	bookingWidgetStyling$: Observable<WidgetConfiguration | undefined> =
		this.store.pipe(select(AffiliateSelectors.getBookingWidget));

	travelArrangementsActive$: Observable<boolean> = this.store.pipe(
		select(AffiliateSelectors.getTravelArrangementsActive),
		filterUndefined,
	);

	affiliateName$: Observable<string> = this.store.pipe(
		select(AffiliateSelectors.getAffiliateName),
		filterUndefined,
	);

	logoUrl$: Observable<string | undefined> = this.store.pipe(
		select(AffiliateSelectors.getLogoUrl),
		filterUndefined,
	);

	logo$: Observable<HeaderConfigurationLogo> = this.store.pipe(
		select(AffiliateSelectors.getLogo),
		filterUndefined,
		startWith({ type: LogoTypes.SUNNY }),
	);

	showCustomContactInfo$: Observable<boolean> = this.store.pipe(
		select(AffiliateSelectors.showCustomContactInfo),
	);

	phoneNumbers$: Observable<ContactPhoneNumber[] | undefined> = this.store.pipe(
		select(AffiliateSelectors.getPhoneNumbers),
	);

	emailAddresses$: Observable<ContactEmail[] | undefined> = this.store.pipe(
		select(AffiliateSelectors.getEmails),
	);

	countryCode$: Observable<SupportedDomainsCapitalized> = this.store.pipe(
		select(AffiliateSelectors.getCountryCode),
		filterUndefined,
	);

	currencyCode$: Observable<string> = this.store.pipe(
		select(AffiliateSelectors.getCurrencyCode),
		filterUndefined,
	);

	isLoading$: Observable<boolean> = this.store.pipe(
		select(AffiliateSelectors.getAffiliateLoading),
	);

	isLoaded$: Observable<boolean> = this.store.pipe(
		select(AffiliateSelectors.getAffiliateLoaded),
	);

	errors$: Observable<ErrorMessageResponse[] | undefined> = this.store.pipe(
		select(AffiliateSelectors.getAffiliateErrors),
	);

	lastAction$: Observable<string | undefined> = this.store.pipe(
		select(AffiliateSelectors.getLastAction),
	);

	hasPendingChanges$: Observable<boolean> = this.store.pipe(
		select(AffiliateSelectors.getHasPendingChanges),
	);

	constructor(
		private readonly store: Store,
		private readonly localeHelper: LocaleHelper,
		private readonly contactDetailsHelper: ContactDetailsHelper,
		private readonly affiliateCustomService: AffiliateCustomService,
	) {}

	updateLocalAffiliateState(
		affiliateConfig: RecursivePartial<AffiliateConfiguration>,
	): void {
		this.store.dispatch(
			AffiliateActions.updateLocalAffiliateState({
				affiliateConfig,
			}),
		);
	}

	/**
	 * Selects localized travel arrangement for current locale language
	 */
	travelArrangementsUrlForLanguage(): Observable<
		Pick<LocalizedTravelArrangement, 'linkLabel' | 'linkURL'>
	> {
		const travelArrangementsUrlOnLanguage$ = this.store.pipe(
			select(
				AffiliateSelectors.getTravelArrangementsUrlForLanguage(
					this.localeHelper.language,
				),
			),
		);

		return combineLatest([this.localeHelper.onLanguageChange, this.store]).pipe(
			switchMap(() => travelArrangementsUrlOnLanguage$),
			filterUndefined,
		);
	}

	/**
	 * Selects phone number matching current locale language, or default phone number from translations, depending on whether custom affiliate is shown
	 */
	phoneNumberForLocaleLanguage(): Observable<string> {
		const phoneNumberWithFallback$ = combineLatest([
			this.affiliateCustomService.customAffiliate$,
			this.store.pipe(
				select(AffiliateSelectors.showCustomContactInfo),
				startWith(true),
			),
		]).pipe(
			switchMap(([isCustom, isCustomContact]) =>
				isCustom && isCustomContact
					? this.store.pipe(
							select(
								AffiliateSelectors.getPhoneNumberForLanguage(
									this.localeHelper.language,
								),
							),
					  )
					: this.contactDetailsHelper.getPhoneNumber(),
			),
		);

		return combineLatest([this.localeHelper.onLanguageChange, this.store]).pipe(
			switchMap(() => phoneNumberWithFallback$),
			filterUndefined,
		);
	}

	/**
	 * Selects phone number information matching current locale language, or default phone number information from translations, depending on whether custom affiliate is shown
	 */
	phoneNumberInfoForLocaleLanguage(): Observable<string> {
		const phoneNumberInfoWithFallback$ = combineLatest([
			this.affiliateCustomService.customAffiliate$,
			this.store.pipe(
				select(AffiliateSelectors.showCustomContactInfo),
				startWith(true),
			),
		]).pipe(
			switchMap(([isCustom, isCustomContact]) =>
				isCustom && isCustomContact
					? this.store.pipe(
							select(
								AffiliateSelectors.getPhoneNumberInfoForLanguage(
									this.localeHelper.language,
								),
							),
					  )
					: of(''),
			),
		);

		return combineLatest([this.localeHelper.onLanguageChange, this.store]).pipe(
			switchMap(() => phoneNumberInfoWithFallback$),
			filterUndefined,
		);
	}

	/**
	 * Selects email address matching current locale language, or default email address from translations, depending on whether custom affiliate is shown
	 */
	emailAddressForLocaleLanguage(): Observable<string> {
		const emailWithFallback$ = combineLatest([
			this.affiliateCustomService.customAffiliate$,
			this.store.pipe(
				select(AffiliateSelectors.showCustomContactInfo),
				startWith(true),
			),
		]).pipe(
			switchMap(([isCustom, isCustomContact]) =>
				isCustom && isCustomContact
					? this.store.pipe(
							select(
								AffiliateSelectors.getEmailAddressForLanguage(
									this.localeHelper.language,
								),
							),
					  )
					: this.contactDetailsHelper.getEmailAddress(),
			),
		);

		return combineLatest([this.localeHelper.onLanguageChange, this.store]).pipe(
			switchMap(() => emailWithFallback$),
			filterUndefined,
		);
	}

	/**
	 * Checks if custom affiliate equals true, and if affiliate configured phone number is loaded
	 */
	displayServiceCode(): Observable<boolean> {
		const phoneNumberLoadedSignal$: Observable<void> = this.store.pipe(
			select(AffiliateSelectors.getPhoneNumbers),
			map(() => void 0),
		);

		return combineLatest([
			this.affiliateCustomService.customAffiliate$,
			this.store.pipe(select(AffiliateSelectors.showCustomContactInfo)),
		]).pipe(
			filter(
				([customAffiliate, customContactInfo]) =>
					customAffiliate && customContactInfo,
			),
			switchMap(() => phoneNumberLoadedSignal$.pipe(map(() => false))),
			startWith(true),
		);
	}

	update(source: SourceType, deploy: boolean, affiliate: Affiliate): void {
		this.store.dispatch(
			AffiliateActions.updateAffiliate({ source, deploy, affiliate }),
		);
	}

	load(
		affiliateKey: number,
		styleProfile: number | undefined,
		contentProfile: number | undefined,
		source: SourceType,
		version?: string,
	): void {
		this.store.dispatch(
			AffiliateActions.loadAffiliate({
				affiliateKey,
				styleProfile,
				contentProfile,
				source,
				version,
			}),
		);
	}

	clearError(): void {
		this.store.dispatch(AffiliateActions.clearError());
	}

	clear(): void {
		this.store.dispatch(AffiliateActions.clear());
	}
}
