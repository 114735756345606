import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { HeadingContentBlockComponent } from '../../heading-content-block/heading-content-block.component';

@Component({
	selector: 'ui-collection',
	templateUrl: './collection.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, HeadingContentBlockComponent],
})
export class CollectionComponent {
	/** Optional title for collection layout */
	@Input() title = '';
	/** Option to display the collection as a list (grid is default) */
	@Input() isList = false;
}
