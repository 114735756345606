import {
	CommonModule,
	isPlatformBrowser,
	isPlatformServer,
} from '@angular/common';
import {
	AfterViewInit,
	Component,
	EventEmitter,
	Inject,
	Input,
	Optional,
	Output,
	PLATFORM_ID,
	TemplateRef,
	ViewChild,
} from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	NgForm,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { RegisterService } from '@sunny-cars/provider-account-service/lib/register/register.service';
import { APIPostalAddressResponse } from '@sunny-cars/provider-address';
import {
	ApplicationUrlEnvironment,
	BaseComponent,
	DateTimeHelper,
	FormErrorScrollHelper,
	SourceType,
	SupportedDomainsCapitalized,
	UrlHelper,
} from '@sunny-cars/util-global';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { Observable } from 'rxjs';
import { ButtonComponent } from '../button/button.component';
import { ButtonSizes, ButtonStyleTypes } from '../button/button.component.data';
import { DialogComponent, DialogTypes } from '../dialog/dialog.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { IconComponent } from '../icon/icon.component';
import { InternationalAddressFormComponent } from '../international-address-form/international-address-form.component';
import { PickerEntry } from '../picker/picker.component';
import * as FormlyConfig from './form';
import { ExistingEmailValidator } from './form-validators/existing-email/existing-email.form-validator';

export interface Entry {
	modifiedLabel?: string;
	label$?: Observable<string>;
	label: string;
	value: string | number;
	[key: string]: unknown;
}

@Component({
	selector: 'ui-register',
	templateUrl: './register.component.html',
	standalone: true,
	imports: [
		ButtonComponent,
		CommonModule,
		DialogComponent,
		FormlyModule,
		FormsModule,
		HeadingContentBlockComponent,
		IconComponent,
		InternationalAddressFormComponent,
		ReactiveFormsModule,
		TranslateModule,
	],
})
export class RegisterComponent extends BaseComponent implements AfterViewInit {
	@ViewChild('registerForm')
	registerForm!: NgForm;
	@ViewChild('monthEntryTemplate')
	monthEntryTemplate!: TemplateRef<Entry>;

	@Input() heading?: string;
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() hasCustomSuccessHandler = false;

	@Output() registered: EventEmitter<string> = new EventEmitter();

	readonly dialogTypes = DialogTypes;
	readonly buttonSizes = ButtonSizes;
	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly formGroup = new FormGroup({
		username: new FormControl('', Validators.required),
		salutation: new FormControl('', Validators.required),
		title: new FormControl(''),
		givenName: new FormControl('', Validators.required),
		familyName: new FormControl('', Validators.required),
		phoneNumber: new FormControl('', Validators.required),
		day: new FormControl('', Validators.required),
		month: new FormControl('', Validators.required),
		year: new FormControl('', Validators.required),
		postalCode: new FormControl('', Validators.required),
		streetNumber: new FormControl('', Validators.required),
		streetNumberAddition: new FormControl(''),
		streetName: new FormControl('', Validators.required),
		city: new FormControl('', Validators.required),
		countryCode: new FormControl('', Validators.required),
		password: new FormControl('', Validators.required),
	});
	model = {
		username: '',
		salutation: '',
		title: '',
		givenName: '',
		familyName: '',
		phoneNumber: '',
		day: '',
		month: '',
		year: '',
		postalCode: '',
		streetNumber: '',
		streetNumberAddition: '',
		streetName: '',
		city: '',
		countryCode: this.domain,
		password: '',
	};
	fieldsBeforeAddress: FormlyFieldConfig[] = [];
	fieldsAfterAddress: FormlyFieldConfig[] = [];
	monthOptions: PickerEntry[] = [];
	isLoading = false;
	hasRegisterError = false;
	isServer = isPlatformServer(this.platformId);

	constructor(
		@Inject(PLATFORM_ID) public platformId: string,
		@Inject('affiliateKey') private readonly affiliateKey: number,
		@Inject('domainSource') private readonly domainSource: SourceType,
		@Inject('applicationUrls')
		private readonly applicationUrls: ApplicationUrlEnvironment,
		@Inject('countryCode')
		private readonly domain: SupportedDomainsCapitalized,
		private readonly accountService: RegisterService,
		private readonly translate: TranslateService,
		private readonly urlHelper: UrlHelper,
		private readonly existingEmailValidator: ExistingEmailValidator,
		@Optional()
		@Inject('envLanguages')
		private readonly envLanguages?: {
			availableLanguages: string[];
			default: string;
		},
	) {
		super();
		this.loadFormlyConfig();
	}

	ngAfterViewInit() {
		this.loadFormlyConfig();
		// @NOTE: This is needed to not show valid icons on load
		setTimeout(() => {
			this.model = {
				...this.model,
			};
		});
	}

	private loadFormlyConfig(): void {
		this.fieldsBeforeAddress =
			FormlyConfig.getRegisterFormFormlyConfigBeforeAddress(
				this.existingEmailValidator,
				DateTimeHelper.getMonthOptions(),
				this.monthEntryTemplate,
			);
		this.fieldsAfterAddress =
			FormlyConfig.getRegisterFormFormlyConfigAfterAddress();
	}

	addressUpdated(data: APIPostalAddressResponse) {
		const updates: Partial<typeof this.model> = {
			city: data.city || '',
			countryCode: (data.country || this.domain) as SupportedDomainsCapitalized,
			streetNumber: data.houseNumber || '',
			streetNumberAddition: data.houseNumberAddition || '',
			postalCode: data.postalCode || '',
			streetName: data.street || '',
		};
		this.model = {
			...this.model,
			...updates,
		};
		this.formGroup.patchValue(updates);
	}

	submit(event: Event): void {
		if (this.formGroup.invalid) {
			event.preventDefault();
			FormErrorScrollHelper.apply(
				document.querySelector('#register-form') as HTMLElement,
			);
			return;
		}
		this.isLoading = true;
		this.hasRegisterError = false;

		const birthdate = new Date(
			parseInt(this.model.year),
			parseInt(this.model.month),
			parseInt(this.model.day),
		);

		this.accountService
			.register({
				affiliateKey: this.affiliateKey,
				birthdate: DateTimeHelper.dateToString(birthdate, true),
				city: this.model.city,
				countryCode: this.model.countryCode,
				emailAddress: this.model.username.trim().toLowerCase(),
				firstName: this.model.givenName,
				gender: this.model.salutation,
				lastName: this.model.familyName,
				password: this.model.password,
				phoneNumber: this.model.phoneNumber,
				postalCode: this.model.postalCode,
				qualificationTitle: this.model.title,
				source: this.domainSource,
				street:
					`${this.model.streetName} ${this.model.streetNumber}${this.model.streetNumberAddition}`.trim(),
			})
			.subscribe((response) => {
				this.isLoading = false;

				if (!response) {
					this.hasRegisterError = true;
				} else if (isPlatformBrowser(this.platformId)) {
					if (this.hasCustomSuccessHandler) {
						this.registered.emit(this.model.username.trim().toLowerCase());
					} else {
						const mySunnyUrl =
							this.urlHelper.getApplicationUrlForDomainAndLanguage(
								this.applicationUrls.mySunnyUrl,
								this.domain,
								this.translate.currentLang,
								this.envLanguages?.default || this.domain.toLowerCase(),
								'mysunny/login',
							);
						window.location.href = `${mySunnyUrl}?registerSuccess=1`;
					}
				}
			});
	}
}
