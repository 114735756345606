import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	AffiliateConfigurationAPI,
	AffiliateListResponseAPI,
	AffiliateRequestAPI,
	AffiliateResponseAPI,
	FontAPI,
	GeneralConfigurationAPI,
	HeaderConfigurationAPI,
	HeaderConfigurationLogoAPI,
	ReviewConfigurationAPI,
	ReviewsAPI,
	TravelArrangementConfigurationAPI,
	TypographyConfigurationAPI,
	WidgetConfigurationAPI,
	WidgetConfigurationColorsAPI,
	WidgetConfigurationLocalizedInnerAPI,
} from '@sunny-cars/util-api-interfaces';
import {
	SourceType,
	SupportedDomainValues,
	SupportedDomainsCapitalized,
} from '@sunny-cars/util-global';
import { BaseComponent } from '@sunny-cars/util-global/lib/components/base.component';
import {
	Affiliate,
	AffiliateConfiguration,
	AffiliateReviews,
	AffiliateSearch,
	GeneralConfiguration,
	HeaderConfiguration,
	HeaderConfigurationLogo,
	ReviewConfiguration,
	TravelArrangementConfiguration,
	TypographyConfiguration,
	WidgetConfiguration,
	WidgetConfigurationColors,
	WidgetConfigurationLocalized,
} from '@sunny-cars/util-global/lib/interfaces/affiliates/affiliates.interface';
import { LogoSizes } from '@sunny-cars/util-global/lib/types/affiliate/logo-sizes.type';
import { LogoTypes } from '@sunny-cars/util-global/lib/types/affiliate/logo.type';
import { FontTypes } from '@sunny-cars/util-global/lib/types/fonts/fonts.type';
import { RecursivePartial } from '@sunny-cars/util-global/lib/types/recursive-partial/recursive-partial.type';
import { Observable, map } from 'rxjs';

interface ConfigRequest {
	typography?: RecursivePartial<TypographyConfigurationAPI>;
	general?: RecursivePartial<GeneralConfigurationAPI>;
	reviews?: RecursivePartial<ReviewConfigurationAPI>;
	header?: RecursivePartial<HeaderConfigurationAPI>;
	widget?: RecursivePartial<WidgetConfigurationAPI>;
	travelArrangement?: RecursivePartial<TravelArrangementConfigurationAPI>;
}

@Injectable({
	providedIn: 'root',
})
export class AffiliatesService extends BaseComponent {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl')
		private readonly apiBaseUrl: string,
	) {
		super();
	}

	getAffiliatesBySearchParam(
		search: string,
		source: SourceType,
	): Observable<AffiliateSearch[]> {
		return this.http
			.get<AffiliateListResponseAPI>(`${this.apiBaseUrl}v1/affiliates`, {
				params: { search, source },
			})
			.pipe(map((response) => this.parseAffiliatesResponse(response)));
	}

	private parseAffiliatesResponse(
		affiliatesResponse: AffiliateListResponseAPI,
	): AffiliateSearch[] {
		return (
			affiliatesResponse.data?.map(
				(affiliate): AffiliateSearch => ({
					id: affiliate.id || undefined,
					url: affiliate.url,
				}),
			) || []
		);
	}

	getAffiliateDetails(
		id: number,
		styleProfile: number | undefined,
		contentProfile: number | undefined,
		source: SourceType,
		version?: string,
	): Observable<Affiliate> {
		return this.http
			.get<AffiliateResponseAPI>(`${this.apiBaseUrl}v1/affiliates/${id}`, {
				params: {
					source,
					...(styleProfile ? { styleprofile: styleProfile } : {}),
					...(contentProfile ? { contentprofile: contentProfile } : {}),
					...(version ? { version } : {}),
				},
			})
			.pipe(map((response) => this.parseAffiliateResponse(response)));
	}

	patchAffiliateDetails(
		source: SourceType,
		deploy: boolean,
		body: RecursivePartial<Affiliate>,
	): Observable<Affiliate> {
		const requestBody = this.parseAffiliateRequest(source, deploy, body);

		return this.http
			.patch<AffiliateResponseAPI>(
				`${this.apiBaseUrl}v1/affiliates/${body.id}`,
				requestBody,
			)
			.pipe(map((response) => this.parseAffiliateResponse(response)));
	}

	putAffiliateLogo(
		affiliateId: number,
		image: File,
		type: LogoSizes,
	): Observable<object> {
		const formData = new FormData();
		formData.append('image', image);

		return this.http.put(
			`${this.apiBaseUrl}v1/affiliates/${affiliateId}/logo${type}`,
			formData,
		);
	}

	deleteAffiliateLogo(
		affiliateId: number,
		type: LogoSizes,
	): Observable<object> {
		return this.http.delete(
			`${this.apiBaseUrl}v1/affiliates/${affiliateId}/logo${type}`,
		);
	}

	private parseAffiliateRequest(
		source: SourceType,
		deploy: boolean,
		affiliate: RecursivePartial<Affiliate>,
	): RecursivePartial<AffiliateRequestAPI> {
		return {
			source: source,
			deploy: deploy,
			configuration: this.parseConfigRequest(affiliate.configuration),
		};
	}

	private parseConfigRequest(
		config: RecursivePartial<AffiliateConfiguration> | undefined | null,
	): RecursivePartial<AffiliateConfigurationAPI> | undefined {
		if (!config) {
			return undefined;
		}

		const configRequestValue: ConfigRequest = {
			typography: this.parseTypographyRequest(config.typography),
			general: this.parseGeneralRequest(config.general),
			reviews: this.parseReviewsRequest(config.reviews),
			header: this.parseHeaderRequest(config.header),
			widget: this.parseWidgetRequest(config.widget),
		};

		if (config.travelArrangement) {
			configRequestValue.travelArrangement = this.parseTravelArrangementRequest(
				config.travelArrangement,
			);
		}

		return configRequestValue;
	}

	private parseTypographyRequest(
		typography: RecursivePartial<TypographyConfiguration> | undefined,
	): RecursivePartial<TypographyConfigurationAPI> | undefined {
		if (!typography) {
			return undefined;
		}

		return {
			fonts: {
				text: this.parseFontRequest(typography.fonts?.text),
				title: this.parseFontRequest(typography.fonts?.title),
			},
			colors: typography.colors,
		};
	}

	private parseFontRequest(font: string | undefined): FontAPI {
		const indexOfFontType = Object.values(FontTypes).indexOf(font as FontTypes);
		const fontAPIkey = Object.keys(FontTypes)[indexOfFontType] as FontAPI;
		return fontAPIkey || FontAPI.Calibri;
	}

	private parseGeneralRequest(
		general: RecursivePartial<GeneralConfiguration> | undefined,
	): RecursivePartial<GeneralConfigurationAPI> | undefined {
		if (!general) {
			return undefined;
		}

		return {
			default: general.default,
			colors: general.colors,
			buttonGradient: general.buttonGradient,
			borderRadius: general.borderRadius,
		};
	}

	private parseReviewsRequest(
		reviews: RecursivePartial<ReviewConfiguration> | undefined,
	): RecursivePartial<ReviewConfigurationAPI> | undefined {
		if (!reviews) {
			return undefined;
		}

		return {
			trustpilot: reviews.trustpilot,
			ekomi: reviews.ekomi,
			google: reviews.google,
		};
	}

	private parseHeaderRequest(
		header: RecursivePartial<HeaderConfiguration> | undefined,
	): RecursivePartial<HeaderConfigurationAPI> | undefined {
		if (!header) {
			return undefined;
		}

		return {
			colors: header.colors,
			url: header.logoRedirectUrl,
			logo: { type: header.logo?.type },
			contact: header.contact,
		};
	}

	private parseWidgetRequest(
		widget: RecursivePartial<WidgetConfiguration> | undefined,
	): RecursivePartial<WidgetConfigurationAPI> | undefined {
		if (!widget) {
			return undefined;
		}

		return {
			colors: this.parseWidgetColorsRequest(widget.colors),
			localized: this.parseWidgetLocalized(widget.localized),
			usp: widget.usp,
			reviews: this.parseReviewsRequest(widget.reviews),
		};
	}

	private parseWidgetColorsRequest(
		colors: RecursivePartial<WidgetConfigurationColors> | undefined,
	): RecursivePartial<WidgetConfigurationColorsAPI> | undefined {
		if (!colors) {
			return undefined;
		}

		return {
			background: colors.background,
			title: colors.title,
			subtitle: colors.subtitle,
			text: colors.text,
			callToAction: colors.callToAction,
		};
	}

	private parseWidgetLocalized(
		localized: RecursivePartial<
			WidgetConfigurationLocalizedInnerAPI[] | undefined | null
		>,
	): WidgetConfigurationLocalized[] {
		if (!localized) {
			return [];
		}
		return (localized as WidgetConfigurationLocalizedInnerAPI[]).map(
			(item: WidgetConfigurationLocalizedInnerAPI) => ({
				language: item.language,
				title: item.title,
				subtitle: item.subtitle,
			}),
		);
	}

	private parseTravelArrangementRequest(
		travelArrangement: RecursivePartial<TravelArrangementConfiguration>,
	): RecursivePartial<TravelArrangementConfigurationAPI> {
		return {
			active: travelArrangement.active,
			localized: travelArrangement.localized,
			partnerName: travelArrangement.affiliateName,
		};
	}

	private parseAffiliateResponse(
		affiliateResponse: AffiliateResponseAPI,
	): Affiliate {
		if (!affiliateResponse.data) {
			return {
				id: 0,
			};
		}

		return {
			id: affiliateResponse.data.id,
			url: affiliateResponse.data.url,
			paymentMethods: affiliateResponse.data.paymentMethods,
			versions: affiliateResponse.data.versions,
			reviews: this.parseAffiliateReviewsResponse(
				affiliateResponse.data.reviews,
			),
			configuration: this.parseConfigResponse(
				affiliateResponse.data.configuration,
			),
			countryCode:
				(affiliateResponse.data.countryCode?.toUpperCase() as SupportedDomainsCapitalized) ||
				SupportedDomainValues.NL,
			currencyCode: affiliateResponse.data.currencyCode,
		};
	}

	private parseAffiliateReviewsResponse(
		reviews: ReviewsAPI | undefined,
	): AffiliateReviews | undefined {
		if (!reviews) {
			return undefined;
		}

		return {
			ekomi: reviews.ekomi,
			trustpilot: reviews.trustpilot,
			google: reviews.google,
		};
	}

	private parseConfigResponse(
		config: AffiliateConfigurationAPI | undefined,
	): AffiliateConfiguration | undefined {
		if (!config) {
			return undefined;
		}

		const travelArrangement = this.parseTravelArrangementResponse(
			config.travelArrangement,
		);

		return {
			general: this.parseGeneralResponse(config.general),
			typography: this.parseTypographyResponse(config.typography),
			reviews: this.parseReviewsResponse(config.reviews),
			header: this.parseHeaderResponse(config.header),
			widget: this.parseWidgetResponse(config.widget),
			...(travelArrangement && { travelArrangement: travelArrangement }),
		};
	}

	private parseTypographyResponse(
		typography: TypographyConfigurationAPI,
	): TypographyConfiguration {
		return {
			fonts: {
				title: this.parseFont(typography.fonts.title),
				text: this.parseFont(typography.fonts.text),
			},
			colors: typography.colors,
		};
	}

	private parseFont(font: string): FontTypes {
		return FontTypes[font as keyof typeof FontTypes];
	}

	private parseGeneralResponse(
		general: GeneralConfigurationAPI,
	): GeneralConfiguration {
		return {
			default: general.default,
			colors: general.colors,
			buttonGradient: general.buttonGradient,
			borderRadius: general.borderRadius,
		};
	}

	private parseReviewsResponse(
		reviews: ReviewConfigurationAPI,
	): ReviewConfiguration {
		return {
			trustpilot: reviews.trustpilot,
			ekomi: reviews.ekomi,
			google: reviews.google,
		};
	}

	private parseHeaderResponse(
		header: HeaderConfigurationAPI,
	): HeaderConfiguration {
		return {
			colors: header.colors,
			logo: this.parseLogoResponse(header.logo),
			logoRedirectUrl: header.url,
			contact: {
				phoneNumbers: header.contact.phoneNumbers.map((phoneNumber) => ({
					language: phoneNumber.language,
					phone: phoneNumber.phone,
					information: phoneNumber.information,
				})),
				emails: header.contact.emails.map((email) => ({
					language: email.language,
					email: email.email,
				})),
				default: header.contact.default,
			},
		};
	}

	private parseLogoResponse(
		logo: HeaderConfigurationLogoAPI,
	): HeaderConfigurationLogo {
		const timestamp = new Date().getTime();

		return {
			desktopURL: logo.desktopURL
				? `${logo.desktopURL}?v=${timestamp}`
				: logo.desktopURL,
			mobileURL: logo.mobileURL
				? `${logo.mobileURL}?v=${timestamp}`
				: logo.mobileURL,
			type: LogoTypes[logo.type],
		};
	}

	private parseWidgetResponse(
		widget: WidgetConfigurationAPI,
	): WidgetConfiguration {
		return {
			colors: this.parseWidgetColorsResponse(widget.colors),
			localized: widget.localized,
			usp: widget.usp,
			gaCode: widget.gaCode,
			reviews: this.parseReviewsResponse(widget.reviews),
		};
	}

	parseTravelArrangementResponse(
		travelArrangement?: TravelArrangementConfigurationAPI,
	): TravelArrangementConfiguration | undefined {
		if (travelArrangement === undefined) {
			return undefined;
		}

		return {
			active: travelArrangement.active,
			affiliateName: travelArrangement.partnerName,
			localized: travelArrangement.localized,
		};
	}

	private parseWidgetColorsResponse(
		colors: WidgetConfigurationColorsAPI,
	): WidgetConfigurationColors {
		return {
			background: colors.background,
			title: colors.title,
			subtitle: colors.subtitle,
			text: colors.text,
			callToAction: colors.callToAction,
		};
	}
}
