import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { SeoSettingsInterface } from '@sunny-cars/provider-cms-service/lib/page/interfaces/page.interface';
import { GoogleTagManagerService } from '@sunny-cars/util-global/lib/helpers/google-tag-manager/google-tag-manager.service';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';

@Injectable()
export class MetadataService {
	constructor(
		private metaTagService: Meta,
		private titleService: Title,
		@Inject(PLATFORM_ID) public platformId: string,
		private readonly gtmService: GoogleTagManagerService,
		private readonly environmentService: EnvironmentService,
	) {}

	updateMetadata(metadata: SeoSettingsInterface): void {
		const metatags: MetaDefinition[] = this.generateMetaDefinitions(metadata);

		this.titleService.setTitle(metadata.title);
		this.metaTagService.addTags([...metatags]);
	}

	generateMetaDefinitions(metadata: SeoSettingsInterface): MetaDefinition[] {
		return [
			{ name: 'title', content: metadata.title },
			{ property: 'og:title', content: metadata.title },

			{ name: 'description', content: metadata.metaDescription },

			{
				property: 'og:description',
				content: metadata.metaDescription,
			},

			{ name: 'og:image', content: metadata.image?.src || '' },
			...(metadata.robots.length
				? [{ name: 'robots', content: metadata.robots }]
				: []),
		];
	}

	setGTM(): void {
		const gtmConfig = this.environmentService.getDomainConfig().gtm?.config;
		this.gtmService.init(gtmConfig);
	}

	addMetaData(metaData?: MetaDefinition[]): void {
		if (metaData) {
			this.metaTagService.addTags(metaData);
		}
	}
}
