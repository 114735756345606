<ui-picker
	ngDefaultControl
	styleModifiers="input w-full bg-white"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[entryTemplate]="to['entryTemplate']"
	[position]="to['position'] || 'bottom'"
	[ignoreTrigger]="to['ignoreTrigger']"
	[entries]="getEntries()"
	[hasStandardSuffixIcon]="true"
	[isValid]="!formControl.errors && !to['hideValid']"
	[isInvalid]="showError"
	[isDisabled]="!!to['disabled']"
></ui-picker>
