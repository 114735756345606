<div
	class="relative p-3"
	data-test-class="notification"
	[ngClass]="{
		'bg-info-low': type === types.INFO,
		'bg-warning-low': type === types.WARNING,
		'bg-error-low': type === types.ERROR,
		'bg-success-low': type === types.SUCCESS,
		'bg-highlight-low': type === types.HIGHLIGHT
	}"
>
	<div class="relative mx-auto flex" [ngClass]="{ 'max-w-hero': isCentered }">
		<div
			class="mr-3 h-7 w-7 shrink-0"
			[class]="iconName"
			[ngClass]="{
				'bg-info': type === types.INFO,
				'bg-warning': type === types.WARNING,
				'bg-error': type === types.ERROR,
				'bg-success': type === types.SUCCESS,
				'bg-highlight': type === types.HIGHLIGHT,
				'icon-information-circle-solid':
					(type === types.INFO || type === types.HIGHLIGHT) && !iconName,
				'icon-check-circle-solid': type === types.SUCCESS && !iconName,
				'icon-exclamation-triangle-solid': type === types.WARNING && !iconName,
				'icon-exclamation-circle-solid': type === types.ERROR && !iconName
			}"
			*ngIf="showIcon"
		></div>
		<div class="text-base">
			<div class="text-lg" *ngIf="title" [ngClass]="{ 'pr-5': isDismissable }">
				<strong>{{ title }}</strong>
				<ng-content select="[ui-notification-title-content]"></ng-content>
			</div>
			<div>
				<ng-content></ng-content>
			</div>
		</div>
		<button
			class="absolute right-1 top-1 h-4 w-4 overflow-hidden bg-icon-cross-ui-critical bg-contain bg-center bg-no-repeat text-transparent"
			*ngIf="isDismissable"
			(click)="removeNotification()"
			data-test-id="dismiss-button"
		>
			{{ 'global.dismiss-action' | translate }}
		</button>
	</div>
</div>
