<div>
	<ui-password-input
		ngDefaultControl
		[formControl]="formControl"
		[formlyAttributes]="field"
		[attr.name]="field.id || ''"
		[controlId]="field.id || ''"
		[isInvalid]="showError"
		[attr.value]="formControl.value"
	></ui-password-input>
</div>
