import { Action, createReducer, on } from '@ngrx/store';
import {
	Affiliate,
	AffiliateConfiguration,
	ErrorHelper,
	ErrorMessageResponse,
	ErrorTypes,
	mergeObjects,
	RecursivePartial,
} from '@sunny-cars/util-global';
import * as AffiliateActions from './affiliate.actions';

export const AFFILIATE_FEATURE_KEY = 'affiliate';

export interface State {
	affiliate?: Affiliate;
	isLoading: boolean;
	isLoaded: boolean;
	errors?: ErrorMessageResponse[];
	lastAction?: string;
	hasPendingChanges: boolean;
}

export const initialState: State = {
	affiliate: undefined,
	isLoading: false,
	isLoaded: false,
	errors: undefined,
	lastAction: undefined,
	hasPendingChanges: false,
};

export enum StoreActions {
	ERROR_REQUEST_FAILED = 'errorrequestfailed',
	ERROR_FORBIDDEN = 'errorforbidden',
	CONCEPT_SUCCESS = 'conceptSuccess',
	CONCEPT_FAILED = 'conceptFailed',
	LIVE_SUCCESS = 'liveSuccess',
	LIVE_FAILED = 'liveFailed',
}

const configReducer = createReducer(
	initialState,
	on(AffiliateActions.init, (state) => ({
		...state,
		isLoading: false,
		isLoaded: false,
		errors: undefined,
		lastAction: undefined,
		hasPendingChanges: false,
	})),
	on(AffiliateActions.updateAffiliate, (state) => ({
		...state,
		isLoading: true,
		isLoaded: false,
		errors: undefined,
		lastAction: undefined,
	})),
	on(
		AffiliateActions.updateAffiliateSuccess,
		(state, { affiliate, deploy }) => ({
			...state,
			affiliate,
			isLoading: false,
			isLoaded: true,
			lastAction: deploy
				? StoreActions.LIVE_SUCCESS
				: StoreActions.CONCEPT_SUCCESS,
			hasPendingChanges: false,
		}),
	),
	on(AffiliateActions.updateAffiliateFailure, (state, { errors, deploy }) => ({
		...state,
		isLoading: false,
		isLoaded: false,
		errors,
		lastAction: deploy ? StoreActions.LIVE_FAILED : StoreActions.CONCEPT_FAILED,
	})),
	on(
		AffiliateActions.updateLocalAffiliateState,
		(state, { affiliateConfig }) => {
			return {
				...state,
				affiliate: state.affiliate
					? mergeAffiliateConfigurationState(state.affiliate, affiliateConfig)
					: undefined,
				hasPendingChanges: true,
			};
		},
	),
	on(AffiliateActions.loadAffiliate, (state) => ({
		...state,
		affiliate: undefined,
		isLoading: true,
		isLoaded: false,
		errors: undefined,
		lastAction: undefined,
	})),
	on(AffiliateActions.loadAffiliateSuccess, (state, { affiliate }) => ({
		...state,
		affiliate,
		isLoading: false,
		isLoaded: true,
	})),
	on(AffiliateActions.loadAffiliateFailure, (state, { errors }) => ({
		...state,
		affiliate: undefined,
		isLoading: false,
		isLoaded: false,
		lastAction: ErrorHelper.getSpecificError(errors, ErrorTypes.ERROR_FORBIDDEN)
			? StoreActions.ERROR_FORBIDDEN
			: StoreActions.ERROR_REQUEST_FAILED,
		errors,
	})),
	on(AffiliateActions.clearError, (state) => ({
		...state,
		errors: undefined,
	})),
	on(AffiliateActions.clear, (state) => ({
		...state,
		affiliate: undefined,
		isLoading: false,
		isLoaded: false,
		errors: undefined,
	})),
);

export function reducer(state: State | undefined, action: Action): State {
	return configReducer(state, action);
}

const mergeAffiliateConfigurationState = (
	affiliate: Affiliate,
	updated: RecursivePartial<AffiliateConfiguration>,
): Affiliate => {
	const updatedConfig: AffiliateConfiguration = mergeObjects(
		JSON.parse(
			JSON.stringify(affiliate.configuration),
		) as AffiliateConfiguration,
		updated,
	);
	return {
		...affiliate,
		configuration: updatedConfig,
	};
};
