/**
 * Helper for scrolling to form error
 */
export class FormErrorScrollHelper {
	/**
	 * Scroll to first form error
	 * @param {HTMLElement} container
	 * @returns {void}
	 */
	static apply(container?: HTMLElement): void {
		const errorElement: HTMLElement | null = (
			container || document
		).querySelector(':not(form).ng-invalid');
		const errorInputElement: HTMLElement | null | undefined =
			errorElement?.querySelector('input');
		errorElement?.scrollIntoView({
			behavior: 'smooth',
			block: 'center',
		});
		// istanbul ignore next
		setTimeout(() => {
			(errorInputElement || errorElement)?.focus();
		}, 500);
	}
}
