import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonSizes, ButtonStyleTypes } from '../button/button.component.data';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonComponent } from '../button/button.component';

@Component({
	selector: 'ui-sheet-header',
	templateUrl: './sheet-header.component.html',
	standalone: true,
	imports: [ButtonComponent, TranslateModule],
})
export class SheetHeaderComponent {
	readonly buttonStyleTypes = ButtonStyleTypes;
	readonly buttonSizes = ButtonSizes;

	@Input() title = '';

	@Output() closed: EventEmitter<void> = new EventEmitter();

	close(): void {
		this.closed.emit();
	}
}
