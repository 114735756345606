import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';
import { Sizes } from '@sunny-cars/util-global';
import {
	PopularCountriesComponentInterface,
	PopularCountryUrlTypes,
} from '@sunny-cars/util-global/lib/interfaces/content/popular-countries.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { ImageComponent } from '../image/image.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-popular-countries',
	templateUrl: './popular-countries.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		CollectionComponent,
		ImageComponent,
		RouterLink,
	],
})
export class PopularCountriesComponent {
	@Input() content?: PopularCountriesComponentInterface;
	readonly urlTypes = PopularCountryUrlTypes;
	sizes = Sizes;
}
