import { Action, createReducer, on } from '@ngrx/store';
import { ErrorMessageResponse, OrderReceipt } from '@sunny-cars/util-global';
import * as ReceiptActions from './receipt.actions';

export const RECEIPT_FEATURE_KEY = 'receipt';

export interface State {
	receipt: OrderReceipt | undefined;
	token: string;
	isLoading: boolean;
	isLoaded: boolean;
	showPriceChangeDialog: boolean;
	hasPriceChanged: boolean;
	locationId: number | undefined;
	errors?: ErrorMessageResponse[] | null;
}

export const initialState: State = {
	receipt: undefined,
	token: '',
	isLoading: false,
	isLoaded: false,
	showPriceChangeDialog: false,
	hasPriceChanged: false,
	locationId: undefined,
	errors: null,
};

const receiptReducer = createReducer(
	initialState,
	on(
		ReceiptActions.loadReceipt,
		(state: State): State => ({
			...state,
			isLoading: true,
			isLoaded: false,
		}),
	),
	on(
		ReceiptActions.loadReceiptSuccess,
		(state: State, { receipt, token }): State => ({
			...state,
			receipt,
			token,
			isLoading: false,
			isLoaded: true,
			errors: null,
		}),
	),
	on(
		ReceiptActions.loadReceiptFailure,
		(state: State, { errors }): State => ({
			...state,
			isLoading: false,
			isLoaded: false,
			showPriceChangeDialog: false,
			hasPriceChanged: false,
			errors,
		}),
	),
	on(
		ReceiptActions.setFilterLocationId,
		(state: State, { locationId }): State => ({
			...state,
			locationId,
		}),
	),
	on(
		ReceiptActions.hidePriceChangeDialog,
		(state: State): State => ({
			...state,
			showPriceChangeDialog: false,
		}),
	),
	on(
		ReceiptActions.showPriceChangeDialog,
		(state: State): State => ({
			...state,
			showPriceChangeDialog: true,
			hasPriceChanged: true,
		}),
	),
	on(
		ReceiptActions.clearError,
		(state: State): State => ({
			...state,
			isLoading: false,
			isLoaded: false,
			showPriceChangeDialog: false,
			hasPriceChanged: false,
			errors: null,
		}),
	),
	on(
		ReceiptActions.clear,
		(): State => ({
			receipt: undefined,
			token: '',
			isLoading: false,
			isLoaded: false,
			showPriceChangeDialog: false,
			hasPriceChanged: false,
			locationId: undefined,
			errors: null,
		}),
	),
	on(
		ReceiptActions.clearToken,
		(state): State => ({
			...state,
			token: '',
			isLoading: false,
			isLoaded: false,
			showPriceChangeDialog: false,
			hasPriceChanged: false,
			errors: null,
		}),
	),
);

/**
 * Receipt reducer
 * @param {State|undefined} state
 * @param {Action} action
 * @returns {State}
 */
export function reducer(state: State | undefined, action: Action): State {
	return receiptReducer(state, action);
}
