import { InjectionToken } from '@angular/core';

/**
 * Token for the storage reducers
 */
export const ACCOUNT_META_REDUCERS = new InjectionToken<string[]>(
	'accountStoreMetaReducers',
);
/**
 * Token for the storage key
 */
export const ACCOUNT_STORAGE_KEY = new InjectionToken<string[]>(
	'accountStoreKey',
);
/**
 * Token for the storage keys
 */
export const ACCOUNT_STORE_KEYS = new InjectionToken<string[]>(
	'accountStoreKeys',
);
