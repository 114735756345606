export enum ContentLayoutTypes {
	AWARD_ITEMS = 'awardItemsComponent',
	BANNER = 'bannerComponent',
	BLOG = 'blogComponent',
	CARD = 'cardComponent',
	CARDS = 'cardsComponent',
	COLLECTION = 'collectionComponent',
	CONTACT = 'contactComponent',
	CONTACT_PERSON = 'contactPersonComponent',
	COVER = 'coverComponent',
	CTA_BUTTON = 'ctaButtonComponent',
	DESTINATION_INFO = 'destinationInfoComponent',
	DOUBLE_COLUMN = 'doubleColumnComponent',
	FAQ = 'faqsComponent',
	FORM = 'formComponent',
	HERO = 'heroComponent',
	HTML_BLOCK = 'HtmlBlockComponent',
	IMAGE = 'imageComponent',
	INFO_ITEM = 'infoItemComponent',
	INFO_ITEMS = 'infoItemsComponent',
	LINK = 'linkComponent',
	MAPS = 'mapsComponent',
	NAVIGATION = 'navigationComponent',
	NEWS_ITEM_OVERVIEW = 'newsItemOverviewComponent',
	NEWSLETTER = 'newsletterComponent',
	PAGE_LINK = 'pageLinkComponent',
	PAGE_LINKS = 'pageLinksComponent',
	POPULAR_COUNTRIES = 'popularCountriesComponent',
	REGISTER = 'registerComponent',
	REVIEWS = 'reviewsComponent',
	SEARCH_TRAVEL_AGENCIES = 'searchTravelAgenciesComponent',
	SEPARATOR = 'separatorComponent',
	SITE_MAP = 'sitemapComponent',
	TEXT = 'textComponent',
	TRAVEL_AGENCIES = 'travelAgenciesComponent',
	USP = 'uspComponent',
	VIDEO = 'videoComponent',
	VISUAL_USP = 'visualUspComponent',
	VISUAL_USPS = 'visualUspsComponent',
	B2B_PORTAL_ITEMS = 'B2bPortalItemsComponent',
}
