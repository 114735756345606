import { getRouterSelectors } from '@ngrx/router-store';

/**
 * Router selectors
 */
export const {
	selectCurrentRoute,
	selectFragment,
	selectQueryParams,
	selectQueryParam,
	selectRouteParams,
	selectRouteParam,
	selectRouteData,
	selectUrl,
} = getRouterSelectors();
