import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { APIContactDetailsRequestParams } from '@sunny-cars/provider-contact-details-service';
import {
	ContactDetails,
	OpeningHours,
	OpeningTimes,
} from '@sunny-cars/util-global';
import { Observable } from 'rxjs';
import * as ContactDetailsActions from './contact-details.actions';
import * as ContactDetailsSelectors from './contact-details.selectors';

@Injectable({
	providedIn: 'root',
})
export class ContactDetailsFacade {
	contactDetails$: Observable<ContactDetails | undefined> = this.store.pipe(
		select(ContactDetailsSelectors.getContactDetails),
	);

	loading$: Observable<boolean> = this.store.pipe(
		select(ContactDetailsSelectors.getContactDetailsLoading),
	);

	loaded$: Observable<boolean | undefined> = this.store.pipe(
		select(ContactDetailsSelectors.getContactDetailsLoaded),
	);

	error$: Observable<string | null> = this.store.pipe(
		select(ContactDetailsSelectors.getContactDetailsError),
	);

	expires$: Observable<string | undefined> = this.store.pipe(
		select(ContactDetailsSelectors.getExpires),
	);

	todaysOpeningTimes$: Observable<OpeningTimes | undefined> = this.store.pipe(
		select(ContactDetailsSelectors.getTodaysOpeningTimes),
	);

	openingHours$: Observable<Array<OpeningHours> | undefined> = this.store.pipe(
		select(ContactDetailsSelectors.getOpeningHours),
	);

	constructor(private readonly store: Store) {}

	getContactDetails(params: APIContactDetailsRequestParams): void {
		this.store.dispatch(ContactDetailsActions.loadContactDetails({ params }));
	}

	clearError(): void {
		this.store.dispatch(ContactDetailsActions.clearError());
	}
}
