import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LocalStorageService } from '@sunny-cars/provider-storage';
import { getStorageReducer } from '@sunny-cars/util-global';
import { ContactDetailsEffects } from './contact-details.effects';
import { ContactDetailsFacade } from './contact-details.facade';
import * as fromContactDetails from './contact-details.reducer';
import {
	CONTACT_DETAILS_META_REDUCERS,
	CONTACT_DETAILS_STORAGE_KEY,
	CONTACT_DETAILS_STORE_KEYS,
	CONTACT_DETAILS_STORE_TTL,
} from './contact-details.tokens';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromContactDetails.CONTACT_DETAILS_FEATURE_KEY,
			fromContactDetails.reducer,
			CONTACT_DETAILS_META_REDUCERS,
		),
		EffectsModule.forFeature([ContactDetailsEffects]),
	],
	providers: [
		ContactDetailsFacade,
		{
			provide: CONTACT_DETAILS_STORAGE_KEY,
			useValue: '__contact_details_storage__',
		},
		{
			provide: CONTACT_DETAILS_STORE_KEYS,
			useValue: ['contactDetails', 'expiresDateTime', 'isLoaded'],
		},
		{
			provide: CONTACT_DETAILS_STORE_TTL,
			useValue: 86400000,
		},
		{
			deps: [
				CONTACT_DETAILS_STORAGE_KEY,
				LocalStorageService,
				CONTACT_DETAILS_STORE_KEYS,
				CONTACT_DETAILS_STORE_TTL,
			],
			provide: CONTACT_DETAILS_META_REDUCERS,
			useFactory: getStorageReducer,
		},
	],
})
export class ContactDetailsStoreModule {}
