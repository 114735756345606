import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	Component,
	ElementRef,
	HostBinding,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	PLATFORM_ID,
	SimpleChanges,
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { ColorHelper } from '@sunny-cars/util-global/lib/helpers/color/color.helper';
import { Icon } from '@sunny-cars/util-global/lib/interfaces/icon/icon.type';
import {
	IconAliasKey,
	IconKey,
	UiIcons,
} from '@sunny-cars/util-global/lib/interfaces/icons.interface';
import { colors } from '../../scss/settings/colors';

import { IconCacheService } from './icon-cache.service';

export interface IconInterface {
	description: string;
	/**
	 * Group the icon belongs to
	 */
	group?: string;
	svg: string;
}

@Component({
	selector: 'ui-icon',
	templateUrl: './icon.component.html',
	standalone: true,
	imports: [CommonModule],
})
export class IconComponent implements OnChanges, OnInit, OnDestroy {
	/**
	 * Set class on host element for alignment
	 * @returns {string}
	 */
	@HostBinding('class') get class(): string {
		if (this.inlined !== undefined) {
			return 'inline-flex items-center align-middle';
		}
		return 'flex items-center';
	}

	@Input() name: Icon | undefined;
	@Input() color = '';
	@Input() inlined: string | undefined;
	@Input() xs: string | undefined;
	@Input() sm: string | undefined;
	@Input() lg: string | undefined;
	@Input() xl: string | undefined;
	@Input() customSize: string | undefined;
	@Input() classModifiers = '';

	/**
	 * Sanitized html string of the icon
	 */
	sanitizedIcon: SafeHtml | undefined;
	/* istanbul ignore next */
	private computedStyles!: CSSStyleDeclaration;

	constructor(
		private readonly sanitizer: DomSanitizer,
		private readonly iconCacheService: IconCacheService,
		private readonly element: ElementRef,
		@Inject(PLATFORM_ID) public platformId: string,
	) {
		if (isPlatformBrowser(this.platformId)) {
			const styleElement = this.element.nativeElement;
			this.computedStyles = getComputedStyle(styleElement);

			window.addEventListener(
				'affiliateStyleChange',
				this.handleStyleChange.bind(this),
			);
		}
	}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			// Detect if element is visible
			if (['none', ''].includes(this.computedStyles.display)) {
				this.computedStyles = getComputedStyle(
					document.querySelector('sc-widget') || document.body,
				);
				this.handleStyleChange();
			}
		}
	}

	ngOnDestroy(): void {
		if (isPlatformBrowser(this.platformId)) {
			window.removeEventListener(
				'affiliateStyleChange',
				this.handleStyleChange.bind(this),
			);
		}
	}

	/**
	 * Updates the icon on property changes
	 */
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['name'] || changes['color']) {
			this.sanitizedIcon = this.getSanitizedIcon(this.name, this.color);
			if (!this.color) {
				// Wait one tick until element is rendered and background color can be determined
				setTimeout(() => {
					this.sanitizedIcon = this.getSanitizedIcon(this.name, this.color);
				});
			}
		}
	}

	/**
	 * Clear cache and fetch icon in most recent color
	 */
	private handleStyleChange(): void {
		this.iconCacheService.clearCache(`${this.name}--${this.color}`);
		this.sanitizedIcon = this.getSanitizedIcon(this.name, this.color);
	}

	/**
	 * Returns (cached) sanitized svg icon content
	 */
	private getSanitizedIcon(name: Icon | undefined, color?: string): SafeHtml {
		let cacheKey = `${name}--${color}`;
		const cache = this.iconCacheService.getCache(cacheKey);
		if (color && cache) {
			return cache;
		}
		const { iconSvg, color: colorValue } = this.getIcon(name, color);
		const sanitizedIcon = this.sanitizer.bypassSecurityTrustHtml(iconSvg);
		if (colorValue) {
			cacheKey = `${name}--${colorValue}`;
			const contrastCache = this.iconCacheService.getCache(cacheKey);
			if (contrastCache) {
				return contrastCache;
			}
			this.iconCacheService.setCache(cacheKey, sanitizedIcon);
		}
		return sanitizedIcon;
	}

	/**
	 * Get icon for name with correct color
	 */
	private getIcon(
		name: Icon | undefined,
		color?: string,
	): { iconSvg: string; color?: string } {
		let result: string = UiIcons.icons[name as IconKey]?.svg;
		let colorResult: string | undefined;
		if (!result) {
			const key: IconKey = UiIcons['icon-aliases'][
				name as IconAliasKey
			] as IconKey;
			result = UiIcons.icons[key]?.svg;
		}
		if (!result) {
			console.error(`Icon with name '${name}' not found`);
			return { iconSvg: '', color: undefined };
		}

		// Trim surrounding quotes
		let svg: string = result.replace(/^'|'$/g, '');

		// Apply given color to svg
		if (isPlatformBrowser(this.platformId)) {
			if (color) {
				// Try to get color from CSS variable
				const cssColor: string = this.computedStyles
					.getPropertyValue(`--color-${color}`)
					.trim();

				if (cssColor) {
					colorResult = cssColor;
					svg = svg.replace(/#000000/g, cssColor);
				}
			} else {
				const backgroundColor = ColorHelper.getBackgroundColor(
					this.element.nativeElement,
				);
				const backgroundColorHex = ColorHelper.rgbToHex(backgroundColor);
				const contrastColor = ColorHelper.getContrastColor(backgroundColorHex);
				if (contrastColor) {
					colorResult = contrastColor;
					svg = svg.replace(/#000000/g, contrastColor);
				}
			}
		} else {
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const colorValues: any = colors;
			const colorValue =
				color &&
				((colorValues[color] && colorValues[color]['DEFAULT']) ||
					colorValues[color]);
			if (colorValue) {
				svg = svg.replace(/#000000/g, colorValue);
				colorResult = colorValue;
			} else {
				svg = svg.replace(/#000000/g, 'transparent');
				colorResult = 'transparent';
			}
		}

		return { iconSvg: svg, color: colorResult };
	}
}
