<div
	class="flex h-11 w-full rounded-input border-2 bg-white p-0.5 sm:items-stretch"
	[ngClass]="{
		'segmented-control--invalid': isInvalid,
		'border-ui-high': !isInvalid
	}"
	data-test-class="segmented-control"
>
	<div
		*ngFor="let control of controlOptions"
		class="segmented-control__divider flex h-9 w-full leading-5 md:flex-1"
	>
		<input
			class="peer h-0 w-0 appearance-none overflow-hidden"
			data-test-class="segmented-control-input"
			type="radio"
			[name]="controlName"
			[value]="control.value"
			[id]="controlName + '-' + control.value"
			[(ngModel)]="value"
			(ngModelChange)="inputChange(control.value)"
			[disabled]="isDisabled"
		/>
		<label
			data-test-class="segmented-control-button"
			class="segmented-control__button"
			[ngClass]="{
				'selected cursor-default bg-selected text-white':
					value === control.value,
				disabled: isDisabled,
				'text-base': sm !== undefined
			}"
			[for]="controlName + '-' + control.value"
		>
			{{ (control.label$ | async) || control.label }}
		</label>
	</div>
</div>
