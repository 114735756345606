import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import * as NavigationActions from './navigation.actions';
import { NavigationEntity } from './navigation.interface';

/**
 * Storage key
 */
export const NAVIGATION_FEATURE_KEY = 'navigation';

/**
 * Store interface
 */
export interface State extends EntityState<NavigationEntity> {
	isRoot: boolean;
}

/**
 * Store adapter
 */
export const navigationAdapter: EntityAdapter<NavigationEntity> =
	createEntityAdapter<NavigationEntity>();

/**
 * Partial store interface
 */
export interface NavigationPartialState {
	/**
	 * State
	 */
	readonly [NAVIGATION_FEATURE_KEY]: State;
}

/**
 * Initial store state
 */
export const initialState: State = navigationAdapter.getInitialState({
	isRoot: true,
});

/**
 * Reducer handler
 */
const navigationReducer = createReducer(
	initialState,
	on(
		NavigationActions.set,
		(state: State, { entities }): State =>
			navigationAdapter.setMany(
				entities,
				navigationAdapter.removeAll({
					...state,
					isRoot: entities.length === 1,
				}),
			),
	),
	on(
		NavigationActions.updateCurrent,
		(state: State, { updates }): State =>
			navigationAdapter.updateOne(
				{
					id: parseInt(state.ids[state.ids.length - 1] as string),
					changes: {
						...updates,
					},
				},
				{
					...state,
				},
			),
	),
	on(NavigationActions.push, (state: State, { entity }): State => {
		let modifiedState: State = state;
		const keyIndex = state.ids.findIndex(
			(entityIdentifier: string | number) => {
				// istanbul ignore next
				return entity.key === state.entities[entityIdentifier]?.key;
			},
		);

		// If key already exists, remove it and everything after
		if (keyIndex > -1) {
			modifiedState = navigationAdapter.removeMany(
				state.ids.slice(keyIndex) as string[],
				state,
			);
		}
		return navigationAdapter.addOne(entity, {
			...modifiedState,
			isRoot: modifiedState.ids.length === 0,
		});
	}),
	on(NavigationActions.backToKey, (state: State, { key }): State => {
		const keyIndex = state.ids.findIndex(
			(entityIdentifier: string | number) => {
				// istanbul ignore next
				return key === state.entities[entityIdentifier]?.key;
			},
		);

		// If key already exists, remove everything after
		if (keyIndex > -1) {
			return navigationAdapter.removeMany(
				state.ids.slice(keyIndex + 1) as string[],
				{
					...state,
					isRoot: keyIndex === 0,
				},
			);
		}
		return state;
	}),
);

/**
 * Navigation reducer
 * @param {State|undefined} state
 * @param {Action} action
 * @returns {State}
 */
export function reducer(state: State | undefined, action: Action): State {
	return navigationReducer(state, action);
}
