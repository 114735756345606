<div
	*ngIf="content?.attributes as content"
	class="mx-auto flex flex-wrap gap-4 xs:gap-6 sm:flex-nowrap sm:gap-8 lg:grid lg:gap-x-9"
	[class.md:max-w-hero]="!isFullWidth"
	[ngClass]="{
		'grid-cols-2': !isTwoThirdColumn,
		'grid-cols-3': isTwoThirdColumn
	}"
>
	<section
		class="flex flex-col gap-3 xs:gap-5"
		[ngClass]="{ 'md:col-span-2': isTwoThirdColumn }"
		[id]="content.layoutSettingsLeft.anchor"
		class="{{ content.layoutSettingsLeft.width }}"
	>
		<ng-container
			*ngTemplateOutlet="columnTemplate; context: { content: content.left }"
		>
		</ng-container>
	</section>
	<section
		class="flex flex-col gap-3 xs:gap-5"
		[ngClass]="{ 'md:col-span-1': isTwoThirdColumn }"
		[id]="content.layoutSettingsRight.anchor"
		class="{{ content.layoutSettingsRight.width }}"
	>
		<ng-container
			*ngTemplateOutlet="columnTemplate; context: { content: content.right }"
		>
		</ng-container>
	</section>
</div>

<ng-template #columnTemplate let-content="content">
	<ng-container *ngFor="let contentItem of content">
		<ng-container *ngIf="contentItem.layout === layoutTypes.CARDS">
			<ng-container *ngIf="isB2B">
				<ui-b2b-cards
					[content]="convertCardsToB2bCards(contentItem)"
				></ui-b2b-cards>
			</ng-container>
			<ng-container *ngIf="!isB2B">
				<ui-cards [content]="contentItem"></ui-cards>
			</ng-container>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.CTA_BUTTON">
			<ui-cta-button [content]="contentItem"></ui-cta-button>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.FAQ">
			<ui-accordion
				[id]="content.anchor"
				[title]="contentItem.heading"
				[text]="contentItem.content"
				[questions]="contentItem.questions"
				[headingContentPosition]="contentItem.textPosition"
				[keepQuestionsVisible]="contentItem.keepQuestionsVisible"
			></ui-accordion>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.IMAGE">
			<ui-image
				class="max-w-md lg:mx-auto"
				[id]="contentItem.attributes.id"
				[image]="{
					altText: contentItem.attributes.alt,
					src: contentItem.attributes.src,
					width: 448,
					height:
						448 * (contentItem.attributes.height / contentItem.attributes.width)
						| roundNumber
				}"
				[className]="contentItem.attributes.widthClass"
			></ui-image>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.NAVIGATION">
			<ui-page-links
				[anchor]="contentItem.attributes.layoutSettings.anchor"
				[header]="contentItem.attributes.header"
				[headingType]="contentItem.attributes.headingType"
				[links]="contentItem.attributes.links"
				[width]="contentItem.attributes.layoutSettings.width"
			></ui-page-links>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.PAGE_LINKS">
			<ui-page-links [pages]="contentItem.attributes.pages"></ui-page-links>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.TEXT">
			<ui-text-block
				class="mt-4 block xs:mt-6 sm:mt-8 lg:mt-10"
				[ngClass]="contentItem.attributes.layoutSettings?.width"
				[id]="contentItem.attributes.layoutSettings.anchor"
				[content]="contentItem.attributes.content"
				[heading]="contentItem.attributes.heading"
				[headingType]="contentItem.attributes.headingType"
				[subheading]="contentItem.attributes.subheading"
			></ui-text-block>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.VISUAL_USPS">
			<ui-visual-usps
				[image]="contentItem.attributes.image"
				[heading]="contentItem.attributes.heading"
				[headingType]="contentItem.attributes.headingType"
				[subheading]="contentItem.attributes.subheading"
				[hasIcon]="contentItem.attributes.hasIcon"
				[usps]="contentItem.attributes.usps"
			></ui-visual-usps>
		</ng-container>

		<ng-container *ngIf="contentItem.layout === layoutTypes.FORM">
			<ng-container
				*ngIf="contentItem.attributes.formId === formTypes.MY_SUNNY_ACCOUNT"
			>
				<ui-register
					[heading]="contentItem.attributes.heading"
					[headingType]="contentItem.attributes.headingType"
				></ui-register>
			</ng-container>
		</ng-container>
	</ng-container>
</ng-template>
