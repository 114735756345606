import { CommonModule, SlicePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import {
	DateTimeFormats,
	NewsItemOverviewComponentInterface,
} from '@sunny-cars/util-global';
import { DateTimePipe } from '@sunny-cars/util-global/lib/pipes/date-time/date-time.pipe';
import { ButtonComponent } from '../button/button.component';
import { ButtonStyleTypes } from '../button/button.component.data';
import { CardComponent } from '../card/card.component';

@Component({
	selector: 'ui-news',
	templateUrl: 'news.component.html',
	standalone: true,
	imports: [
		ButtonComponent,
		CommonModule,
		CardComponent,
		SlicePipe,
		DateTimePipe,
	],
})
export class NewsComponent {
	@Input() itemAmount = 6;
	@Input() itemIncrement = 6;
	@Input() content?: NewsItemOverviewComponentInterface;

	readonly buttonStyleTypes = ButtonStyleTypes;

	dateTimeFormats = DateTimeFormats;
}
