<ng-template #logo>
	<ui-logo
		[isCustomAffiliate]="isCustomAffiliate$ | async"
		[logo]="logo$ | async"
	></ui-logo>
</ng-template>

<footer>
	<ng-content select="ui-footer-back-bar"></ng-content>
	<div
		*ngIf="hasHelpBar && expertrecId"
		class="flex justify-center bg-ui-low p-6 sm:p-12"
	>
		<div class="w-full max-w-7.5xl">
			<div class="mb-3 text-xl font-bold sm:text-2xl">
				<ui-icon
					class="flex pr-2"
					customSize
					classModifiers="w-6 h-6"
					name="icon-search"
					color="black"
					inlined
				></ui-icon
				>{{ 'components.footer.search-title' | translate }}
			</div>
			<ci-search></ci-search>
		</div>
	</div>
	<div
		class="bg-header-footer px-6 py-12 text-white sm:p-12"
		*ngIf="hasHelpBar"
	>
		<div class="m-auto max-w-7.5xl">
			<div *ngIf="heading" class="mb-6 break-words text-4xl md:text-5xl">
				{{ heading }}
			</div>
			<div
				*ngIf="content || phone || subFooterMenu"
				class="grid grid-flow-row grid-cols-1 gap-6 md:grid-flow-col md:grid-cols-2"
			>
				<div>
					<div [innerHTML]="content"></div>
					<div class="mt-4 flex items-center gap-2" *ngIf="phone">
						<ui-icon
							class="flex h-9 w-9 justify-center rounded-full bg-black/40 p-2"
							name="icon-phone"
							color="white"
							inlined
						></ui-icon>
						<div class="flex flex-col">
							<a
								[href]="'tel:' + phone"
								class="cursor-pointer font-bold leading-none underline"
							>
								{{ phone }}
							</a>
							<div
								class="mt-1 text-sm leading-none"
								*ngIf="showOpeningHours || (contact?.url && contact?.title)"
							>
								<span *ngIf="showOpeningHours">
									{{ openingTimesLabel$ | async }}
								</span>
								<a
									*ngIf="contact && contact.url && contact.title"
									class="underline"
									[routerLink]="contact.url"
								>
									{{ contact.title }}
								</a>
							</div>
						</div>
					</div>
				</div>
				<nav *ngIf="subFooterMenu" aria-label="Footer links">
					<div *ngFor="let item of subFooterMenu">
						<a
							*ngIf="item.value?.startsWith('http')"
							[target]="item.target"
							[href]="item.value"
							class="flex justify-between px-4 py-3 hover:bg-header-footer-critical"
						>
							<span>
								{{ item.name }}
							</span>
							<ui-icon
								name="icon-chevron-right"
								color="white"
								inlined
							></ui-icon>
						</a>
						<a
							*ngIf="item.value && !item.value.startsWith('http')"
							[routerLink]="item.value"
							[target]="item.target"
							class="flex justify-between px-4 py-3 hover:bg-header-footer-critical"
						>
							<span>
								{{ item.name }}
							</span>
							<ui-icon
								name="icon-chevron-right"
								color="white"
								inlined
							></ui-icon>
						</a>
						<hr class="opacity-30" />
					</div>
				</nav>
			</div>
			<hr
				class="mb-6 mt-10 opacity-30"
				*ngIf="
					(heading || content || phone || subFooterMenu) &&
					((newsletter?.url && newsletter?.title) ||
						newsletter?.description ||
						hasSocials(socials))
				"
			/>
			<div
				*ngIf="
					(newsletter?.url && newsletter?.title) ||
					newsletter?.description ||
					hasSocials(socials)
				"
				class="flex flex-col justify-between gap-4 md:flex-row"
			>
				<div class="flex items-center gap-2">
					<ng-container
						*ngIf="
							newsletter &&
							((newsletter.url && newsletter.title) || newsletter.description)
						"
					>
						<ui-icon
							class="flex h-9 w-9 justify-center rounded-full bg-black/40 p-2"
							name="icon-newsletter"
							color="white"
							inlined
						></ui-icon>
						<div class="flex flex-col">
							<a
								*ngIf="newsletter.url && newsletter.title"
								[href]="newsletter.url"
								class="cursor-pointer font-bold leading-none underline"
								>{{ newsletter.title }}</a
							>
							<span *ngIf="newsletter.description">{{
								newsletter.description
							}}</span>
						</div>
					</ng-container>
				</div>
				<ui-social-media
					*ngIf="socials && hasSocials(socials)"
					[twitter]="socials.twitter"
					[facebook]="socials.facebook"
					[instagram]="socials.instagram"
					[vimeo]="socials.vimeo"
					[blog]="socials.blog"
				>
				</ui-social-media>
			</div>
		</div>
	</div>
	<div
		uiContrastText
		class="flex flex-col flex-wrap justify-between gap-3 p-6 px-8 text-white sm:flex-row sm:items-center"
		[ngClass]="{
			'bg-header-footer': !hasHelpBar,
			'bg-header-footer-critical': hasHelpBar
		}"
	>
		<div>
			<ng-container *ngIf="logoRedirectUrl$ | async as url; else logo">
				<a *ngIf="!url.startsWith('http')" [routerLink]="url">
					<ng-container *ngTemplateOutlet="logo"></ng-container>
				</a>
				<a *ngIf="url.startsWith('http')" [href]="url">
					<ng-container *ngTemplateOutlet="logo"></ng-container>
				</a>
			</ng-container>
		</div>
		<div class="flex items-center">
			<nav
				*ngIf="(isCustomAffiliate$ | async) && !footerMenu"
				ui-custom-affiliate-links
				[baseURL]="baseURL"
				class="row flex flex-col flex-wrap gap-x-4 text-base sm:flex-row"
				aria-label="Site links"
			></nav>
			<nav
				*ngIf="(isCustomAffiliate$ | async) === false && !footerMenu"
				class="row flex flex-col flex-wrap gap-x-4 text-base sm:flex-row"
				aria-label="Site links"
			>
				<ng-container *ngTemplateOutlet="defaultLinks"></ng-container>
			</nav>
			<nav
				*ngIf="footerMenu"
				class="row flex flex-col flex-wrap gap-x-4 text-base sm:flex-row"
				aria-label="Site links"
			>
				<ng-container *ngFor="let item of footerMenu">
					<a
						*ngIf="item.value?.startsWith('http')"
						[target]="item.target"
						[href]="item.value"
					>
						{{ item.name }}
					</a>
					<a
						*ngIf="item.value && !item.value.startsWith('http')"
						[target]="item.target"
						[routerLink]="item.value"
					>
						{{ item.name }}
					</a>
				</ng-container>
			</nav>
		</div>
	</div>
</footer>

<ng-template #defaultLinks>
	<a
		*ngIf="
			('components.footer.links.terms-label' | translate).trim() &&
			('components.footer.links.terms-label' | translate) !==
				'components.footer.links.terms-label'
		"
		target="_blank"
		rel="noopener noreferrer"
		[href]="baseURL + ('components.footer.links.terms-page' | translate)"
	>
		{{ 'components.footer.links.terms-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.privacy-label' | translate).trim() &&
			('components.footer.links.privacy-label' | translate) !==
				'components.footer.links.privacy-label'
		"
		target="_blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.privacy-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.privacy-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.cookies-label' | translate).trim() &&
			('components.footer.links.cookies-label' | translate) !==
				'components.footer.links.cookies-label'
		"
		target="_blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.cookies-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.cookies-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.colophon-label' | translate).trim() &&
			('components.footer.links.colophon-label' | translate) !==
				'components.footer.links.colophon-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.colophon-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.colophon-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.imprint-label' | translate).trim() &&
			('components.footer.links.imprint-label' | translate) !==
				'components.footer.links.imprint-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.imprint-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.imprint-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.copyright-label' | translate).trim() &&
			('components.footer.links.copyright-label' | translate) !==
				'components.footer.links.copyright-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.copyright-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.copyright-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.jobs-label' | translate).trim() &&
			('components.footer.links.jobs-label' | translate) !==
				'components.footer.links.jobs-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="baseURL + ('components.footer.links.jobs-legacy-uri' | translate)"
	>
		{{ 'components.footer.links.jobs-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.b2b-label' | translate).trim() &&
			('components.footer.links.b2b-label' | translate) !==
				'components.footer.links.b2b-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="baseURL + ('components.footer.links.b2b-legacy-uri' | translate)"
	>
		{{ 'components.footer.links.b2b-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.press-label' | translate).trim() &&
			('components.footer.links.press-label' | translate) !==
				'components.footer.links.press-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="baseURL + ('components.footer.links.press-legacy-uri' | translate)"
	>
		{{ 'components.footer.links.press-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.about-label' | translate).trim() &&
			('components.footer.links.about-label' | translate) !==
				'components.footer.links.about-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="baseURL + ('components.footer.links.about-legacy-uri' | translate)"
	>
		{{ 'components.footer.links.about-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.destinations-label' | translate).trim() &&
			('components.footer.links.destinations-label' | translate) !==
				'components.footer.links.destinations-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="
			('components.footer.links.destinations-legacy-uri' | translate).includes(
				'http'
			)
				? ('components.footer.links.destinations-legacy-uri' | translate)
				: baseURL +
					('components.footer.links.destinations-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.destinations-label' | translate }}
	</a>
	<a
		*ngIf="
			('components.footer.links.sitemap-label' | translate).trim() &&
			('components.footer.links.sitemap-label' | translate) !==
				'components.footer.links.sitemap-label'
		"
		target="blank"
		rel="noopener noreferrer"
		[href]="
			baseURL + ('components.footer.links.sitemap-legacy-uri' | translate)
		"
	>
		{{ 'components.footer.links.sitemap-label' | translate }}
	</a>
</ng-template>
