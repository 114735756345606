import { createFeatureSelector, createSelector } from '@ngrx/store';
import { NavigationEntity } from './navigation.interface';
import {
	NAVIGATION_FEATURE_KEY,
	State,
	navigationAdapter,
} from './navigation.reducer';

/**
 * Lookup the 'Navigation' feature state managed by NgRx
 */
export const getNavigationState = createFeatureSelector<State>(
	NAVIGATION_FEATURE_KEY,
);

/**
 * Adapter selectors
 */
const { selectAll, selectEntities } = navigationAdapter.getSelectors();

/**
 * Select all navigation entities
 */
export const getNavigationEntities = createSelector(
	getNavigationState,
	(state: State) => selectEntities(state),
);

/**
 * Select navigation entities list
 */
export const getAllNavigation = createSelector(
	getNavigationState,
	(state: State) => {
		return selectAll(state);
	},
);

/**
 * Select previous navigation item
 */
export const getNavigationCurrent = createSelector(
	getAllNavigation,
	(stack: NavigationEntity[]) => {
		return stack[stack.length - 1];
	},
);

/**
 * Select previous navigation item
 */
export const getNavigationBack = createSelector(
	getAllNavigation,
	(stack: NavigationEntity[]) => {
		return stack[stack.length - 2];
	},
);

/**
 * Select navigation item by key
 * @param {string} key
 * @returns {any} - MemoizedSelector
 */
export const getNavigationByKey = (key: string) =>
	createSelector(getAllNavigation, (stack: NavigationEntity[]) => {
		return stack.find((entity: NavigationEntity) => entity.key === key);
	});
