<div>
	<ng-container *ngIf="heading" [ngSwitch]="headingLevel">
		<h1
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H1"
		>
			{{ heading }}
		</h1>
		<h2
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H2"
		>
			{{ heading }}
		</h2>
		<h3
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H3"
		>
			{{ heading }}
		</h3>
		<h4
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H4"
		>
			{{ heading }}
		</h4>
		<h5
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H5"
		>
			{{ heading }}
		</h5>
		<h6
			[ngClass]="customHeadingClasses || headingClasses"
			*ngSwitchCase="headingLevels.H6"
		>
			{{ heading }}
		</h6>
	</ng-container>
	<div
		*ngIf="subheading"
		[innerHTML]="subheading"
		[ngClass]="
			customSubHeadingClasses ||
			(heading ? subHeadingClasses + ' mt-2' : subHeadingClasses)
		"
	></div>
	<div
		*ngIf="intro"
		[innerHTML]="intro"
		class="prose sm:prose-lg"
		[ngClass]="{ 'mt-3 sm:mt-5': heading || subheading }"
	></div>
</div>
