import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import {
	PictureInterface,
	PictureSourceInterface,
} from '@sunny-cars/util-global';
import { LazyImageLoadingDirective } from '@sunny-cars/util-global/lib/directives/lazy-image/lazy-image.directive';
import { ImageQueryPipe } from '@sunny-cars/util-global/lib/pipes/image-query/image-query.pipe';
import resolveConfig from 'tailwindcss/resolveConfig';

// NOTE: use require instead of import due to strict
// eslint-disable-next-line @typescript-eslint/no-var-requires
const tailwindConfig = require('@sunny-cars/tailwind.config');

@Component({
	selector: 'ui-image',
	templateUrl: './image.component.html',
	standalone: true,
	imports: [CommonModule, LazyImageLoadingDirective, ImageQueryPipe],
})
export class ImageComponent implements OnChanges {
	@Input() image: PictureInterface = { src: '', width: 0, height: 0 };
	@Input() sources: PictureSourceInterface[] = [];
	@Input() className?: string;

	private fullConfig = resolveConfig && resolveConfig(tailwindConfig);
	private screenSizes =
		this.fullConfig.theme &&
		(this.fullConfig.theme['screens'] as { [key: string]: string });
	resolvedSources: (PictureSourceInterface & { media: string })[] = [];

	ngOnChanges(): void {
		this.resolvedSources = [];

		this.sources.forEach((source) => {
			let media = '';
			if (this.screenSizes) {
				if (source.min) {
					media = `(min-width: ${this.screenSizes[source.min]})`;
				}
				if (source.min && source.max) {
					media = media + ' and ';
				}
				if (source.max) {
					media =
						media + `(max-width: calc(${this.screenSizes[source.max]} - 1))`;
				}
			}
			this.resolvedSources.push({
				...source,
				media,
			});
		});
	}
}
