import { CommonModule, SlicePipe, isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PageService } from '@sunny-cars/provider-cms-service/lib/page/page.service';
import { ButtonComponent } from '@sunny-cars/ui/lib/button/button.component';
import {
	ButtonSizes,
	ButtonStyleTypes,
} from '@sunny-cars/ui/lib/button/button.component.data';
import { CardComponent } from '@sunny-cars/ui/lib/card/card.component';
import {
	DialogComponent,
	DialogTypes,
} from '@sunny-cars/ui/lib/dialog/dialog.component';
import { FormControlComponent } from '@sunny-cars/ui/lib/form-control/form-control.component';
import { SearchTravelAgencyComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content';
import { Observable, Subject, catchError, of, switchMap, tap } from 'rxjs';

@Component({
	selector: 'content-search-travel-agencies',
	templateUrl: './search-travel-agencies.component.html',
	standalone: true,
	imports: [
		ButtonComponent,
		CardComponent,
		CommonModule,
		DialogComponent,
		FormControlComponent,
		FormsModule,
		ReactiveFormsModule,
		SlicePipe,
		TranslateModule,
	],
})
export class SearchTravelAgenciesComponent implements OnInit {
	searchTerms$ = new Subject<string>();
	form = new FormGroup({
		search: new FormControl('', [Validators.required, Validators.minLength(3)]),
	});
	travelAgencies$: Observable<SearchTravelAgencyComponentInterface[]> = of([]);
	dialogTypes = DialogTypes;
	isFormSubmitted = false;
	travelAgencyCounter = 1;
	isLoading = false;
	readonly buttonSizes = ButtonSizes;
	readonly buttonStyleTypes = ButtonStyleTypes;

	constructor(
		private readonly pageService: PageService,
		@Inject(PLATFORM_ID) public platformId: string,
	) {}

	ngOnInit(): void {
		if (isPlatformBrowser(this.platformId)) {
			this.travelAgencies$ = this.searchTerms$.pipe(
				switchMap((query: string) => {
					return this.pageService.searchTravelAgencies(query).pipe(
						catchError(() => of([])),
						tap(() => {
							this.isLoading = false;
						}),
					);
				}),
			);
		}
	}

	searchTravelAgencies(): void {
		this.isFormSubmitted = true;
		if (this.form.valid) {
			this.isLoading = true;
			this.searchTerms$.next(this.form.controls['search'].value as string);
		}
	}
}
