import { FormControl } from '@angular/forms';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { FormlyFieldConfig, FormlyFieldProps } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormlyExtendedInterface } from './formly.interface';

/* istanbul ignore file */

export const fieldIsValid = (
	_model: unknown,
	_formState: unknown,
	fieldConfig: {
		formControl?: Pick<FormControl, 'valid'>;
	},
): boolean => !!fieldConfig?.formControl?.valid;

marker('global.formly.error.required');
marker('global.formly.error.email');
marker('global.formly.error.min');
marker('global.formly.error.max');
marker('global.formly.error.password');

export class TranslateExtension {
	constructor(private translate: TranslateService) {}

	prePopulate(field: FormlyFieldConfig): void {
		const to: (FormlyFieldProps & FormlyExtendedInterface) | undefined =
			field?.props;

		if (!to?.translate || to._translated) {
			return;
		}

		to._translated = true;

		const translatedTexts: { [key: string]: Observable<string> } = {};

		if (to.label) {
			translatedTexts['props.label'] = this.translate.stream(to.label);
		}
		if (to.label) {
			translatedTexts['props.label'] = this.translate.stream(to.label);
		}

		if (to.placeholder) {
			translatedTexts['props.placeholder'] = this.translate.stream(
				to.placeholder,
			);
		}

		if (to.labelExtras?.helperText) {
			translatedTexts['props.labelExtras.helperText'] = this.translate.stream(
				to.labelExtras.helperText,
				to.labelExtras.helperTextParams,
			);
		}

		if (to.validation?.messages && Object.keys(to.validation.messages).length) {
			for (const key in to.validation.messages) {
				translatedTexts[`validation.messages.${key}`] = this.translate.stream(
					to.validation.messages[key],
				);
			}
		}

		const { validation } = field;

		if (
			validation?.messages &&
			validation['params'] &&
			Object.keys(validation['params']).length
		) {
			Object.keys(validation.messages).forEach((messageKey) => {
				if (
					validation.messages &&
					validation.messages[messageKey] &&
					validation['params']
				) {
					const message = validation.messages[messageKey];
					const params = validation['params'];

					translatedTexts[`validation.messages.${messageKey}`] =
						this.translate.stream(message as string, params);
				}
			});
		}

		if (
			field.validators &&
			Object.keys(field.validators).length &&
			field.props
		) {
			if (Array.isArray(field.props.options)) {
				const options = field.props.options;
				field.props.options = this.translate
					.stream(options.map((option) => option.label))
					.pipe(
						map((labels) => {
							// eslint-disable-next-line @typescript-eslint/no-explicit-any
							Object.keys(options).forEach((option: any) => {
								options[option]['label'] = labels[Object.keys(labels)[option]];
							});
							return options;
						}),
					);
			}
		}

		field.expressions = {
			...(field.expressions || {}),
			...translatedTexts,
		};
	}
}

export function registerTranslateExtension(translate: TranslateService): {
	extensions: { extension: TranslateExtension; name: string }[];
	validationMessages: {
		name: string;
		message:
			| string
			| ((error: Error, field: FormlyFieldConfig) => Observable<string>);
	}[];
} {
	return {
		extensions: [
			{
				extension: new TranslateExtension(translate),
				name: 'translate',
			},
		],
		validationMessages: [
			{
				name: 'required',
				message: (_err, field) => {
					return translate.stream('global.formly.error.required', {
						field: field.props?.label,
					});
				},
			},
			{
				name: 'min',
				message: (_err, field) => {
					return translate.stream('global.formly.error.min', {
						value: field.formControl?.value,
					});
				},
			},
			{
				name: 'max',
				message: (_err, field) => {
					return translate.stream('global.formly.error.max', {
						value: field.formControl?.value,
					});
				},
			},
			{
				name: 'email',
				message: (_err, field) => {
					return translate.stream('global.formly.error.email', {
						value: field.formControl?.value,
					});
				},
			},
			{
				name: 'password',
				message: (_err, field) => {
					return translate.stream('global.formly.error.password', {
						value: field.formControl?.value,
					});
				},
			},
		],
	};
}
