import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	Inject,
	Injectable,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../../components/base.component';
import { CrawlerHelper } from '../crawler/crawler.helper';

@Injectable({
	providedIn: 'root',
})
export class UsercentricsService extends BaseComponent {
	private readonly renderer: Renderer2;
	private isCreated = false;

	constructor(
		@Inject(PLATFORM_ID) private platformId: string,
		@Inject(DOCUMENT) private document: Document,
		private readonly rendererFactory: RendererFactory2,
		private readonly translate: TranslateService,
		private readonly crawlerHelper: CrawlerHelper,
	) {
		super();
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	initUsercentrics(
		isProductionEnvironment: boolean,
		countryCode: string,
		settingsId: string,
		enableDataProtector: boolean,
		language?: string,
	) {
		if (this.isCreated) {
			return;
		}
		this.isCreated = true;
		this.createUsercentricsScriptTag(
			isProductionEnvironment,
			countryCode,
			settingsId,
			language,
		);

		if (enableDataProtector) {
			this.createSmartDataScriptTag();
		}

		this.updateUsercentricsLanguageAttribute();
		if (isPlatformBrowser(this.platformId) && !this.crawlerHelper.isCrawler()) {
			this.addUsercentricsEventListener();
		}
	}

	hide(): void {
		const body: HTMLElement = this.document.body;
		const script = this.renderer.createElement('script');
		script.setAttribute('id', 'hideUsercentrics');
		script.setAttribute('type', 'application/javascript');
		const text = this.renderer.createText(
			'var UC_UI_SUPPRESS_CMP_DISPLAY=true;',
		);
		this.renderer.appendChild(script, text);
		this.renderer.appendChild(body, script);
	}

	showIfConsentRequired(): void {
		const hideScript = document.getElementById('hideUsercentrics');
		hideScript?.remove();

		/* eslint-disable @typescript-eslint/no-explicit-any */
		if ((window as any).UC_UI?.isConsentRequired()) {
			(window as any).UC_UI.showFirstLayer();
		}
		/* eslint-enable @typescript-eslint/no-explicit-any */
	}

	private usercentricsEventCallBack(): void {
		window.location.reload();
	}

	private createUsercentricsScriptTag(
		isProductionEnvironment: boolean,
		countryCode: string,
		settingsId: string,
		language?: string,
	): void {
		const body: HTMLElement = this.document.body;
		const script = this.renderer.createElement('script');
		script.setAttribute('id', 'usercentrics-cmp');
		if (!isProductionEnvironment) {
			script.setAttribute('data-version', 'preview');
		}
		script.setAttribute(
			'data-language',
			`${language || this.translate.currentLang}`,
		);
		script.setAttribute(
			'src',
			`https://uc.sunnycars.${countryCode.toLowerCase()}/?settingsId=${settingsId}`,
		);
		script.setAttribute('data-settings-id', `${settingsId}`);
		script.setAttribute('async', '');
		this.renderer.appendChild(body, script);
	}

	private createSmartDataScriptTag(): void {
		const head: HTMLElement = this.document.head;
		const smartDataScript = this.renderer.createElement('script');
		const blockScript = this.renderer.createElement('script');
		smartDataScript.setAttribute(
			'src',
			`https://privacy-proxy.usercentrics.eu/latest/uc-block.bundle.js`,
		);
		smartDataScript.setAttribute('type', `application/javascript`);

		// Reference: https://docs.usercentrics.com/#/smart-data-protector?id=currently-supported-technologies
		blockScript.text = `
			uc.deactivateBlocking([
				'Hko_qNsui-Q' // Do not block ReCaptcha
			]);
			uc.blockElements({
				'S1pcEj_jZX': '[data-smart-data-protector]', // Google Maps wrapper, it does not work out of the box
			  });
		`;
		this.renderer.insertBefore(head, smartDataScript, head.firstChild);
		this.renderer.appendChild(head, blockScript);
	}

	private addUsercentricsEventListener(): void {
		window.addEventListener(
			'itp_consent_save_finished',
			this.usercentricsEventCallBack,
		);
	}

	private updateUsercentricsLanguageAttribute(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}

		this.addSubscription(
			this.translate.onLangChange.subscribe(() => {
				const scriptTag: HTMLElement | null =
					this.document.querySelector('#usercentrics-cmp');

				scriptTag?.setAttribute(
					'data-language',
					`${this.translate.currentLang}`,
				);
			}),
		);
	}
}
