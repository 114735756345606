<div class="flex flex-wrap items-center gap-2">
	<ui-icon
		*ngFor="let paymentMethod of paymentMethodsItems"
		[uiTooltip]="
			showToolTips && paymentMethod.label
				? (paymentMethod.label | translate)
				: undefined
		"
		[name]="paymentMethod.icon"
		[xl]="iconSize === 'xl' ? '' : undefined"
		[lg]="iconSize === 'lg' ? '' : undefined"
		[sm]="iconSize === 'sm' ? '' : undefined"
		[xs]="iconSize === 'xs' ? '' : undefined"
	></ui-icon>
</div>
