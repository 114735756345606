<div>
	<ui-phonenumber-input
		ngDefaultControl
		[formControl]="formControl"
		[formlyAttributes]="field"
		[attr.name]="field.id || ''"
		[controlId]="field.id || ''"
		[isInvalid]="showError"
		[isValid]="!formControl.errors && !props['hideValid']"
		[attr.value]="{ countryCode: 'nl', phoneNumber: formControl.value }"
	>
	</ui-phonenumber-input>
</div>
