import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AffiliateProviderModule } from '@sunny-cars/provider-affiliate-service';
import { SessionStorageService } from '@sunny-cars/provider-storage';
import { getStorageReducer } from '@sunny-cars/util-global';
import { AffiliateEffects } from './affiliate.effects';
import { AffiliateFacade } from './affiliate.facade';
import * as fromAffiliate from './affiliate.reducer';
import {
	AFFILIATE_META_REDUCERS,
	AFFILIATE_STORAGE_KEY,
	AFFILIATE_STORE_KEYS,
} from './affiliate.tokens';

@NgModule({
	imports: [
		CommonModule,
		StoreModule.forFeature(
			fromAffiliate.AFFILIATE_FEATURE_KEY,
			fromAffiliate.reducer,
			AFFILIATE_META_REDUCERS,
		),
		EffectsModule.forFeature([AffiliateEffects]),
		AffiliateProviderModule,
	],
	providers: [
		AffiliateFacade,
		{
			provide: AFFILIATE_STORAGE_KEY,
			useValue: '__affiliate_storage__',
		},
		{
			provide: AFFILIATE_STORE_KEYS,
			useValue: ['affiliate'],
		},
		{
			deps: [
				AFFILIATE_STORAGE_KEY,
				SessionStorageService,
				AFFILIATE_STORE_KEYS,
			],
			provide: AFFILIATE_META_REDUCERS,
			useFactory: getStorageReducer,
		},
	],
})
export class AffiliateStoreModule {}
