import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { SafeHtmlPipe } from '@sunny-cars/util-global/lib/pipes/safe-html/safe-html.pipe';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';

@Component({
	selector: 'ui-video',
	templateUrl: './video.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [
		`
			ui-video iframe {
				@apply aspect-video h-auto w-[640px];
			}
		`,
	],
	standalone: true,
	imports: [CommonModule, HeadingContentBlockComponent, SafeHtmlPipe],
})
export class VideoComponent {
	@Input() content = '';
	@Input() heading = '';
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() html = '';
}
