import { MapsComponentInterface } from '@sunny-cars/util-global';
import { AwardItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/award-items.interface';
import {
	B2BPortalItemInterface,
	B2BPortalItemsComponentInterface,
} from '@sunny-cars/util-global/lib/interfaces/content/b2b-portal-items.interface';
import { BannerComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/banner.interface';
import { BlogComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/blog.interface';
import { CardsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cards.interface';
import { CollectionComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/collection.interface';
import { ContactPersonComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/contact-person.interface';
import { ContactComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/contact.interface';
import { CoverComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cover.interface';
import { CtaButtonComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cta-button.interface';
import { DestinationInfoComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/destination-info.interface';
import {
	DoubleColumnComponentInterface,
	DoubleColumnComponentProperty,
} from '@sunny-cars/util-global/lib/interfaces/content/double-column.interface';
import {
	FaqComponentInterface,
	FaqTextPosition,
} from '@sunny-cars/util-global/lib/interfaces/content/faq.interface';
import {
	FormComponentInterface,
	FormType,
} from '@sunny-cars/util-global/lib/interfaces/content/form.interface';
import { HeroComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/hero.interface';
import { HtmlBlockComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/html-block.interface';
import { ImageInterface } from '@sunny-cars/util-global/lib/interfaces/content/image-type.interface';
import { ImageComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/image.interface';
import { InfoItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/info-items.interface';
import { LayoutWidthClasses } from '@sunny-cars/util-global/lib/interfaces/content/layout-width-classes.interface';
import { LinkInterface } from '@sunny-cars/util-global/lib/interfaces/content/link-type.interface';
import { NavigationComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/navigation.interface';
import { NewsItemOverviewComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/news-item-overview.interface';
import { NewsletterComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/newsletter.interface';
import { PageContentInterfaces } from '@sunny-cars/util-global/lib/interfaces/content/page-content.interface';
import { PageLinksComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/page-links.interface';
import {
	PopularCountriesComponentInterface,
	PopularCountryUrlTypes,
} from '@sunny-cars/util-global/lib/interfaces/content/popular-countries.interface';
import { ReviewsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/reviews.interface';
import { SeparatorComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/separator-interface';
import {
	SitemapComponentInterface,
	SitemapItem,
} from '@sunny-cars/util-global/lib/interfaces/content/sitemap.interface';
import { TextComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/text.interface';
import {
	SearchTravelAgenciesComponentInterface,
	TravelAgenciesComponentInterface,
} from '@sunny-cars/util-global/lib/interfaces/content/travel-agencies.interface';
import { VideoComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/video.interface';
import { VisualUspsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/visual-usps.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { ContentLayoutTypes } from '@sunny-cars/util-global/lib/interfaces/page-content-layout-types.interface';
import {
	ExtraInterface,
	HrefLang,
	PageNotificationInterface,
	PageNotificationTypeInterface,
	PageEntityInterface,
	PageTranslation,
	SeoSettingsInterface,
} from './interfaces';
import {
	ExtraResponseInterface,
	HrefLangLinkResponse,
	PageEntityResponseInterface,
	PageTranslation as PageTranslationResponseInterface,
	SeoSettingsResponseInterface,
} from './interfaces/page-response.interface';
import {
	AwardItemResponseInterface,
	AwardItemsResponseInterface,
	BannerResponseInterface,
	BlogResponseInterface,
	CardsResponseInterface,
	CoverResponseInterface,
	CtaButtonResponseInterface,
	DoubleColumnResponseInterface,
	DoubleColumnResponseProperty,
	FormResponseInterface,
	HeroResponseInterface,
	HtmlBlocksResponseInterface,
	ImageComponentResponseInterface,
	ImageResponseInterface,
	InfoItemsResponseInterface,
	LinkResponseInterface,
	NavigationResponseInterface,
	NewsletterResponseInterface,
	PopularCountriesResponseInterface,
	ReviewsResponseInterface,
	SeparatorResponseInterface,
	SitemapResponseInterface,
	TextResponseInterface,
	TravelAgenciesResponseInterface,
	VideoResponseInterface,
	VisualUspsResponseInterface,
} from './interfaces/response';
import { B2BPortalItemsResponseInterface } from './interfaces/response/b2b-portal-items.interface';
import { CollectionResponseInterface } from './interfaces/response/collection.interface';
import { ContactPersonResponseInterface } from './interfaces/response/contact-person.interface';
import { ContactResponseInterface } from './interfaces/response/contact.interface';
import { DestinationInfoResponseInterface } from './interfaces/response/destination-info.interface';
import { FaqComponentResponseInterface } from './interfaces/response/faq.interface';
import { LayoutWidths } from './interfaces/response/layout-widths.interface';
import { MapsResponseInterface } from './interfaces/response/maps.interface';
import { NewsItemOverviewResponseInterface } from './interfaces/response/news-item-overview.interface';
import { PageContentResponseInterfaces } from './interfaces/response/page-content-response.interface';
import { PageLinksResponseInterface } from './interfaces/response/page-links.interface';
import { SearchTravelAgenciesResponseInterface } from './interfaces/response/travel-agencies.interface';
import { PageNotificationCMSAPI } from '@sunny-cars/util-api-interfaces';

/**
 * Helper for parsing page content responses to page content component interfaces
 */
export class PageHelper {
	static parsePageResponse(
		data: PageEntityResponseInterface,
	): PageEntityInterface {
		return {
			breadcrumbs:
				data.breadcrumbs?.map((breadcrumb) => {
					return {
						isActive: breadcrumb.active,
						path: breadcrumb.path,
						title: breadcrumb.title,
					};
				}) || [],
			content: this.parsePageEntityContents(data.content),
			destination: data.destination,
			extra: this.parseExtra(data.extra),
			hasCustomPath: data.use_custom_path,
			locale: {
				isoCode: data.locale.iso_code,
				languageUri: data.locale.language_uri,
			},
			path: data.path,
			hrefLangs: this.parseHrefLangs(data.href_lang_links),
			seoSettings: this.parseSeoSettings(data.seo_settings),
			regionId: data.region_id,
			countryId: data.country_id,
			slug: data.slug,
			title: data.title,
			type: data.page_type,
			translations: this.parseTranslations(data.translations),
			altURLs: this.parseAltURLs(
				{ language: data.locale.language_uri, path: data.path },
				data.translations,
			),
			hideUsercentrics: data.hide_usercentrics ?? false,
			notification: this.parseNotification(data.notification),
		};
	}

	static convertCardsToB2bCards(
		cards: CardsComponentInterface,
	): B2BPortalItemsComponentInterface {
		return {
			layout: ContentLayoutTypes.B2B_PORTAL_ITEMS,
			heading: cards.attributes.heading,
			headingType: cards.attributes.headingType,
			subheading: '',
			content: cards.attributes.content,
			items: cards.attributes.cards.map((card) => ({
				button: card.attributes.link
					? {
							title: card.attributes.link.text,
							link: card.attributes.link.href,
							openInNewTab: card.attributes.link.shouldOpenInNewTab,
						}
					: undefined,
				image: card.attributes.image,
				content: card.attributes.content,
				publishDate: '',
				title: card.attributes.title,
			})),
			layoutSettings: {
				anchor: cards.attributes.layoutSettings.anchor,
				width: cards.attributes.layoutSettings.width,
			},
			twoColumns: false,
			isHorizontal: cards.attributes.isHorizontal,
			showImage: !!cards.attributes.cards.find(
				(card) => !!card.attributes.image,
			),
		};
	}

	private static parseExtra(
		extra: ExtraResponseInterface | null,
	): ExtraInterface | undefined {
		if (!extra) {
			return undefined;
		}
		try {
			return {
				email: extra.contact_person.email,
				function: extra.contact_person.translations?.function || '',
				image: this.parseImage(
					extra.contact_person.image,
					extra.contact_person.translations?.image_alt || '',
				),
				linkedin: extra.contact_person.linkedin || '',
				title: extra.contact_person.translations?.title || '',
				phone: extra.contact_person.phone || '',
				twitter: extra.contact_person.twitter || '',
			};
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	private static parseSeoSettings(
		seoSettings: SeoSettingsResponseInterface | null,
	): SeoSettingsInterface | undefined {
		if (!seoSettings) {
			return undefined;
		}
		try {
			return {
				canonicalUrl: seoSettings.canonical_url,
				image: seoSettings.og_image
					? {
							name: seoSettings.og_image.file_name,
							src: seoSettings.og_image.url,
						}
					: undefined,
				linkDataJson: seoSettings.ld_json || undefined,
				metaDescription: seoSettings.meta_description,
				robots: seoSettings.indexable.toString(),
				title: seoSettings.title,
			};
		} catch (error) {
			console.error(error);
			return undefined;
		}
	}

	private static parseTranslations(
		translations?: PageTranslationResponseInterface[],
	): PageTranslation[] {
		if (!translations) {
			return [];
		}
		try {
			return translations.map((translation) => {
				return {
					isoCode: translation.locale.iso_code,
					languageUri: translation.locale.language_uri,
					path: translation.path,
					url: translation.url,
				};
			});
		} catch (error) {
			console.error(error);
			return [];
		}
	}

	private static parseHrefLangs(
		hrefLangs?: HrefLangLinkResponse[],
	): HrefLang[] {
		if (!hrefLangs || !Array.isArray(hrefLangs)) {
			return [];
		}
		return hrefLangs.map((hrefLang) => {
			return {
				href: hrefLang.url,
				lang: hrefLang.locale.iso_code,
			};
		});
	}

	private static parsePageEntityContents(
		contents: Array<PageContentResponseInterfaces>,
	): PageContentInterfaces[] {
		const output: PageContentInterfaces[] = [];

		contents.forEach((data) => {
			try {
				switch (data.layout) {
					case ContentLayoutTypes.AWARD_ITEMS:
						output.push(this.parseAwardItemsResponse(data));
						break;
					case ContentLayoutTypes.B2B_PORTAL_ITEMS:
						output.push(this.parseB2BPortalItemsResponse(data));
						break;
					case ContentLayoutTypes.BANNER:
						output.push(this.parseBannerResponse(data));
						break;
					case ContentLayoutTypes.BLOG:
						output.push(this.parseBlogResponse(data));
						break;
					case ContentLayoutTypes.CARDS:
						output.push(this.parseCardsResponse(data));
						break;
					case ContentLayoutTypes.COLLECTION:
						output.push(this.parseCollectionResponse(data));
						break;
					case ContentLayoutTypes.CONTACT:
						output.push(this.parseContactResponse(data));
						break;
					case ContentLayoutTypes.CONTACT_PERSON:
						output.push(this.parseContactPersonResponse(data));
						break;
					case ContentLayoutTypes.COVER:
						output.push(this.parseCoverResponse(data));
						break;
					case ContentLayoutTypes.CTA_BUTTON:
						output.push(this.parseCtaButtonResponse(data));
						break;
					case ContentLayoutTypes.DESTINATION_INFO:
						output.push(this.parseDestinationInfo(data));
						break;
					case ContentLayoutTypes.DOUBLE_COLUMN:
						output.push(this.parseDoubleColumnResponse(data));
						break;
					case ContentLayoutTypes.FAQ:
						output.push(this.parseFaqResponse(data));
						break;
					case ContentLayoutTypes.HERO:
						output.push(this.parseHeroReponse(data));
						break;
					case ContentLayoutTypes.FORM:
						output.push(this.parseFormResponse(data));
						break;
					case ContentLayoutTypes.HTML_BLOCK:
						output.push(this.parseHtmlBlockResponse(data));
						break;
					case ContentLayoutTypes.IMAGE:
						output.push(this.parseImageComponentResponse(data));
						break;
					case ContentLayoutTypes.INFO_ITEMS:
						output.push(this.parseInfoItemsResponse(data));
						break;
					case ContentLayoutTypes.MAPS:
						output.push(this.parseMapsResponse(data));
						break;
					case ContentLayoutTypes.NAVIGATION:
						output.push(this.parseNavigationResponse(data));
						break;
					case ContentLayoutTypes.NEWS_ITEM_OVERVIEW:
						output.push(this.parseNewsItemOverviewResponse(data));
						break;
					case ContentLayoutTypes.NEWSLETTER:
						output.push(this.parseNewsletterResponse(data));
						break;
					case ContentLayoutTypes.POPULAR_COUNTRIES:
						output.push(this.parsePopularCountriesResponse(data));
						break;
					case ContentLayoutTypes.REVIEWS:
						output.push(this.parseReviewsResponse(data));
						break;
					case ContentLayoutTypes.SITE_MAP:
						output.push(this.parseSitemapResponse(data));
						break;
					case ContentLayoutTypes.SEARCH_TRAVEL_AGENCIES:
						output.push(this.parseSearchTravelAgenciesResponse(data));
						break;
					case ContentLayoutTypes.SEPARATOR:
						output.push(this.parseSeparatorResponse(data));
						break;
					case ContentLayoutTypes.TEXT:
						output.push(this.parseTextResponse(data));
						break;
					case ContentLayoutTypes.TRAVEL_AGENCIES:
						output.push(this.parseTravelAgenciesResponse(data));
						break;
					case ContentLayoutTypes.VIDEO:
						output.push(this.parseVideoResponse(data));
						break;
					case ContentLayoutTypes.VISUAL_USPS:
						output.push(this.parseVisualUspsResponse(data));
				}
			} catch (e) {
				console.error(e);
			}
		});
		return output;
	}

	private static parseAltURLs(
		currentRoute: { language: string; path: string },
		altRoutes?: PageTranslationResponseInterface[],
	): { [key: string]: string }[] {
		let currentLanguage = currentRoute.language;
		if (currentLanguage.length > 2) {
			currentLanguage = currentLanguage.slice(-2);
		}
		let currentRoutePath = currentRoute.path;
		if (currentRoutePath.startsWith('/')) {
			currentRoutePath = currentRoutePath.substring(1);
		}
		const current: { [key: string]: string } = {
			[currentLanguage]: currentRoutePath,
		};
		const alternatives = altRoutes
			? altRoutes.map((route: PageTranslationResponseInterface) => {
					let language = route.locale.language_uri;
					let routePath = route.path;
					if (language.length > 2) {
						language = language.slice(-2);
					}
					if (routePath.startsWith('/')) {
						routePath = routePath.substring(1);
					}
					return {
						[language]: routePath,
					};
				})
			: [];
		return [current, ...alternatives];
	}

	private static parseAwardItemsResponse(
		data: AwardItemsResponseInterface,
	): AwardItemsComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings?.anchor || '',
					width: this.parseLayoutWidths(
						attributes.layout_settings?.width || null,
					),
				},
				items: attributes.items.map((item: AwardItemResponseInterface) => {
					return {
						date: this.parseDate(item.date),
						description: item.description,
						link: item.link || undefined,
						logo: item.logo
							? this.parseImage(item.logo, item.logo_alt)
							: undefined,
						name: item.name,
						separateLogo: item.separate_logo
							? this.parseImage(item.separate_logo, item.separate_logo_alt)
							: undefined,
					};
				}),
			},
			layout: data.layout,
		};
	}

	private static parseB2BPortalItemsResponse(
		data: B2BPortalItemsResponseInterface,
	): B2BPortalItemsComponentInterface {
		return {
			content: data.attributes.content || '',
			heading: data.attributes.heading || '',
			headingType: data.attributes.heading_type as HeadingLevels,
			subheading: data.attributes.subheading || '',
			showImage: true,
			items:
				data.attributes.items?.map(
					(item): B2BPortalItemInterface => ({
						button: item.button
							? {
									title: item.button.title || '',
									link: item.button.link?.path || item.button.link?.url || '',
									openInNewTab: !!item.button.open_in_new_tab,
								}
							: undefined,
						content: item.content || '',
						image: item.image ? this.parseImage(item.image) : undefined,
						publishDate: (item.show_publish_date && item.publish_date) || '',
						title: item.title || '',
					}),
				) || [],
			layoutSettings: {
				anchor: data.attributes.layout_settings?.anchor || '',
				width: data.attributes.layout_settings?.width || '',
			},
			layout: data.layout,
		};
	}

	private static parseBannerResponse(
		data: BannerResponseInterface,
	): BannerComponentInterface {
		return {
			attributes: {
				image: this.parseImage(data.attributes.image),
				title: data.attributes.title,
			},
			layout: data.layout,
		};
	}

	private static parseBlogResponse(
		data: BlogResponseInterface,
	): BlogComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor,
					width: this.parseLayoutWidths(
						(attributes.layout_settings.width as LayoutWidths) || '1-1',
					),
				},
				blogTags: attributes.blog_tags.map((tag) => {
					return {
						name: tag.name || '',
						slug: tag.slug || '',
						url: tag.url || '',
					};
				}),
				headingType:
					(attributes.heading_type as HeadingLevels) || HeadingLevels.H1,
				buttonText: attributes.button_text || '',
				introTitle: attributes.introduction_heading || '',
				introText: attributes.introduction_text || '',
				title: attributes.title || '',
				items: attributes.items.map((item) => {
					return {
						date: this.parseDate(item.date || ''),
						description: item.description || '',
						imageAlt: item.image_alt || '',
						imageUrl: item.image_url || '',
						title: item.title || '',
						url: item.link || '',
					};
				}),
				link: {
					href: attributes.link?.href || '',
					rel: attributes.link?.rel || '',
					shouldOpenInNewTab: attributes.link?.open_in_new_tab || false,
					text: attributes.link?.link_text || '',
					title: attributes.link?.link_title || '',
				},
				tagTitle: attributes.tag_title || '',
			},
			layout: data.layout,
		};
	}

	private static parseCardsResponse(
		data: CardsResponseInterface,
	): CardsComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				cards: attributes.cards.map((card) => {
					return {
						attributes: {
							content: card.attributes.content,
							image: card.attributes.image
								? this.parseImage(
										card.attributes.image,
										card.attributes.image_alt,
									)
								: undefined,
							link: card.attributes.link
								? this.parseLink(card.attributes.link)
								: undefined,
							title: card.attributes.title,
						},
						layout: card.layout,
					};
				}),
				content: attributes.content,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				useShadow: attributes.use_shadow || false,
				isHorizontal: attributes.horizontal || false,
			},
			layout: data.layout,
		};
	}

	private static parseCollectionResponse(
		data: CollectionResponseInterface,
	): CollectionComponentInterface {
		const { attributes } = data;

		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				content: null,
				heading: attributes.heading,
				headingType: attributes.heading_type,
				subheading: attributes.subheading,
				items: attributes.items.map((item) => ({
					attributes: {
						layoutSettings: {
							anchor: item.attributes.layout_settings.anchor || '',
							width: this.parseLayoutWidths(
								item.attributes.layout_settings.width,
							),
						},
						image: this.parseImage(
							item.attributes.image,
							item.attributes.image_alt,
						),
					},
					layout: item.layout,
				})),
			},
			layout: data.layout,
		};
	}

	private static parseContactResponse(
		data: ContactResponseInterface,
	): ContactComponentInterface {
		const { attributes } = data;

		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				image: this.parseImage(attributes.image, attributes.image_alt),
				content: attributes.content,
				showOpeningHours: attributes.show_opening_hours,
			},
			layout: data.layout,
		};
	}

	private static parseContactPersonResponse(
		data: ContactPersonResponseInterface,
	): ContactPersonComponentInterface {
		const { attributes } = data;

		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				content: attributes.content,
				coloredBackground: attributes.colored_background,
				heading: attributes.heading,
				headingType: attributes.heading_type,
				subheading: attributes.subheading,
				contactPerson: {
					name: attributes.contact_person.translations.title,
					function: attributes.contact_person.translations.function,
					email: attributes.contact_person.email,
					phone: attributes.contact_person.phone,
					linkedin: attributes.contact_person.linkedin,
					twitter: attributes.contact_person.twitter,
					image: {
						alt: attributes.contact_person.translations.image_alt,
						height: attributes.contact_person.image.height,
						name: attributes.contact_person.image.file_name,
						src: attributes.contact_person.image.url,
						width: attributes.contact_person.image.width,
					},
				},
			},
			layout: data.layout,
		};
	}

	private static parseCoverResponse(
		data: CoverResponseInterface,
	): CoverComponentInterface {
		const { attributes } = data;

		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				content: attributes.content,
				imageAlt: attributes.image_alt,
				image: attributes.image ? this.parseImage(attributes.image) : undefined,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
			},
			layout: data.layout,
		};
	}

	private static parseCtaButtonResponse(
		data: CtaButtonResponseInterface,
	): CtaButtonComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				backToTop: attributes.back_to_top,
				buttonClass: attributes.button_class,
				buttonPosition: attributes.button_position,
				hasDarkBackground: attributes.colored_background,
				icon: attributes.icon,
				label: attributes.label ?? '',
				labelPosition: attributes.label_position ?? '',
				link: attributes.link ? this.parseLink(attributes.link) : undefined,
				title: attributes.title,
			},
			layout: data.layout,
		};
	}

	private static parseDestinationInfo(
		data: DestinationInfoResponseInterface,
	): DestinationInfoComponentInterface {
		const { attributes } = data;
		return {
			runningHead: attributes.heading || '',
			anchor: attributes.layout_settings.anchor || '',
			content: attributes.content || '',
			layout: data.layout,
			image: attributes.image
				? this.parseImage(attributes.image, attributes.image_alt)
				: undefined,
			items: attributes.items.map((item) => {
				const info = item.attributes;
				return {
					title: info.title,
					content: info.content,
				};
			}),
			titleQuickInfo: attributes.title_quickinfo || '',
		};
	}

	private static parseDoubleColumnResponse(
		data: DoubleColumnResponseInterface,
	): DoubleColumnComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettingsLeft: {
					anchor: attributes.layout_settings_left.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings_left.width),
				},
				layoutSettingsRight: {
					anchor: attributes.layout_settings_right.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings_right.width),
				},
				left: attributes.left.map((property) =>
					this.parseDoubleColumnProperty(property),
				),
				right: attributes.right.map((property) =>
					this.parseDoubleColumnProperty(property),
				),
			},
			layout: data.layout,
		};
	}

	private static parseDoubleColumnProperty(
		data: DoubleColumnResponseProperty,
	): DoubleColumnComponentProperty {
		switch (data.layout) {
			case ContentLayoutTypes.CARDS:
				return this.parseCardsResponse(data);
			case ContentLayoutTypes.CTA_BUTTON:
				return this.parseCtaButtonResponse(data);
			case ContentLayoutTypes.FAQ:
				return this.parseFaqResponse(data);
			case ContentLayoutTypes.FORM:
				return this.parseFormResponse(data);
			case ContentLayoutTypes.IMAGE:
				return this.parseImageComponentResponse(data);
			case ContentLayoutTypes.NAVIGATION:
				return this.parseNavigationResponse(data);
			case ContentLayoutTypes.PAGE_LINKS:
				return this.parsePageLinksResponse(data);
			case ContentLayoutTypes.TEXT:
				return this.parseTextResponse(data);
			case ContentLayoutTypes.VISUAL_USPS:
				return this.parseVisualUspsResponse(data);
		}
	}

	private static parseFaqResponse(
		data: FaqComponentResponseInterface,
	): FaqComponentInterface {
		const { attributes } = data;
		return {
			anchor: attributes.layout_settings.anchor,
			content: attributes.content || '',
			heading: attributes.heading || '',
			keepQuestionsVisible: attributes.multiple_fold,
			layout: data.layout,
			questions: attributes.questions.map((question) => {
				const faq = question.attributes;
				return {
					title: faq.question,
					text: faq.answer,
				};
			}),
			showText: attributes.show_text,
			textPosition:
				(attributes.text_position as FaqTextPosition) || FaqTextPosition.TOP,
		};
	}

	private static parseFormResponse(
		data: FormResponseInterface,
	): FormComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				formId: attributes.form as FormType,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
			},
			layout: data.layout,
		};
	}

	private static parseHeroReponse(
		data: HeroResponseInterface,
	): HeroComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				backgroundImage: attributes.background_image
					? this.parseImage(
							attributes.background_image,
							attributes.background_image_alt,
						)
					: undefined,
				heading: attributes.heading || '',
				link: attributes.link ? this.parseLink(attributes.link) : undefined,
				promotion: attributes.promotion
					? {
							title: attributes.promotion.title || '',
							subtitle: attributes.promotion.subtitle || '',
							description: attributes.promotion.description || '',
						}
					: undefined,
				subheading: attributes.subheading || '',
				usps: data.attributes.usps
					? data.attributes.usps.map((usp) => {
							return {
								title: usp.title,
								subtitle: usp.subtitle || '',
								sortOrder: usp.sort_order,
							};
						})
					: [],
				showReviews: attributes.show_reviews,
				reviewsPosition: attributes.reviews_position,
				headingType: attributes.heading_type,
			},
			layout: data.layout,
		};
	}

	private static parseHtmlBlockResponse(
		data: HtmlBlocksResponseInterface,
	): HtmlBlockComponentInterface {
		return {
			attributes: data.attributes,
			layout: data.layout,
		};
	}

	private static parseImageComponentResponse(
		data: ImageComponentResponseInterface,
	): ImageComponentInterface {
		const attributes = data.attributes;
		return {
			attributes: {
				alt: attributes.image_alt || attributes.image.file_name,
				id: attributes.layout_settings.anchor || '',
				src: attributes.image.url,
				height: attributes.image.height,
				width: attributes.image.width,
				widthClass: this.parseLayoutWidths(attributes.layout_settings.width),
			},
			layout: data.layout,
		};
	}

	private static parseInfoItemsResponse(
		data: InfoItemsResponseInterface,
	): InfoItemsComponentInterface {
		return {
			attributes: {
				layoutSettings: {
					anchor: data.attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(data.attributes.layout_settings.width),
				},
				heading: data.attributes.heading || '',
				headingType: data.attributes.heading_type,
				subheading: data.attributes.subheading || '',
				content: data.attributes.content || '',
				items: data.attributes.items.map((item) => {
					const { attributes } = item;
					return {
						attributes: {
							icon: attributes.icon,
							content: attributes.content,
							title: attributes.title,
							link:
								attributes.add_href && attributes.link
									? this.parseLink(attributes.link)
									: undefined,
						},
						layout: item.layout,
					};
				}),
			},
			layout: data.layout,
		};
	}

	private static parseMapsResponse(
		data: MapsResponseInterface,
	): MapsComponentInterface {
		return {
			attributes: {
				layoutSettings: {
					anchor: data.attributes.layout_settings?.anchor || '',
					width: this.parseLayoutWidths(
						(data.attributes.layout_settings?.width as LayoutWidths) || null,
					),
				},
				content: data.attributes.content || '',
				title: data.attributes.title || '',
				pathPrefix: data.attributes.path_prefix || '',
				radius: data.attributes.radius || undefined,
			},
			layout: data.layout,
		};
	}

	private static parseNavigationResponse(
		data: NavigationResponseInterface,
	): NavigationComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				header: attributes.header || '',
				headingType: attributes.heading_type,
				links: attributes.links.map((linkComponent) => {
					return {
						attributes: {
							link: linkComponent.attributes.link
								? this.parseLink(linkComponent.attributes.link)
								: undefined,
						},
						layout: linkComponent.layout,
					};
				}),
			},
			layout: data.layout,
		};
	}

	private static parseNewsItemOverviewResponse(
		data: NewsItemOverviewResponseInterface,
	): NewsItemOverviewComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				items: attributes.items.map((item) => {
					return {
						category: item.details.page_category,
						city: item.details.city || '',
						guid: item.guid,
						name: item.name,
						path: item.path,
						publishDate: this.parseDate(item.publish_date),
						slug: item.slug,
						title: item.title,
						url: item.url,
					};
				}),
				amount: attributes.amount,
				showMoreButton: attributes.show_more_button,
				showMoreButtonLabel: attributes.show_more_button_label,
			},
			layout: data.layout,
		};
	}

	private static parseNewsletterResponse(
		data: NewsletterResponseInterface,
	): NewsletterComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				buttonText: attributes.button_text,
				image: attributes.image
					? this.parseImage(attributes.image, attributes.image_alt)
					: undefined,
				privacyStatement: attributes.privacy_statement,
				subtitle: attributes.subtitle,
				title: attributes.title,
				buttonPosition: attributes.button_position,
				buttonClass: attributes.button_class,
				isShowingSocialMediaIcons: attributes.show_social_media_icons,
			},
			layout: data.layout,
		};
	}

	private static parsePageLinksResponse(
		data: PageLinksResponseInterface,
	): PageLinksComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				pages: attributes.pages.map((page) => {
					return {
						attributes: {
							page: {
								guid: page.attributes.page.guid || '',
								locale: {
									guid: page.attributes.page.locale.guid || '',
									name: page.attributes.page.locale.name || '',
									isoCode: page.attributes.page.locale.iso_code || '',
									languageUri: page.attributes.page.locale.language_uri || '',
								},
								title: page.attributes.page.title || '',
								name: page.attributes.page.name || '',
								slug: page.attributes.page.slug || '',
								path: page.attributes.page.path || '',
								url: page.attributes.page.url || '',
							},
							text: page.attributes.text,
						},
						layout: page.layout,
					};
				}),
			},
			layout: data.layout,
		};
	}

	private static parsePopularCountriesResponse(
		data: PopularCountriesResponseInterface,
	): PopularCountriesComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				content: attributes.content,
				hasDarkBackground: attributes.colored_background,
				items: attributes.items.map((country) => {
					return {
						image: this.parseImage(country.image, country.image_alt),
						name: country.name,
						slug: country.slug || '',
						subtitle: country.subtitle || '',
						urlType: country.url_type as PopularCountryUrlTypes,
						customUrl: country.custom_url || '',
						path: country.path || '',
					};
				}),
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				link: attributes.show_button
					? this.parseLink(attributes.link)
					: undefined,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				subheading: attributes.subheading || '',
			},
			layout: data.layout,
		};
	}

	private static parseReviewsResponse(
		data: ReviewsResponseInterface,
	): ReviewsComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				averageScores: {
					showAverageScores: attributes.average_scores.show_average_scores,
					platforms: attributes.average_scores.platforms.map((platform) => {
						return {
							name: platform.platform,
							profileUrl: platform.profile_url,
							reviews: platform.reviews,
							stars: platform.stars,
						};
					}),
				},
				hasDarkBackground: attributes.colored_background,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				layoutSettings: {
					anchor: attributes.layout_settings.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings.width),
				},
				reviews: attributes.reviews.map((review) => {
					return {
						createdAt: this.parseDate(review.date),
						platform: review.platform,
						reviewBy: review.review_by,
						stars: review.stars,
						text: review.text,
						title: review.title,
						url: review.url || '',
					};
				}),
				subheading: attributes.subheading || '',
			},
			layout: data.layout,
		};
	}

	private static parseSearchTravelAgenciesResponse(
		data: SearchTravelAgenciesResponseInterface,
	): SearchTravelAgenciesComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings?.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings?.width),
				},
				content: attributes.content,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				subheading: attributes.subheading || '',
			},
			layout: data.layout,
		};
	}

	private static parseSeparatorResponse(
		data: SeparatorResponseInterface,
	): SeparatorComponentInterface {
		return {
			layout: data.layout,
		};
	}

	private static parseSitemapResponse(
		data: SitemapResponseInterface,
	): SitemapComponentInterface {
		const { sitemap } = data.attributes;

		const recursiveSitemapParser = (item: SitemapItem): SitemapItem => {
			const parsedSitemapItem: SitemapItem = {
				path: item.path,
				slug: item.slug,
				title: item.title,
			};
			if (item.children && item.children.length) {
				parsedSitemapItem.children = item.children.map(recursiveSitemapParser);
			}
			return parsedSitemapItem;
		};

		return {
			attributes: {
				sitemap: sitemap.map(recursiveSitemapParser),
			},
			layout: data.layout,
		};
	}

	private static parseTextResponse(
		data: TextResponseInterface,
	): TextComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings?.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings?.width),
				},
				content: attributes.content,
				heading: attributes.heading ? attributes.heading : '',
				headingType: attributes.heading_type,
				subheading: attributes.subheading ? attributes.subheading : '',
			},
			layout: data.layout,
		};
	}

	private static parseTravelAgenciesResponse(
		data: TravelAgenciesResponseInterface,
	): TravelAgenciesComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				layoutSettings: {
					anchor: attributes.layout_settings?.anchor || '',
					width: this.parseLayoutWidths(attributes.layout_settings?.width),
				},
				content: attributes.content,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				items: attributes.items.map((item) => ({
					image: this.parseImage(item.image, item.image_alt),
					title: item.title,
				})),
				subheading: attributes.subheading || '',
			},
			layout: data.layout,
		};
	}

	private static parseVideoResponse(
		data: VideoResponseInterface,
	): VideoComponentInterface {
		const { attributes } = data;
		const video = attributes.video.data;

		return {
			attributes: {
				content: attributes.content,
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				video: {
					html: video.html,
					title: video.title,
					thumbnail: {
						src: video.thumbnail_url,
						srcWithPlayButton: video.thumbnail_url_with_play_button,
					},
				},
			},
			layout: data.layout,
		};
	}

	private static parseVisualUspsResponse(
		data: VisualUspsResponseInterface,
	): VisualUspsComponentInterface {
		const { attributes } = data;
		return {
			attributes: {
				image: this.parseImage(attributes.image, attributes.image_alt),
				heading: attributes.heading || '',
				headingType: attributes.heading_type,
				subheading: attributes.subheading || '',
				hasIcon: attributes.show_icon,
				usps: attributes.usps.map((usp) => {
					return {
						attributes: {
							text: usp.attributes.text,
						},
						layout: usp.layout,
					};
				}),
			},
			layout: data.layout,
		};
	}

	private static parseNotification(
		notification: PageNotificationCMSAPI | undefined,
	): PageNotificationInterface | undefined {
		if (!notification) {
			return undefined;
		}

		return {
			guid: notification.guid ?? '',
			title: notification.title ?? '',
			description: notification.description ?? '',
			type: this.parseNotificationType(notification.type),
		};
	}

	private static parseNotificationType(
		notificationType: string | undefined,
	): PageNotificationTypeInterface {
		const mapping: { [key: string]: PageNotificationTypeInterface } = {
			INFORMATION: 'info',
			WARNING: 'warning',
			ERROR: 'error',
			SUCCESS: 'success',
		};

		// Fallback to info
		return mapping[notificationType ?? 'info'];
	}

	private static parseImage(
		image: ImageResponseInterface,
		alt?: string | null,
	): ImageInterface {
		return {
			alt: alt || '',
			height: image.height,
			name: image.file_name,
			src: image.url,
			width: image.width,
		};
	}

	private static parseLayoutWidths(
		width: LayoutWidths | null,
	): LayoutWidthClasses {
		switch (width) {
			case '1-4':
				return 'w-1/4';
			case '1-3':
				return 'w-1/3';
			case '1-2':
				return 'w-1/2';
			case '2-3':
				return 'w-2/3';
			case '3-4':
				return 'w-3/4';
			default:
				return 'w-full';
		}
	}

	private static parseLink(link: LinkResponseInterface): LinkInterface {
		return {
			href: link.href || '',
			rel: link.rel || '',
			shouldOpenInNewTab: link.open_in_new_tab,
			text: link.link_text || '',
			title: link.link_title || '',
		};
	}

	private static parseDate(date: string): string {
		// remove timezone
		return date.split('+')[0];
	}
}
