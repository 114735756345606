import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	ProfileResponseAPI,
	ProfileResponseAllOfDataAPI,
	SourceAPI,
} from '@sunny-cars/util-api-interfaces';
import { Profile } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import { Observable, catchError, map, of } from 'rxjs';

export interface RegisterRequestBody {
	affiliateKey: number;
	/** Birthdate in YYYY-MM-DD format */
	birthdate: string;
	city: string;
	countryCode: string;
	emailAddress: string;
	firstName: string;
	gender: string;
	lastName: string;
	password: string;
	phoneNumber: string;
	postalCode: string;
	qualificationTitle: string;
	source: SourceAPI;
	street: string;
}

@Injectable({
	providedIn: 'root',
})
export class RegisterService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	register(body: RegisterRequestBody): Observable<Profile | undefined> {
		return this.http
			.post<ProfileResponseAPI>(`${this.endpoint}v1/account/profile`, body)
			.pipe(
				map((response: ProfileResponseAPI) =>
					this.parsePageResponse(response.data),
				),
				catchError((error) => {
					console.error('register', error);
					return of(undefined);
				}),
			);
	}

	private parsePageResponse(
		data: ProfileResponseAllOfDataAPI | undefined,
	): Profile | undefined {
		if (!data) {
			return undefined;
		}
		return {
			birthdate: data.birthdate || '',
			city: data.city || '',
			companyName: data.companyName || '',
			countryCode: data.countryCode || '',
			emailAddress: data.emailAddress || '',
			firstName: data.firstName || '',
			gender: data.gender || '',
			lastName: data.lastName || '',
			phoneNumber: data.phoneNumber || '',
			postalCode: data.postalCode || '',
			street: data.street || '',
			title: data.qualificationTitle || '',
		};
	}
}
