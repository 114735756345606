<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="{{ content.layoutSettings.width }}"
	[ngClass]="{
		'bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10': content.hasDarkBackground
	}"
>
	<div
		class="flex pb-6"
		[ngClass]="{
			'justify-start': content.buttonPosition === 'left',
			'justify-center': content.buttonPosition === 'center',
			'justify-end': content.buttonPosition === 'right'
		}"
	>
		<div
			class="flex flex-wrap gap-3"
			[ngClass]="{
				'flex-col':
					content.labelPosition === 'top' || content.labelPosition === 'bottom',
				'flex-col-reverse': content.labelPosition === 'bottom',
				'items-center':
					content.labelPosition === 'left' || content.labelPosition === 'right',
				'flex-row-reverse': content.labelPosition === 'right'
			}"
		>
			<p *ngIf="content.label" class="font-serif text-lg font-bold">
				{{ content.label }}
			</p>
			<button
				*ngIf="content.backToTop"
				type="button"
				class="btn group relative block w-fit font-semibold"
				[ngClass]="{
					'btn-primary': content.buttonClass === 'pink',
					'btn-secondary': content.buttonClass === 'green',
					'pr-9': content.icon && !content.icon.includes('car'),
					'pr-4': content.icon?.includes('car'),
					'pl-12': content.icon?.includes('car')
				}"
				(click)="scrollToTop()"
			>
				<ui-icon
					*ngIf="content.icon as icon"
					class="absolute top-1/2 -translate-y-1/2"
					[ngClass]="{
						'left-3 group-hover:animate-drive-away': icon.includes('car'),
						'right-3': !icon.includes('car')
					}"
					[name]="icon"
					inlined
					color="white"
					[sm]="icon.includes('car') ? undefined : ''"
					[classModifiers]="icon.includes('car') ? '-mt-1 w-7 h-7' : ''"
				></ui-icon>
				{{ content.title }}
			</button>
			<a
				*ngIf="content.link && !content.backToTop"
				class="btn group relative block w-fit font-semibold"
				[ngClass]="{
					'btn-primary': content.buttonClass === 'pink',
					'btn-secondary': content.buttonClass === 'green',
					'pr-9': content.icon && !content.icon.includes('car'),
					'pr-4': content.icon?.includes('car'),
					'pl-12': content.icon?.includes('car')
				}"
				[href]="content.link.href"
				[rel]="content.link.rel"
				[title]="content.link.title"
				[attr.target]="content.link.shouldOpenInNewTab ? '_blank' : null"
			>
				<ui-icon
					*ngIf="content.icon as icon"
					class="absolute top-1/2 -translate-y-1/2"
					[ngClass]="{
						'left-3 group-hover:animate-drive-away': icon.includes('car'),
						'right-3': !icon.includes('car')
					}"
					[name]="icon"
					inlined
					color="white"
					[sm]="icon.includes('car') ? undefined : ''"
					[classModifiers]="icon.includes('car') ? '-mt-1 w-7 h-7' : ''"
				></ui-icon>
				{{ content.title }}
			</a>
		</div>
	</div>
	<hr class="m-0 block border-0 border-t border-t-ui-moderate" />
</section>
