import { Pipe, PipeTransform } from '@angular/core';
import { LocaleHelper } from '../../helpers/locale/locale.helper';
import {
	DateTimeFormats,
	DateTimeHelper,
} from './../../helpers/date-time/date-time.helper';

/**
 * Convert ISO date string to readable date string
 * @implements {PipeTransform}
 */
@Pipe({
	name: 'datetime',
	// @TODO: Find way to make pipe pure but update on locale change
	pure: false,
	standalone: true,
})
export class DateTimePipe implements PipeTransform {
	constructor(private readonly localeHelper: LocaleHelper) {}

	/* Convert ISO date string to readable date string */
	transform(value: string, format?: DateTimeFormats | string): string {
		return DateTimeHelper.convertISOToFormat(
			value,
			format,
			this.localeHelper.language,
		);
	}
}
