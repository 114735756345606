import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { AffiliateService } from './affiliate.service';

/**
 * Affiliate provider module
 */
@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [AffiliateService],
})
export class AffiliateProviderModule {}
