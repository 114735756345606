import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	DateTimeFormats,
	LocaleHelper,
	OpeningHours,
	Sizes,
} from '@sunny-cars/util-global';
import { ImageInterface } from '@sunny-cars/util-global/lib/interfaces/content/image-type.interface';
import { DateTimePipe } from '@sunny-cars/util-global/lib/pipes/date-time/date-time.pipe';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-contact',
	templateUrl: './contact.component.html',
	standalone: true,
	imports: [CommonModule, ImageComponent, TranslateModule, DateTimePipe],
})
export class ContactComponent {
	@Input() content = '';
	@Input() image?: ImageInterface;
	@Input() showOpeningHours?: boolean;
	@Input() openingHours?: Array<OpeningHours> | null;

	openingHoursText = '';
	sizes = Sizes;
	dateTimeFormats = DateTimeFormats;

	constructor(readonly localeHelper: LocaleHelper) {}
}
