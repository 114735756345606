import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { PageHelper } from '@sunny-cars/provider-cms-service/lib/page/page.helper';
import { CardsComponentInterface } from '@sunny-cars/util-global';
import { B2BPortalItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/b2b-portal-items.interface';
import { DoubleColumnComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/double-column.interface';
import { FormTypes } from '@sunny-cars/util-global/lib/interfaces/content/form.interface';
import { ContentLayoutTypes } from '@sunny-cars/util-global/lib/interfaces/page-content-layout-types.interface';
import { RoundNumberPipe } from '@sunny-cars/util-global/lib/pipes/round-number/round-number.pipe';
import { AccordionComponent } from '../../accordion/accordion.component';
import { B2BCardsComponent } from '../../b2b-cards/b2b-cards.component';
import { CardsComponent } from '../../cards/cards.component';
import { CtaButtonComponent } from '../../cta-button/cta-button.component';
import { ImageComponent } from '../../image/image.component';
import { PageLinksComponent } from '../../page-links/page-links.component';
import { RegisterComponent } from '../../register/register.component';
import { TextBlockComponent } from '../../text-block/text-block.component';
import { VisualUspsComponent } from '../../visual-usps/visual-usps.component';

@Component({
	selector: 'ui-double-column',
	templateUrl: './double-column.component.html',
	standalone: true,
	imports: [
		CommonModule,
		B2BCardsComponent,
		CardsComponent,
		CtaButtonComponent,
		AccordionComponent,
		ImageComponent,
		PageLinksComponent,
		TextBlockComponent,
		VisualUspsComponent,
		RegisterComponent,
		RoundNumberPipe,
	],
})
export class DoubleColumnComponent {
	@Input() content?: DoubleColumnComponentInterface;
	@Input() isFullWidth = false;
	@Input() isTwoThirdColumn = false;
	@Input() isB2B = false;

	layoutTypes = ContentLayoutTypes;
	formTypes = FormTypes;

	convertCardsToB2bCards(
		cards: CardsComponentInterface,
	): B2BPortalItemsComponentInterface {
		return PageHelper.convertCardsToB2bCards(cards);
	}
}
