import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { TravelAgenciesResponseCMSAPI } from '@sunny-cars/util-api-interfaces';
import { Menu } from '@sunny-cars/util-global';
import { SearchTravelAgencyComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content';
import { Observable, catchError, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CMS_SETTINGS } from '../settings.tokens';
import { SiteMenuResponse } from '../site/interfaces/response/site-response.interface';
import { SiteHelper } from '../site/site.helper';
import { PageEntityInterface } from './interfaces';
import { PageResponseInterface } from './interfaces/page-response.interface';
import { PageHelper } from './page.helper';

@Injectable({
	providedIn: 'root',
})
export class PageService {
	constructor(
		private readonly http: HttpClient,
		@Inject('cmsApiBaseUrl')
		private readonly endpoint: string,
		@Inject(CMS_SETTINGS)
		private readonly cmsSettings: {
			siteDomain: string;
		},
	) {}

	getPageByPath(
		path: string,
		previewKey?: string,
	): Observable<PageEntityInterface | undefined> {
		let headers: HttpHeaders = new HttpHeaders({
			sitedomain: this.cmsSettings.siteDomain,
		});

		if (previewKey) {
			headers = headers.append('previewKey', previewKey);
		}

		path = path.toLowerCase();
		path = path.replace(/\/(\?)|\/(#)|(\/$)/, '$1$2');
		if (path && !path.startsWith('/') && !path.startsWith('?')) {
			path = `/${path}`;
		}

		return this.http
			.get<PageResponseInterface>(`${this.endpoint}v1/page${path}`, {
				headers,
			})
			.pipe(
				map((page: PageResponseInterface) => {
					return PageHelper.parsePageResponse(page.data);
				}),
			);
	}

	getMultilevelMenu(baseUrl?: string): Observable<Menu | undefined> {
		const headers: HttpHeaders = new HttpHeaders({
			sitedomain: this.cmsSettings.siteDomain,
		});

		return this.http
			.get<{ data: SiteMenuResponse }>(`${this.endpoint}v1/multilevelmenu`, {
				headers,
			})
			.pipe(
				map((site: { data: SiteMenuResponse }) => {
					return SiteHelper.parseMenuResponse(site.data, baseUrl);
				}),
				catchError(() => of(undefined)),
			);
	}

	searchTravelAgencies(
		query: string,
	): Observable<SearchTravelAgencyComponentInterface[]> {
		const headers: HttpHeaders = new HttpHeaders({
			sitedomain: this.cmsSettings.siteDomain,
		});

		return this.http
			.get<TravelAgenciesResponseCMSAPI>(`${this.endpoint}v1/travelagencies`, {
				headers,
				params: {
					searchkey: query,
				},
			})
			.pipe(map(this.mapTravelAgencies));
	}

	private mapTravelAgencies(
		response: TravelAgenciesResponseCMSAPI,
	): SearchTravelAgencyComponentInterface[] {
		if (response.data) {
			return response.data.map((agency) => ({
				city: agency.city || '',
				countryCode: agency.countrycode || '',
				name: agency.name || '',
				phoneNumber: agency.phonenumber || '',
				postalCode: agency.zipcode || '',
				street: agency.street || '',
				url: agency.url || '',
			}));
		}
		return [];
	}
}
