import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocaleHelper } from '../../helpers/locale/locale.helper';
import { EnvironmentService } from '../../services/environment/environment.service';

/**
 * AcceptLanguageInterceptor adds Accept-Language header to all outgoing requests
 */
@Injectable()
export class AcceptLanguageInterceptor implements HttpInterceptor {
	constructor(
		private readonly localeHelper: LocaleHelper,
		private readonly environmentService: EnvironmentService,
	) {}

	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		let language = '';
		const cmsBaseUrl =
			this.environmentService.getDomainConfig().api.cms.baseUrl;
		const countryCode = this.environmentService.getDomainConfig().countryCode;
		if (cmsBaseUrl && request.url.startsWith(cmsBaseUrl)) {
			if (this.localeHelper.language === 'en') {
				language = this.localeHelper.language;
			} else {
				language = `${this.localeHelper.language.toLowerCase()}-${countryCode.toUpperCase()}`;
			}
		} else {
			language = this.localeHelper.locale;
		}

		const modifiedRequest: HttpRequest<T> = request.clone({
			headers: request.headers.set('Accept-Language', language),
		});
		return next.handle(modifiedRequest);
	}
}
