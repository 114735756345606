<div class="relative flex p-0.5">
	<input
		data-test-class="password-input"
		class="peer z-10 grow overflow-hidden text-ellipsis rounded-medium bg-transparent p-1 pl-4 outline-none"
		[ngClass]="{
			'opacity-disabled': isDisabled
		}"
		#passwordInput
		autocomplete="current-password"
		spellcheck="false"
		autocorrect="off"
		[attr.aria-label]="controlName"
		[name]="controlName"
		[id]="controlId"
		[type]="isVisible ? 'text' : 'password'"
		[disabled]="isDisabled"
		[(ngModel)]="value"
		(ngModelChange)="handleChange()"
	/>
	<span
		class="z-10 h-auto w-6 bg-icon-exclamation-circle-solid-error bg-icon bg-center bg-no-repeat bg-origin-content p-1"
		*ngIf="isInvalid"
	></span>
	<span
		class="z-10 h-auto w-6 bg-icon-check-circle-solid-success bg-icon bg-center bg-no-repeat bg-origin-content p-1"
		*ngIf="isValid"
	></span>
	<button
		class="z-10 m-1 grow-0 rounded bg-ui-moderate px-2 py-1"
		[ngClass]="{
			'opacity-disabled': isDisabled
		}"
		type="button"
		[disabled]="isDisabled"
		(click)="toggleVisibility()"
	>
		<span *ngIf="isVisible">{{ 'global.hide-action' | translate }}</span>
		<span *ngIf="!isVisible">{{ 'global.show-action' | translate }}</span>
	</button>
	<div
		class="input pointer-events-none absolute inset-0 peer-focus:border-focus peer-focus:shadow-input-focus peer-focus:outline peer-focus:outline-1 peer-focus:outline-focus-moderate"
		[ngClass]="{
			'border-error-high outline outline-1 outline-error-moderate peer-focus:border-error-high peer-focus:shadow-input-error peer-focus:outline-error-moderate':
				isInvalid,
			'border-ui-moderate shadow-none outline-none outline-transparent':
				isDisabled,
			'!border-error-moderate': isDisabled && isInvalid
		}"
	></div>
</div>
