import { createAction, props } from '@ngrx/store';
import { SessionType } from '@sunny-cars/util-global';
import { AuthenticateRequestParams } from './session.interface';

/**
 * Init action
 */
export const init = createAction('[Session] Init');

/**
 * Login
 */
export const loadSession = createAction(
	'[Session] Load Session',
	props<{
		body: AuthenticateRequestParams;
	}>(),
);

/**
 * Login success
 */
export const loadSessionSuccess = createAction(
	'[Session] Load Session Success',
	props<{
		sessionID?: string;
		tokenType?: SessionType;
		expiresAt?: string;
		id?: string;
		username?: string;
	}>(),
);

/**
 * Login failure
 */
export const loadSessionFailure = createAction(
	'[Session] Load Session Failure',
	props<{ error: string }>(),
);

/**
 * Clear error
 */
export const clearError = createAction('[Session] Clear Error');

/**
 * Logout
 */
export const logout = createAction(
	'[Session] Logout Session',
	props<{ reason: string }>(),
);
