<div class="flex flex-col gap-2">
	<div>
		<meter
			class="block h-1 w-full appearance-none rounded-full bg-ui-moderate"
			min="0"
			max="5"
			low="3"
			high="3"
			optimum="5"
			[value]="strength"
			[ngClass]="{
				'[&::-moz-meter-bar]:bg-transparent': strength === 0,
				'[&::-moz-meter-bar]:bg-error-critical': strength === 1,
				'[&::-moz-meter-bar]:bg-error-high': strength === 2,
				'[&::-moz-meter-bar]:bg-warning-high': strength === 3,
				'[&::-moz-meter-bar]:bg-highlight-high': strength === 4,
				'[&::-moz-meter-bar]:bg-success-high': strength === 5
			}"
		>
			<div
				class="h-1 rounded-full transition-[width,background-color] duration-1000 ease-out"
				[ngClass]="{
					'w-0 bg-transparent': strength === 0,
					'w-1/5 bg-error-critical': strength === 1,
					'w-2/5 bg-error-high': strength === 2,
					'w-3/5 bg-warning-high': strength === 3,
					'w-4/5 bg-highlight-high': strength === 4,
					'w-full bg-success-high': strength === 5
				}"
			></div>
		</meter>
	</div>
	<p class="text-base">
		<span class="font-bold" *ngIf="!isValid">
			{{
				'components.password-strength-indicator.min-length-feedback-text'
					| translate
			}}
		</span>
		<span class="font-bold" *ngIf="isValid" [ngSwitch]="strength">
			<ng-container *ngSwitchCase="1">{{
				'components.password-strength-indicator.strength.1-unsafe' | translate
			}}</ng-container>
			<ng-container *ngSwitchCase="2">{{
				'components.password-strength-indicator.strength.2-weak' | translate
			}}</ng-container>
			<ng-container *ngSwitchCase="3">{{
				'components.password-strength-indicator.strength.3-mediocre' | translate
			}}</ng-container>
			<ng-container *ngSwitchCase="4">{{
				'components.password-strength-indicator.strength.4-good' | translate
			}}</ng-container>
			<ng-container *ngSwitchCase="5">{{
				'components.password-strength-indicator.strength.5-strong' | translate
			}}</ng-container>
		</span>
		{{ 'components.password-strength-indicator.helper-text' | translate }}
	</p>
</div>
