import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PickerComponent as UiPickerComponent } from '@sunny-cars/ui/lib/picker/picker.component';

export interface PickerEntry {
	modifiedLabel?: string;
	label$: Observable<string>;
	label: string;
	value: string | number;
	[key: string]: unknown;
}

@Component({
	selector: 'formly-field-picker',
	templateUrl: 'picker.component.html',
	standalone: true,
	imports: [UiPickerComponent, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class PickerComponent extends FieldType<FieldTypeConfig> {
	constructor(private readonly translate: TranslateService) {
		super();
	}

	getEntries() {
		if (this.props['translate']) {
			this.props['entries'].forEach((entry: PickerEntry) => {
				entry.label$ = this.translate.stream(entry.label);
			});
		}

		return this.props['entries'];
	}
}
