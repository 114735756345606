import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
	FormControl,
	FormGroup,
	FormGroupDirective,
	FormsModule,
	ReactiveFormsModule,
	Validators,
} from '@angular/forms';
import {
	FormlyFieldConfig,
	FormlyFormOptions,
	FormlyModule,
} from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	NewsletterService,
	SubscribeToNewsletterParams,
} from '@sunny-cars/provider-account-service';
import {
	ImageInterface,
	Sizes,
	SocialMediaInterface,
} from '@sunny-cars/util-global';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import {
	Observable,
	catchError,
	filter,
	fromEvent,
	map,
	of,
	switchMap,
	tap,
} from 'rxjs';
import { DialogComponent, DialogTypes } from '../dialog/dialog.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { ImageComponent } from '../image/image.component';
import { SocialMediaComponent } from '../social-media/social-media.component';
import { form } from './form';

@Component({
	selector: 'ui-newsletter',
	templateUrl: './newsletter.component.html',
	standalone: true,
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		ImageComponent,
		HeadingContentBlockComponent,
		DialogComponent,
		FormlyModule,
		SocialMediaComponent,
		TranslateModule,
	],
})
export class NewsletterComponent implements OnInit {
	@ViewChild('subscribeForm', { static: true, read: ElementRef })
	subscribeForm!: ElementRef<HTMLFormElement>;

	@Input() title?: string;
	@Input() subtitle?: string;
	@Input() image?: ImageInterface | null; // TODO for accessibility an alt should be given along the image src
	@Input() buttonText?: string;
	@Input() buttonClass: 'primary' | 'secondary' = 'primary';
	@Input() buttonPosition: 'right' | 'bottom' = 'right';
	@Input() isShowingSocialMediaIcons = false;
	@Input() privacyStatement = '';
	@Input() socials?: SocialMediaInterface;
	readonly formGroup = new FormGroup({
		email: new FormControl('', Validators.required),
	});
	readonly model = { email: '' };
	readonly dialogTypes = DialogTypes;
	readonly headingLevels = HeadingLevels;
	subscribed$?: Observable<string>;
	isFormVisible = true;
	fields: FormlyFieldConfig[] = form;
	options: FormlyFormOptions = {};
	sizes = Sizes;
	formSubmitted = false;

	constructor(private readonly newsletterService: NewsletterService) {}

	ngOnInit(): void {
		this.subscribed$ = this.setupSubscribeResult();
	}

	/**
	 * succesfull form submit will subscribe to the newsletter andnpm  return the mapped response
	 */
	private setupSubscribeResult(): Observable<string> {
		return fromEvent(this.subscribeForm.nativeElement, 'submit').pipe(
			tap(() => {
				this.formSubmitted = true;
			}),
			filter(() => this.formGroup.valid),
			switchMap(() => this.newsletterService.getNewsletterSettings()),
			switchMap((settings) =>
				this.newsletterService.subscribeToNewsletter(
					this.createSubscribeNewsletterRequestParams(settings.data.version),
				),
			),
			map((success: boolean) => {
				if (success) {
					this.isFormVisible = false;
					return 'success';
				}

				return 'error';
			}),
			catchError(() => {
				return of('error');
			}),
		);
	}

	resetForm(formDirective: FormGroupDirective) {
		this.isFormVisible = true;
		this.formSubmitted = false;
		this.model.email = '';
		formDirective.resetForm();
	}

	private createSubscribeNewsletterRequestParams(
		version: string,
	): SubscribeToNewsletterParams {
		return {
			email: this.model.email,
			partner: false,
			scriptname: 'script_subscribe',
			version,
		};
	}
}
