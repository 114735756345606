import { ErrorMessageResponse } from '../../interfaces/error/error.interface';

export class ErrorHelper {
	static getSpecificError(
		errors: ErrorMessageResponse[] | string[] | undefined | null,
		errorTypeCode: ErrorTypeCode,
	): ErrorMessageResponse | undefined {
		if (!errors) {
			return undefined;
		}

		if (!isErorMessageResponseArray(errors)) {
			return undefined;
		}

		return errors
			.slice()
			.reverse()
			.find((error: ErrorMessageResponse) => {
				return error.code === errorTypeCode;
			});
	}
}

function isErorMessageResponseArray(
	errorCollection: string[] | ErrorMessageResponse[],
): errorCollection is ErrorMessageResponse[] {
	return (errorCollection as ErrorMessageResponse[]).every(
		(error) => error.code !== undefined,
	);
}

export type ErrorTypeCode =
	| ErrorTypes.ERROR_FILEUPLOAD
	| ErrorTypes.ERROR_FORBIDDEN
	| ErrorTypes.ERROR_GATEWAY
	| ErrorTypes.ERROR_NO_TOKEN
	| ErrorTypes.ERROR_PROMOTION_CODE_NOT_APPLICABLE
	| ErrorTypes.ERROR_RATE_NOT_FOUND
	| ErrorTypes.ERROR_RECAPTCHA
	| ErrorTypes.ERROR_REQUEST_FAILED
	| ErrorTypes.ERROR_UNAUTHORIZED
	| ErrorTypes.ERROR_UNDER_AGE;

export enum ErrorTypes {
	ERROR_FILEUPLOAD = 'ERROR_FILEUPLOAD',
	ERROR_FORBIDDEN = 'ERROR_FORBIDDEN',
	ERROR_GATEWAY = 'ERROR_GATEWAY',
	ERROR_NO_TOKEN = 'ERROR_NO_TOKEN',
	ERROR_PROMOTION_CODE_NOT_APPLICABLE = 'ERROR_PROMOTION_CODE_NOT_APPLICABLE',
	ERROR_RATE_NOT_FOUND = 'ERROR_RATE_NOT_FOUND',
	ERROR_RECAPTCHA = 'ERROR_RECAPTCHA',
	ERROR_REQUEST_FAILED = 'ERROR_REQUEST_FAILED',
	ERROR_UNAUTHORIZED = 'ERROR_UNAUTHORIZED',
	ERROR_UNDER_AGE = 'ERROR_UNDER_AGE',
}
