import {
	HttpEvent,
	HttpHandler,
	HttpInterceptor,
	HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DomainHelper } from '../../helpers/domain/domain.helper';

/**
 * Interceptor that adds an x-site-domain header to the request
 */
@Injectable()
export class SiteDomainInterceptor implements HttpInterceptor {
	constructor(private readonly domainHelper: DomainHelper) {}

	intercept<T>(
		request: HttpRequest<T>,
		next: HttpHandler,
	): Observable<HttpEvent<T>> {
		const domain = this.domainHelper.siteDomain;
		const modifiedRequest: HttpRequest<T> = request.clone({
			headers: request.headers.set('x-site-domain', domain),
		});
		return next.handle(modifiedRequest);
	}
}
