import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AdditionalCostsCodes, OrderReceipt } from '@sunny-cars/util-global';
import { RECEIPT_FEATURE_KEY, State } from './receipt.reducer';

export const getReceiptState =
	createFeatureSelector<State>(RECEIPT_FEATURE_KEY);

export const getReceiptLoaded = createSelector(
	getReceiptState,
	(state: State) => state.isLoaded,
);

export const getReceiptIsLoading = createSelector(
	getReceiptState,
	(state: State) => state.isLoading,
);

export const getShowPriceChangeDialog = createSelector(
	getReceiptState,
	(state: State) => state.showPriceChangeDialog,
);

export const hasPriceChanged = createSelector(
	getReceiptState,
	(state: State) => state.hasPriceChanged,
);

export const getReceiptError = createSelector(
	getReceiptState,
	(state: State) => state.errors,
);

export const getToken = createSelector(
	getReceiptState,
	(state: State) => state.token,
);

export const getReceipt = createSelector(
	getReceiptState,
	(state: State) => state.receipt,
);

export const getFilterLocationId = createSelector(
	getReceiptState,
	(state: State) => state.locationId,
);

export const getPriceChangeDialog = createSelector(
	getShowPriceChangeDialog,
	getReceipt,
	(showDialog: boolean, receipt: OrderReceipt | undefined) => {
		return showDialog && receipt && receipt.rate.vehicle
			? {
					vehicle: receipt.rate.vehicle,
					originalPrice: receipt.originalPrice.value,
					price: receipt.rate.price.baseValue,
			  }
			: undefined;
	},
);

export const getPickupAddress = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.pickup?.delivery;
	},
);

export const getAvailableOptionPickupLocations = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.pickupLocations;
	},
);

export const getAvailableOptionDropoffLocations = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.dropoffLocations;
	},
);

export const getCrossBorderRegions = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.crossBorderRegions;
	},
);

export const getCrossBorderRegionsAdditionalCosts = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.rate?.price.additionalCosts.find(
			(additionalCost) =>
				additionalCost.type === AdditionalCostsCodes.CROSS_BORDER,
		);
	},
);

export const getAgeRangeAdditionalCost = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.rate?.price.additionalCosts.find(
			(additionalCost) =>
				additionalCost.type === AdditionalCostsCodes.UNDER_AGE,
		);
	},
);

export const getAvailableEquipment = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.equipments;
	},
);

export const getAvailableCrossBorderRegions = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.crossBorderRegions;
	},
);

export const getAvailableFlexservice = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.flexservice;
	},
);

export const getSelectedFlexservice = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.flexService;
	},
);

export const getAvailableDefaultAgeRange = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.availableOptions?.defaultAgeRange;
	},
);

export const getSiteCurrency = createSelector(
	getReceipt,
	(receipt: OrderReceipt | undefined) => {
		return receipt?.totals?.site.local.currencyCode;
	},
);
