import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID, Renderer2 } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class ExpertrecService {
	isLoaded = false;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) public platformId: string,
	) {}

	setExpertrecScript(renderer2: Renderer2, id: string): void {
		if (!this.isLoaded && isPlatformBrowser(this.platformId)) {
			const script = renderer2.createElement('script');
			script.text = `
				(function () {
					var id = '${id}';
					var ci_search = document.createElement('script');
					ci_search.type = 'text/javascript';
					ci_search.async = true;
					ci_search.src =
						'https://cse.expertrec.com/api/js/ci_common.js?id=' + id;
					var s = document.getElementsByTagName('script')[0];
					s.parentNode.insertBefore(ci_search, s);
				})();
			`;

			renderer2.appendChild(this._document.body, script);
			this.isLoaded = true;
		}
	}
}
