import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	Input,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { BaseControlComponent } from '../base.control';

@Component({
	selector: 'ui-password-input',
	templateUrl: './password-input.component.html',
	styleUrls: ['./password-input.component.scss'],
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PasswordInputComponent,
		},
	],
	standalone: true,
	imports: [CommonModule, FormsModule, TranslateModule],
})
export class PasswordInputComponent extends BaseControlComponent {
	@Input() isVisible = false;

	@ViewChild('passwordInput') passwordInputElement?: ElementRef;

	value = '';

	toggleVisibility(): void {
		this.isVisible = !this.isVisible;
		this.passwordInputElement?.nativeElement.focus();
	}

	handleChange(): void {
		this.markAsTouched();
		this.onChange(this.value);
	}

	/**
	 * Handle value set by form
	 */
	override writeValue(value: string): void {
		this.value = value;
	}
}
