<ng-template #logo>
	<ui-logo
		[isCustomAffiliate]="isCustomAffiliate$ | async"
		[logo]="logo$ | async"
	></ui-logo>
</ng-template>

<header
	uiContrastText
	class="bg-header-footer text-white"
	data-test-class="header"
>
	<div class="flex flex-wrap items-center justify-between p-2 xs:px-8 xs:py-3">
		<div class="inline-flex items-center gap-1 md:gap-3">
			<div>
				<ng-container *ngIf="logoRedirectUrl$ | async as url; else logo">
					<a class="block" *ngIf="!url.startsWith('http')" [routerLink]="url">
						<ng-container *ngTemplateOutlet="logo"></ng-container>
					</a>
					<a class="block" *ngIf="url.startsWith('http')" [href]="url">
						<ng-container *ngTemplateOutlet="logo"></ng-container>
					</a>
				</ng-container>
			</div>
			<a
				[routerLink]="upActionRoute"
				[queryParams]="upActionParams"
				type="button"
				class="btn btn--arrow-shaped bg-header-button-transparent px-2 py-1 text-base font-bold md:px-4 md:py-2"
				*ngIf="showUpActionButton && upActionRoute"
			>
				<span>{{ upActionLabel || ('up-action.back-label' | translate) }}</span>
			</a>
		</div>
		<div class="flex items-center">
			<ng-content></ng-content>
			<!-- Customer service contact on desktop -->
			<ng-template #customerServiceContact>
				<div
					class="hidden pr-3 text-right lg:block"
					*ngIf="!omitButtons.includes('contact')"
				>
					<div>
						<a
							*ngIf="phoneNumber$ | async as phoneNumber"
							class="block underline"
							[href]="phoneHelper.phoneNumberToURL(phoneNumber)"
						>
							<strong>
								{{ phoneNumber }}
							</strong>
						</a>
						<div
							*ngIf="showAffiliateContactDetails$ | async"
							[ngClass]="{ 'text-sm': (phoneNumber$ | async) }"
						>
							<span
								*ngIf="(phoneNumberInfo$ | async) && (phoneNumber$ | async)"
							>
								{{ phoneNumberInfo$ | async }}
							</span>
							<a
								*ngIf="email$ | async as email"
								class="underline"
								[href]="'mailto:' + email"
							>
								{{ email }}
							</a>
						</div>
					</div>
					<div
						class="text-sm"
						*ngIf="(showAffiliateContactDetails$ | async) === false"
					>
						<span *ngIf="openingTimesLoaded$ | async"
							>{{ openingTimesLabel$ | async }}&nbsp;</span
						>
						<a
							class="underline"
							*ngIf="(isCustomAffiliate$ | async) === false"
							target="_blank"
							[href]="legacyContactUrl"
							>{{ 'components.header.contact-information' | translate }}</a
						>
					</div>
				</div>
			</ng-template>
			<ng-container
				*ngIf="
					!omitButtons.includes('contact') &&
					(!displayServiceCode || (showAffiliateContactDetails$ | async))
				"
			>
				<ng-container *ngTemplateOutlet="customerServiceContact"></ng-container>
			</ng-container>
			<!-- Service code popup on hover/mouseover (when service code is available) -->
			<ui-popover
				*ngIf="
					!omitButtons.includes('contact') &&
					displayServiceCode &&
					(showAffiliateContactDetails$ | async) === false
				"
				class="hidden lg:block"
				[padding]="32"
				[triggerType]="'MOUSEOVER'"
				[hasCloseButton]="true"
				(isPopOverVisible)="triggerContactPopoverVisible($event)"
			>
				<ng-container customTrigger>
					<ng-container
						*ngTemplateOutlet="customerServiceContact"
					></ng-container>
				</ng-container>
				<div class="sm:min-w-popover">
					<div class="flex">
						<img
							class="mr-5 h-12 w-12 flex-shrink-0 rounded-full object-cover"
							src="/assets/images/customer-service.jpg"
							[alt]="'components.header.contact-information' | translate"
							width="50"
							height="50"
						/>
						<div>
							<a
								*ngIf="
									(isCustomAffiliate$ | async) === false &&
									('components.header.customer-service' | translate)
								"
								class="mb-2 flex min-w-60 items-center justify-between rounded bg-ui-low p-2 hover:bg-selected-low"
								target="_blank"
								[href]="legacyContactUrl"
							>
								<p class="leading-none">
									<span class="inline-block w-full font-semibold">
										{{ 'components.header.customer-service' | translate }}
									</span>
									<span class="text-sm">
										{{ 'components.header.customer-service-text' | translate }}
									</span>
								</p>
								<ui-icon
									class="ml-2"
									inlined
									sm
									name="icon-chevron-right"
								></ui-icon>
							</a>
							<p
								class="rounded bg-ui-low p-3 leading-none"
								*ngIf="
									(hasNoRateLinkError$ | async) && (displayServiceCode$ | async)
								"
							>
								<ui-icon
									*ngIf="isLoadingRateLink$ | async; else serviceCodeDesktop"
									classModifiers="block"
									name="icon-loader"
									inlined
									color="ui-critical"
								></ui-icon>
								<ng-template #serviceCodeDesktop>
									<span class="inline-block w-full font-semibold">
										{{ 'components.header.service-code' | translate }}
									</span>
									<span class="text-sm" [innerHTML]="serviceCodeString"> </span>
								</ng-template>
							</p>
						</div>
					</div>
				</div>
			</ui-popover>
			<div class="btn-group">
				<!-- Rent a car -->
				<button
					class="btn group relative inline-flex h-8 items-center overflow-hidden rounded-none bg-header-button-transparent py-1 pl-8 pr-2 text-base font-semibold sm:pl-12 md:h-10 md:py-2 md:pr-3"
					type="button"
					uiContrastText
					*ngIf="!omitButtons.includes('home')"
					[routerLink]="['/']"
				>
					<ui-icon
						inlined
						name="icon-car"
						customSize
						classModifiers="w-5.5 h-5.5"
						class="absolute left-3 pb-0.5 group-hover:animate-drive-away sm:left-4 sm:px-0"
					></ui-icon>
					<span class="hidden md:inline-block">{{
						'global.reservation-label' | translate
					}}</span>
				</button>
				<!-- Language -->
				<ui-popover
					#languagePopover
					data-test-id="language-popover"
					[triggerLabel]="
						selectedLanguage ? $any(selectedLanguage['labelShort']) : null
					"
					[title]="'global.language-label' | translate"
					[padding]="32"
					wrapperStyleModifiers="flex lg:inline-block text-base {{
						omitButtons.includes('search') && omitButtons.includes('mysunny')
							? 'lg:rounded-button'
							: ''
					}}"
					styleModifiers="btn block bg-right-3-center bg-no-repeat px-2 py-1 text-base font-semibold sm:bg-icon-chevron-down-white rounded-none bg-header-button-transparent sm:bg-3 sm:pr-8 md:px-3 md:pr-8 md:py-2 lg:inline-block"
					*ngIf="
						!omitButtons.includes('language') && languageEntries.length > 1
					"
				>
					<ng-template
						#languageButtonTemplate
						let-language="language"
						let-first="first"
					>
						<div
							class="relative flex justify-between whitespace-nowrap p-2 hover:bg-selected-low"
							[ngClass]="{
								'font-bold': language.value === selectedLanguage?.value,
								'border-t border-ui-moderate': !first
							}"
						>
							<span
								class="pr-5"
								[ngClass]="{
									'pr-10': selectedLanguage?.value !== language.value
								}"
							>
								{{
									'languages.' + $any(language.value).toLowerCase() + '-label'
										| translate
								}}
							</span>
							<ui-icon
								inlined
								name="icon-check-regular"
								sm
								*ngIf="selectedLanguage?.value === language.value"
							></ui-icon>
						</div>
					</ng-template>
					<ng-container
						*ngFor="let language of languageEntries; let first = first"
					>
						<ng-container *ngIf="useLanguageRoutes">
							<a
								data-test-class="language-popover-row"
								class="block"
								(click)="selectLanguage(language)"
								[href]="language['path']"
							>
								<ng-container
									*ngTemplateOutlet="
										languageButtonTemplate;
										context: { language, first }
									"
								></ng-container>
							</a>
						</ng-container>
						<ng-container *ngIf="!useLanguageRoutes">
							<button
								data-test-class="language-popover-row"
								class="block w-full"
								(click)="selectLanguage(language)"
							>
								<ng-container
									*ngTemplateOutlet="
										languageButtonTemplate;
										context: { language, first }
									"
								></ng-container>
							</button>
						</ng-container>
					</ng-container>
				</ui-popover>
				<!-- Search -->
				<ui-popover
					[triggerLabel]="'global.search-action' | translate"
					wrapperStyleModifiers="flex lg:inline-block text-base"
					styleModifiers="btn block w-10 bg-header-button-transparent rounded-none lg:w-auto lg:pl-9 bg-icon-looking-glass-white bg-icon bg-left-3-center bg-no-repeat px-2 py-1 text-0 font-semibold md:px-3 md:py-2 lg:inline-block lg:text-base"
					[usePixelMaxWidth]="true"
					[title]="'global.search-action' | translate"
					*ngIf="!omitButtons.includes('search') && expertrecId"
				>
					<ci-search></ci-search>
				</ui-popover>
				<!-- Contact -->
				<ui-popover
					*ngIf="
						!omitButtons.includes('contact') &&
						((showAffiliateContactDetails$ | async) === false ||
							(phoneNumber$ | async) ||
							(email$ | async))
					"
					[triggerLabel]="'components.header.contact' | translate"
					[title]="'components.header.contact' | translate"
					(isPopOverVisible)="triggerContactPopoverVisible($event)"
					class="lg:hidden"
					wrapperStyleModifiers="flex xs:block lg:hidden"
					styleModifiers="block btn lg:inline-block
						w-auto px-2 py-1 bg-header-button-transparent rounded-none md:pl-3 md:py-2 pr-8
						font-semibold text-0 sm:text-base
						bg-icon bg-icon-phone-white bg-no-repeat sm:bg-icon-chevron-down-white bg-right-3-center sm:bg-3
					"
				>
					<div class="divide-y-2 divide-ui-low sm:min-w-popover">
						<a
							*ngIf="'components.header.customer-service' | translate"
							class="flex min-w-60 items-center justify-between p-3 hover:bg-selected-low"
							target="_blank"
							[href]="legacyContactUrl"
						>
							<p>{{ 'components.header.customer-service' | translate }}</p>
							<ui-icon inlined sm name="icon-chevron-right"></ui-icon>
						</a>
						<a
							*ngIf="phoneNumber$ | async as phoneNumber"
							class="flex min-w-60 items-center justify-between gap-4 p-3 hover:bg-selected-low"
							[href]="phoneHelper.phoneNumberToURL(phoneNumber)"
						>
							<div>
								<span class="inline-block underline">
									{{ phoneNumber }}
								</span>
								<div
									*ngIf="
										(showAffiliateContactDetails$ | async) &&
										(phoneNumberInfo$ | async) &&
										(phoneNumber$ | async)
									"
									class="-mt-0.5 text-sm text-text-tertiary"
								>
									{{ phoneNumberInfo$ | async }}
								</div>
								<div
									*ngIf="
										(showAffiliateContactDetails$ | async) === false &&
										(openingTimesLoaded$ | async)
									"
									class="-mt-0.5 text-sm text-text-tertiary"
								>
									{{ openingTimesLabel$ | async }}
								</div>
							</div>
							<ui-icon inlined name="icon-phone"></ui-icon>
						</a>
						<ng-container *ngIf="showAffiliateContactDetails$ | async">
							<a
								*ngIf="email$ | async as email"
								class="flex min-w-60 items-center justify-between gap-4 p-3 underline hover:bg-selected-low"
								[href]="'mailto:' + email"
							>
								{{ email }}
								<ui-icon inlined name="icon-document-envelop"></ui-icon>
							</a>
						</ng-container>
						<p
							class="p-3"
							*ngIf="
								displayServiceCode &&
								(showAffiliateContactDetails$ | async) === false &&
								(hasNoRateLinkError$ | async) &&
								(displayServiceCode$ | async)
							"
						>
							<ng-container *ngIf="(isCustomAffiliate$ | async) === false">
								{{ 'components.header.service-code' | translate }}:
							</ng-container>
							<ng-container *ngIf="isCustomAffiliate$ | async">
								{{ 'components.header.service-code-affiliate' | translate }}:
							</ng-container>
							<ui-icon
								*ngIf="isLoadingRateLink$ | async; else serviceCodeMobile"
								classModifiers="block"
								name="icon-loader"
								inlined
								color="ui-critical"
							></ui-icon>
							<ng-template #serviceCodeMobile>{{
								serviceCode$ | async
							}}</ng-template>

							<span
								class="-mt-0.5 inline-block w-full text-sm text-text-tertiary"
							>
								<ng-container *ngIf="(isCustomAffiliate$ | async) === false">
									{{ 'components.header.service-code-short' | translate }}
								</ng-container>
								<ng-container *ngIf="isCustomAffiliate$ | async">
									{{
										'components.header.service-code-short-affiliate' | translate
									}}
								</ng-container>
							</span>
						</p>
					</div>
				</ui-popover>
				<!-- MySunny -->
				@if (!omitButtons.includes('mysunny')) {
					@if (!(session$ | async)?.length) {
						<a
							uiContrastText
							class="btn inline-flex h-8 items-center gap-2 rounded-none bg-header-button-transparent px-2 py-1 text-base font-semibold md:h-10 md:px-3 md:py-2 lg:rounded-r-button"
							type="button"
							[href]="mySunnyUrl"
						>
							<div class="relative">
								<ui-icon name="icon-user" color="white"></ui-icon>
								<div class="absolute -right-1 -top-1">
									<ui-icon
										class="rounded-full bg-white p-px"
										xs
										name="icon-cross-circle-solid"
										color="error"
									></ui-icon>
								</div>
							</div>
							<span class="hidden md:inline-block">{{
								'components.header.mysunny.login-action' | translate
							}}</span>
						</a>
					} @else if (session$ | async) {
						<ui-popover
							[title]="'components.header.mysunny.title' | translate"
							[padding]="32"
							wrapperStyleModifiers="flex bg-header-button-transparent rounded-none lg:rounded-r-button [&>:nth-child(2)]:p-0"
							styleModifiers="btn px-2 py-1 md:px-3 md:py-2"
						>
							<div
								customTrigger
								class="inline-flex items-center gap-2 align-text-bottom text-base font-semibold sm:flex"
							>
								<div class="relative">
									<ui-icon name="icon-user"></ui-icon>
									<div class="absolute -right-1 -top-1">
										<ui-icon
											class="rounded-full bg-white p-px"
											xs
											name="icon-check-circle-solid"
											color="success"
										></ui-icon>
									</div>
								</div>
								<div class="hidden sm:block">
									<ng-container
										*ngIf="account$ | async as account; else noAccount"
									>
										{{ account.firstname }}
									</ng-container>
									<ng-template #noAccount>
										{{ 'components.header.mysunny.authenticated' | translate }}
									</ng-template>
								</div>
							</div>
							<div class="flex flex-col gap-6 p-4 sm:min-w-80 sm:p-6">
								<div
									class="flex items-center gap-3 rounded-medium max-sm:bg-ui-low max-sm:p-3"
								>
									<div
										class="relative flex h-14 w-14 shrink-0 grow-0 items-center justify-center rounded-full bg-secondary text-4xl font-bold text-white"
									>
										<ng-container *ngIf="account$ | async as account">
											{{ account.firstname[0] }}
										</ng-container>
										<div class="absolute -right-1 top-0">
											<ui-icon
												class="rounded-full bg-white p-0.5"
												name="icon-check-circle-solid"
												color="success"
											></ui-icon>
										</div>
									</div>
									<div class="font-bold">
										<p class="text-xl text-secondary max-sm:hidden">
											{{
												'components.header.mysunny.welcome-title' | translate
											}}
										</p>
										<p class="text-xl text-secondary sm:hidden">
											{{
												'components.header.mysunny.welcome-short-title'
													| translate
											}}
											<ng-container *ngIf="account$ | async as account">
												{{ account.firstname }}
											</ng-container>
										</p>
										<p class="text-base text-text-tertiary">
											{{
												'components.header.mysunny.welcome-intro' | translate
											}}
										</p>
									</div>
								</div>
								<div
									class="w-full divide-y divide-ui-moderate [&>*]:flex [&>*]:w-full [&>*]:cursor-pointer [&>*]:items-center [&>*]:gap-3 [&>*]:bg-icon-small [&>*]:bg-right-3-center [&>*]:bg-no-repeat [&>*]:px-4 [&>*]:py-2 [&>*]:text-left hover:[&>*]:bg-focus-low max-sm:[&>*]:bg-icon-chevron-right-light"
								>
									<a
										[href]="mySunnyUrl + ('ROUTES_MYSUNNY.profile' | translate)"
									>
										<ui-icon
											class="max-sm:hidden"
											name="icon-user"
											color="ui"
										></ui-icon>
										{{ 'components.header.mysunny.profile-action' | translate }}
									</a>
									<a [href]="mySunnyUrl">
										<ui-icon
											class="max-sm:hidden"
											name="icon-document"
											color="ui"
										></ui-icon>
										{{
											'components.header.mysunny.dashboard-action' | translate
										}}
									</a>
									<a
										[href]="
											mySunnyUrl + ('ROUTES_MYSUNNY.password-reset' | translate)
										"
									>
										<ui-icon
											class="max-sm:hidden"
											name="icon-key"
											color="ui"
										></ui-icon>
										{{
											'components.header.mysunny.password-reset-action'
												| translate
										}}
									</a>
									<button (click)="logout()">
										<ui-icon
											class="max-sm:hidden"
											name="icon-external-link"
											color="ui"
										></ui-icon>
										{{ 'components.header.mysunny.logout-action' | translate }}
									</button>
								</div>
							</div>
						</ui-popover>
					}
				}
				<!-- Mobile main menu  -->
				<ui-popover
					*ngIf="headerMenu?.length"
					[triggerLabel]="'global.menu' | translate"
					[title]="'global.menu' | translate"
					[padding]="32"
					[mobileViewUpToLg]="true"
					wrapperStyleModifiers="flex xs:block lg:hidden bg-header-button-transparent rounded-r-button"
					styleModifiers="block lg:inline-block
						w-auto pr-8 px-2 py-1 md:px-3 md:py-2 md:pr-8
						font-semibold text-0 sm:text-base
						bg-icon bg-icon-menu-white bg-no-repeat sm:bg-icon-chevron-down-white bg-right-3-center sm:bg-3
					"
					[isOpen]="isMobileMenuOpen"
					(isPopOverVisible)="isMobileMenuOpen = true"
					[hasTopMenuControls]="false"
				>
					<div class="divide-y-2 divide-ui-low sm:min-w-popover">
						<ui-main-menu
							[items]="headerMenu"
							[isMobile]="true"
							(closed)="mobileClosedHandler()"
						>
						</ui-main-menu>
					</div>
				</ui-popover>
			</div>
		</div>
	</div>
	<ng-container *ngIf="headerMenu?.length">
		<ui-main-menu
			[items]="headerMenu"
			[isMobile]="false"
			wrapperStyleModifiers="hidden lg:flex lg:flex-wrap"
		>
		</ui-main-menu>
	</ng-container>
</header>
