import { DOCUMENT } from '@angular/common';
import {
	HTTP_INTERCEPTORS,
	HttpClient,
	provideHttpClient,
	withInterceptorsFromDi,
} from '@angular/common/http';
import {
	APP_ID,
	APP_INITIALIZER,
	ApplicationConfig,
	ErrorHandler,
	Injectable,
	Optional,
	RendererFactory2,
	importProvidersFrom,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
	BrowserModule,
	provideClientHydration,
	withHttpTransferCacheOptions,
} from '@angular/platform-browser';
import {
	ActivatedRouteSnapshot,
	RouteReuseStrategy,
	UrlSerializer,
	provideRouter,
	withInMemoryScrolling,
} from '@angular/router';
import {
	ApmErrorHandler,
	ApmModule,
	ApmService,
} from '@elastic/apm-rum-angular';
import { EffectsModule } from '@ngrx/effects';
import { StoreRouterConnectingModule, routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AccountStoreModule } from '@sunny-cars/data-access-account';
import { AffiliateStoreModule } from '@sunny-cars/data-access-affiliate';
import { ContactDetailsStoreModule } from '@sunny-cars/data-access-contact-details';
import { SessionStoreModule } from '@sunny-cars/data-access-session';
import { SharedFormlyModule } from '@sunny-cars/formly/lib/formly/formly.module';
import { PageService } from '@sunny-cars/provider-cms-service/lib/page/page.service';
import { CMS_SETTINGS } from '@sunny-cars/provider-cms-service/lib/settings.tokens';
import {
	AcceptLanguageInterceptor,
	CacheLabelService,
} from '@sunny-cars/util-global';
import { TrailingSlashUrlSerializer } from '@sunny-cars/util-global/lib/helpers/trailing-slash-url-serializer/trailing-slash-url-serializer';
import { SiteDomainInterceptor } from '@sunny-cars/util-global/lib/interceptors/site-domain/site-domain.interceptor';
import {
	Domain,
	Environment,
	SupportedDomains,
} from '@sunny-cars/util-global/lib/services/environment/environment.interface';
import { EnvironmentToken } from '@sunny-cars/util-global/lib/services/environment/environment.token';
import { appRoutes } from './app.routes';
import { PageGuard } from './guards/page/page.guard';
import { ContactDetailsStateService } from './services/contact-details/contact-details-state.service';
import { LinkService } from './services/link/link.service';
import { MetadataService } from './services/metadata/metadata.service';
import { ScriptService } from './services/script/script.service';
import { SSRActiveDomainToken } from './ssr/server.token';

export function httpLoaderFactory(
	http: HttpClient,
	environmentData: Environment,
): TranslateHttpLoader {
	const cachedUnixTimestamp: number = CacheLabelService.getTimestamp();
	const domain =
		environmentData.domain.config[environmentData.domain.active].countryCode;
	return new TranslateHttpLoader(
		http,
		`./assets/ui/i18n/${domain.toLowerCase()}/`,
		`.json?t=${cachedUnixTimestamp}`,
	);
}

@Injectable()
export class CustomReuseStrategy extends RouteReuseStrategy {
	retrieve(): null {
		return null;
	}

	shouldAttach(): boolean {
		return false;
	}

	shouldDetach(): boolean {
		return false;
	}

	shouldReuseRoute(
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		_future: ActivatedRouteSnapshot,
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		_current: ActivatedRouteSnapshot,
	): boolean {
		return false;
	}

	store(): void {
		return;
	}
}

export const appConfig: (
	environmentData: Environment,
	environmentDomainData: Domain,
) => ApplicationConfig = (
	environmentData: Environment,
	environmentDomainData: Domain,
) => ({
	providers: [
		provideClientHydration(
			withHttpTransferCacheOptions({
				includeHeaders: ['accept-language', 'expires'],
			}),
		),
		importProvidersFrom(
			ApmModule,
			BrowserModule,
			ReactiveFormsModule,
			SharedFormlyModule,
			StoreDevtoolsModule.instrument({ connectInZone: true }),
			StoreModule.forRoot({
				router: routerReducer,
			}),
			StoreRouterConnectingModule.forRoot(),
			EffectsModule.forRoot(),
			TranslateModule.forRoot({
				loader: {
					deps: [HttpClient, EnvironmentToken],
					provide: TranslateLoader,
					useFactory: httpLoaderFactory,
				},
			}),
			ContactDetailsStoreModule,
			SessionStoreModule,
			AccountStoreModule,
			AffiliateStoreModule,
		),
		PageGuard,
		ApmService,
		PageService,
		MetadataService,
		LinkService,
		ScriptService,
		ContactDetailsStateService,
		{
			provide: APP_ID,
			useValue: 'serverApp',
		},
		{
			provide: RouteReuseStrategy,
			useClass: CustomReuseStrategy,
		},
		{
			provide: ErrorHandler,
			useClass: ApmErrorHandler,
		},
		{
			provide: UrlSerializer,
			useClass: TrailingSlashUrlSerializer,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: SiteDomainInterceptor,
		},
		{
			multi: true,
			provide: HTTP_INTERCEPTORS,
			useClass: AcceptLanguageInterceptor,
		},
		{
			provide: EnvironmentToken,
			useValue: environmentData,
		},
		{
			provide: CMS_SETTINGS,
			useValue: environmentDomainData.api.cms.settings,
		},
		{
			provide: 'isProduction',
			useValue: environmentData.production,
		},
		{
			provide: 'cmsApiBaseUrl',
			useValue: environmentDomainData.api.cms.baseUrl,
		},
		{
			provide: 'envLanguages',
			useValue: {
				availableLanguages: environmentDomainData.language.supported,
				default: environmentDomainData.language.default,
			},
		},
		{
			provide: 'apiBaseUrl',
			useValue: environmentDomainData.api.bff.baseUrl,
		},
		{
			provide: 'acceptLanguage',
			useValue: environmentDomainData.api.cms.settings.acceptLanguage,
		},
		{
			provide: 'applicationBaseUrl',
			useValue: environmentDomainData.appBaseUrl,
		},
		{
			provide: 'countryCode',
			useValue: environmentDomainData.countryCode,
		},
		{
			provide: 'domainSource',
			useValue: environmentDomainData.source,
		},
		{
			provide: 'affiliateKey',
			useValue: environmentDomainData.affiliateKey,
		},
		{
			provide: 'applicationUrls',
			useValue: environmentDomainData.applicationUrls,
		},
		{
			provide: 'googleMapsApiKey',
			useValue: environmentDomainData.googleMaps?.apiKey,
		},
		{
			provide: 'siteDomain',
			useValue: environmentDomainData.countryCode,
		},
		{
			provide: 'isB2B',
			useValue: environmentDomainData.isB2B,
		},
		{
			provide: APP_INITIALIZER,
			useFactory: (
				rendererFactory: RendererFactory2,
				document: Document,
				activeDomain?: SupportedDomains,
			) => {
				// Write active domain to script tag for client side use
				if (activeDomain) {
					const render = rendererFactory.createRenderer(null, null);
					const body: HTMLElement = document.head;
					const script = render.createElement('script');
					const text = render.createText(
						`window.activeDomain = '${activeDomain}'`,
					);
					script.appendChild(text);
					render.appendChild(body, script);
				}
				return () => true;
			},
			deps: [
				RendererFactory2,
				DOCUMENT,
				[new Optional(), SSRActiveDomainToken],
			],
			multi: true,
		},
		provideHttpClient(withInterceptorsFromDi()),
		provideRouter(
			appRoutes,
			withInMemoryScrolling({
				anchorScrolling: 'enabled',
				scrollPositionRestoration: 'enabled',
			}),
		),
	],
});
