import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@sunny-cars/ui/lib/icon/icon.component';
import { TooltipDirective } from '@sunny-cars/ui/lib/tooltip/tooltip.directive';
import { IconKey } from '@sunny-cars/util-global/lib/interfaces/icons.interface';

marker('payment-methods.ideal');
marker('payment-methods.paypal');
marker('payment-methods.amazonpay');
marker('payment-methods.bancontact');
marker('payment-methods.giropay');
marker('payment-methods.cc3dsecure');
marker('payment-methods.debitcash');
marker('payment-methods.sepa');

export interface PaymentMethodItem {
	label: string;
	icon: IconKey;
}

@Component({
	selector: 'content-payment-options',
	templateUrl: './payment-options.component.html',
	styles: [
		`
			:host {
				@apply block;
			}
		`,
	],
	standalone: true,
	imports: [CommonModule, IconComponent, TooltipDirective, TranslateModule],
})
export class PaymentOptionsComponent {
	@Input()
	iconSize: 'xs' | 'sm' | 'lg' | 'xl' = 'lg';

	@Input()
	showToolTips = false;

	@Input()
	paymentMethodsItems?: PaymentMethodItem[] | null;
}
