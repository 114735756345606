import { enableProdMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { CacheLabelService } from '@sunny-cars/util-global';
import { Environment } from '@sunny-cars/util-global/lib/services/environment/environment.interface';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';

let isDomContentLoaded = false;
document.addEventListener('DOMContentLoaded', () => {
	isDomContentLoaded = true;
});

const cachedUnixTimestamp: number = CacheLabelService.getTimestamp();
fetch(`./assets/environments/env.json?t=${cachedUnixTimestamp}`).then(
	(fileData) => {
		fileData.json().then((environmentData: Environment) => {
			if (environmentData.production) {
				enableProdMode();
			}

			let environmentDomainData =
				environmentData.domain.config[environmentData.domain.active];
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			const activeDomain = window && (window as any).activeDomain;
			const domainData = environmentData.domain.config[activeDomain];
			if (activeDomain && domainData) {
				environmentData.domain.active = activeDomain;
				environmentDomainData = domainData;
			}

			function bootstrap() {
				bootstrapApplication(
					AppComponent,
					appConfig(environmentData, environmentDomainData),
				).catch((err) => console.error(err));
			}

			if (document.readyState === 'complete' || isDomContentLoaded) {
				bootstrap();
			} else {
				document.addEventListener('DOMContentLoaded', () => {
					bootstrap();
				});
			}
		});
	},
);
