import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Icon } from '@sunny-cars/util-global/lib/interfaces/icon/icon.type';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'ui-box',
	templateUrl: './box.component.html',
	standalone: true,
	imports: [CommonModule, IconComponent, RouterModule, TranslateModule],
})
export class BoxComponent {
	/** Whether the box shows an icon */
	@Input() icon?: Icon;
	/** The box title */
	@Input() title = '';
	/** The box text */
	@Input() content = '';
	/** The read more link */
	@Input() link = '';
}
