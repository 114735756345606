import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';

/**
 * FormControl component
 */
@Component({
	selector: 'ui-form-control',
	templateUrl: './form-control.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule],
})
export class FormControlComponent {
	@Input() isHiddenLabel = false;
	@Input() isFullWidth = false;
}
