import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SESSION_FEATURE_KEY, State } from './session.reducer';

export const accountBlockedErrorCode = 'Account blocked';

/**
 * Lookup the 'Session' feature state managed by NgRx
 */
export const getSessionState =
	createFeatureSelector<State>(SESSION_FEATURE_KEY);

/**
 * Get id
 */
export const getId = createSelector(
	getSessionState,
	(state: State) => state.id,
);

/**
 * Select the session identifier
 */
export const getSessionID = createSelector(
	getSessionState,
	(state: State) => state.sessionID,
);

/**
 * Get token type
 */
export const getTokenType = createSelector(
	getSessionState,
	(state: State) => state.tokenType,
);

/**
 * Get expiration at
 */
export const getExpiresAt = createSelector(
	getSessionState,
	(state: State) => state.expiresAt,
);

/**
 * Get username
 */
export const getUsername = createSelector(
	getSessionState,
	(state: State) => state.username,
);

/**
 * Select whether the session is loading
 */
export const getSessionLoading = createSelector(
	getSessionState,
	(state: State) => state.isLoading,
);

/**
 * Select whether the session is loaded
 */
export const getSessionLoaded = createSelector(
	getSessionState,
	(state: State) => state.isLoaded,
);

/**
 * Select session error
 */
export const getSessionError = createSelector(
	getSessionState,
	(state: State) => state.error,
);

/**
 * Select logout reason
 */
export const getLogoutReason = createSelector(
	getSessionState,
	(state: State) => state.logoutReason,
);
