<ui-heading-content-block
	*ngIf="heading || subheading"
	class="mb-3"
	[id]="id"
	[heading]="heading"
	[headingLevel]="headingType"
	[subheading]="subheading"
></ui-heading-content-block>
<div
	class="grid grid-cols-collection gap-3 xs:gap-5 sm:grid-cols-collection-desktop"
>
	<ng-container *ngFor="let image of images">
		<ui-image
			class="max-w-md lg:mx-auto"
			[image]="{
				altText: image.attributes.image.alt,
				src: image.attributes.image.src,
				height: height,
				width: width
			}"
			[sources]="[]"
			className="rounded-large"
		></ui-image>
	</ng-container>
</div>
