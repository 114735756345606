import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
	providedIn: 'root',
})
export class PageScrollHelper {
	constructor(@Inject(PLATFORM_ID) private readonly platformId: string) {}

	toggleBackgroundScroll(state: boolean, breakpoint?: number): void {
		if (isPlatformBrowser(this.platformId)) {
			const shouldDisableScroll =
				state && window.innerWidth < (breakpoint || 640);
			document
				.querySelector('html')
				?.classList.toggle('overflow-hidden', shouldDisableScroll);
		}
	}
}
