import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { PromotionCodeResponseAPI } from '@sunny-cars/util-api-interfaces';
import {
	catchError,
	EMPTY,
	Observable,
	ReplaySubject,
	Subject,
	take,
	tap,
} from 'rxjs';
import { APIPromotionCodeDetailRequestParams } from './promotion-code-detail.interface';

@Injectable({
	providedIn: 'root',
})
export class PromotionCodeDetailService {
	private isInEvaluation = false;

	private readonly _isPromoCodeValid$: ReplaySubject<boolean> =
		new ReplaySubject(1);
	private readonly _promoCodeDetail$: ReplaySubject<PromotionCodeResponseAPI> =
		new ReplaySubject(1);
	private readonly _promoCodeLoaded$: Subject<void> = new Subject();

	get isPromoCodeValid$(): Observable<boolean> {
		return this._isPromoCodeValid$.asObservable();
	}

	get promoCodeDetail$(): Observable<PromotionCodeResponseAPI> {
		return this._promoCodeDetail$.asObservable();
	}

	get promoCodeLoaded$(): Observable<void> {
		return this._promoCodeLoaded$.asObservable();
	}

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl')
		private readonly endpoint: string,
	) {}

	/**
	 * Fetches promotion code, publishes both the promo code details and promo code validity
	 * through isPromoCodeValid$ and promoCodeDetails$.
	 */
	getPromotionCodeDetail(
		promotionCode: string | undefined,
		params: APIPromotionCodeDetailRequestParams,
	): void {
		if (this.isInEvaluation) {
			return;
		}

		if (!promotionCode) {
			this._isPromoCodeValid$.next(false);
			return;
		}

		this.isInEvaluation = true;

		this.evaluatePromoCode(promotionCode, params)
			.pipe(this.publishPromotionCodeEvaluation.bind(this), take(1))
			.subscribe({
				complete: () => {
					this._promoCodeLoaded$.next(void 0);
				},
			});
	}

	/**
	 * Make promotion code evaluation HTTP call
	 */
	private evaluatePromoCode(
		promotionCode: string,
		params: APIPromotionCodeDetailRequestParams,
	): Observable<PromotionCodeResponseAPI> {
		return this.http.get<PromotionCodeResponseAPI>(
			`${this.endpoint}v1/promotioncode/${promotionCode}`,
			{
				params: {
					...params,
				},
			},
		);
	}

	/**
	 * Publish the promotion code evaluation for consumers
	 */
	private publishPromotionCodeEvaluation(
		source$: Observable<PromotionCodeResponseAPI>,
	) {
		return source$.pipe(
			catchError((err) => {
				this._promoCodeDetail$.next(err);
				this._isPromoCodeValid$.next(false);
				this.isInEvaluation = false;
				return EMPTY;
			}),
			tap((promotionCodeResponse) => {
				this._promoCodeDetail$.next(promotionCodeResponse);
				this._isPromoCodeValid$.next(true);
				this.isInEvaluation = false;
			}),
		);
	}
}
