import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	ProfileResponseAPI,
	ProfileResponseAllOfDataAPI,
	ProfileUpdateRequestAPI,
} from '@sunny-cars/util-api-interfaces';
import { SourceType } from '@sunny-cars/util-global';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import { Observable, map } from 'rxjs';
import { APIGetProfileRequestParams } from './interfaces/get-profile.interface';
import {
	APIUpdatePasswordRequestBody,
	APIUpdatePasswordResponse,
} from './interfaces/update-password.interface';

@Injectable({
	providedIn: 'root',
})
export class ProfileService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	getProfile(
		params: APIGetProfileRequestParams,
		bearer: string,
	): Observable<AccountData> {
		return this.http
			.get<ProfileResponseAPI>(`${this.endpoint}v1/account/profile`, {
				params: {
					...params,
				},
				headers: new HttpHeaders({
					authorization: `Bearer ${bearer}`,
				}),
			})
			.pipe(
				map((response) => {
					return this.parseAccount(response.data);
				}),
			);
	}

	editProfile(
		data: AccountData,
		affiliateKey: number,
		source: SourceType,
	): Observable<AccountData> {
		const body: ProfileUpdateRequestAPI = {
			...data,
			affiliateKey,
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			source: source as any,
		};
		return this.http
			.patch<ProfileResponseAPI>(`${this.endpoint}v1/account/profile`, body)
			.pipe(
				map((response) => {
					return this.parseAccount(response.data);
				}),
			);
	}

	updatePassword(
		body: APIUpdatePasswordRequestBody,
		bearer: string,
	): Observable<APIUpdatePasswordResponse> {
		return this.http.post<APIUpdatePasswordResponse>(
			`${this.endpoint}v1/account/update-password`,
			body,
			{
				headers: new HttpHeaders({
					authorization: `Bearer ${bearer}`,
				}),
			},
		);
	}

	private parseAccount(
		response: ProfileResponseAllOfDataAPI | undefined,
	): AccountData {
		return {
			birthdate: response?.birthdate || '',
			city: response?.city || '',
			countrycode: response?.countryCode || '',
			emailaddress: response?.emailAddress || '',
			firstname: response?.firstName || '',
			gender: response?.gender || '',
			id: response?.id || '',
			lastname: response?.lastName || '',
			phonenumber: response?.phoneNumber || '',
			mobilePhoneNumber: response?.mobilePhoneNumber || '',
			postalcode: response?.postalCode || '',
			qualificationTitle: response?.qualificationTitle || '',
			salutationtitle: '',
			street: response?.street || '',
		};
	}
}
