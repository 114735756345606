import { IconKey } from '../icons.interface';

export type PaymentMethodKey =
	| 'ideal'
	| 'paypal'
	| 'amazonpay'
	| 'bancontact'
	| 'giropay'
	| 'cc3dsecure'
	| 'debitcash'
	| 'sepa';

export enum PaymentMethodsIcons {
	ideal = 'icon-ideal-colored',
	paypal = 'icon-card-paypal-colored',
	amazonpay = 'icon-card-amazon-pay-colored',
	bancontact = 'icon-bancontact-colored',
	giropay = 'icon-card-giropay-colored',
	cc3dsecure = 'icon-card-amex-visa-mastercard-colored',
	debitcash = 'icon-card-debitcash-colored',
	sepa = 'icon-card-sepa-colored',
}

export interface PaymentMethodItem {
	label: string;
	icon: IconKey;
}
