import { Component, Input } from '@angular/core';
import { LocaleHelper } from '@sunny-cars/util-global';
import { LazyImageLoadingDirective } from '@sunny-cars/util-global/lib/directives/lazy-image/lazy-image.directive';

export enum EkomiLogoType {
	STANDARD = 'standard',
	BRONZE = 'bronze',
	SILVER = 'silver',
	GOLD = 'gold',
}

@Component({
	selector: 'ui-ekomi-logo',
	templateUrl: 'ekomi-logo.component.html',
	standalone: true,
	imports: [LazyImageLoadingDirective],
})
export class EkomiLogoComponent {
	@Input({ required: true }) score = 0;
	@Input() reviewCount = 0;
	@Input() assetDomain = '';

	constructor(readonly localeHelper: LocaleHelper) {}

	getEkomiLogoType(score: number): EkomiLogoType {
		if (score < 4 || (this.reviewCount && this.reviewCount < 50)) {
			return EkomiLogoType.STANDARD;
		} else if (score >= 4 && score <= 4.3) {
			return EkomiLogoType.BRONZE;
		} else if (score >= 4.4 && score < 4.8) {
			return EkomiLogoType.SILVER;
		} else {
			return EkomiLogoType.GOLD;
		}
	}
}
