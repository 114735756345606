import { Action, ActionReducer } from '@ngrx/store';
import {
	CookieStorageService,
	LocalStorageService,
	SessionStorageService,
} from '@sunny-cars/provider-storage';
import dayjs from 'dayjs';

type StorageStateType = { [key: string]: unknown } & { expiresAt?: number };

export function getStorageReducer(
	storageKey: string,
	storageService:
		| LocalStorageService
		| SessionStorageService
		| CookieStorageService,
	saveKeys?: string[],
	ttl?: number,
): {
	/**
	 * Meta reducers
	 */
	metaReducers: unknown[];
} {
	return {
		metaReducers: [
			storageMetaReducerFactory(storageKey, storageService, saveKeys, ttl),
		],
	};
}

export function storageMetaReducerFactory<
	S extends StorageStateType,
	A extends Action = Action,
>(
	storageKey: string,
	storageService:
		| LocalStorageService
		| SessionStorageService
		| CookieStorageService,
	saveKeys?: string[],
	ttl?: number,
): (reducer: ActionReducer<S, A>) => (state: S, action: A) => S {
	let onInit = true;

	return function (reducer: ActionReducer<S, A>): (state: S, action: A) => S {
		return function (state: S, action: A): S {
			// Get the next state.
			const nextState = reducer(state, action);

			// Save only the desired keys of the next state to the application storage.
			let stateToStore: StorageStateType = {};
			if (!saveKeys?.length) {
				stateToStore = nextState;
			} else {
				if (ttl) {
					saveKeys.push('expiresAt');
				}
				saveKeys.forEach((key: string) => {
					const value = nextState[key];
					if (value) {
						stateToStore[key] = value;
					}
				});
			}

			// Initialize the application state.
			if (onInit) {
				onInit = false;
				const savedState = storageService.getSavedState(storageKey);

				return savedState.expiresAt &&
					dayjs().isAfter(dayjs.unix(savedState.expiresAt))
					? {}
					: {
							...stateToStore,
							...savedState,
						};
			}

			if (ttl) {
				const savedState = storageService.getSavedState(storageKey);
				stateToStore = getStateToStoreWhenTTLIsEnabled(
					stateToStore,
					savedState,
					ttl,
				);
			}

			storageService.setSavedState(stateToStore, storageKey);
			return nextState;
		};
	};
}

function getStateToStoreWhenTTLIsEnabled(
	state: StorageStateType,
	savedState: StorageStateType,
	ttl: number,
): StorageStateType {
	const hasState = Object.keys(state).length;
	const hasSavedState = Object.keys(savedState).length;
	const statesAreEqual = expiryStatesAreEqual(state, savedState);
	const isExpired =
		hasSavedState &&
		savedState.expiresAt &&
		dayjs().isAfter(dayjs.unix(savedState.expiresAt));

	if (isExpired) {
		return {};
	}

	if (hasState && hasSavedState && statesAreEqual) {
		return savedState;
	}

	if (
		hasState &&
		(!savedState.expiresAt || (hasSavedState && !statesAreEqual))
	) {
		state.expiresAt = dayjs().unix() + ttl;
	}

	return { ...savedState, ...state };
}

function expiryStatesAreEqual(
	stateA: StorageStateType,
	stateB: StorageStateType,
) {
	const deleteExpireKeyAndParseToJsonString = (state: StorageStateType) => {
		const _state = { ...state };
		delete _state.expiresAt;
		return JSON.stringify(_state);
	};

	return (
		deleteExpireKeyAndParseToJsonString(stateA) ===
		deleteExpireKeyAndParseToJsonString(stateB)
	);
}
