import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
	CustomAffiliateStorageData,
	customAffiliateStorageKey,
} from '../deep-link-params/deep-link-params.helper';

/**
 * Service responsible for discovering and publishing if application is ran for a custom affiliate
 */
@Injectable({
	providedIn: 'root',
})
export class AffiliateCustomService {
	private _customAffiliate$ = new BehaviorSubject<boolean>(false);

	get customAffiliate$(): Observable<boolean> {
		return this._customAffiliate$.asObservable();
	}

	init() {
		const storage = sessionStorage.getItem(customAffiliateStorageKey);
		const affiliateSettings: CustomAffiliateStorageData | undefined = storage
			? JSON.parse(storage)
			: undefined;

		if (affiliateSettings?.status === true) {
			this._customAffiliate$.next(true);
		}
	}
}
