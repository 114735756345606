<!-- templates -->
<ng-template #skeletonTemplate let-index="index">
	<div class="flex items-center border-t border-ui-low px-3 py-2">
		<div uiContrastText>
			<ui-icon
				classModifiers="block"
				name="icon-loader"
				inlined
				color="ui-critical"
			></ui-icon>
			<span>&nbsp;</span>
		</div>
	</div>
</ng-template>

<ng-template #phoneCountryCodeSelectedTemplate let-data="entry">
	<div
		*ngIf="data"
		class="flex flex-row items-center gap-2"
		[attr.data-value]="data.countryCode"
	>
		<div>
			<span
				class="flex h-5 w-5 items-center justify-center overflow-hidden rounded [&>*]:h-full [&>*]:shrink-0"
				[innerHTML]="flagSVGs[data.countryCode] | SafeHtml"
			></span>
		</div>
		<div>
			<span class="pl-1"> ({{ data.dialCode }}) </span>
		</div>
	</div>
</ng-template>

<ng-template
	#phoneCountryCodeEntryTemplate
	let-data="entry"
	let-selected="isSelected"
>
	<div
		uiContrastText
		class="flex items-center border-t border-ui-low py-3 pl-0 pr-3 text-sm"
		[attr.data-value]="data.countryCode"
	>
		<div class="px-3">
			<span
				class="flex h-5 w-5 items-center justify-center overflow-hidden rounded [&>*]:h-full [&>*]:shrink-0"
				[innerHTML]="flagSVGs[data.countryCode] | SafeHtml"
			></span>
		</div>
		<div>
			<span [innerHTML]="data.modifiedLabel || data.label"></span>
			<span class="pl-1 opacity-60"> {{ data.dialCode }} </span>
		</div>
	</div>
</ng-template>
<ng-template #phoneCountryCodeEmptyTemplate>
	<div class="bg-highlight-low px-3 py-2">
		{{ 'components.phonenumber.results-empty-feedback-text' | translate }}
	</div>
</ng-template>

<ui-autocomplete-button
	[(ngModel)]="value.countryCode"
	(ngModelChange)="handleChange()"
	data-test-class="phone-country-code-button"
	controlId="phone-country-code"
	id="phone-country-code"
	name="phone-country-code"
	[entryTemplate]="phoneCountryCodeEntryTemplate"
	[emptyTemplate]="phoneCountryCodeEmptyTemplate"
	[selectedTemplate]="phoneCountryCodeSelectedTemplate"
	[skeletonTemplate]="skeletonTemplate"
	[entries]="countryListFiltered"
	[title]="'personal-details.form.contact.phone-number-label' | translate"
	(searchChange)="handlePhoneCountryCodeSearchInput($event)"
	[styleModifiers]="'rounded-r-none border-r-1'"
>
	<input
		[(ngModel)]="value.phoneNumber"
		(ngModelChange)="handleChange()"
		class="input w-full rounded-l-none border-l-1"
		#mobilePhoneNumber="ngModel"
		[id]="'mobile-phone-number' + idSuffix"
		name="mobile-phone-number"
		type="text"
		data-test-class="phone-number-input"
		[ngClass]="{
			'input--invalid': mobilePhoneNumber?.errors && isSubmitted,
			'input--valid': !mobilePhoneNumber?.errors
		}"
		pattern="^[0-9\(][0-9\-\(\) ]{5,16}[0-9]"
		required
	/>
</ui-autocomplete-button>
