<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	[class]="content.layoutSettings.width"
>
	<ui-collection [isList]="true">
		<section *ngFor="let award of content.items">
			<ng-container *ngIf="award.link; else awardContent">
				<a [href]="award.link" *ngIf="award.link.startsWith('http')">
					<ng-container *ngTemplateOutlet="awardContent"></ng-container>
				</a>
				<a [routerLink]="award.link" *ngIf="!award.link.startsWith('http')">
					<ng-container *ngTemplateOutlet="awardContent"></ng-container>
				</a>
			</ng-container>
			<ng-template #awardContent>
				<div
					class="flex flex-col rounded-large border border-ui-moderate p-2 xs:p-4 md:flex-row"
				>
					<div
						class="mb-2 flex-shrink-0 flex-grow-0 md:mb-0 md:mr-4 md:h-60 md:w-80"
					>
						<ui-image
							*ngIf="award.logo"
							className="h-full w-full max-w-none overflow-hidden rounded-medium object-cover"
							[sources]="[
								{
									min: null,
									max: sizes.SM,
									width: 350,
									height: 350
								},
								{
									min: sizes.SM,
									max: sizes.MD,
									width: 639,
									height: 639
								},
								{
									min: sizes.MD,
									max: null,
									width: 320,
									height: 240
								}
							]"
							[image]="{
								src: award.logo.src,
								altText: award.logo.alt,
								width: 639,
								height: 639
							}"
						></ui-image>
					</div>
					<div>
						<header class="mb-2 flex flex-row items-center md:mb-4">
							<div
								class="mr-4 flex w-24 flex-shrink-0 flex-grow-0 flex-col items-center break-normal rounded-large border border-ui-moderate p-2"
							>
								<ui-image
									*ngIf="award.separateLogo"
									className="h-20 w-20 overflow-hidden rounded object-scale-down"
									[image]="{
										src: award.separateLogo.src,
										altText: award.separateLogo.alt,
										height: 80,
										width: 80
									}"
								></ui-image>
								<time
									class="mt-2 text-sm font-bold"
									[attr.datetime]="award.date"
									>{{ award.date | datetime : dateTimeFormats.DATE_LONG }}</time
								>
							</div>
							<h2
								class="break-words text-xl font-bold text-secondary xs:text-2xl"
							>
								{{ award.name }}
							</h2>
						</header>
						<p class="prose" [innerHTML]="award.description"></p>
					</div>
				</div>
			</ng-template>
		</section>
	</ui-collection>
</section>
