import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnChanges,
	SimpleChanges,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import zxcvbn from 'zxcvbn';

@Component({
	selector: 'ui-password-strength-indicator',
	templateUrl: './password-strength-indicator.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class PasswordStrengthIndicatorComponent implements OnChanges {
	@Input() value = '';
	@Input() userData: string[] = [];
	@Input() isValid = false;

	strength = 0;

	ngOnChanges(changes: SimpleChanges): void {
		if (changes['value'] || changes['userData'] || changes['isValid']) {
			this.checkStrength(this.value, this.userData);
		}
	}

	checkStrength(value: string, userData?: string[]): void {
		if (!value || !this.isValid) {
			this.strength = 0;
		} else {
			const analisys = zxcvbn(value, userData);
			this.strength = analisys.score + 1;
		}
	}
}
