<ng-container *ngIf="siteData$ | async as siteData">
	<ng-container *ngIf="pageData$ | async as pageData">
		<ng-container
			*ngIf="!isB2B && pageData.type !== pageTypes.DESTINATION_PAGE"
		>
			<ng-container
				*ngTemplateOutlet="
					breadcrumbsComponent;
					context: {
						position: positions.TOP,
						breadcrumbs: pageData.breadcrumbs
					}
				"
			></ng-container>
		</ng-container>
		<ng-container *ngIf="notification && isNotificationVisible()">
			<ui-notification
				[title]="notification.title"
				[type]="notification.type"
				[isDismissable]="true"
				(dismissed)="dismissNotification()"
			>
				{{ notification.description }}
			</ui-notification>
		</ng-container>
		<ng-container *ngIf="heroComponent">
			<ui-hero
				[backgroundImage]="heroComponent.attributes.backgroundImage"
				[link]="heroComponent.attributes.link"
				[promotion]="heroComponent.attributes.promotion"
				[subtitle]="heroComponent.attributes.subheading"
				[title]="heroComponent.attributes.heading"
				[usps]="heroComponent.attributes.usps"
				[reviews]="
					(heroComponent.attributes.showReviews && (reviews$ | async)) ||
					undefined
				"
				[reviewsCenterAligned]="
					heroComponent.attributes.reviewsPosition === 'center'
				"
				[headingType]="heroComponent.attributes.headingType"
			>
				<div class="min-h-[451px]">
					<div [outerHTML]="widgetHtml"></div>
				</div>
				<!-- Payment options and affiliate logo's -->
				<div class="mt-2 flex items-center justify-between">
					<content-payment-options
						[paymentMethodsItems]="paymentMethodItems$ | async"
						iconSize="xl"
					></content-payment-options>
					<div *ngIf="affiliatedWith" class="flex items-center gap-2">
						<span class="hidden text-2xs text-text-quaternary xs:block">
							{{ 'global.affiliated-with-capitalize' | translate }}
						</span>
						<span
							*ngFor="let affiliated of affiliatedWith"
							class="inline-flex items-start"
						>
							<!-- ANVR logo. Only display on the NL website -->
							<a
								*ngIf="affiliated === affiliatedWithTypes.ANVR"
								href="https://www.anvr.nl/anvrleden/Certificaat.aspx?nummer=9471 "
								target="_blank"
							>
								<ui-icon inlined lg name="icon-anvr"></ui-icon>
							</a>
							<!-- SGR logo -->
							<a
								*ngIf="affiliated === affiliatedWithTypes.SGR"
								href="https://www.sgr.nl/aangesloten-reisorganisaties/sunny-cars/"
								target="_blank"
							>
								<ui-icon inlined lg name="icon-sgr"> </ui-icon>
							</a>
						</span>
					</div>
				</div>
			</ui-hero>
		</ng-container>
		<ng-container *ngIf="coverComponent">
			<ui-cover [content]="coverComponent"></ui-cover>
		</ng-container>
		<ng-container
			*ngIf="!isB2B && pageData.type === pageTypes.DESTINATION_PAGE"
		>
			<div class="pt-6 sm:px-12">
				<div
					class="mx-auto px-4 sm:px-0"
					[ngClass]="{
						'max-w-optimized-reading': [
							pageTypes.NEWS_PAGE,
							pageTypes.CONTENT_PAGE,
							pageTypes.VACANCY_PAGE
						].includes(pageData.type),
						'max-w-7xl': [pageTypes.DESTINATION_PAGE].includes(pageData.type),
						'max-w-7.5xl': ![
							pageTypes.NEWS_PAGE,
							pageTypes.CONTENT_PAGE,
							pageTypes.VACANCY_PAGE,
							pageTypes.DESTINATION_PAGE
						].includes(pageData.type)
					}"
				>
					<ng-container
						*ngTemplateOutlet="
							breadcrumbsComponent;
							context: {
								position: positions.BOTTOM,
								breadcrumbs: pageData.breadcrumbs
							}
						"
					></ng-container>
				</div>
			</div>
		</ng-container>
		<div class="flex grow justify-center overflow-x-hidden py-6 sm:p-12">
			<div
				[ngClass]="{
					'max-w-optimized-reading': [
						pageTypes.NEWS_PAGE,
						pageTypes.CONTENT_PAGE,
						pageTypes.VACANCY_PAGE
					].includes(pageData.type),
					'max-w-7xl': [pageTypes.DESTINATION_PAGE].includes(pageData.type),
					'max-w-7.5xl': ![
						pageTypes.NEWS_PAGE,
						pageTypes.CONTENT_PAGE,
						pageTypes.VACANCY_PAGE,
						pageTypes.DESTINATION_PAGE
					].includes(pageData.type)
				}"
				class="flex w-full flex-col gap-y-4 px-4 xs:gap-y-6 sm:gap-y-8 sm:px-0 lg:gap-y-10"
			>
				<ng-container *ngFor="let content of pageData.content">
					<ng-container *ngIf="content.layout === componentType.NEWSLETTER">
						<ui-newsletter
							[buttonClass]="content.attributes.buttonClass"
							[buttonPosition]="content.attributes.buttonPosition"
							[buttonText]="content.attributes.buttonText"
							[image]="content.attributes.image"
							[isShowingSocialMediaIcons]="
								content.attributes.isShowingSocialMediaIcons
							"
							[privacyStatement]="content.attributes.privacyStatement"
							[socials]="siteData.socialMedia"
							[subtitle]="content.attributes.subtitle"
							[title]="content.attributes.title"
						></ui-newsletter>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.AWARD_ITEMS">
						<ui-awards [content]="content"></ui-awards>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.BLOG">
						<ui-blog
							[content]="content"
							[isDestinationPage]="pageData.type === pageTypes.DESTINATION_PAGE"
						></ui-blog>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.CARDS">
						<ng-container *ngIf="isB2B">
							<ui-b2b-cards
								[content]="convertCardsToB2bCards(content)"
							></ui-b2b-cards>
						</ng-container>
						<ng-container *ngIf="!isB2B">
							<ui-cards [content]="content"></ui-cards>
						</ng-container>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.CONTACT">
						<ui-contact
							[content]="content.attributes.content"
							[image]="content.attributes.image"
							[openingHours]="openingHours$ | async"
							[showOpeningHours]="content.attributes.showOpeningHours"
						></ui-contact>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.CONTACT_PERSON">
						<ui-contact-person
							[contactPerson]="content.attributes.contactPerson"
							[content]="content.attributes.content"
							[heading]="content.attributes.heading"
							[headingType]="content.attributes.headingType"
							[subheading]="content.attributes.subheading"
							class="block"
						></ui-contact-person>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.COLLECTION">
						<ui-image-collection
							[heading]="content.attributes.heading"
							[headingType]="content.attributes.headingType"
							[id]="content.attributes.layoutSettings.anchor"
							[images]="content.attributes.items"
							[subheading]="content.attributes.subheading"
						></ui-image-collection>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.CTA_BUTTON">
						<ui-cta-button [content]="content"></ui-cta-button>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.DESTINATION_INFO"
					>
						<ui-wrapper>
							<ui-destination-info
								[content]="content.content"
								[image]="content.image"
								[items]="content.items"
								[runningHead]="content.runningHead"
								[titleQuickInfo]="content.titleQuickInfo"
							></ui-destination-info>
						</ui-wrapper>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.DOUBLE_COLUMN">
						<ui-double-column
							[content]="content"
							[isB2B]="isB2B"
							[isFullWidth]="pageData.type !== pageTypes.HOME_PAGE"
							[isTwoThirdColumn]="pageData.type === pageTypes.DESTINATION_PAGE"
							class="block"
						></ui-double-column>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.FAQ">
						<ui-accordion
							[headingContentPosition]="content.textPosition"
							[id]="content.anchor"
							[keepQuestionsVisible]="content.keepQuestionsVisible"
							[questions]="content.questions"
							[text]="content.content"
							[title]="content.heading"
						></ui-accordion>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.HTML_BLOCK">
						<div
							[innerHTML]="content.attributes.code | SafeHtml"
							class="block"
						></div>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.IMAGE">
						<ui-image
							[className]="content.attributes.widthClass"
							[id]="content.attributes.id"
							[image]="{
								altText: content.attributes.alt,
								src: content.attributes.src,
								width: 752,
								height:
									752 * (content.attributes.height / content.attributes.width)
									| roundNumber
							}"
							[sources]="[
								{
									min: null,
									max: sizes.XS,
									width: 400,
									height:
										400 * (content.attributes.height / content.attributes.width)
										| roundNumber
								},
								{
									min: sizes.XS,
									max: sizes.SM,
									width: 640,
									height:
										640 * (content.attributes.height / content.attributes.width)
										| roundNumber
								},
								{
									min: sizes.SM,
									max: null,
									width: 752,
									height:
										752 * (content.attributes.height / content.attributes.width)
										| roundNumber
								}
							]"
							class="block max-w-optimized-reading"
						>
						</ui-image>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.INFO_ITEMS">
						<ui-info-items
							[content]="content"
							[isElevated]="pageData.type === pageTypes.DESTINATION_PAGE"
						></ui-info-items>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.NEWS_ITEM_OVERVIEW"
					>
						<ui-news
							[content]="content"
							[itemAmount]="content.attributes.amount"
							[itemIncrement]="content.attributes.amount"
						></ui-news>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.NAVIGATION">
						<ui-page-links
							[anchor]="content.attributes.layoutSettings.anchor"
							[header]="content.attributes.header"
							[headingType]="content.attributes.headingType"
							[links]="content.attributes.links"
							[width]="content.attributes.layoutSettings.width"
						></ui-page-links>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.POPULAR_COUNTRIES"
					>
						<ui-popular-countries [content]="content"></ui-popular-countries>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.REVIEWS">
						<ui-reviews [content]="content"></ui-reviews>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.SEARCH_TRAVEL_AGENCIES"
					>
						<div class="block">
							<ui-heading-content-block
								[heading]="content.attributes.heading"
								[headingLevel]="content.attributes.headingType"
								[id]="content.attributes.layoutSettings?.anchor"
								[intro]="content.attributes.content"
								[subheading]="content.attributes.subheading"
								class="mb-4 xs:mb-6 sm:mb-8 lg:mb-10"
							></ui-heading-content-block>
							<content-search-travel-agencies></content-search-travel-agencies>
						</div>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.SEPARATOR">
						<hr
							class="border-t border-solid border-ui-moderate py-4 xs:py-6 sm:py-8 lg:py-10"
						/>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.SITE_MAP">
						<ui-sitemap
							[list]="content.attributes.sitemap"
							class="block"
						></ui-sitemap>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.TEXT">
						<ui-text-block
							[content]="content.attributes.content"
							[heading]="content.attributes.heading"
							[headingType]="content.attributes.headingType"
							[id]="content.attributes.layoutSettings?.anchor"
							[ngClass]="content.attributes.layoutSettings?.width"
							[subheading]="content.attributes.subheading"
							class="block"
						></ui-text-block>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.TRAVEL_AGENCIES"
					>
						<div class="block py-4">
							<div
								class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
							>
								<div
									*ngFor="let agency of content.attributes.items"
									class="rounded-large bg-ui-low p-4 md:p-6"
								>
									<img
										[alt]="agency.image.alt"
										class="max-w-[66%] lg:max-w-full"
										height="auto"
										[src]="agency.image.src"
										width="auto"
									/>
									<span class="block md:mt-4">{{ agency.title }}</span>
								</div>
							</div>
						</div>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.VISUAL_USPS">
						<ui-visual-usps
							[hasIcon]="content.attributes.hasIcon"
							[heading]="content.attributes.heading"
							[headingType]="content.attributes.headingType"
							[image]="content.attributes.image"
							[subheading]="content.attributes.subheading"
							[usps]="content.attributes.usps"
						></ui-visual-usps>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.VIDEO">
						<ui-video
							[content]="content.attributes.content"
							[heading]="content.attributes.heading"
							[headingType]="content.attributes.headingType"
							[html]="content.attributes.video.html"
							class="block"
						></ui-video>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.MAPS">
						<ui-region-map
							#regionMap
							[content]="content.attributes.content"
							[hidden]="regionMap.hidden"
							[id]="content.attributes.layoutSettings.anchor"
							[isDestinationPage]="pageData.type === pageTypes.DESTINATION_PAGE"
							[pathPrefix]="content.attributes.pathPrefix"
							[regionId]="pageData.regionId"
							[title]="content.attributes.title"
							[source]="source"
							[affiliateKey]="affiliateKey"
							[radius]="content.attributes.radius"
						></ui-region-map>
					</ng-container>
					<ng-container *ngIf="content.layout === componentType.FORM">
						<ng-container
							*ngIf="content.attributes.formId === formTypes.MY_SUNNY_ACCOUNT"
						>
							<ui-register
								[heading]="content.attributes.heading"
								[headingType]="content.attributes.headingType"
							></ui-register>
						</ng-container>
						<ng-container
							*ngIf="content.attributes.formId === formTypes.CONTACT"
						>
							Placeholder for Contact form
						</ng-container>
					</ng-container>
					<ng-container
						*ngIf="content.layout === componentType.B2B_PORTAL_ITEMS"
					>
						<ui-heading-content-block
							*ngIf="content.heading || content.subheading || content.content"
							[heading]="content.heading"
							[headingLevel]="content.headingType"
							[id]="content.layoutSettings.anchor"
							[intro]="content.content"
							[subheading]="content.subheading"
						></ui-heading-content-block>
						<ui-b2b-cards
							[content]="content"
							[isHorizontal]="!content.twoColumns && !!content.showImage"
						></ui-b2b-cards>
					</ng-container>
				</ng-container>
				<ng-container *ngIf="pageData.type === pageTypes.NEWS_PAGE">
					<div class="flex flex-col gap-2">
						<ui-heading-content-block
							[headingLevel]="headingLevels.H2"
							[heading]="'content.share-title' | translate"
							customHeadingClasses="text-xl font-semibold text-subheading sm:text-2xl"
						></ui-heading-content-block>
						<div class="flex gap-2">
							<a
								[href]="
									'https://twitter.com/intent/tweet?text=' +
									pageData.title +
									'%20' +
									currentUrl
								"
								class="btn btn-sm bg-ui-moderate text-base font-bold"
								target="_blank"
							>
								Twitter
							</a>
							<a
								[href]="
									'https://www.facebook.com/sharer/sharer.php?u=' + currentUrl
								"
								class="btn btn-sm bg-ui-moderate text-base font-bold"
								target="_blank"
							>
								Facebook
							</a>
						</div>
					</div>
					<div class="rounded-large bg-ui-low p-4 xs:p-6 sm:p-8 lg:p-10">
						<ui-heading-content-block
							[intro]="
								siteData.contactInfo?.translations?.aboutContent
									? siteData.contactInfo?.translations?.aboutContent
									: ('content.about-sunny-cars-text' | translate)
							"
							[subheading]="
								siteData.contactInfo?.translations?.aboutHeading
									? siteData.contactInfo?.translations?.aboutHeading
									: ('content.about-sunny-cars-title' | translate)
							"
						></ui-heading-content-block>
					</div>
					<ng-container *ngIf="pageData.extra as contact">
						<ui-contact-person
							[contactPerson]="{
								name: contact.title,
								function: contact.function,
								email: contact.email,
								phone: contact.phone || '',
								twitter: contact.twitter || '',
								linkedin: contact.linkedin || '',
								image: contact.image
							}"
							[subheading]="'content.news-contact-title' | translate"
						>
						</ui-contact-person>
					</ng-container>
				</ng-container>
			</div>
		</div>
		<ng-container *ngIf="!isB2B">
			<div class="p-4 xs:px-6 sm:px-8 lg:px-10">
				<ng-container
					*ngTemplateOutlet="
						breadcrumbsComponent;
						context: {
							position: positions.BOTTOM,
							breadcrumbs: pageData.breadcrumbs
						}
					"
				></ng-container>
			</div>
		</ng-container>
	</ng-container>
</ng-container>

<ng-template
	#breadcrumbsComponent
	let-breadcrumbs="breadcrumbs"
	let-position="position"
>
	<ng-container *ngIf="breadcrumbs?.length > 1">
		<ui-breadcrumbs
			[breadcrumbs]="breadcrumbs"
			[positionOnPage]="position"
		></ui-breadcrumbs>
	</ng-container>
</ng-template>
