import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, map, switchMap } from 'rxjs/operators';
import {
	internationalAddressCountryMapper,
	mapInternationalAddressDetailsResponse,
} from './mappers';
import {
	APIInternationalAddressDetails,
	APIInternationalAddressDetailsResponse,
	APIInternationalAddress,
	APIInternationalAddressQueryParams,
	APIInternationalAddressResponse,
	APIPostalAddressResponse,
	APIPostalAddressRequest,
} from './interfaces';

@Injectable({
	providedIn: 'root',
})
export class InternationalAddressService {
	ADDRESS_PRECISION = 'Address';

	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
	) {}

	/**
	 * BuildTermQuery
	 * @param {APIPostalAddressRequest} postalAddressResponse
	 * @returns {string}
	 */
	private static buildTermQuery(
		postalAddressResponse: APIPostalAddressRequest,
	): string {
		let query = `${postalAddressResponse.postalCode || ''} `;
		query += `${postalAddressResponse.city || ''} `;
		query += `${postalAddressResponse.street || ''} `;
		query += `${postalAddressResponse.houseNumber || ''} `;
		query += `${postalAddressResponse.houseNumberAddition || ''} `;

		return decodeURI(query).trim();
	}

	/**
	 * Get international address
	 * @param {APIInternationalAddressQueryParams} queryParams
	 * @returns {Observable<APIInternationalAddress[]>}
	 */
	getInternationalAddress(
		queryParams: APIInternationalAddressQueryParams,
	): Observable<APIInternationalAddress[]> {
		if (queryParams.term === '') {
			return of([]);
		}

		return this.http
			.get<APIInternationalAddressResponse>(
				`${this.endpoint}v1/address-international/autocomplete/${queryParams.context}/${queryParams.term}`,
			)
			.pipe(debounceTime(500))
			.pipe(
				map((response: APIInternationalAddressResponse) => {
					return response.data;
				}),
			)
			.pipe(catchError(() => of([])));
	}

	/**
	 * Get international address details
	 * @param {string} context
	 * @returns {Observable<APIInternationalAddressDetails>}
	 */
	getInternationalAddressDetails(
		context: string,
	): Observable<APIInternationalAddressDetails> {
		return this.http
			.get<APIInternationalAddressDetailsResponse>(
				`${this.endpoint}v1/address-international/context/${context}`,
			)
			.pipe(
				map((response: APIInternationalAddressDetailsResponse) => {
					return response.data;
				}),
			);
	}

	/**
	 * Get post address API observable
	 * @param {APIPostalAddressRequest} postalAddressResponse
	 * @returns {Observable<APIPostalAddressResponse>}
	 */
	getInternationalPostAddress(
		postalAddressResponse: APIPostalAddressRequest,
	): Observable<APIPostalAddressResponse> {
		const country: string = postalAddressResponse.country || 'NL';
		const term: string = InternationalAddressService.buildTermQuery(
			postalAddressResponse,
		);
		const queryParams: APIInternationalAddressQueryParams = {
			context: internationalAddressCountryMapper(
				country.toUpperCase(),
			).toLowerCase(),
			term: encodeURI(term),
		};

		return this.getInternationalAddress(queryParams)
			.pipe(
				switchMap((internationalAddresses: APIInternationalAddress[]) => {
					if (
						internationalAddresses.length === 1 &&
						internationalAddresses[0].precision === this.ADDRESS_PRECISION
					) {
						return this.getInternationalAddressDetails(
							internationalAddresses[0].context,
						).pipe(map(mapInternationalAddressDetailsResponse));
					}

					return of({});
				}),
			)
			.pipe(catchError(() => of({})));
	}
}
