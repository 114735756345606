import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';

@Component({
	selector: 'ui-text-block',
	templateUrl: './text-block.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HeadingContentBlockComponent],
})
export class TextBlockComponent {
	@Input() heading = '';
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() subheading = '';
	@Input() content = '';
}
