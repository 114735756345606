import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
/* istanbul ignore file */
/**
 * Extendable base class, all pages should use.
 */
@Component({
	template: '',
})
export abstract class BaseComponent implements OnDestroy {
	/**
	 * Array of subscriptions handled by this component.
	 */
	protected mutableSubscriptions: Array<Subscription> = [];

	/**
	 * Add a subscription
	 * @param { Subscription } subscription
	 * @returns { Subscription }
	 */
	protected addSubscription(subscription: Subscription): Subscription {
		this.mutableSubscriptions.push(subscription);
		return subscription;
	}

	/**
	 * Unsubscribe from all subscriptions
	 * @returns {void}
	 */
	protected unsubscribeAll(): void {
		this.mutableSubscriptions.forEach(
			(subscription: Subscription) =>
				!subscription.closed && subscription.unsubscribe(),
		);
	}

	/**
	 * Should be used by all pages when ngOnDestroy wants to be triggered
	 * @returns {void}
	 */
	protected extendedNgOnDestroy(): void {
		return;
	}

	/**
	 * Destructor which unsubscribe the dangling subscriptions
	 * @returns {void}
	 */
	ngOnDestroy(): void {
		this.unsubscribeAll();
		this.extendedNgOnDestroy();
	}
}
