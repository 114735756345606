import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { CookieService } from '../cookie/cookie.service';
import { customAffiliateStorageKey } from '../deep-link-params/deep-link-params.helper';
import { UrlHelper } from '../url/url.helper';

@Injectable({
	providedIn: 'root',
})
export class AffiliateKeyService {
	static affiliateKey = -1;
	static styleProfile?: number;
	static contentProfile?: number;
	static version?: string;

	private readonly affiliateLegacyCookieKey = 's_ak';
	private readonly affiliateCookieKey = 'site_ak';
	private readonly expirationInDays = 30;
	private readonly sqlIntLimit = 2147483647;

	constructor(
		@Optional()
		@Inject('affiliateKey')
		private readonly domainAffiliateKey: number | undefined,
		@Optional()
		@Inject('cookieDuration')
		private readonly cookieDuration: number | undefined,
		private readonly cookieService: CookieService,
		private readonly urlHelper: UrlHelper,
		@Inject(PLATFORM_ID) public platformId: string,
	) {}

	removeAffiliateCookies() {
		this.cookieService.removeCookie(this.affiliateLegacyCookieKey);
		this.cookieService.removeCookie(this.affiliateCookieKey);
	}

	getAffiliateKey(): number {
		let queryAffiliateKey = this.getAffiliateKeyFromURL();
		const cookieLegacyAffiliateKey = this.fetchAffiliateKeyFromCookie(
			this.affiliateLegacyCookieKey,
		);
		const cookieAffiliateKey = this.fetchAffiliateKeyFromCookie(
			this.affiliateCookieKey,
		);

		if (!this.isValidAffiliateKey(queryAffiliateKey)) {
			queryAffiliateKey = undefined;
		}

		// Overwriting the existing legacy cookie is not always possible so we set a new one and attempt to set the legacy one
		if (queryAffiliateKey) {
			this.updateAffiliateCookie(
				queryAffiliateKey,
				cookieAffiliateKey,
				cookieLegacyAffiliateKey,
			);
		}

		AffiliateKeyService.affiliateKey =
			queryAffiliateKey || cookieAffiliateKey || this.domainAffiliateKey || -1;
		AffiliateKeyService.styleProfile = this.getAffiliateStyleProfile();
		AffiliateKeyService.contentProfile = this.getAffiliateContentProfile();
		AffiliateKeyService.version = this.getAffiliateVersion();

		return AffiliateKeyService.affiliateKey;
	}

	updateAffiliateCookie(
		affilateKey: number,
		currentCookieAk?: number,
		currentLegacyCookieAk?: number,
	) {
		let changeLegacyCookie = false;
		if (affilateKey !== currentCookieAk) {
			this.removeAffiliateCookies();
			changeLegacyCookie = true;
			this.cookieService.persistCookieForDuration(
				this.affiliateCookieKey,
				affilateKey,
				this.cookieDuration || this.expirationInDays,
			);
		}
		if (affilateKey !== currentLegacyCookieAk || changeLegacyCookie) {
			this.cookieService.persistCookieForDuration(
				this.affiliateLegacyCookieKey,
				affilateKey,
				this.cookieDuration || this.expirationInDays,
			);
		}
	}

	isValidAffiliateKey(ak: number | undefined): boolean {
		return !!(ak && ak <= this.sqlIntLimit);
	}

	private getParams() {
		if (isPlatformBrowser(this.platformId)) {
			return UrlHelper.getParamsWithLowerCasedKeys();
		}
		return {};
	}

	private getStorage() {
		if (isPlatformBrowser(this.platformId)) {
			const storage = sessionStorage.getItem(customAffiliateStorageKey);
			return storage ? JSON.parse(storage) : undefined;
		}
		return undefined;
	}

	private getAffiliateKeyFromURL(): number | undefined {
		return parseInt(this.getParams()?.['ak']) || undefined;
	}

	private getAffiliateStyleProfile(): number | undefined {
		const params = this.getParams();
		const paramValue = params['styleprofile'] || params['sp'];
		return parseInt(paramValue) || this.getStorage()?.['styleProfile'];
	}

	private getAffiliateContentProfile(): number | undefined {
		const params = this.getParams();
		const paramValue = params['contentprofile'] || params['cp'];
		return parseInt(paramValue) || this.getStorage()?.['contentProfile'];
	}

	private getAffiliateVersion(): string | undefined {
		const params = this.getParams();
		const paramValue = params['affiliateversion'];
		return paramValue || this.getStorage()?.['affiliateVersion'];
	}

	private fetchAffiliateKeyFromCookie(key: string): number | undefined {
		const affiliateKey = this.cookieService.getCookie(key);
		const numericKey = Number(affiliateKey);

		return isNaN(numericKey) ? undefined : numericKey;
	}
}
