import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { APIGetProfileRequestParams } from '@sunny-cars/provider-account-service';
import { SourceType } from '@sunny-cars/util-global';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import { Observable } from 'rxjs';
import * as AccountActions from './account.actions';
import * as AccountSelectors from './account.selectors';

/**
 * Account facade
 */
@Injectable({
	providedIn: 'root',
})
export class AccountFacade {
	accountBlockedErrorCode = AccountSelectors.accountBlockedErrorCode;

	/**
	 * Account identifier
	 */
	account$: Observable<AccountData | null> = this.store.pipe(
		select(AccountSelectors.getAccount),
	);
	/**
	 * Whether account is loading
	 */
	loading$: Observable<boolean> = this.store.pipe(
		select(AccountSelectors.getAccountLoading),
	);
	/**
	 * Whether account is loaded
	 */
	loaded$: Observable<boolean> = this.store.pipe(
		select(AccountSelectors.getAccountLoaded),
	);
	/**
	 * Account load error
	 */
	error$: Observable<string | null> = this.store.pipe(
		select(AccountSelectors.getAccountError),
	);

	constructor(private readonly store: Store) {}

	/**
	 * Get account info
	 * @param {APIGetProfileRequestParams} params
	 * @returns {void}
	 */
	getAccount(params: APIGetProfileRequestParams): void {
		this.store.dispatch(AccountActions.loadAccount({ params }));
	}

	/**
	 * Update account info
	 * @param {AccountData} account
	 * @returns {void}
	 */
	updateAccount(
		account: AccountData,
		affiliateKey: number,
		source: SourceType,
	): void {
		this.store.dispatch(
			AccountActions.updateAccount({ account, affiliateKey, source }),
		);
	}

	/**
	 * Clear the error state
	 * @returns {void}
	 */
	clearError(): void {
		this.store.dispatch(AccountActions.clearError());
	}

	/**
	 * Clear the error state
	 * @returns {void}
	 */
	clear(): void {
		this.store.dispatch(AccountActions.clear());
	}
}
