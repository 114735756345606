import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import Crawlers from 'crawler-user-agents';

@Injectable({
	providedIn: 'root',
})
export class CrawlerHelper {
	private readonly crawlers = Crawlers;

	constructor(@Inject(PLATFORM_ID) public platformId: string) {}

	isCrawler(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const userAgent = window.navigator.userAgent;

			return this.isCrawlerAgent(userAgent);
		}
		return false;
	}

	private isCrawlerAgent(userAgent: string): boolean {
		return this.crawlers.some((crawler) => {
			const pattern = new RegExp(crawler.pattern);

			return pattern.test(userAgent);
		});
	}
}
