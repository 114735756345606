import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PasswordInputComponent } from '@sunny-cars/ui/lib/password-input/password-input.component';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { PasswordStrengthIndicatorComponent } from '@sunny-cars/ui/lib/password-strength-indicator/password-strength-indicator.component';

@Component({
	selector: 'formly-field-new-password',
	templateUrl: 'new-password.component.html',
	standalone: true,
	imports: [
		PasswordInputComponent,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
		PasswordStrengthIndicatorComponent,
	],
})
export class NewPasswordComponent extends FieldType<FieldTypeConfig> {
	/**
	 * Get form values to use for password strength check
	 */
	getFormValues(): string[] {
		if (!this.field.id) {
			return [];
		}

		// istanbul ignore next
		// eslint-disable-next-line @typescript-eslint/no-unused-vars
		const { [this.field.id]: _, ...newModel } = this.model;

		return Object.values(newModel).filter(
			(value) => typeof value === 'string',
		) as string[];
	}
}
