import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

/**
 * The old application sometimes needed a hard reload which was achieved by adding a query parameter to the url. This has now been turned into an easteregg.
 */
@Injectable({
	providedIn: 'root',
})
export class EasterHelper {
	constructor(@Inject(PLATFORM_ID) public platformId: string) {}

	isEaster(): boolean {
		if (isPlatformBrowser(this.platformId)) {
			const params = Object.fromEntries(
				new URLSearchParams(window.location.search).entries(),
			);
			if (params && params['app.reload'] === 'w@reload') {
				return true;
			}
		}
		return false;
	}
}
