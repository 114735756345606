import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { InfoItemsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/info-items.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { BoxComponent } from '../box/box.component';
import { CardComponent } from '../card/card.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-info-items',
	templateUrl: './info-items.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		CollectionComponent,
		BoxComponent,
		CardComponent,
	],
})
export class InfoItemsComponent {
	@Input() content?: InfoItemsComponentInterface;
	@Input() isElevated = false;
	readonly headingLevels = HeadingLevels;
}
