import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, Optional, PLATFORM_ID } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { ApmService } from '@elastic/apm-rum-angular';
import { TranslateService } from '@ngx-translate/core';
import { AccountFacade } from '@sunny-cars/data-access-account';
import { ContactDetailsFacade } from '@sunny-cars/data-access-contact-details';
import { SessionFacade } from '@sunny-cars/data-access-session';
import { FooterComponent } from '@sunny-cars/ui/lib/footer/footer.component';
import { HeaderComponent } from '@sunny-cars/ui/lib/header/header.component';
import {
	HeaderOmitButton,
	LocaleHelper,
	Menu,
	PageTypes,
	filterUndefined,
} from '@sunny-cars/util-global';
import { ConvertExperiencesService } from '@sunny-cars/util-global/lib/helpers/convert-experiences/convert-experiences.service';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';
import { combineLatest, of } from 'rxjs';
import { PageDataService } from './services/page-data/page-data.service';

@Component({
	selector: 'content-root',
	templateUrl: './app.component.html',
	standalone: true,
	imports: [CommonModule, RouterOutlet, HeaderComponent, FooterComponent],
})
export class AppComponent {
	isB2B = false;
	readonly logoRedirectPath$ = of('/');
	availableLanguages: string[] =
		this.environmentService.getDomainConfig().language.supported;
	language = this.environmentService.getDomainConfig().language.default;
	readonly expertrecId =
		this.environmentService.getDomainConfig().expertrec?.apiKey;
	headerOmitButtons: HeaderOmitButton[] = ['home'];
	menus: Menu[] = [];

	readonly openingHoursLoaded$ = this.contactDetailsFacade.loaded$;
	readonly todaysOpeningTimes$ = this.contactDetailsFacade.todaysOpeningTimes$;
	readonly siteData$ = this.pageDataService.siteData$;
	readonly pageData$ = this.pageDataService.pageData$;
	readonly multilevelMenu$ = this.pageDataService.multilevelMenuData$;

	constructor(
		private readonly translateService: TranslateService,
		private readonly environmentService: EnvironmentService,
		private readonly contactDetailsFacade: ContactDetailsFacade,
		private readonly sessionStore: SessionFacade,
		private readonly accountStore: AccountFacade,
		private readonly localeHelper: LocaleHelper,
		private readonly pageDataService: PageDataService,
		private readonly apmService: ApmService,
		private readonly convertExperiencesService: ConvertExperiencesService,
		@Inject(PLATFORM_ID) public platformId: string,
		@Optional()
		@Inject('isB2B')
		private readonly envIsB2B?: boolean,
	) {
		this.translateService.setDefaultLang(
			this.environmentService.getDomainConfig().language.default,
		);

		this.init();

		this.isB2B = !!this.envIsB2B;
	}

	init() {
		const apmConfig = this.environmentService.getDomainConfig().apm;
		/* istanbul ignore next */
		if (apmConfig) {
			const apm = this.apmService.init({
				serviceName: 'content',
				serverUrl: apmConfig.url,
				serviceVersion: this.environmentService.config.version,
				environment: apmConfig.environment,
				breakdownMetrics: true,
				distributedTracingOrigins: apmConfig.origins,
			});
			apm.addLabels(apmConfig.labels);
		}

		const convertConfig = this.environmentService.getDomainConfig().convert;
		/* istanbul ignore next */
		if (convertConfig) {
			this.convertExperiencesService.init(convertConfig.apiKey);
		}

		this.localeHelper.onLanguageChange.subscribe((lang: string) => {
			this.language = lang;
		});

		combineLatest([this.siteData$, this.multilevelMenu$]).subscribe(
			([siteData, multiLevelMenu]) => {
				this.menus =
					multiLevelMenu && siteData
						? [...siteData.menus, multiLevelMenu]
						: siteData
							? siteData.menus
							: [];
			},
		);

		if (isPlatformBrowser(this.platformId)) {
			this.sessionStore.sessionID$.pipe(filterUndefined).subscribe(() => {
				this.accountStore.getAccount({
					affiliatekey: this.environmentService.getDomainConfig().affiliateKey,
					source: this.environmentService.getDomainConfig().source,
				});
			});
		}

		this.pageData$.subscribe((pageData) => {
			this.availableLanguages = this.environmentService
				.getDomainConfig()
				.language.supported.filter((language) => {
					if (
						!pageData ||
						!pageData?.locale?.languageUri.slice(-2) ||
						language === pageData?.locale?.languageUri.slice(-2) ||
						pageData?.translations?.find(
							(item) => item.languageUri.slice(-2) === language,
						)
					) {
						return true;
					}
					return false;
				});

			let omitButtons: HeaderOmitButton[] = ['home'];
			if (pageData?.type !== PageTypes.HOME_PAGE) {
				omitButtons = [];
			}
			this.headerOmitButtons = omitButtons;
		});
	}
}
