import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import { Sizes } from '@sunny-cars/util-global';
import { LinkInterface } from '@sunny-cars/util-global/lib/interfaces/content/link-type.interface';
import { Icon } from '@sunny-cars/util-global/lib/interfaces/icon/icon.type';
import { IconComponent } from '../icon/icon.component';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-card',
	templateUrl: './card.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, RouterLink, ImageComponent, IconComponent],
})
export class CardComponent {
	@Input() avatarImageUrl = '';
	@Input() elevated = false;
	@Input() isHorizontal = false;
	@Input() imageUrl = '';
	@Input() imageAlt = '';
	@Input() imageWidth?: number;
	@Input() imageHeight?: number;
	@Input() icon: Icon | undefined;
	@Input() link?: LinkInterface;
	@Input() showButton = false;
	@Input() title = '';
	@Input() subtitle = '';
	@Input() text = '';
	@Input() useMaxWidth = true;
	@Input() useFullHeight = false;
	sizes = Sizes;
}
