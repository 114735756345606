import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	Output,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

/**
 * Available notification types
 */
export enum NotificationTypes {
	INFO = 'info',
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error',
	HIGHLIGHT = 'highlight',
}

@Component({
	selector: 'ui-notification',
	templateUrl: './notification.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class NotificationComponent {
	@Input() type: NotificationTypes = NotificationTypes.INFO;
	@Input() showIcon = true;
	/**
	 * Tailwind class for custom icon
	 */
	@Input() iconName = '';
	@Input() title = '';
	@Input() isDismissable = false;
	@Input() isCentered = true;
	@Output() dismissed: EventEmitter<void> = new EventEmitter<void>();

	types = NotificationTypes;

	constructor(private readonly element: ElementRef) {}

	/**
	 * Remove the notification element completely
	 * @returns {void}
	 */
	removeNotification(): void {
		this.dismissed.emit();

		// It's not easy to mock elementRef, therefore we ignore this
		/* istanbul ignore next */
		const element: HTMLElement = this.element.nativeElement;
		/* istanbul ignore next */
		element.parentNode?.removeChild(element);
	}
}
