import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Sizes } from '@sunny-cars/util-global';
import { ImageInterface } from '@sunny-cars/util-global/lib/interfaces/content/image-type.interface';
import { VisualUspItem } from '@sunny-cars/util-global/lib/interfaces/content/visual-usps.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { IconComponent } from '../icon/icon.component';
import { ImageComponent } from '../image/image.component';

@Component({
	selector: 'ui-visual-usps',
	templateUrl: 'visual-usps.component.html',
	standalone: true,
	imports: [CommonModule, ImageComponent, IconComponent],
})
export class VisualUspsComponent {
	@Input() image?: ImageInterface;
	@Input() heading?: string;
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() subheading?: string;
	@Input() hasIcon? = true;
	@Input() usps?: VisualUspItem[];
	sizes = Sizes;
}
