import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'ui-social-media',
	templateUrl: './social-media.component.html',
	standalone: true,
	imports: [CommonModule, IconComponent],
})
export class SocialMediaComponent {
	@Input() color = 'white';
	@Input() twitter!: string;
	@Input() facebook!: string;
	@Input() instagram!: string;
	@Input() vimeo!: string;
	@Input() blog!: string;
}
