import { InjectionToken } from '@angular/core';
import {
	CookieStorageService,
	LocalStorageService,
	SessionStorageService,
} from '@sunny-cars/provider-storage';

export const CONTACT_DETAILS_META_REDUCERS = new InjectionToken<string[]>(
	'contactDetailsStoreMetaReducers',
);

export const CONTACT_DETAILS_STORAGE_KEY = new InjectionToken<string[]>(
	'contactDetailsStoreKey',
);

export const CONTACT_DETAILS_STORE_KEYS = new InjectionToken<string[]>(
	'contactDetailsStoreKeys',
);

export const EXPIRES_DATE_TIME_STORE_KEYS = new InjectionToken<string[]>(
	'expiresDateTimeStoreKeys',
);

export const CONTACT_DETAILS_STORE_TYPE_SERVICE = new InjectionToken<
	SessionStorageService | LocalStorageService | CookieStorageService
>('sessionStoreTypeService');

export const CONTACT_DETAILS_STORE_TTL = new InjectionToken<number>(
	'contactDetailsStoreTTL',
);
