import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FieldWrapper } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	selector: 'formly-wrapper-heading',
	template: `<label *ngIf="props.label" class="text-xl font-bold sm:text-2xl">{{
		props['label'] | translate
	}}</label>`,
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class HeadingWrapperComponent extends FieldWrapper {}
