/**
 * Sunny Cars Platform BFF
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: development@aanzee.nl
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export type FontAPI =
	| 'CALIBRI'
	| 'VERDANA'
	| 'HELVETICA_NEUE'
	| 'GEORGIA'
	| 'OPEN_SANS'
	| 'MONTSERRAT';

export const FontAPI = {
	Calibri: 'CALIBRI' as FontAPI,
	Verdana: 'VERDANA' as FontAPI,
	HelveticaNeue: 'HELVETICA_NEUE' as FontAPI,
	Georgia: 'GEORGIA' as FontAPI,
	OpenSans: 'OPEN_SANS' as FontAPI,
	Montserrat: 'MONTSERRAT' as FontAPI,
};
