<div
	uiContrastText
	class="relative inline-block"
	[ngClass]="wrapperStyleModifiers"
>
	<button
		#trigger
		type="button"
		class="text-left"
		[ngClass]="styleModifiers"
		[attr.aria-expanded]="_isOpen ? 'true' : 'false'"
		(click)="triggerType === 'CLICK' ? toggle() : ''"
		(mouseover)="triggerType === 'MOUSEOVER' ? toggle() : ''"
		(keydown.escape)="close()"
	>
		{{ triggerLabel }}
		<ng-content select="[customTrigger]"></ng-content>
	</button>
	<div
		#results
		class="popover z-40 overflow-x-hidden bg-white text-black"
		[ngClass]="{
			'invisible absolute': !shouldRenderResults,
			'h-0 overflow-hidden': !_isOpen,
			'sm:overflow-visible': _isOpen,
			'overflow-y-auto': _isOpen && mobileViewUpToLg,
			'sm:rounded-popover sm:shadow-md': !mobileViewUpToLg,
			'popover--mobile-view-up-to-lg lg:rounded-popover lg:shadow-md':
				mobileViewUpToLg,
			'popover--top shadow-lg': mobilePositionTop !== undefined,
			'min-w-[320px]': usePixelMaxWidth,
			'min-w-full': !usePixelMaxWidth
		}"
		data-test-class="popover-results"
		aria-labelledby="popover"
	>
		<ng-container *ngIf="hasTopMenuControls">
			<div
				*ngIf="hasCloseButton"
				class="sticky top-0 z-10 flex justify-end bg-ui-root-transparent pb-2 text-sm font-bold"
			>
				<span class="text-secondary-high" (click)="close()">{{
					'global.dismiss-action' | translate
				}}</span>
			</div>
			<ui-sheet-header
				[title]="title"
				(closed)="close()"
				[ngClass]="{
					'sm:hidden': !mobileViewUpToLg,
					'sm:text-base lg:hidden': mobileViewUpToLg
				}"
			>
			</ui-sheet-header>
			<div
				*ngIf="!mobileViewUpToLg"
				class="popover__arrow"
				data-popper-arrow
			></div>
		</ng-container>
		<div class="px-4 pb-4 sm:pt-4">
			<ng-content></ng-content>
		</div>
	</div>
</div>

<!-- Backdrop (only shows when popover is open on screen < 640px or < 1040px if mobile view is up to lg breakpoint) -->
<div
	class="fixed inset-0 z-30 max-h-sheet-like-xs bg-modal-backdrop xs:max-h-sheet-like"
	[ngClass]="{
		'sm:hidden': !mobileViewUpToLg,
		'lg:hidden': mobileViewUpToLg,
		hidden: !_isOpen
	}"
	(click)="close()"
></div>
