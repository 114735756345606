import { createFeatureSelector, createSelector } from '@ngrx/store';
import { ACCOUNT_FEATURE_KEY, State } from './account.reducer';

export const accountBlockedErrorCode = 'Account blocked';

/**
 * Lookup the 'Account' feature state managed by NgRx
 */
export const getAccountState =
	createFeatureSelector<State>(ACCOUNT_FEATURE_KEY);

/**
 * Select the account identifier
 */
export const getAccount = createSelector(
	getAccountState,
	(state: State) => state.account,
);

/**
 * Select whether the account is loading
 */
export const getAccountLoading = createSelector(
	getAccountState,
	(state: State) => state.isLoading,
);

/**
 * Select whether the account is loaded
 */
export const getAccountLoaded = createSelector(
	getAccountState,
	(state: State) => state.isLoaded,
);

/**
 * Select account error
 */
export const getAccountError = createSelector(
	getAccountState,
	(state: State) => state.error,
);
