import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	NewsletterSettingsResponseAPI,
	NewsletterSubscribeRequestAPI,
	NewsletterSubscribeResponseAPI,
} from '@sunny-cars/util-api-interfaces';
import { SupportedDomains } from '@sunny-cars/util-global';
import { Observable, catchError, map, mergeMap, of, throwError } from 'rxjs';
import { NewsletterSettings } from './interfaces/get-newsletter.interface';
import { SubscribeNewsletterRequestParams } from './interfaces/post-newsletter.interface';

export type SubscribeToNewsletterParams = Omit<
	SubscribeNewsletterRequestParams,
	'domain' | 'source'
>;

@Injectable({
	providedIn: 'root',
})
export class NewsletterService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl') private readonly endpoint: string,
		@Inject('domainSource') private readonly source: string,
		@Inject('countryCode') private readonly domain: SupportedDomains,
	) {}

	getNewsletterSettings(): Observable<NewsletterSettings> {
		return this.http
			.get<NewsletterSettingsResponseAPI>(
				`${this.endpoint}v1/newsletter/settings`,
			)
			.pipe(
				mergeMap((response) => {
					if (response.data && response.data.consent && response.data.version) {
						return of({
							data: {
								consent: response.data.consent,
								version: response.data.version,
							},
						});
					}

					return throwError(
						() => 'Failed parsing newsletter settings response!',
					);
				}),
			);
	}

	subscribeToNewsletter(
		params: SubscribeToNewsletterParams,
	): Observable<boolean> {
		return this.http
			.post<NewsletterSubscribeResponseAPI>(
				`${this.endpoint}v1/newsletter/subscribe`,
				this.parseSubscribeToNewsletterParams(params),
			)
			.pipe(
				map((response) => response.data?.success || false),
				catchError(() => of(false)),
			);
	}

	private parseSubscribeToNewsletterParams(
		params: SubscribeToNewsletterParams,
	): NewsletterSubscribeRequestAPI {
		return {
			source: this.source,
			email: params.email,
			partner: params.partner,
			scriptname: params.scriptname,
			version: params.version,
		};
	}
}
