import UiIconsData from '../../assets/ui-icons.json';

/**
 * Exports icon data
 */
export const UiIcons = UiIconsData;

/**
 * Icon name option types
 */
export type IconKey = keyof typeof UiIcons['icons'];
/**
 * Icon alias option types
 */
export type IconAliasKey = keyof typeof UiIcons['icon-aliases'];
