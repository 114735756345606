import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	OnInit,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeFormats } from '@sunny-cars/util-global/lib/helpers/date-time/date-time.helper';
import { LocaleHelper } from '@sunny-cars/util-global/lib/helpers/locale/locale.helper';
import { ReviewsComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/reviews.interface';
import { DateTimePipe } from '@sunny-cars/util-global/lib/pipes/date-time/date-time.pipe';
import { EkomiLogoComponent } from '../ekomi-logo/ekomi-logo.component';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { IconComponent } from '../icon/icon.component';
import { CollectionComponent } from '../layouts/collection/collection.component';

@Component({
	selector: 'ui-reviews',
	templateUrl: './reviews.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		EkomiLogoComponent,
		CollectionComponent,
		IconComponent,
		TranslateModule,
		DateTimePipe,
	],
})
export class ReviewsComponent implements OnInit {
	@Input() content?: ReviewsComponentInterface;
	readonly dateTimeFormats = DateTimeFormats;

	constructor(readonly localeHelper: LocaleHelper) {}

	ekomiScore = 0;

	ngOnInit(): void {
		this.ekomiScore =
			this.content?.attributes.averageScores.platforms.find(
				(platform) => platform.name === 'Ekomi',
			)?.stars || 0;
	}
}
