import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import {
	SupportedDomainValues,
	SupportedDomainsCapitalized,
} from '../../interfaces/domain/domain.interface';
import { DomainUrlRecord } from '../../interfaces/environment/environment.interface';

@Injectable({
	providedIn: 'root',
})
export class UrlHelper {
	getApplicationUrlForDomainAndLanguage(
		urlRecord: DomainUrlRecord,
		domain: SupportedDomainsCapitalized,
		language = '',
		defaultLanguage = '',
		path = '',
		params: HttpParams | undefined = undefined,
		useDomainInLanguage = true,
	): string {
		const baseUrl = urlRecord[domain] || urlRecord.DEFAULT;
		let queryParams = '';

		if (language && defaultLanguage !== language) {
			if (useDomainInLanguage && domain !== SupportedDomainValues.NL) {
				language = domain.toLowerCase() + language;
			}
			language += '/';
		} else {
			language = '';
		}
		if (path && !path.endsWith('/')) {
			path += '/';
		}
		if (params) {
			queryParams = `?${params
				.toString()
				.split('&')
				.map((item) =>
					item.replace(/^[a-zA-Z0-9]*=/g, (match) => match.toLowerCase()),
				)
				.join('&')}`;
		}

		const urlPath = `${baseUrl}${language}${path}`.toLowerCase();
		return `${urlPath}${queryParams}`;
	}

	static getParamsWithLowerCasedKeys(params?: Params): Params {
		const result: Params = {};
		if (!params) {
			params = Object.fromEntries(
				new URLSearchParams(window.location.search).entries(),
			);
		}
		Object.keys(params).forEach((key) => {
			result[key.toLowerCase()] = (params as Params)[key];
		});
		return result;
	}
}
