import { Inject, Injectable, Optional, SkipSelf } from '@angular/core';
import { SourceType } from '../../interfaces/source/source.interface';
import { Domain, Environment, SupportedDomains } from './environment.interface';
import { EnvironmentToken } from './environment.token';

@Injectable({
	providedIn: 'root',
})
export class EnvironmentService {
	config: Environment;
	countryCode: string;

	constructor(
		@Optional()
		@Inject(EnvironmentToken)
		private readonly environmentData: Environment,
		@Optional() @SkipSelf() parentModule?: EnvironmentService,
	) {
		if (parentModule) {
			console.warn('The environmentService was already initialized');
		}

		this.config = this.environmentData;
		this.countryCode = this.getDomainConfig().countryCode;
	}

	getDomainConfig(domain?: SupportedDomains): Domain {
		return this.config?.domain.config[domain || this.config?.domain.active];
	}

	getSource(): SourceType {
		return this.getDomainConfig().source;
	}

	getSupportedLanguages(): string[] {
		return this.getDomainConfig().language.supported;
	}

	setDomain(domain: SupportedDomains) {
		if (!this.config?.domain) {
			console.warn('No config available. Did you load it in `/main.ts`?');
		} else {
			this.config.domain.active = domain;
		}
	}

	updateCountryCode(value: string) {
		const domain = value.toUpperCase() as SupportedDomains;

		if (Object.values(SupportedDomains).includes(domain)) {
			this.setDomain(domain);
			this.countryCode = this.getDomainConfig().countryCode;
		} else {
			console.error('EnvironmentService updateCountryCode, domain not found');
		}
	}

	addSupportedLanguage(language: string): void {
		if (
			!this.config?.domain.config[
				this.config?.domain.active
			].language.supported.includes(language)
		) {
			this.config?.domain.config[
				this.config?.domain.active
			].language.supported.push(language);
		}
	}
}
