<ng-template #countryEmptyTemplate>
	<div class="bg-highlight-low px-3 py-2">
		{{
			(hasFoundCountries
				? 'components.address-form.no-countries-found-label'
				: 'components.address-form.form.postal-address.country-empty-label'
			) | translate
		}}
	</div>
</ng-template>
<ng-template #countryEntryTemplate let-data="entry" let-selected="isSelected">
	<div
		class="flex items-center border-t-2 border-ui-moderate py-3 pl-3 pr-3"
		[attr.data-value]="data.value"
	>
		<div>
			<span [innerHTML]="data.modifiedLabel || data.label"></span>
		</div>
	</div>
</ng-template>
<ng-template #skeletonTemplate let-index="index">
	<div class="flex items-center border-t-2 border-ui-moderate px-3 py-2">
		<div>
			<ui-icon
				*ngIf="index === 0 && isVisibleLoading"
				classModifiers="block"
				name="icon-loader"
				inlined
				color="ui-critical"
			></ui-icon>
			<span>&nbsp;</span>
		</div>
	</div>
</ng-template>

<!-- Address information -->
<form #internationalAddressForm="ngForm">
	<div class="grid gap-3">
		<div class="grid gap-2" #internationalAddressFormElement>
			<ng-container *ngIf="addressFormOrder$ | async as formOrder">
				<!-- Postal code -->
				<ui-form-control [isFullWidth]="isFullWidth" [id]="formOrder.postalId">
					<div ui-form-control-control class="grid grid-cols-2 gap-3">
						<div class="flex flex-col">
							<label
								class="text-base"
								[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
								ui-form-control-label
								for="postal-code"
							>
								{{
									'components.address-form.form.postal-address.postal-code-label'
										| translate
								}}
							</label>
							<input
								#postalAddress="ngModel"
								required
								class="input"
								id="postal-code"
								data-test-id="international-address-postal-code"
								name="postalCode"
								type="text"
								[(ngModel)]="postalAddressResponse.postalCode"
								[ngClass]="{
									'input--invalid': isSubmitted && postalAddress.errors,
									'input--valid': !postalAddress.errors
								}"
							/>
						</div>
					</div>
					<label
						ui-form-control-feedback
						class="text-sm text-error"
						for="postal-code"
						*ngIf="
							isSubmitted &&
							postalAddress.errors &&
							postalAddress.errors['required']
						"
					>
						{{ 'global.postal-code-required-feedback-text' | translate }}
					</label>
				</ui-form-control>
				<!-- House number -->
				<ui-form-control
					[isFullWidth]="isFullWidth"
					[id]="formOrder.houseNumberId"
					*ngIf="(isLoggedIn$ | async) !== true"
				>
					<div
						ui-form-control-control
						class="grid grid-cols-2 gap-3 sm:flex sm:flex-row"
					>
						<div class="flex flex-col sm:max-w-[136px]">
							<label
								class="text-base"
								[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
								ui-form-control-label
								for="house-number"
							>
								{{
									'components.address-form.form.postal-address.house-number-label'
										| translate
								}}
							</label>
							<input
								#houseNumber="ngModel"
								required
								class="input"
								id="house-number"
								data-test-id="international-address-house-number"
								name="houseNumber"
								type="text"
								[(ngModel)]="postalAddressResponse.houseNumber"
								[ngClass]="{
									'input--invalid': isSubmitted && houseNumber.errors,
									'input--valid': !houseNumber.errors
								}"
							/>
						</div>
						<div class="flex flex-col sm:max-w-xs">
							<!-- Addition -->
							<label
								class="text-base"
								[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
								for="house-number-addition"
							>
								{{
									'components.address-form.form.postal-address.house-number-addition-label'
										| translate
								}}
							</label>
							<input
								#houseNumberAddition="ngModel"
								class="input"
								id="house-number-addition"
								name="houseNumberAddition"
								type="text"
								[(ngModel)]="postalAddressResponse.houseNumberAddition"
							/>
						</div>
					</div>
					<label
						ui-form-control-feedback
						class="text-sm text-error"
						for="house-number"
						*ngIf="
							isSubmitted &&
							houseNumber.errors &&
							houseNumber.errors['required']
						"
					>
						{{ 'global.house-number-required-feedback-text' | translate }}
					</label>
				</ui-form-control>
				<!-- Street -->
				<ui-form-control [isFullWidth]="isFullWidth" [id]="formOrder.street">
					<label
						class="text-base"
						[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
						ui-form-control-label
						for="street"
						*ngIf="(isLoggedIn$ | async) !== true"
					>
						{{
							'components.address-form.form.postal-address.street-label'
								| translate
						}}
					</label>
					<label
						class="text-base"
						[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
						ui-form-control-label
						for="street"
						*ngIf="(isLoggedIn$ | async) === true"
					>
						{{
							'components.address-form.form.postal-address.street-with-house-number-label'
								| translate
						}}
					</label>
					<input
						ui-form-control-control
						required
						#street="ngModel"
						class="input"
						id="street"
						data-test-id="international-address-street"
						name="street"
						type="text"
						[(ngModel)]="postalAddressResponse.street"
						[ngClass]="{
							'input--invalid': isSubmitted && street.errors,
							'input--valid': !street.errors,
							'bg-ui-low bg-icon-loader-ui-critical bg-icon bg-left-3-center bg-no-repeat':
								isLoading && !street.value
						}"
						[disabled]="isLoading && !street.value"
					/>
					<label
						ui-form-control-feedback
						class="text-sm text-error"
						for="street"
						*ngIf="isSubmitted && street.errors && street.errors['required']"
					>
						{{ 'global.street-required-feedback-text' | translate }}
					</label>
				</ui-form-control>
				<!-- City -->
				<ui-form-control [isFullWidth]="isFullWidth" [id]="formOrder.city">
					<label
						class="text-base"
						[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
						ui-form-control-label
						for="city"
					>
						{{
							'components.address-form.form.postal-address.city-label'
								| translate
						}}
					</label>
					<input
						ui-form-control-control
						required
						#city="ngModel"
						class="input"
						id="city"
						data-test-id="international-address-city"
						name="city"
						type="text"
						[(ngModel)]="postalAddressResponse.city"
						[ngClass]="{
							'input--invalid': isSubmitted && city.errors,
							'input--valid': !city.errors,
							'bg-ui-low bg-icon-loader-ui-critical bg-icon bg-left-3-center bg-no-repeat':
								isLoading && !city.value
						}"
						[disabled]="isLoading && !city.value"
					/>
					<label
						ui-form-control-feedback
						class="text-sm text-error"
						for="city"
						*ngIf="isSubmitted && city.errors && city.errors['required']"
					>
						{{ 'global.city-required-feedback-text' | translate }}
					</label>
				</ui-form-control>
			</ng-container>
		</div>
		<!-- Country -->
		<ui-form-control [isFullWidth]="isFullWidth">
			<label
				class="text-base"
				[ngClass]="hasBoldLabels ? 'font-bold' : 'font-normal'"
				ui-form-control-label
				for="country"
			>
				{{
					'components.address-form.form.postal-address.country-label'
						| translate
				}}
			</label>
			<ui-autocomplete
				#countryAutoCompleteElement
				#country="ngModel"
				controlId="country"
				data-test-id="international-address-country"
				id="country"
				name="country"
				ui-form-control-control
				required
				(clear)="clearCountry()"
				(selectedEntryChange)="
					selectedCountryEntryChanged(mapNumberToString($event))
				"
				(searchChange)="updateFilteredEntries($event)"
				[(ngModel)]="postalAddressResponse.country"
				[emptyTemplate]="countryEmptyTemplate"
				[entries]="filteredCountryOptions"
				[entryTemplate]="countryEntryTemplate"
				[isInvalid]="!!isSubmitted && postalAddressResponse.country === ''"
				[isValid]="postalAddressResponse.country !== ''"
				[skeletonTemplate]="skeletonTemplate"
				[title]="'global.country' | translate"
			>
			</ui-autocomplete>
			<label
				ui-form-control-feedback
				class="text-sm text-error"
				for="country"
				*ngIf="isSubmitted && postalAddressResponse.country === ''"
			>
				{{ 'global.country-required-feedback-text' | translate }}
			</label>
		</ui-form-control>
	</div>
</form>
