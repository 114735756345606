import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate if an email is valid
 * @returns {ValidatorFn}
 */
export function emailValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const emailValue = control.value;
		let isValidEmail = true;

		if (!emailValue || !isValidEmailTest(emailValue)) {
			isValidEmail = false;
		}

		return isValidEmail ? null : { email: true };
	};
}

export function isValidEmailTest(email: string): boolean {
	// eslint-disable-next-line no-useless-escape, no-control-regex
	return /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(
		email,
	);
}
