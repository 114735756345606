<div
	class="sticky top-0 z-10 flex min-h-popover-header items-center justify-between bg-ui-root-transparent text-sm font-bold"
>
	<div>
		<button
			class="inline-flex items-center gap-1 text-text-tertiary"
			*ngIf="level > 1"
			(click)="backAction()"
		>
			<ui-icon
				classModifiers="ml-2"
				color="text-tertiary"
				inlined
				sm
				name="icon-chevron-left-light"
			></ui-icon>
			{{ 'global.back-action' | translate }}
		</button>
	</div>
	<ui-sheet-header (closed)="close()"> </ui-sheet-header>
</div>
<!-- Menu level 1 items -->
<div
	class="divide-y divide-ui-moderate border-b border-ui-moderate"
	[ngClass]="{ 'border-t': level === 1 }"
>
	<ng-container *ngFor="let item of items; let level1Index = index">
		<ng-container *ngIf="item.type !== menuItemTypes.DROPDOWN">
			<ng-container
				*ngTemplateOutlet="
					linkElement;
					context: {
						item: item,
						hiddenClass: level !== 1 ? 'main-menu-mobile__item--hidden' : ''
					}
				"
			></ng-container>
		</ng-container>
		<ng-container *ngIf="item.type === menuItemTypes.DROPDOWN">
			<div
				[class]="linkClasses"
				[ngClass]="{ 'main-menu-mobile__item--hidden': level !== 1 }"
				(click)="showItems(2, level1Index)"
			>
				{{ item.name }}
				<ui-icon
					classModifiers="ml-2"
					inlined
					sm
					name="icon-chevron-right-light"
				></ui-icon>
			</div>
			<!-- Menu level 2 items -->
			<ng-container *ngIf="item.children?.length">
				<ng-container
					*ngFor="let level2item of item.children; let level2Index = index"
				>
					<div
						[class]="linkClasses"
						[ngClass]="{
							'main-menu-mobile__item--hidden':
								!level2item.children ||
								level !== 2 ||
								activeLevel1Index !== level1Index
						}"
						(click)="showItems(3, level2Index)"
					>
						{{ level2item.name }}
						<ui-icon
							classModifiers="ml-2"
							inlined
							sm
							name="icon-chevron-right-light"
						></ui-icon>
					</div>
					<ng-container
						*ngTemplateOutlet="
							linkElement;
							context: {
								item: level2item,
								hiddenClass:
									level2item.children ||
									level !== 2 ||
									activeLevel1Index !== level1Index
										? 'main-menu-mobile__item--hidden'
										: ''
							}
						"
					></ng-container>
					<!-- Menu level 3 items -->
					<ng-container *ngIf="level2item.children?.length">
						<ng-container
							*ngFor="
								let level3item of level2item.children;
								let level3Index = index
							"
						>
							<ng-container
								*ngTemplateOutlet="
									linkElement;
									context: {
										item: level3item,
										hiddenClass:
											level !== 3 ||
											activeLevel1Index !== level1Index ||
											activeLevel2Index !== level2Index
												? 'main-menu-mobile__item--hidden'
												: ''
									}
								"
							></ng-container>
						</ng-container>
					</ng-container>
					<!-- Call to action -->
					<ng-container *ngIf="level2item.extra?.callToAction">
						<ng-container
							*ngTemplateOutlet="
								callToAction;
								context: {
									item: level2item.extra?.callToAction,
									hiddenClass:
										level !== 3 ||
										activeLevel1Index !== level1Index ||
										activeLevel2Index !== level2Index
											? 'main-menu-mobile__item--hidden'
											: ''
								}
							"
						></ng-container>
					</ng-container>
				</ng-container>
			</ng-container>
		</ng-container>
	</ng-container>
</div>

<ng-template #linkElement let-item="item" let-hiddenClass="hiddenClass">
	<a
		*ngIf="item.type === menuItemTypes.STATIC"
		[class]="linkClasses + ' ' + hiddenClass"
		[href]="item.value"
		[target]="item.target"
		(click)="close()"
	>
		{{ item.name }}
	</a>
	<a
		*ngIf="item.type === menuItemTypes.PAGE"
		[class]="linkClasses + ' ' + hiddenClass"
		[routerLink]="item.value"
		(click)="close()"
	>
		{{ item.name }}
	</a>
	<div
		*ngIf="item.type === menuItemTypes.TEXT"
		[class]="'text-base relative whitespace-nowrap px-2.5 py-3 ' + hiddenClass"
		[innerHtml]="item.name"
	></div>
</ng-template>

<ng-template #callToAction let-item="item" let-hiddenClass="hiddenClass">
	<div [class]="hiddenClass ? noDividerClasses + ' ' + zeroHeightClasses : ''">
		<div [class]="hiddenClass ? zeroHeightClasses : 'flex justify-end mt-5'">
			<a
				*ngIf="item.type === linkTypes.RELATIVE"
				[class]="callToActionClasses + ' ' + hiddenClass"
				[routerLink]="item.url"
				(click)="close()"
			>
				{{ item.name }}
			</a>
			<a
				*ngIf="item.type === linkTypes.ABSOLUTE"
				[class]="callToActionClasses + ' ' + hiddenClass"
				[href]="item.url"
				(click)="close()"
			>
				{{ item.name }}
			</a>
		</div>
	</div>
</ng-template>
