import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { PageEntityInterface } from '@sunny-cars/provider-cms-service/lib/page/interfaces/page.interface';
import { PageService } from '@sunny-cars/provider-cms-service/lib/page/page.service';
import { SiteEntityInterface } from '@sunny-cars/provider-cms-service/lib/site/interfaces/parsed/site.interface';
import { SiteService } from '@sunny-cars/provider-cms-service/lib/site/site.service';
import { PromotionCodeDetailService } from '@sunny-cars/provider-promotion-code-service';
import {
	AffiliateKeyService,
	DeepLinkParams,
	DeepLinkParamsService,
} from '@sunny-cars/util-global';
import { Menu } from '@sunny-cars/util-global/lib/interfaces/menu/menu.interface';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';
import { BehaviorSubject } from 'rxjs';
import { ContactDetailsStateService } from '../contact-details/contact-details-state.service';

export interface QueryParamData extends DeepLinkParams {
	slug: string;
	language: string;
	previewKey?: string;
}

@Injectable({
	providedIn: 'root',
})
export class PageDataService {
	private _siteData$ = new BehaviorSubject<SiteEntityInterface | undefined>(
		undefined,
	);
	private _pageData$ = new BehaviorSubject<PageEntityInterface | undefined>(
		undefined,
	);
	private _multilevelMenuData$ = new BehaviorSubject<Menu | undefined>(
		undefined,
	);
	private _currentSiteLanguage?: string;
	private _currentMenuLanguage?: string;
	private _currentContactLanguage?: string;
	params: QueryParamData = {
		skipRedirect: false,
		isNonAffiliate: false,
		slug: '',
		language: '',
	};

	siteData$ = this._siteData$.asObservable();
	pageData$ = this._pageData$.asObservable();
	multilevelMenuData$ = this._multilevelMenuData$.asObservable();

	constructor(
		private readonly siteService: SiteService,
		private readonly pageService: PageService,
		private readonly deepLinkService: DeepLinkParamsService,
		private readonly promoCodeDetailService: PromotionCodeDetailService,
		private readonly contactDetailsStateService: ContactDetailsStateService,
		private readonly affiliateKeyService: AffiliateKeyService,
		private readonly environmentService: EnvironmentService,
		@Inject(PLATFORM_ID) private platformId: string,
	) {}

	parseParams(
		activeRoute: ActivatedRouteSnapshot,
		isDestinationPage?: boolean,
	): QueryParamData {
		const previewKey = activeRoute.queryParams['previewKey'] || undefined;
		const deeplinkParams = this.deepLinkService.parseParams(
			activeRoute.queryParams,
			isDestinationPage,
		);
		if (
			deeplinkParams.affiliateKey &&
			this.affiliateKeyService.isValidAffiliateKey(deeplinkParams.affiliateKey)
		) {
			this.affiliateKeyService.updateAffiliateCookie(
				deeplinkParams.affiliateKey,
			);
		}

		if (isPlatformBrowser(this.platformId)) {
			this.deepLinkService.handleParams(
				deeplinkParams,
				this.environmentService.getDomainConfig().language.supported,
				this.promoCodeDetailService.isPromoCodeValid$,
			);
		}

		let languageFromPath = activeRoute.url[0]?.path;
		if (languageFromPath?.length === 4) {
			languageFromPath = languageFromPath.slice(-2);
		}
		const isLanguageInPath =
			!!activeRoute.url.length &&
			this.environmentService
				.getDomainConfig()
				.language.supported.includes(languageFromPath);
		const language = isLanguageInPath
			? languageFromPath
			: this.environmentService.getDomainConfig().language.default;

		const slug = activeRoute.url
			.slice(isLanguageInPath ? 1 : 0)
			.map((segment) => segment.path)
			.join('/');

		this.params = {
			...deeplinkParams,
			language: deeplinkParams.language || language,
			previewKey,
			slug,
			isNonAffiliate: true,
		};
		return this.params;
	}

	fetchSiteData(language: string): void {
		if (this._currentSiteLanguage === language) {
			return;
		}
		this._currentSiteLanguage = language;

		this.siteService.getSite(language).subscribe((response) => {
			this._siteData$.next(response);
		});
	}

	fetchMenuData(language: string): void {
		if (this._currentMenuLanguage === language) {
			return;
		}
		this._currentMenuLanguage = language;

		this.pageService.getMultilevelMenu().subscribe((response) => {
			this._multilevelMenuData$.next(response);
		});
	}

	fetchContactData(language: string): void {
		if (this._currentContactLanguage === language) {
			return;
		}
		this._currentContactLanguage = language;

		this.contactDetailsStateService.init();
	}

	setPageData(data: PageEntityInterface | undefined): void {
		this._pageData$.next(data);
	}
}
