import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { ProfileService } from './profile.service';

/**
 * Profile provider module
 */
@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [ProfileService],
})
export class ProfileProviderModule {}
