import { createAction, props } from '@ngrx/store';
import {
	Affiliate,
	AffiliateConfiguration,
	ErrorMessageResponse,
	RecursivePartial,
	SourceType,
} from '@sunny-cars/util-global';

export const init = createAction('[Affiliate] Init');

export const updateAffiliate = createAction(
	'[Affiliate/API] Update Affiliate',
	props<{
		source: SourceType;
		deploy: boolean;
		affiliate: Affiliate;
	}>(),
);

export const updateAffiliateSuccess = createAction(
	'[Affiliate/API] Update Affiliate Success',
	props<{ affiliate: Affiliate; deploy: boolean }>(),
);

export const updateAffiliateFailure = createAction(
	'[Affiliate/API] Update Affiliate Failure',
	props<{ errors: ErrorMessageResponse[]; deploy: boolean }>(),
);

/**
 * Update local affiliate store state without patching it externally
 */
export const updateLocalAffiliateState = createAction(
	'[Affiliate] Update Local Affiliate Store State',
	props<{ affiliateConfig: RecursivePartial<AffiliateConfiguration> }>(),
);

/**
 * Load
 */
export const loadAffiliate = createAction(
	'[Affiliate/API] Load Affiliate',
	props<{
		affiliateKey: number;
		styleProfile?: number;
		contentProfile?: number;
		source: SourceType;
		version?: string;
	}>(),
);

export const loadAffiliateSuccess = createAction(
	'[Affiliate/API] Load Affiliate Success',
	props<{ affiliate: Affiliate }>(),
);
export const loadAffiliateFailure = createAction(
	'[Affiliate/API] Load Affiliate Failure',
	props<{ errors: ErrorMessageResponse[] }>(),
);

export const clearError = createAction('[Affiliate] Clear Error');

export const clear = createAction('[Affiliate] Clear');
