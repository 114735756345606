import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

@Component({
	selector: 'formly-field-input',
	templateUrl: 'input.component.html',
	standalone: true,
	imports: [CommonModule, FormsModule, ReactiveFormsModule, FormlyModule],
})
export class InputComponent
	extends FieldType<FieldTypeConfig>
	implements OnInit
{
	ngOnInit(): void {
		if (this.props['forceDisabled']) {
			this.formControl.disable();

			this.formControl.statusChanges.subscribe((status) => {
				if (status !== 'DISABLED') {
					// Wait one tick to make sure disable is run after formly enables fields
					setTimeout(() => {
						this.formControl.disable();
					});
				}
			});
		}
	}
}
