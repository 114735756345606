import { HeadingLevels } from '../heading-levels.interface';
import { ContentLayoutTypes } from '../page-content-layout-types.interface';
import { LayoutSettings } from './layout-settings-type.interface';

export interface FormComponentInterface {
	attributes: {
		layoutSettings: LayoutSettings;
		formId: FormType;
		heading: string;
		headingType: HeadingLevels;
	};
	layout: ContentLayoutTypes.FORM;
}

export enum FormTypes {
	MY_SUNNY_ACCOUNT = 'MY_SUNNY_ACCOUNT',
	CONTACT = 'CONTACT',
}

export type FormType = FormTypes.MY_SUNNY_ACCOUNT | FormTypes.CONTACT;
