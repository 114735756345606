import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
	ContactDetailsService,
	ContactDetailsWithExpiration,
} from '@sunny-cars/provider-contact-details-service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as ContactDetailsActions from './contact-details.actions';

@Injectable()
export class ContactDetailsEffects implements OnInitEffects {
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ContactDetailsActions.loadContactDetails),
			switchMap((action) => {
				return this.contactDetailsService.getContactDetails(action.params).pipe(
					map((response: ContactDetailsWithExpiration) => {
						if (response.expiration) {
							return ContactDetailsActions.loadContactDetailsSuccess({
								contactDetails: response,
								expires: response.expiration,
							});
						}
						return ContactDetailsActions.loadContactDetailsFailure({
							error: 'No data received',
						});
					}),
					catchError(() => {
						return of(
							ContactDetailsActions.loadContactDetailsFailure({
								error: 'Request failed',
							}),
						);
					}),
				);
			}),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly contactDetailsService: ContactDetailsService,
	) {}

	/**
	 * Handle init effect
	 */
	ngrxOnInitEffects(): Action {
		return ContactDetailsActions.init();
	}
}
