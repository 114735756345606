import { HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { SupportedDomainsCapitalized } from '../../interfaces/domain/domain.interface';
import { ApplicationUrlEnvironment } from '../../interfaces/environment/environment.interface';
import { LocaleHelper } from '../locale/locale.helper';
import { UrlHelper } from '../url/url.helper';

/**
 * Helper to build and get base urls
 */
@Injectable({
	providedIn: 'root',
})
export class BaseUrlHelper {
	constructor(
		@Optional()
		@Inject('applicationUrls')
		private readonly applicationUrls: ApplicationUrlEnvironment,
		@Inject('countryCode')
		private readonly countryCode: SupportedDomainsCapitalized,
		private readonly localeHelper: LocaleHelper,
		private readonly urlHelper: UrlHelper,
		@Optional()
		@Inject('envLanguages')
		private readonly envLanguages?: {
			availableLanguages: string[];
			default: string;
		},
	) {}

	getLegacyBaseUrl(
		uri: string | undefined = '',
		options?: { queryParams?: HttpParams },
		isMySunny?: boolean,
	): string {
		return this.urlHelper.getApplicationUrlForDomainAndLanguage(
			isMySunny
				? this.applicationUrls.mySunnyUrl
				: this.applicationUrls.contentUrl,
			this.countryCode,
			this.localeHelper.language,
			this.envLanguages?.default || this.countryCode.toLowerCase(),
			uri,
			options?.queryParams ? options.queryParams : undefined,
		);
	}
}
