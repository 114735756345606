export enum RegionTypes {
	AIRPORT = 'airport',
	CITY = 'city',
	COUNTRY = 'country',
	REGION = 'region',
}

export type RegionType =
	| RegionTypes.AIRPORT
	| RegionTypes.CITY
	| RegionTypes.COUNTRY
	| RegionTypes.REGION;
