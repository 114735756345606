import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	EventEmitter,
	Input,
	OnInit,
	Output,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { FormsModule, NG_VALUE_ACCESSOR, NgModel } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { LocaleHelper } from '@sunny-cars/util-global';
import { SafeHtmlPipe } from '@sunny-cars/util-global/lib/pipes/safe-html/safe-html.pipe';
import CountryList from 'country-list-with-dial-code-and-flag';
import CountryFlagSvg from 'country-list-with-dial-code-and-flag/dist/flag-svg';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import countries from 'i18n-iso-countries';
import isoCountriesDE from 'i18n-iso-countries/langs/de.json';
import isoCountriesEN from 'i18n-iso-countries/langs/en.json';
import isoCountriesFR from 'i18n-iso-countries/langs/fr.json';
import isoCountriesIT from 'i18n-iso-countries/langs/it.json';
import isoCountriesNL from 'i18n-iso-countries/langs/nl.json';
import { BehaviorSubject } from 'rxjs';
import { AutocompleteButtonComponent } from '../autocomplete-button/autocomplete-button.component';
import { AutocompleteEntry } from '../autocomplete/autocomplete.component';
import { BaseControlComponent } from '../base.control';
import { IconComponent } from '../icon/icon.component';

export interface PhonenumberValue {
	formattedPhoneNumber: string;
	phoneNumber: string;
	countryCode: string;
}

@Component({
	selector: 'ui-phonenumber-input',
	templateUrl: './phonenumber-input.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			multi: true,
			useExisting: PhonenumberInputComponent,
		},
	],
	standalone: true,
	imports: [
		CommonModule,
		IconComponent,
		AutocompleteButtonComponent,
		FormsModule,
		SafeHtmlPipe,
		TranslateModule,
	],
})
export class PhonenumberInputComponent
	extends BaseControlComponent
	implements OnInit
{
	@ViewChild('mobilePhoneNumber')
	mobilePhoneNumberInputElement?: NgModel;

	@Input() isSubmitted = false;
	@Input() idSuffix = '';

	@Output() validationStatusChange = new EventEmitter();

	readonly countryList = CountryList.getAll();
	readonly flagSVGs = CountryFlagSvg;

	value: PhonenumberValue = {
		formattedPhoneNumber: '',
		phoneNumber: '',
		countryCode: '',
	};
	countryListFiltered: AutocompleteEntry[] = [];
	countryFullList: AutocompleteEntry[] = [];

	private phoneUtilInstance = PhoneNumberUtil.getInstance();
	private readonly phoneCountryCodeInput$ = new BehaviorSubject<string>('');

	constructor(private readonly localeHelper: LocaleHelper) {
		super();

		countries.registerLocale(isoCountriesNL);
		countries.registerLocale(isoCountriesDE);
		countries.registerLocale(isoCountriesFR);
		countries.registerLocale(isoCountriesEN);
		countries.registerLocale(isoCountriesIT);
	}

	ngOnInit(): void {
		this.setupCountryList();
		this.handleCountryCodeInputChanges();

		this.setupPhoneNumber();
		this.formatPhoneNumber();
	}

	handleCountryCodeInputChanges() {
		this.phoneCountryCodeInput$.subscribe((searchValue) => {
			this.countryListFiltered = this.countryFullList;

			if (searchValue && searchValue.length >= 1) {
				this.countryListFiltered = this.countryFullList.filter(
					(country) =>
						country.label.toLowerCase().includes(searchValue.toLowerCase()) ||
						(country['countryCode'] as string)
							.toString()
							.toLocaleLowerCase()
							.includes(searchValue.toLowerCase()),
				);
			}
		});
	}

	setupPhoneNumber() {
		const destructedPhoneNumber = this.destructMobilePhonenumber(
			this.value.phoneNumber,
			this.value.countryCode,
		);

		this.value.phoneNumber = destructedPhoneNumber.number;
		this.value.countryCode = destructedPhoneNumber.code;
	}

	private destructMobilePhonenumber(
		phonenumber: string,
		defaultCountryCode: string,
	) {
		phonenumber = phonenumber.replace(/[()\s-]/g, '');

		let phoneCountryCode;
		if (!defaultCountryCode.includes('+')) {
			phoneCountryCode = `+${this.phoneUtilInstance
				.getCountryCodeForRegion(defaultCountryCode)
				.toString()}-${defaultCountryCode}`;
		} else {
			phoneCountryCode = defaultCountryCode;
		}

		if (phonenumber && phonenumber.startsWith('+') && phonenumber.length >= 7) {
			const number = this.phoneUtilInstance.parseAndKeepRawInput(phonenumber);

			if (number) {
				const regionCode =
					this.phoneUtilInstance.getRegionCodeForNumber(number);
				const foundCountries = this.countryList.filter((country) => {
					return country.code === regionCode;
				});

				let countryList = foundCountries;
				if (!foundCountries.length) {
					countryList = this.countryList;
				}

				const foundCountry = countryList.find((country) => {
					return phonenumber.startsWith(country.dialCode);
				});

				if (foundCountry) {
					return {
						number: phonenumber.replace(`${foundCountry.dialCode}`, '').trim(),
						code: `${foundCountry.dialCode}-${foundCountry.code}`,
					};
				}
			}
		}
		return { number: phonenumber, code: phoneCountryCode };
	}

	formatPhoneNumber() {
		if (
			this.value.phoneNumber &&
			this.value.countryCode &&
			this.value.phoneNumber.length >= 7 &&
			(!this.mobilePhoneNumberInputElement?.errors ||
				!this.mobilePhoneNumberInputElement?.errors['pattern'])
		) {
			const number = this.phoneUtilInstance.parseAndKeepRawInput(
				this.value.countryCode.split('-')[0] + this.value.phoneNumber,
				this.value.countryCode.split('-')[1],
			);

			const formattedNumber = this.phoneUtilInstance.format(
				number,
				PhoneNumberFormat.INTERNATIONAL,
			);
			this.value.formattedPhoneNumber = formattedNumber;
		} else {
			this.value.formattedPhoneNumber = '';
		}
	}

	setupCountryList() {
		this.countryList.forEach((country) => {
			const countryEntry = {
				label:
					countries.getName(
						country.code,
						this.localeHelper.language.toUpperCase(),
					) || country.name,
				value: country.dialCode + '-' + country.code,
				countryCode: country.code,
				dialCode: country.dialCode,
			};

			this.countryFullList.push(countryEntry);
		});

		this.countryFullList = this.countryFullList.sort((a, b) => {
			if (a.label < b.label) {
				return -1;
			} else if (a.label > b.label) {
				return 1;
			} else if ((a['dialCode'] as string) < (b['dialCode'] as string)) {
				return -1;
			} else if ((a['dialCode'] as string) > (b['dialCode'] as string)) {
				return 1;
			}
			return 0;
		});

		this.countryListFiltered = this.countryFullList;
	}

	handlePhoneCountryCodeSearchInput(value: string) {
		this.phoneCountryCodeInput$.next(value);
	}

	validate() {
		this.validationStatusChange.emit(
			this.mobilePhoneNumberInputElement?.errors,
		);
	}

	handleChange(): void {
		this.formatPhoneNumber();
		this.markAsTouched();
		this.onChange(this.value);
		this.validate();
	}

	override writeValue(value: PhonenumberValue): void {
		this.value = value;

		if (value) {
			this.setupPhoneNumber();
			this.formatPhoneNumber();
			setTimeout(() => {
				this.validate();
			}, 1);
		}
	}
}
