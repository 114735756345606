export type LinkItem = AbsoluteLink | RelativeLink | StaticLink;

export enum LinkTypes {
	TEXT = 'text',
	ABSOLUTE = 'static-url',
	RELATIVE = 'page-link',
}

export interface AbsoluteLink extends Link {
	href: string;
	target: string;
	type: LinkTypes.ABSOLUTE;
}

export interface RelativeLink extends Link {
	href: string;
	type: LinkTypes.RELATIVE;
}

export interface StaticLink extends Link {
	type: LinkTypes.TEXT;
}

interface Link {
	text: string;
	type: LinkTypes;
}

export interface Menu {
	type: MenuTypes;
	items: MenuItem[];
}

export enum MenuTypes {
	MAIN = 'main-navigation',
	FOOTER = 'footer-navigation',
	SUBFOOTER = 'subfooter-navigation',
	PARTNERLINK = 'partnerlink-navigation',
	BOOKING_WIDGET_FOOTER = 'booking-widget-footer-navigation',
	BOOKING_WIDGET_FOOTER_PL = 'booking-widget-footer-navigation-pl',
	CUSTOM = 'custom-navigation',
	MULTILEVEL = 'multilevel-navigation',
	EXITLAYER = 'exit-layer',
}

export interface MenuItem {
	name: string;
	value: string | null;
	target: string;
	type: MenuItemTypes;
	subtitle?: string;
	extra?: MenuItemExtra;
	children?: MenuItem[];
}

export enum MenuItemTypes {
	TEXT = 'text',
	STATIC = 'static-url',
	PAGE = 'page-link',
	DROPDOWN = 'dropdown',
}

export interface MenuItemExtra {
	callToAction?: ActionItem;
	header?: Header;
	categories?: Category[];
	parentCategoryId?: string;
}

export interface Category {
	id: string;
	name: string;
	title: string;
	icon?: string;
	description?: string;
}

export interface Header {
	title: string;
	intro?: string;
	icon?: string;
	image?: string;
}

export interface ActionItem {
	name: string;
	primary?: boolean;
	url: string;
	type: LinkTypes;
}
