import { AfterViewChecked, Directive, ElementRef } from '@angular/core';
import { ContrastTextHelper } from '@sunny-cars/util-global/lib/helpers/contrast-text/contrast-text.helper';

@Directive({
	selector: '[uiContrastText]',
	standalone: true,
})
export class ContrastTextDirective implements AfterViewChecked {
	constructor(private element: ElementRef) {}

	ngAfterViewChecked(): void {
		const contrastColor = ContrastTextHelper.getContrastText(this.element);
		if (contrastColor) {
			this.element.nativeElement.style.color = contrastColor;
		}
	}
}
