import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType, OnInitEffects } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { AuthenticateService } from '@sunny-cars/provider-account-service';
import { Session } from '@sunny-cars/util-global';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import * as SessionActions from './session.actions';
import { accountBlockedErrorCode } from './session.selectors';

/**
 * Session effects
 */
@Injectable()
export class SessionEffects implements OnInitEffects {
	private readonly accountBlockedErrorCode = 'ERROR_ACCOUNT_TEMPORARILY_LOCKED';

	/**
	 * Load effect
	 */
	load$ = createEffect(() =>
		this.actions$.pipe(
			ofType(SessionActions.loadSession),
			switchMap((action) => {
				return this.authenticateService.authenticate(action.body).pipe(
					map((session: Session | undefined) => {
						if (!session?.token) {
							return SessionActions.loadSessionFailure({
								error: 'No token received',
							});
						}

						return SessionActions.loadSessionSuccess({
							id: session.id,
							username: action.body.username,
							sessionID: session.token,
							tokenType: session.type,
							expiresAt: session.expiresAt,
						});
					}),
					catchError((errorResponse) => {
						if (
							errorResponse.error?.errors?.length &&
							errorResponse.error.errors[0].code ===
								this.accountBlockedErrorCode
						) {
							return of(
								SessionActions.loadSessionFailure({
									error: accountBlockedErrorCode,
								}),
							);
						}
						return of(
							SessionActions.loadSessionFailure({
								error: 'Request failed',
							}),
						);
					}),
				);
			}),
		),
	);

	constructor(
		private readonly actions$: Actions,
		private readonly authenticateService: AuthenticateService,
	) {}

	/**
	 * Handle init effect
	 */
	ngrxOnInitEffects(): Action {
		return SessionActions.init();
	}
}
