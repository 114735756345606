import { Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FieldType, FieldTypeConfig, FormlyModule } from '@ngx-formly/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import { PhonenumberInputComponent } from '@sunny-cars/ui/lib/phonenumber-input/phonenumber-input.component';

@Component({
	selector: 'formly-field-phone',
	templateUrl: 'phone.component.html',
	standalone: true,
	imports: [
		PhonenumberInputComponent,
		FormsModule,
		ReactiveFormsModule,
		FormlyModule,
	],
})
export class PhoneComponent extends FieldType<FieldTypeConfig> {}
