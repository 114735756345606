export enum PageTypes {
	CONTENT_PAGE = 'CONTENT_PAGE',
	CONTENT_WIDE_PAGE = 'CONTENT_WIDE_PAGE',
	DESTINATION_PAGE = 'DESTINATION_PAGE',
	ERROR_PAGE = 'ERROR_PAGE',
	HOME_PAGE = 'HOME_PAGE',
	LINKED_PAGE = 'LINKED_PAGE',
	NEWS_PAGE = 'NEWS_PAGE',
	OVERVIEW_PAGE = 'OVERVIEW_PAGE',
	VACANCY_PAGE = 'VACANCY_PAGE',
}
