<!-- Level 2 Item Template -->
<ng-template #levelTwoMenuItem let-item="item" let-index="index">
	<!-- level 2 Item With Children -->
	<button
		*ngIf="item.type === menuItemTypes.DROPDOWN"
		[ngClass]="{
			'bg-white pr-2 font-extrabold text-secondary-critical':
				levelTwoSelected === index
		}"
		class="flex w-full items-center justify-between px-6 py-2 text-black hover:bg-white"
		(click)="openLevelThreeMenu(index)"
	>
		<div
			class="w-3/4 grow pr-2 text-left"
			[ngClass]="{
				'pr-6': levelTwoSelected === index
			}"
		>
			{{ item.name }}
		</div>
		<ui-icon
			*ngIf="levelTwoSelected === index"
			sm
			inlined
			name="icon-chevron-right-bold"
			[color]="'secondary'"
		>
		</ui-icon>
		<ui-icon
			*ngIf="levelTwoSelected !== index"
			sm
			inlined
			name="icon-chevron-right-light"
		>
		</ui-icon>
	</button>
	<!-- level 2 Item Link or text -->
	<a
		*ngIf="item.type === menuItemTypes.PAGE"
		[ngClass]="{
			'font-extrabold text-secondary-critical': levelTwoSelected === index
		}"
		class="flex justify-between px-6 py-2 text-black hover:bg-white"
		[routerLink]="item.value"
		[target]="item.target"
		(click)="close()"
		>{{ item.name }}</a
	>
	<a
		*ngIf="item.type === menuItemTypes.STATIC"
		[ngClass]="{
			'font-extrabold text-secondary-critical': levelTwoSelected === index
		}"
		class="flex justify-between px-6 py-2 text-black hover:bg-white"
		[href]="item.value"
		[target]="item.target"
		(click)="close()"
		>{{ item.name }}</a
	>
	<span
		*ngIf="item.type === menuItemTypes.TEXT"
		class="flex justify-between px-6 py-2 text-black hover:bg-white"
		>{{ item.name }}</span
	>
</ng-template>

<!-- Level 3 Item Template -->
<ng-template
	#levelThreeMenuItem
	let-item="item"
	let-levelTwo="levelTwo"
	let-levelThree="levelThree"
>
	<!-- Relative Link -->
	<a
		*ngIf="item.type === menuItemTypes.PAGE"
		class="block px-4 py-2 hover:bg-ui-low hover:text-secondary"
		[attr.tabindex]="
			levelTwoSelected !== levelTwo || levelOneSelected !== levelThree
				? -1
				: null
		"
		[routerLink]="item.value"
		[target]="item.target"
		(click)="close()"
	>
		{{ item.name }}
	</a>
	<!-- Static Link -->
	<a
		*ngIf="item.type === menuItemTypes.STATIC"
		class="block px-4 py-2 hover:bg-ui-low hover:text-secondary"
		[attr.tabindex]="
			levelTwoSelected !== levelTwo || levelOneSelected !== levelThree
				? -1
				: null
		"
		[href]="item.value"
		[target]="item.target"
		(click)="close()"
	>
		{{ item.name }}
	</a>
	<!-- Text -->
	<span
		*ngIf="item.type === menuItemTypes.TEXT"
		class="block px-4 py-2 hover:bg-ui-low hover:text-secondary"
	>
		{{ item.name }}
	</span>
</ng-template>

<!-- Main Menu -->
<nav
	class="flex flex-wrap items-center bg-header-navigation shadow-nav-menu-inner xs:px-8 pl-2 {{
		wrapperStyleModifiers
	}}"
>
	<ng-container *ngFor="let item of items; let levelOneIndex = index">
		<a
			*ngIf="item.type === menuItemTypes.STATIC"
			class="{{ linkClasses }} flex items-center py-6"
			[href]="item.value"
			[target]="item.target"
			[innerHtml]="item.name"
			(click)="close()"
		></a>
		<a
			*ngIf="item.type === menuItemTypes.PAGE"
			class="{{ linkClasses }} flex items-center py-6"
			[href]="item.value"
			[routerLink]="item.value"
			[innerHtml]="item.name"
			(click)="close()"
		></a>
		<span
			*ngIf="item.type === menuItemTypes.TEXT"
			class="{{ linkClasses }} flex items-center py-6"
			[innerHtml]="item.name"
		></span>
		<button
			*ngIf="item.type === menuItemTypes.DROPDOWN"
			class="{{
				linkClasses
			}} border-b-4 border-solid flex items-center pt-6 pb-5 hover:border-ui-moderate"
			aria-hidden="true"
			[ngClass]="
				levelOneSelected === levelOneIndex
					? 'border-ui-moderate'
					: 'border-transparent'
			"
			(click)="openLevelTwoMenu(levelOneIndex)"
		>
			<span class="mr-2">
				{{ item.name }}
			</span>
			<ui-icon
				xs
				inlined
				[name]="
					levelOneSelected === levelOneIndex
						? 'icon-point-up'
						: 'icon-point-down'
				"
			></ui-icon>
		</button>
	</ng-container>
</nav>

<!-- Dropdown Menu Container -->
<div
	*ngIf="{ value: menuHeight$ | async } as menuHeight"
	class="rounded-b-lg absolute w-full select-none bg-white pr-2 text-base shadow-lg"
	[ngClass]="{
		'pointer-events-none -z-50 opacity-0': levelOneSelected === -1,
		'z-50 opacity-100': levelOneSelected !== -1
	}"
	[style.height.px]="menuHeight.value"
>
	<!-- Level 2 Containers Left (stacked) -->
	<div class="fixed w-1/5 bg-ui-low" [style.height.px]="menuHeight.value">
		<div
			class="fixed flex w-1/5 flex-col pt-8"
			*ngFor="let item of items; let levelTwoLeftContainerIndex = index"
			#levelTwoContainers
			[ngClass]="{
				'pointer-events-none -z-50 opacity-0':
					levelOneSelected !== levelTwoLeftContainerIndex,
				'z-50 opacity-100': levelOneSelected === levelTwoLeftContainerIndex
			}"
		>
			<!-- Level 2 Items -->
			<div *ngFor="let subItem of item.children; let levelTwoIndex = index">
				<ng-container
					*ngTemplateOutlet="
						levelTwoMenuItem;
						context: { item: subItem, index: levelTwoIndex }
					"
				></ng-container>
			</div>
		</div>
	</div>

	<!-- Level 2 Containers Right (stacked) -->
	<div
		class="fixed left-1/4 flex flex-col"
		*ngFor="let item of items; let levelTwoRightContainerIndex = index"
		[ngClass]="{
			'z-50': levelOneSelected === levelTwoRightContainerIndex,
			'-z-50': levelOneSelected !== levelTwoRightContainerIndex
		}"
	>
		<div
			#levelThreeContainers
			class="fixed w-3/4 pr-12 pt-10 text-black"
			*ngFor="
				let subItem of item.children;
				let levelThreeContainerIndex = index
			"
			[ngClass]="{
				'pointer-events-none -z-50 opacity-0':
					levelTwoSelected !== levelThreeContainerIndex ||
					levelOneSelected !== levelTwoRightContainerIndex,
				'z-50 opacity-100':
					levelTwoSelected === levelThreeContainerIndex &&
					levelOneSelected === levelTwoRightContainerIndex
			}"
		>
			<!-- Segmented Control -->
			<div
				*ngIf="subItem.extra?.categories && subItem.extra?.categories?.length"
				class="mb-4"
			>
				<ui-segmented-control
					sm
					[name]="'level-3-segmented-control'"
					[controlOptions]="subItem.extra?.categories | segmentedControl"
					[formControl]="segmentedControlControl"
				></ui-segmented-control>
			</div>
			<ng-container *ngIf="(segmentedControlSelection$ | async) !== undefined">
				<div
					*ngIf="
						subItem.children
							| parentCategory: segmentedControlSelection$
							| async as selectedSegmentedControlChildren
					"
					[ngClass]="{
						'columns-1': selectedSegmentedControlChildren.length <= 10,
						'columns-2':
							selectedSegmentedControlChildren.length > 10 &&
							selectedSegmentedControlChildren.length <= 20,
						'columns-3':
							selectedSegmentedControlChildren.length > 20 &&
							selectedSegmentedControlChildren.length <= 30,
						'columns-4':
							selectedSegmentedControlChildren.length > 30 &&
							selectedSegmentedControlChildren.length <= 40,
						'columns-5': selectedSegmentedControlChildren.length > 40
					}"
				>
					<!-- Level 3 Items -->
					<div
						*ngFor="
							let leaf of subItem.children
								| parentCategory: segmentedControlSelection$
								| async
						"
						class="break-words"
					>
						<ng-container
							*ngTemplateOutlet="
								levelThreeMenuItem;
								context: {
									item: leaf,
									levelTwo: levelTwoRightContainerIndex,
									levelThree: levelThreeContainerIndex
								}
							"
						>
							>
						</ng-container>
					</div>
				</div>
			</ng-container>

			<!-- Call To Action -->
			<div
				*ngIf="subItem.extra?.callToAction"
				class="mt-8 flex w-full justify-end"
				[ngClass]="{
					'pointer-events-none -z-50 opacity-0':
						levelTwoSelected !== levelThreeContainerIndex ||
						levelOneSelected !== levelTwoRightContainerIndex,
					'pointer-events-auto z-50 opacity-100':
						levelTwoSelected === levelThreeContainerIndex &&
						levelOneSelected === levelTwoRightContainerIndex
				}"
			>
				<a
					*ngIf="subItem.extra?.callToAction?.type === LinkTypes.RELATIVE"
					class="btn w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold"
					[ngClass]="
						subItem.extra?.callToAction?.primary
							? 'btn-primary'
							: 'btn-secondary'
					"
					[routerLink]="subItem.extra?.callToAction?.url"
					(click)="close()"
				>
					{{ subItem.extra?.callToAction?.name }}
				</a>
				<a
					*ngIf="subItem.extra?.callToAction?.type === LinkTypes.ABSOLUTE"
					class="btn w-fit bg-icon-chevron-right-bold-white bg-3 bg-right-3-center bg-no-repeat pr-10 text-left font-semibold"
					[ngClass]="
						subItem.extra?.callToAction?.primary
							? 'btn-primary'
							: 'btn-secondary'
					"
					[href]="subItem.extra?.callToAction?.url"
					(click)="close()"
				>
					{{ subItem.extra?.callToAction?.name }}
				</a>
			</div>
		</div>
	</div>
</div>
