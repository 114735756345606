import { Route } from '@angular/router';
import { RootComponent } from './components/root.component';
import { PageGuard } from './guards/page/page.guard';

export const appRoutes: Route[] = [
	{
		path: '**',
		component: RootComponent,
		canActivate: [PageGuard],
	},
];
