<picture [class]="className">
	<source
		*ngFor="let source of resolvedSources"
		type="image/jpeg"
		[media]="source.media"
		[srcset]="
			image.src | imageQuery: { height: source.height, width: source.width }
		"
	/>
	<img
		[src]="image.src | imageQuery: { height: image.height, width: image.width }"
		[alt]="image.altText"
		[class]="className"
		[attr.style]="image.style"
	/>
</picture>
