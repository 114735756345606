import { DOCUMENT } from '@angular/common';
import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';

@Injectable()
export class ScriptService {
	private renderer: Renderer2;

	constructor(
		private readonly rendererFactory: RendererFactory2,
		@Inject(DOCUMENT) private document: Document,
	) {
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	addScript(tag: ScriptDefinition, content?: string) {
		const script = this.renderer.createElement('script');
		const head = this.document.head;

		Object.keys(tag).forEach((prop: string) => {
			return this.renderer.setAttribute(script, prop, tag[prop]);
		});
		if (content) {
			script.text = content;
		}

		this.renderer.appendChild(head, script);
	}
}

export declare type ScriptDefinition = {
	type?: string;
} & {
	[prop: string]: string;
};
