import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
	ContactDetails,
	OpeningHours,
	OpeningTimes,
} from '@sunny-cars/util-global';
import { CONTACT_DETAILS_FEATURE_KEY, State } from './contact-details.reducer';

export const getContactDetailsState = createFeatureSelector<State>(
	CONTACT_DETAILS_FEATURE_KEY,
);

export const getContactDetails = createSelector(
	getContactDetailsState,
	(state: State) => state.contactDetails,
);

export const getContactDetailsLoading = createSelector(
	getContactDetailsState,
	(state: State) => state.isLoading,
);

export const getContactDetailsLoaded = createSelector(
	getContactDetailsState,
	(state: State) => state.isLoaded,
);

export const getContactDetailsError = createSelector(
	getContactDetailsState,
	(state: State) => state.error,
);

export const getExpires = createSelector(
	getContactDetailsState,
	(state: State) => state.expiresDateTime,
);

export const getTodaysOpeningTimes = createSelector(
	getContactDetails,
	(contactDetails: ContactDetails | undefined): OpeningTimes | undefined => {
		// @NOTE: in the odd chance customer service is closed, startDateTime and endDateTime will be empty strings
		if (
			!contactDetails ||
			!contactDetails.openingHours.length ||
			!contactDetails.openingHours[0].startDateTime ||
			!contactDetails.openingHours[0].endDateTime
		) {
			return undefined;
		}
		return {
			startTime: contactDetails.openingHours[0].startDateTime.substring(11, 16),
			endTime: contactDetails.openingHours[0].endDateTime.substring(11, 16),
		};
	},
);

export const getOpeningHours = createSelector(
	getContactDetails,
	(
		contactDetails: ContactDetails | undefined,
	): Array<OpeningHours> | undefined => {
		if (!contactDetails || !contactDetails.openingHours.length) {
			return undefined;
		}
		return contactDetails.openingHours;
	},
);
