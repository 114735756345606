<details class="w-full {{ detailStyleModifiers }}" [open]="isOpen">
	<summary
		type="button"
		class="flex w-full justify-between text-left text-lg font-bold md:text-xl {{
			summaryStyleModifiers
		}}"
		(click)="setIsOpen($event)"
	>
		<div>
			<span> {{ title }} </span>
			<ng-content select="[sub-title]"></ng-content>
		</div>
		<div class="ml-2 flex content-end items-center">
			<ui-icon
				class="transition-transform"
				[ngClass]="{ 'rotate-180': isOpen }"
				inlined
				name="icon-point-down"
				color="ui-high"
				customSize
				sm
			></ui-icon>
		</div>
	</summary>
	<ng-content></ng-content>
</details>
