export interface PictureInterface {
	src: string;
	altText?: string;
	style?: string;
	width: number;
	height: number;
}

export interface PictureSourceInterface {
	min: Sizes | null;
	max: Sizes | null;
	height: number;
	width: number;
}

export enum Sizes {
	XS = 'xs',
	SM = 'sm',
	MD = 'md',
	LG = 'lg',
	XL = 'xl',
	XXL = '2xl',
	HERO_LAYOUT_DESKTOP = 'hero-layout-desktop',
}
