<section
	*ngIf="content?.attributes as content"
	[id]="content.layoutSettings.anchor"
	class="grid grid-cols-1 md:gap-8 md:grid-rows-cover md:grid-cols-cover-tablet lg:gap-10 lg:grid-rows-cover lg:grid-cols-cover-desktop {{
		content.layoutSettings.width
	}}"
>
	<div class="col-span-full row-start-1 row-end-1 md:bg-secondary"></div>
	<div
		class="col-start-1 col-end-2 row-span-1 row-start-1 p-0 xs:p-6 sm:w-[61.8%] sm:p-12 md:col-end-3 md:row-span-full md:flex md:w-full md:justify-end md:p-0"
	>
		<div
			class="relative mb-0 h-fit-content w-full overflow-hidden shadow-lg before:block before:pb-aspect-ratio-1/1 xs:rounded md:mt-12 lg:max-w-[40.75rem]"
			*ngIf="content.image"
		>
			<ui-image
				class="absolute left-0 top-0 h-full w-full"
				className="w-full h-full object-cover"
				[sources]="[
					{
						min: null,
						max: sizes.XS,
						width: 400,
						height: 400
					},
					{
						min: sizes.XS,
						max: sizes.SM,
						width: 591,
						height: 591
					},
					{
						min: sizes.SM,
						max: sizes.MD,
						width: 380,
						height: 380
					},
					{
						min: sizes.MD,
						max: null,
						width: 652,
						height: 652
					}
				]"
				[image]="{
					src: content.image.src,
					altText: content.imageAlt,
					width: 672,
					height: 672
				}"
			></ui-image>
		</div>
	</div>
	<header
		class="z-10 col-span-1 col-start-1 row-span-1 row-start-1 -mb-3 ml-0 mr-12 mt-auto flex h-fit-content w-fit items-end rounded-r bg-secondary p-3 pl-4 xs:mb-0 xs:pl-6 sm:p-4 sm:pl-12 md:col-start-3 md:mr-0 md:rounded-none md:bg-transparent md:pb-6 md:pl-0 md:pr-0"
	>
		<ui-heading-content-block
			[heading]="content.heading"
			[headingLevel]="content.headingType"
			customHeadingClasses="text-2xl xs:text-4xl leading-none text-white md:text-5xl sm:leading-tight md:ml-0"
		></ui-heading-content-block>
	</header>

	<div
		class="col-span-1 col-start-1 row-span-1 row-start-2 h-fit-content p-4 pt-7 xs:px-4 xs:pt-4 sm:px-12 md:col-start-3 md:p-0"
		*ngIf="content.content"
	>
		<div class="prose sm:prose-lg" [innerHTML]="content.content"></div>
	</div>
</section>
