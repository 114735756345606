<a
	*ngIf="
		(
			'components.custom-affiliate-links.general-terms-label' | translate
		).trim() &&
		('components.custom-affiliate-links.general-terms-label' | translate) !==
			'components.custom-affiliate-links.general-terms-label'
	"
	target="_blank"
	rel="noopener noreferrer"
	[href]="
		baseURL +
		('components.custom-affiliate-links.general-terms-uri' | translate)
	"
>
	{{ 'components.custom-affiliate-links.general-terms-label' | translate }}
</a>
<a
	*ngIf="
		('components.custom-affiliate-links.privacy-label' | translate).trim() &&
		('components.custom-affiliate-links.privacy-label' | translate) !==
			'components.custom-affiliate-links.privacy-label'
	"
	target="_blank"
	rel="noopener noreferrer"
	[href]="
		baseURL + ('components.custom-affiliate-links.privacy-uri' | translate)
	"
>
	{{ 'components.custom-affiliate-links.privacy-label' | translate }}
</a>
<a
	*ngIf="
		('components.custom-affiliate-links.imprint-label' | translate).trim() &&
		('components.custom-affiliate-links.imprint-label' | translate) !==
			'components.custom-affiliate-links.imprint-label'
	"
	target="_blank"
	rel="noopener noreferrer"
	[href]="
		baseURL + ('components.custom-affiliate-links.imprint-uri' | translate)
	"
>
	{{ 'components.custom-affiliate-links.imprint-label' | translate }}
</a>
<a
	*ngIf="
		('components.custom-affiliate-links.copyright-label' | translate).trim() &&
		('components.custom-affiliate-links.copyright-label' | translate) !==
			'components.custom-affiliate-links.copyright-label'
	"
	target="_blank"
	rel="noopener noreferrer"
	[href]="
		baseURL + ('components.custom-affiliate-links.copyright-uri' | translate)
	"
>
	{{ 'components.custom-affiliate-links.copyright-label' | translate }}
</a>
<a
	*ngIf="
		('components.custom-affiliate-links.cookies-label' | translate).trim() &&
		('components.custom-affiliate-links.cookies-label' | translate) !==
			'components.custom-affiliate-links.cookies-label'
	"
	target="_blank"
	rel="noopener noreferrer"
	[href]="
		baseURL + ('components.custom-affiliate-links.cookies-uri' | translate)
	"
>
	{{ 'components.custom-affiliate-links.cookies-label' | translate }}
</a>
