import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { SessionType } from '@sunny-cars/util-global';
import { Observable } from 'rxjs';
import * as SessionActions from './session.actions';
import { AuthenticateRequestParams } from './session.interface';
import * as SessionSelectors from './session.selectors';

/**
 * Session facade
 */
@Injectable({
	providedIn: 'root',
})
export class SessionFacade {
	accountBlockedErrorCode = SessionSelectors.accountBlockedErrorCode;

	/**
	 * Session ak id
	 */
	id$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getId),
	);
	/**
	 * Session identifier
	 */
	sessionID$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getSessionID),
	);
	/**
	 * Token type f.e. ADMIN
	 */
	tokenType$: Observable<SessionType | undefined> = this.store.pipe(
		select(SessionSelectors.getTokenType),
	);
	/**
	 * Token expiration
	 */
	expiresAt$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getExpiresAt),
	);

	/**
	 * Token expiration
	 */
	username$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getUsername),
	);
	/**
	 * Whether session is loading
	 */
	loading$: Observable<boolean> = this.store.pipe(
		select(SessionSelectors.getSessionLoading),
	);
	/**
	 * Whether session is loaded
	 */
	loaded$: Observable<boolean> = this.store.pipe(
		select(SessionSelectors.getSessionLoaded),
	);
	/**
	 * Session load error
	 */
	error$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getSessionError),
	);
	/**
	 * Logout reason
	 */
	logoutReason$: Observable<string | undefined> = this.store.pipe(
		select(SessionSelectors.getLogoutReason),
	);

	constructor(private readonly store: Store) {}

	/**
	 * Use the login action to create a new session
	 * @param {AuthenticationRequestAPI} body
	 * @returns {void}
	 */
	login(body: AuthenticateRequestParams): void {
		this.store.dispatch(SessionActions.loadSession({ body }));
	}

	/**
	 * Use the logout action remove the current session
	 * @param {string} reason
	 * @returns {void}
	 */
	logout(reason: string): void {
		this.store.dispatch(SessionActions.logout({ reason }));
	}

	/**
	 * Clear the error state
	 * @returns {void}
	 */
	clearError(): void {
		this.store.dispatch(SessionActions.clearError());
	}
}
