<div class="flex gap-3">
	<ng-container
		*ngTemplateOutlet="
			social;
			context: { url: twitter, icon: 'icon-social-circle-solid-twitter' }
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			social;
			context: { url: facebook, icon: 'icon-social-circle-solid-facebook' }
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			social;
			context: { url: instagram, icon: 'icon-social-circle-solid-instagram' }
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			social;
			context: { url: vimeo, icon: 'icon-social-circle-solid-vimeo' }
		"
	></ng-container>
	<ng-container
		*ngTemplateOutlet="
			social;
			context: { url: blog, icon: 'icon-social-circle-solid-blog' }
		"
	></ng-container>
</div>

<ng-template #social let-icon="icon" let-url="url">
	<a *ngIf="url" [href]="url" target="_blank" rel="noopener noreferrer"
		><ui-icon
			[name]="icon"
			[color]="color"
			customSize
			classModifiers="w-6 h-6"
		></ui-icon
	></a>
</ng-template>
