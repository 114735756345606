<div
	class="rounded border-2 border-ui-important relative p-3 flex bg-white {{
		styleModifiers
	}}"
	[class.my-3]="isPadded"
	data-test-class="dialog"
	[ngClass]="{
		'border-ui-important': type === types.INFO,
		'border-success-important': type === types.SUCCESS,
		'border-warning-important': type === types.WARNING,
		'border-error-important': type === types.ERROR,
		'd-flex align-items-start': showIcon
	}"
>
	<div
		class="mr-3 h-7 w-7 shrink-0"
		[class]="iconName"
		[ngClass]="{
			'bg-secondary': type === types.INFO,
			'bg-success': type === types.SUCCESS,
			'bg-warning': type === types.WARNING,
			'bg-error': type === types.ERROR,
			'icon-information-circle-solid': type === types.INFO && !iconName,
			'icon-check-circle-solid': type === types.SUCCESS && !iconName,
			'icon-exclamation-triangle-solid': type === types.WARNING && !iconName,
			'icon-exclamation-circle-solid': type === types.ERROR && !iconName
		}"
		*ngIf="showIcon"
	></div>
	<div class="text-base">
		<div class="text-lg" *ngIf="title" [ngClass]="{ 'pr-5': isDismissable }">
			<strong>{{ title }}</strong>
			<ng-content select="[ui-dialog-title-content]"></ng-content>
		</div>
		<div>
			<ng-content></ng-content>
		</div>
	</div>
	<button
		class="absolute right-3 top-3 h-4 w-4 overflow-hidden bg-icon-cross-circle-solid-ui-critical bg-contain bg-center bg-no-repeat text-transparent"
		*ngIf="isDismissable"
		(click)="removeDialog()"
		data-test-id="dismiss-button"
	>
		{{ 'global.dismiss-action' | translate }}
	</button>
</div>
