<ui-autocomplete
	ngDefaultControl
	#autoCompleteElement
	[title]="props.label || ''"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[entries]="filteredEntries"
	[entryTemplate]="to['entryTemplate']"
	[emptyTemplate]="to['emptyTemplate']"
	[skeletonTemplate]="to['skeletonTemplate']"
	[isValid]="!formControl.errors && !to['hideValid']"
	[isInvalid]="showError"
	(clear)="clearCountry()"
	(searchChange)="updateFilteredEntries($event)"
>
</ui-autocomplete>
