import { Action, createReducer, on } from '@ngrx/store';
import { AccountData } from '@sunny-cars/util-global/lib/interfaces/profile/profile.interface';
import * as AccountActions from './account.actions';

/**
 * Storage key
 */
export const ACCOUNT_FEATURE_KEY = 'account';

/**
 * Store interface
 */
export interface State {
	/**
	 * Account identifier
	 */
	account: AccountData | null;
	/**
	 * is the Account been loading
	 */
	isLoading: boolean;
	/**
	 * has the Account been loaded
	 */
	isLoaded: boolean;
	/**
	 * last known error (if any)
	 */
	error: string | null;
}

/**
 * Initial store state
 */
export const initialState: State = {
	account: null,
	isLoading: false,
	isLoaded: false,
	error: null,
};

/**
 * Reducer handler
 */
const accountReducer = createReducer(
	initialState,
	on(AccountActions.init, (state) => ({
		...state,
		isLoading: false,
		isLoaded: false,
		error: null,
	})),
	on(AccountActions.loadAccount, (state) => ({
		...state,
		account: null,
		isLoading: true,
		isLoaded: false,
		error: null,
	})),
	on(AccountActions.loadAccountSuccess, (state, { account }) => ({
		...state,
		account,
		isLoading: false,
		isLoaded: true,
	})),
	on(AccountActions.loadAccountFailure, (state, { error }) => ({
		...state,
		account: null,
		isLoading: false,
		isLoaded: false,
		error,
	})),
	on(AccountActions.updateAccount, (state) => ({
		...state,
		isLoading: true,
		isLoaded: false,
		error: null,
	})),
	on(AccountActions.updateAccountSuccess, (state, { account }) => ({
		...state,
		account: state.account && { ...state.account, ...account },
		isLoading: false,
		isLoaded: true,
	})),
	on(AccountActions.updateAccountFailure, (state, { error }) => ({
		...state,
		isLoading: false,
		isLoaded: false,
		error,
	})),
	on(AccountActions.clearError, (state) => ({
		...state,
		error: null,
	})),
	on(AccountActions.clear, (state) => ({
		...state,
		account: null,
		isLoading: false,
		isLoaded: false,
		error: null,
	})),
);

/**
 * Account reducer
 * @param {State|undefined} state
 * @param {Action} action
 * @returns {State}
 */
export function reducer(state: State | undefined, action: Action): State {
	return accountReducer(state, action);
}
