<section class="flex flex-col gap-6" *ngIf="content">
	<ui-card
		*ngFor="let item of content.attributes.items | slice: 0 : itemAmount"
		[title]="item.title"
		[subtitle]="
			item.category +
			' &middot; ' +
			(item.city ? item.city + ', ' : '') +
			(item.publishDate | datetime: dateTimeFormats.DAY_DATE_LONG)
		"
		[link]="{
			href: item.path,
			rel: '',
			shouldOpenInNewTab: false,
			text: '',
			title: ''
		}"
		[elevated]="true"
		[useFullHeight]="true"
		[useMaxWidth]="false"
	>
	</ui-card>
	<div
		*ngIf="
			content.attributes.showMoreButton &&
			itemAmount < content.attributes.items.length
		"
	>
		<ui-button
			[styleType]="buttonStyleTypes.SECONDARY_SOLID"
			(click)="itemAmount = itemAmount + itemIncrement"
		>
			{{ content.attributes.showMoreButtonLabel }}
		</ui-button>
	</div>
</section>
