<input
	class="input w-full"
	[ngClass]="props['classModifiers']"
	ngDefaultControl
	[class.input--invalid]="showError"
	[class.input--valid]="!formControl.errors && !props['hideValid']"
	[formControl]="formControl"
	[formlyAttributes]="field"
	[type]="props['type'] || 'text'"
/>
