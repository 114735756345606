import { Pipe, PipeTransform } from '@angular/core';
import { MenuItem } from '@sunny-cars/util-global';
import { map, Observable, of, withLatestFrom } from 'rxjs';

@Pipe({
	name: 'parentCategory',
	pure: false,
	standalone: true,
})
export class ParentCategoryPipe implements PipeTransform {
	transform(
		items?: MenuItem[],
		parentCategory?: Observable<string | null>,
	): Observable<MenuItem[]> {
		if (!items) {
			return of([]);
		}
		if (!parentCategory) {
			return of(items);
		}

		return of(items).pipe(
			withLatestFrom(parentCategory),
			map(([items, parentCategory]) => {
				if (!parentCategory) {
					return items;
				}

				return items.filter(
					(item) => item.extra?.parentCategoryId === parentCategory,
				);
			}),
		);
	}
}
