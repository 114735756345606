import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SitemapItem } from '@sunny-cars/util-global/lib/interfaces/content/sitemap.interface';

@Component({
	selector: 'ui-sitemap',
	templateUrl: './sitemap.component.html',
	standalone: true,
	imports: [CommonModule],
})
export class SitemapComponent {
	@Input() list: SitemapItem[] = [];
}
