import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	/* eslint-disable-next-line @angular-eslint/component-selector */
	selector: 'nav[ui-custom-affiliate-links]',
	templateUrl: './custom-affiliate-links.component.html',
	standalone: true,
	imports: [CommonModule, TranslateModule],
})
export class CustomAffiliateLinksComponent {
	@Input() baseURL!: string;
}
