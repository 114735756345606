import { CommonModule } from '@angular/common';
import {
	ChangeDetectionStrategy,
	Component,
	Input,
	ViewEncapsulation,
} from '@angular/core';
import { marker } from '@colsen1991/ngx-translate-extract-marker';
import { TranslateModule } from '@ngx-translate/core';
import { ImageInterface } from '@sunny-cars/util-global';
import { QuickInfo } from '@sunny-cars/util-global/lib/interfaces/content/destination-info.interface';
import { ImageComponent } from '../image/image.component';

marker('components.quick-info.quick-info');

@Component({
	selector: 'ui-quick-info[items]',
	templateUrl: './quick-info.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [CommonModule, ImageComponent, TranslateModule],
})
export class QuickInfoComponent {
	@Input() items!: QuickInfo[];
	@Input() image: ImageInterface | undefined;
	@Input() titleQuickInfo = '';
}
