import {
	AfterViewInit,
	Directive,
	ElementRef,
	Input,
	OnChanges,
	OnDestroy,
	SimpleChanges,
} from '@angular/core';
import tippy, { Instance, Props } from 'tippy.js';

@Directive({
	selector: '[uiTooltip],[uiTooltipOptions]',
	standalone: true,
})
export class TooltipDirective implements AfterViewInit, OnDestroy, OnChanges {
	/**
	 * Tooltip text (with same name as the selector)
	 */
	@Input() uiTooltip: string | undefined | null;
	/**
	 * Tooltip options (with same name as the selector)
	 */
	@Input() uiTooltipOptions: Partial<Props> | undefined;

	/**
	 * Tippy instance
	 */
	private instance: Instance | undefined | null;

	constructor(private readonly element: ElementRef) {}

	ngAfterViewInit(): void {
		this.instance = tippy(this.element.nativeElement as HTMLElement, {});

		this.updateProps({
			...(this.uiTooltipOptions || {}),
			allowHTML: true,
			content: this.uiTooltip || '',
		});
	}

	ngOnDestroy(): void {
		if (this.instance) {
			this.instance.destroy();
			this.instance = null;
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		const props = {
			...(this.uiTooltipOptions ? this.uiTooltipOptions : {}),
			content: changes['uiTooltip']?.currentValue || this.uiTooltip,
		};

		this.updateProps(props);
	}

	/**
	 * Update tooltip properties
	 */
	private updateProps(props: Partial<Props>): void {
		if (
			this.instance &&
			JSON.stringify(props) !== JSON.stringify(this.instance.props)
		) {
			this.instance.setProps(this.normalizeOptions(props));

			if (!props.content) {
				this.instance.disable();
			} else {
				this.instance.enable();
			}
		}
	}

	/**
	 * Normalize tooltip options with sane defaults
	 */
	private normalizeOptions(props: Partial<Props>): Partial<Props> {
		return {
			...props,
			duration: props.duration || [50, 50],
			theme: props.theme || 'sc-theme',
		};
	}
}
