import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectorRef,
	Component,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	Renderer2,
	RendererFactory2,
	ViewChild,
} from '@angular/core';
import {
	GoogleMap,
	MapInfoWindow,
	MapMarker,
	MapMarkerClusterer,
} from '@angular/google-maps';
import { RouterLink } from '@angular/router';
import { Loader } from '@googlemaps/js-api-loader';
import { TranslateModule } from '@ngx-translate/core';
import { RegionsService } from '@sunny-cars/provider-regions-service/lib/regions/regions.service';
import {
	APICountry,
	CountryService,
} from '@sunny-cars/provider-rental-office-service';
import {
	BaseComponent,
	Country,
	LayoutWidthClasses,
	RegionDestination,
	RegionType,
	RegionTypes,
	SourceType,
	clusterIconStyle,
	mapOptions,
} from '@sunny-cars/util-global';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { BehaviorSubject } from 'rxjs';
import { HeadingContentBlockComponent } from '../heading-content-block/heading-content-block.component';
import { RegionMapListComponent } from '../region-map-list/region-map-list.component';
import { RegionMapHelper } from './region-map.helper';

@Component({
	selector: 'ui-region-map',
	templateUrl: './region-map.component.html',
	standalone: true,
	imports: [
		CommonModule,
		HeadingContentBlockComponent,
		RouterLink,
		RegionMapListComponent,
		GoogleMap,
		MapMarkerClusterer,
		MapMarker,
		MapInfoWindow,
		TranslateModule,
	],
})
export class RegionMapComponent extends BaseComponent implements OnInit {
	@Input() anchor = '';
	@Input() title = '';
	@Input() content = '';
	@Input() controlId = '';
	@Input() pathPrefix = '';
	@Input() isDestinationPage = false;
	@Input() regionId?: number;
	@Input() widthClass: LayoutWidthClasses = 'w-full';
	@Input() source: SourceType = 'NL';
	@Input() affiliateKey = 62;
	@Input() radius?: number;

	@ViewChild(GoogleMap) map?: GoogleMap;
	@ViewChild(MapInfoWindow) infoWindow?: MapInfoWindow;
	readonly headingLevels = HeadingLevels;
	mapBounds: google.maps.LatLngBounds | undefined;
	mapOptions: google.maps.MapOptions = {
		...mapOptions,
	};
	mapsLoader = new Loader({
		apiKey: this.googleMapsApiKey,
		version: 'weekly',
	});
	isMapLoaded$: BehaviorSubject<boolean> = new BehaviorSubject(false);
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	markers: google.maps.Marker[] | any[] = [];
	/** markerClusterIconStyle exposes clusterIconStyle to the template */
	markerClusterIconStyle = clusterIconStyle;

	countries?: Required<Country>[];
	region?: RegionDestination;
	subRegions: RegionDestination[] = [];

	regionsAreNested = false;
	countryRegion?: RegionDestination;
	countryName = '';

	markerRegions: RegionDestination[] = [];
	infoContent = '';
	infoWindowRouterLink = '';
	hidden = false;
	path = '/';
	slugify = RegionMapHelper.slugify;
	private readonly renderer: Renderer2;

	constructor(
		@Inject('googleMapsApiKey') private readonly googleMapsApiKey: string,
		@Inject(PLATFORM_ID) private platformId: string,
		@Inject(DOCUMENT) private document: Document,
		private readonly changeDetectorRef: ChangeDetectorRef,
		private readonly countryService: CountryService,
		private readonly regionsService: RegionsService,
		readonly rendererFactory: RendererFactory2,
	) {
		super();
		this.renderer = this.rendererFactory.createRenderer(null, null);
	}

	private static flattenValidChildren(
		regions: RegionDestination[],
		recursiveDepth = 1,
	): RegionDestination[] {
		return regions.flatMap((region) => {
			if (!RegionMapHelper.isRegionWithGeo(region)) return [];
			if (
				!region.children ||
				region.children.length === 0 ||
				recursiveDepth >= 4
			) {
				return region;
			}
			return this.flattenValidChildren(region.children, recursiveDepth + 1);
		});
	}

	private static setMarkerIcon(subType: RegionType | undefined): string {
		let iconUrl = '/assets/ui/images/map-markers/marker-default.svg';

		if (subType) {
			switch (subType) {
				case RegionTypes.AIRPORT:
					iconUrl = '/assets/ui/images/map-markers/airport-marker-default.svg';
					break;
				case RegionTypes.CITY:
					iconUrl =
						'/assets/ui/images/map-markers/city-office-marker-default.svg';
					break;
			}
		}
		return iconUrl;
	}

	ngOnInit(): void {
		if (this.isDestinationPage && this.regionId) {
			this.loadSpecificRegion(this.regionId);
		} else {
			this.loadCountries();
		}

		if (this.pathPrefix) {
			this.path = `/${this.pathPrefix}/`;
		}
	}

	openInfo(marker: MapMarker, content: string): void {
		if (!this.infoWindow) {
			return;
		}

		this.infoWindowRouterLink = this.path + this.slugify(content);
		this.infoContent = `<h2 class="text-base">${content}</h2>`;
		this.infoWindow.open(marker, false);
	}

	private mapCountries(countries: APICountry[]): void {
		const filteredCountries = countries.filter(
			(country) =>
				country.geo && country.geo.lat !== 0 && country.geo.lng !== 0,
		) as Required<Country>[];
		const capitalizedCountries = filteredCountries.map((country) => {
			return {
				name: country.name[0].toUpperCase() + country.name.substring(1),
				id: country.id,
				code: country.code,
				type: undefined,
				geo: {
					lat: country.geo.lat,
					lng: country.geo.lng,
				},
			};
		}) as Required<Country>[];

		this.countries = capitalizedCountries.sort(
			(countryA: Country, countryB: Country) =>
				countryA.name < countryB.name
					? -1
					: countryA.name > countryB.name
						? 1
						: 0,
		);

		this.loadMapScripts();
	}

	private loadCountries(): void {
		const hasRegions = true;
		this.addSubscription(
			this.countryService
				.getCountries(hasRegions)
				.subscribe((countries) => this.mapCountries(countries)),
		);
	}

	private loadSpecificRegion(regionId: number): void {
		this.addSubscription(
			this.regionsService
				.fetchRegionWithSubregions(regionId)
				.subscribe((response: RegionDestination | undefined) => {
					if (response) {
						if (this.region) {
							this.countryRegion = response;
							this.countryName = response.name || '';
						} else {
							this.handleNewRegion(response);
						}
					} else {
						this.hidden = true;
					}
				}),
		);
	}

	private handleNewRegion(response: RegionDestination): void {
		this.region = response;
		this.subRegions = [...this.subRegions, ...response.children];

		if (
			response.type === RegionTypes.CITY ||
			response.type === RegionTypes.AIRPORT
		) {
			this.regionsService
				.fetchRegionsByCoordinates(
					response.geo.lat,
					response.geo.lng,
					this.source,
					this.affiliateKey,
					this.radius,
				)
				.subscribe((result) => {
					if (result) {
						if (this.region) {
							this.region.children = [...result, ...this.region.children];
						}

						this.subRegions = [...result, ...this.subRegions];
					}
					this.loadAllCountryMarkersForSubregion();
					this.loadMapScripts();
				});
		} else {
			this.loadAllCountryMarkersForSubregion();
			this.loadMapScripts();
		}
	}

	private loadAllCountryMarkersForSubregion(): void {
		if (!this.subRegions?.length) {
			this.hidden = true;
		} else {
			this.regionsAreNested = this.subRegions.some(
				(item) => item.children && item.children.length,
			);
			this.markerRegions = RegionMapComponent.flattenValidChildren(
				this.subRegions,
			);

			if (
				this.region?.countryRegionID &&
				this.region.countryRegionID !== this.regionId
			) {
				this.loadSpecificRegion(this.region.countryRegionID);
			}
		}
	}

	private loadMapScripts(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		// Bugfix for the implementation of the @angular/googlemaps should be fixed in the future
		// https://github.com/angular/components/issues/23695
		const clustererScript = this.renderer.createElement('script');
		clustererScript.src = '/assets/ui/libs/markerclustererplus/index.min.js';
		this.renderer.appendChild(this.document.head, clustererScript);

		clustererScript.onload = () => {
			this.mapsLoader.load().then(() => {
				this.isMapLoaded$.next(true);
				this.changeDetectorRef.detectChanges();
				this.loadMap();
			});
		};
	}

	private loadMap(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		if (this.regionsAreNested) {
			this.initMap(this.markerRegions);
		} else if (this.subRegions?.length) {
			this.initMap(this.subRegions);
		} else if (this.countries) {
			this.initMap(this.countries);
		}
	}

	private initMap(markerData: RegionDestination[] | Required<Country>[]): void {
		this.addMarkers(markerData);

		google.maps.event.addListener(window, 'resize', () => {
			if (this.map !== undefined && this.mapBounds !== undefined) {
				google.maps.event.trigger(this.map, 'resize');
				this.updateMapBounds();
			}
		});
	}

	private addMarkers(
		locations: RegionDestination[] | Required<Country>[],
	): void {
		this.mapBounds = new google.maps.LatLngBounds();

		const addMarker = (location: RegionDestination | Required<Country>) => {
			const marker = new google.maps.Marker({
				position: {
					lat: location.geo.lat,
					lng: location.geo.lng,
				},
				icon: {
					scaledSize: new google.maps.Size(28, 42),
					url: RegionMapComponent.setMarkerIcon(
						'type' in location ? location.type : undefined,
					),
				},
				title: location.name,
			});
			marker.set('location', location);
			this.markers.push(marker);
			if (this.mapBounds !== undefined) {
				this.mapBounds.extend(location.geo);
			}
		};

		locations.forEach((location) => {
			addMarker(location);
		});

		this.updateMapBounds();
	}

	private updateMapBounds(): void {
		if (this.map && this.mapBounds) {
			this.map.fitBounds(this.mapBounds);
		}
	}
}
