import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
	ContactDetailResponseAPI,
	ContactDetailsAPI,
} from '@sunny-cars/util-api-interfaces';
import { DateTimeFormats, OpeningHours } from '@sunny-cars/util-global';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { Observable, map } from 'rxjs';
import {
	APIContactDetailsRequestParams,
	ContactDetailsWithExpiration,
} from './contact-details.interface';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

@Injectable({
	providedIn: 'root',
})
export class ContactDetailsService {
	constructor(
		private readonly http: HttpClient,
		@Inject('apiBaseUrl')
		private readonly endpoint: string,
	) {}

	getContactDetails(
		params: APIContactDetailsRequestParams,
	): Observable<ContactDetailsWithExpiration> {
		return this.http
			.get<ContactDetailResponseAPI>(`${this.endpoint}v1/contactdetails`, {
				params: {
					date: dayjs().tz('CET').format(DateTimeFormats.DATE),
					...params,
				},
				observe: 'response',
			})
			.pipe(
				map((response) =>
					this.parseData(response.body?.data, response.headers.get('expires')),
				),
			);
	}

	private parseData(
		response: ContactDetailsAPI | undefined,
		expiration: string | null,
	): ContactDetailsWithExpiration {
		return {
			expiration: expiration || '',
			emailAddress: response?.emailAddress || '',
			openingHours:
				response?.openingHours.map(
					(openingHour): OpeningHours => ({
						endDateTime: openingHour.endTime || '',
						startDateTime: openingHour.startTime || '',
					}),
				) || [],
			infoURL: response?.infoURL || '',
			phone: response?.phone || '',
		};
	}
}
