/**
 * Named additional cost codes
 */
export enum AdditionalCostsCodes {
	CROSS_BORDER = 'CB',
	UNDER_AGE = 'UA',
	ONE_WAY = 'OW',
	OUT_OF_HOURS = 'OOH',
	OUT_OF_HOURS_PICKUP = 'OOH - PU',
	OUT_OF_HOURS_DROPOFF = 'OOH - DO',
	DELIVERY = 'DEL',
	DELIVERY_PICKUP = 'DEL - PU',
	DELIVERY_DROPOFF = 'DEL - DO',
}
/**
 * Available additional cost codes
 */
export type AdditionalCostsCode =
	| AdditionalCostsCodes.CROSS_BORDER
	| AdditionalCostsCodes.UNDER_AGE
	| AdditionalCostsCodes.ONE_WAY
	| AdditionalCostsCodes.OUT_OF_HOURS
	| AdditionalCostsCodes.OUT_OF_HOURS_PICKUP
	| AdditionalCostsCodes.OUT_OF_HOURS_DROPOFF
	| AdditionalCostsCodes.DELIVERY
	| AdditionalCostsCodes.DELIVERY_PICKUP
	| AdditionalCostsCodes.DELIVERY_DROPOFF;
