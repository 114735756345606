import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	HostListener,
	Inject,
	Input,
	OnInit,
	PLATFORM_ID,
	QueryList,
	ViewChildren,
	ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';
import {
	BaseComponent,
	LinkTypes,
	MenuItem,
	MenuItemTypes,
} from '@sunny-cars/util-global';
import {
	Observable,
	ReplaySubject,
	Subject,
	map,
	merge,
	shareReplay,
	startWith,
} from 'rxjs';
import { IconComponent } from '../../icon/icon.component';
import { SegmentedControlComponent } from '../../segmented-control/segmented-control.component';
import { ParentCategoryPipe } from '../parent-category-pipe/parent-category.pipe';
import { SegmentedControlPipe } from '../segmented-control-pipe/segmented-control.pipe';

@Component({
	selector: 'ui-main-menu-desktop',
	templateUrl: './main-menu-desktop.component.html',
	encapsulation: ViewEncapsulation.None,
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [
		CommonModule,
		IconComponent,
		RouterLink,
		SegmentedControlComponent,
		FormsModule,
		ReactiveFormsModule,
		SegmentedControlPipe,
		ParentCategoryPipe,
	],
})
export class MainMenuDesktopComponent extends BaseComponent implements OnInit {
	@ViewChildren('levelTwoContainers')
	levelTwoContainers!: QueryList<ElementRef>;
	@ViewChildren('levelThreeContainers')
	levelThreeContainers!: QueryList<ElementRef>;

	@Input() items?: MenuItem[] = [];
	@Input() wrapperStyleModifiers = '';
	@Input() onScroll$?: Observable<void>;

	readonly menuHeight$: Subject<number> = new ReplaySubject<number>(1);
	readonly menuSwitched$: Subject<void> = new Subject<void>();
	readonly linkClasses = 'mr-6 h-6 text-base last:mr-0 sm:mr-5';
	readonly menuItemTypes = MenuItemTypes;
	readonly segmentedControlControl = new FormControl();
	readonly segmentedControlSelection$ =
		this.getSegmentedControlSelectionStream();

	LinkTypes = LinkTypes;
	levelOneSelected = -1;
	levelTwoSelected = 0;
	levelThreeContainerHeights: number[] = [];
	preventClose = false;

	/**
	 * Handle click on element
	 */
	@HostListener('click')
	clickInside() {
		this.preventClose = true;
	}

	/**
	 * Handle click outside of element
	 */
	@HostListener('document:click')
	clickOutside() {
		if (!this.preventClose && this.levelOneSelected !== -1) {
			this.close();
		}
		this.preventClose = false;
	}

	constructor(
		private readonly cdr: ChangeDetectorRef,
		@Inject(PLATFORM_ID) public platformId: string,
	) {
		super();
	}

	ngOnInit(): void {
		this.onScroll$?.subscribe(() => {
			this.reset();
		});
		this.addSubscription(
			this.segmentedControlSelection$.subscribe(() => {
				if (isPlatformBrowser(this.platformId)) {
					setTimeout(() => {
						this.menuHeight$.next(this.getMenuHeight());
					});
				}
			}),
		);
	}

	openLevelTwoMenu(levelOneIndex: number): void {
		if (this.levelOneSelected === levelOneIndex) {
			this.levelOneSelected = -1;
			this.levelTwoSelected = 0;
		} else {
			this.levelOneSelected = levelOneIndex;
			this.levelTwoSelected = 0;
		}

		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				this.menuHeight$.next(this.getMenuHeight());
			});
		}
		this.menuSwitched$.next();
		this.setDefaultSegmentedControlSelection();
	}

	openLevelThreeMenu(index: number): void {
		this.levelTwoSelected = index;
		if (isPlatformBrowser(this.platformId)) {
			setTimeout(() => {
				this.menuHeight$.next(this.getMenuHeight());
			});
		}
		this.menuSwitched$.next();
		this.setDefaultSegmentedControlSelection();
	}

	close() {
		this.reset();
	}

	private reset() {
		this.levelOneSelected = -1;
		this.levelTwoSelected = 0;
		this.cdr.markForCheck(); // subscribe falls outside onPush change detection
	}

	private getSegmentedControlSelectionStream(): Observable<string | null> {
		return merge(
			this.segmentedControlControl.valueChanges.pipe(startWith(null)),
			this.menuSwitched$.pipe(map(() => null)),
		).pipe(shareReplay(1));
	}

	private getMenuHeight(): number {
		this.levelThreeContainerHeights = this.levelThreeContainers.map(
			(el) => el.nativeElement.offsetHeight,
		);

		const levelTwoContainerHeight =
			this.levelTwoContainers.get(this.levelOneSelected)?.nativeElement
				.offsetHeight || 0;

		const levelThreeContainerIndex = this.mapOffsetsToIndex(
			this.levelOneSelected,
			this.levelTwoSelected,
		);

		const levelThreeContainerHeight =
			this.levelThreeContainerHeights[levelThreeContainerIndex] || 0;

		const bottomPadding = 40;

		return (
			(levelTwoContainerHeight > levelThreeContainerHeight
				? levelTwoContainerHeight
				: levelThreeContainerHeight) + bottomPadding
		);
	}

	/**
	 * The viewchildren are represented as a vector, the items as a matrix(nested objects)
	 * this function maps the matrix offsets into the matching vector index
	 */
	private mapOffsetsToIndex(
		levelOneOffset: number,
		levelTwoOffset: number,
	): number {
		let count = 0;

		if (this.items) {
			for (let itemIndex = 0; itemIndex < this.items.length; itemIndex++) {
				const topItem = this.items[itemIndex];
				if (topItem.children) {
					for (
						let childItemIndex = 0;
						childItemIndex < topItem.children.length;
						childItemIndex++
					) {
						count++;
						if (
							levelOneOffset === itemIndex &&
							levelTwoOffset === childItemIndex
						) {
							return count - 1;
						}
					}
				}
			}
		}

		return -1;
	}

	private setDefaultSegmentedControlSelection(): void {
		if (this.items && this.items.length && this.levelOneSelected !== -1) {
			const children = this.items[this.levelOneSelected].children;
			if (children) {
				const categories = children[this.levelTwoSelected].extra?.categories;
				if (categories && categories.length) {
					this.segmentedControlControl.setValue(categories[0].id);
				}
			}
		}
	}
}
