import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { CtaButtonComponentInterface } from '@sunny-cars/util-global/lib/interfaces/content/cta-button.interface';
import { IconComponent } from '../icon/icon.component';

@Component({
	selector: 'ui-cta-button',
	templateUrl: 'cta-button.component.html',
	standalone: true,
	imports: [CommonModule, IconComponent],
})
export class CtaButtonComponent {
	@Input() content?: CtaButtonComponentInterface;

	scrollToTop() {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}
}
