import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

/**
 * Validate if a password contains minimum security checks
 * @returns {ValidatorFn}
 */
export function passwordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const passwordValue = control.value;
		let isValidPassword = true;

		if (
			!passwordValue ||
			passwordValue.length < 8 // At least 8 characters
		) {
			isValidPassword = false;
		}

		return isValidPassword ? null : { password: true };
	};
}

/**
 * Validate if a password contains minimum security checks
 * @returns {ValidatorFn}
 */
export function newPasswordValidator(): ValidatorFn {
	return (control: AbstractControl): ValidationErrors | null => {
		const passwordValue = control.value;
		let isValidPassword = true;

		if (
			!passwordValue ||
			passwordValue.length < 8 || // At least 8 characters
			// At least: 1 lowercase character, 1 uppercase character and 1 number
			!/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()_\-=+[\]{};:'\\|,<.>/?`~±§ ]).*$/g.test(
				passwordValue,
			)
		) {
			isValidPassword = false;
		}

		return isValidPassword ? null : { password: true };
	};
}
