import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

/**
 * Widget input validation methods
 */
@Injectable({
	providedIn: 'root',
})
export class InputValidators {
	/**
	 * Default time
	 */
	private readonly defaultTime = '10:00';
	/**
	 * Allowed date formats
	 */
	private readonly allowedDateFormats: string[] = [
		'DDMMYYYY',
		'DD.MM.YYYY',
		'YYYY-MM-DD',
	];

	/**
	 * Whether a property was invalid
	 */
	hasInvalidProperty = false;

	/**
	 * Get validated pickup time
	 * @param {string} time
	 * @param {string} defaultTime
	 * @returns {string} - Defaults to '10:00'
	 */
	getValidatedPickupTime(
		time: string,
		defaultTime: string = this.defaultTime,
	): string {
		const validatedTime = this.getValidatedTime(time, defaultTime);
		if (validatedTime === 'invalid') {
			console.warn('Invalid pickup time');
			this.hasInvalidProperty = true;
			return defaultTime;
		}
		return validatedTime;
	}

	/**
	 * Get validated dropoff time
	 * @param {string} time
	 * @param {string} defaultTime
	 * @returns {string} - Defaults to '10:00'
	 */
	getValidatedDropoffTime(
		time: string,
		defaultTime: string = this.defaultTime,
	): string {
		const validatedTime = this.getValidatedTime(time, defaultTime);
		if (validatedTime === 'invalid') {
			console.warn('Invalid dropoff time');
			this.hasInvalidProperty = true;
			return defaultTime;
		}
		return validatedTime;
	}

	/**
	 * Get validated time
	 * @param {string} time
	 * @param {string} defaultTime - Defaults to '10:00'
	 * @returns {string}
	 */
	getValidatedTime(
		time: string,
		defaultTime: string = this.defaultTime,
	): string {
		if (time) {
			if (time.match(/^[\d]{2}:[\d]{2}$/)) {
				// Allow '10:30' format
				if (
					parseInt(time.slice(0, 2)) < 24 &&
					['00', '30'].includes(time.slice(3, 5))
				) {
					// Only allow 0 and 30 minute values and hours < 24
					return time;
				} else {
					return 'invalid';
				}
			} else if (time.match(/[\d]{4}/g)) {
				// Allow '1030' format
				return `${time.slice(0, 2)}:${time.slice(2, 4)}`;
			} else {
				return 'invalid';
			}
		}
		return defaultTime;
	}

	/**
	 * Get validated pickup date
	 * @param {string} date
	 * @param {string} defaultDate
	 * @returns {string} - Defaults to ISO date 1 week from now
	 */
	getValidatedPickupDate(date: string, defaultDate?: string): string {
		if (!defaultDate) {
			defaultDate = dayjs().add(1, 'week').format();
		}
		const validatedDate = this.getValidatedDate(date);
		if (validatedDate === 'invalid') {
			console.warn('Invalid pickup date');
			this.hasInvalidProperty = true;
			return defaultDate;
		}
		if (validatedDate === 'passed') {
			console.warn('Pickup date must be today or later');
			this.hasInvalidProperty = true;
			return defaultDate;
		}
		return validatedDate;
	}

	/**
	 * Get validated dropoff date
	 * @param {string} date
	 * @param {string} pickupDate
	 * @param {string} defaultDate
	 * @returns {string} - Defaults to ISO date 2 weeks from now
	 */
	getValidatedDropoffDate(
		date: string,
		pickupDate: string,
		defaultDate?: string,
	): string {
		if (!defaultDate) {
			defaultDate = dayjs(pickupDate).add(1, 'week').format();
		}
		const validatedDate = this.getValidatedDate(date);
		if (validatedDate === 'invalid') {
			console.warn('Invalid dropoff date');
			this.hasInvalidProperty = true;
			return defaultDate;
		}
		if (validatedDate === 'passed') {
			console.warn('Dropoff date must be today or later');
			this.hasInvalidProperty = true;
			return defaultDate;
		}
		if (validatedDate < dayjs(pickupDate).format()) {
			console.warn('Dropoff date must be later than pickup date');
			this.hasInvalidProperty = true;
			return defaultDate;
		}
		return validatedDate;
	}

	/**
	 * Get validated date
	 * @param {string} date
	 * @returns {string}
	 */
	getValidatedDate(date: string): string {
		const validatedDate = dayjs(date, this.allowedDateFormats, true);
		if (!validatedDate.isValid()) {
			return 'invalid';
		}
		if (!validatedDate.isSameOrAfter(dayjs().startOf('date'))) {
			return 'passed';
		}
		return validatedDate.format();
	}

	/**
	 * Get validated driver age
	 * @param {string} age
	 */
	getValidatedDriverAge(age: string) {
		if (!age || age === '') {
			return '';
		}

		const parsedAge = parseInt(age);

		const outerAgeRange = {
			max: 100,
			min: 18,
		};

		if (parsedAge < outerAgeRange.min) {
			console.warn('Invalid driver too young');
			this.hasInvalidProperty = true;
			return '';
		}
		if (parsedAge > outerAgeRange.max) {
			console.warn('Invalid driver too young');
			this.hasInvalidProperty = true;
			return '';
		}
		return age;
	}
}
