import { HeadingLevels } from '../heading-levels.interface';
import { ContentLayoutTypes } from '../page-content-layout-types.interface';
import { ImageInterface } from './image-type.interface';
import { LayoutSettings } from './layout-settings-type.interface';
import { LinkInterface } from './link-type.interface';

export interface PopularCountriesComponentInterface {
	attributes: {
		layoutSettings: LayoutSettings;
		content: string;
		hasDarkBackground: boolean;
		items: PopularCountryInterface[];
		link?: LinkInterface;
		heading: string;
		headingType: HeadingLevels;
		subheading: string;
	};
	layout: ContentLayoutTypes.POPULAR_COUNTRIES;
}

interface PopularCountryInterface {
	image: ImageInterface;
	name: string;
	urlType: PopularCountryUrlTypes;
	path: string;
	customUrl: string;
	slug: string;
	subtitle: string;
}

export enum PopularCountryUrlTypes {
	CUSTOM_URL = 'custom_url',
	PATH = 'path',
}
