import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { LocationsService } from './locations.service';

/**
 * Locations provider module
 */
@NgModule({
	imports: [CommonModule, HttpClientModule],
	providers: [LocationsService],
})
export class LocationsProviderModule {}
