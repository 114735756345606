import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	Output,
	PLATFORM_ID,
	ViewChild,
	ViewEncapsulation,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {
	createPopper,
	Placement,
	Instance as PopperInstance,
	StrictModifiers,
} from '@popperjs/core';
import { PageScrollHelper } from '@sunny-cars/util-global/lib/helpers/page-scroll/page-scroll.helper';
import { ContrastTextDirective } from '../contrast-text/contrast-text.directive';
import { SheetHeaderComponent } from '../sheet-header/sheet-header.component';

export interface PopperOptions {
	placement: Placement;
	modifiers: StrictModifiers[];
}

export type TriggerType = 'MOUSEOVER' | 'CLICK';

@Component({
	selector: 'ui-popover',
	templateUrl: './popover.component.html',
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [
		CommonModule,
		ContrastTextDirective,
		SheetHeaderComponent,
		TranslateModule,
	],
})
export class PopoverComponent implements AfterViewInit {
	@Input() title = '';
	@Input() wrapperStyleModifiers = '';
	@Input() styleModifiers = '';
	@Input() mobilePositionTop: string | undefined;
	@Input() position: Placement = 'bottom';
	@Input() padding = 8;
	@Input() hasCloseButton = false;
	@Input() triggerLabel = '';
	@Input() triggerType: TriggerType = 'CLICK';
	@Input() usePixelMaxWidth = false;
	@Input() mobileViewUpToLg = false;
	@Input() hasTopMenuControls = true;

	@Input() set isOpen(isOpen: boolean) {
		this._isOpen = isOpen;
		this.disableBackgroundScroll(this._isOpen);
		if (!isOpen) {
			this.close();
		}
	}

	@Output() isPopOverVisible: EventEmitter<boolean> =
		new EventEmitter<boolean>();

	@ViewChild('trigger') triggerElement: ElementRef | undefined;
	@ViewChild('results') resultsElement: ElementRef | undefined;

	_isOpen = false;
	/**
	 * Popper instance
	 */
	instance: PopperInstance | undefined;
	/**
	 * Whether to prevent closing on click outside
	 */
	private preventClose = false;
	shouldRenderResults = false;

	/**
	 * Handle click on element
	 * @returns {void}
	 */
	@HostListener('click')
	clickInside() {
		this.preventClose = true;
	}

	/**
	 * Handle click outside of element
	 * @returns {void}
	 */
	@HostListener('document:click')
	clickOutside() {
		if (!this.preventClose && this._isOpen) {
			this.close();
		}
		this.preventClose = false;
	}

	constructor(
		@Inject(PLATFORM_ID) public platformId: string,
		private readonly pageScrollHelper: PageScrollHelper,
	) {
		if (isPlatformBrowser(this.platformId)) {
			this.shouldRenderResults = true;
		}
	}

	ngAfterViewInit(): void {
		if (!isPlatformBrowser(this.platformId)) {
			return;
		}
		/* istanbul ignore next */
		this.instance = createPopper(
			this.triggerElement?.nativeElement,
			this.resultsElement?.nativeElement,
			this.getOptions(),
		);
	}

	/**
	 * Set the trigger element
	 * @param {HTMLElement} element
	 * @returns {void}
	 */
	setTriggerElement(element: HTMLElement): void {
		this.instance?.destroy();
		/* istanbul ignore next */
		this.instance = createPopper(
			element,
			this.resultsElement?.nativeElement,
			this.getOptions(),
		);
		setTimeout(() => {
			this.instance?.update();
		});
	}

	/**
	 * Close the results
	 * @returns {void}
	 */
	close(): void {
		this._isOpen = false;
		this.disableBackgroundScroll(false);
	}

	/**
	 * Open the results
	 * @returns {void}
	 */
	open(): void {
		if (!this._isOpen) {
			this.isPopOverVisible.emit(true);
		}
		this._isOpen = true;
		this.disableBackgroundScroll(true);
		setTimeout(() => {
			this.instance?.update();
		});
	}

	/**
	 * Toggle open state
	 * @returns {void}
	 */
	toggle(): void {
		if (this._isOpen && this.triggerType !== 'MOUSEOVER') {
			this.close();
		} else {
			this.open();
		}
	}

	/**
	 * Get options
	 * @returns {PopperOptions}
	 */
	getOptions(): PopperOptions {
		return {
			placement: this.position,
			modifiers: [
				{
					name: 'preventOverflow',
					options: {
						padding: this.padding,
					},
				},
				{
					name: 'offset',
					options: {
						offset: [0, 20],
					},
				},
				{
					name: 'arrow',
					options: {
						padding: 5, // 5px from the edges of the popper
					},
				},
			],
		};
	}

	private disableBackgroundScroll(state: boolean): void {
		this.pageScrollHelper.toggleBackgroundScroll(
			state,
			this.mobileViewUpToLg ? 1040 : undefined,
		);
	}
}
