export enum SessionType {
	ADMIN = 'ADMIN',
	AFFILIATE = 'AFFILIATE',
	CUSTOMER = 'CUSTOMER',
}

export type SessionTypes =
	| SessionType.ADMIN
	| SessionType.AFFILIATE
	| SessionType.CUSTOMER;
