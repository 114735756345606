import { RegionDestination } from '@sunny-cars/util-global';

export class RegionMapHelper {
	static slugify(string = ''): string {
		return string
			.trim()
			.toLowerCase()
			.normalize('NFD')
			.replace(/[\u0300-\u036f]/g, '')
			.replace(/\s+/g, '-')
			.replace(/[^\w-]+/g, '')
			.replace(/-{2,}/g, '-');
	}

	static isRegionWithGeo(region: RegionDestination): boolean {
		return region.geo.lat !== 0 && region.geo.lng !== 0;
	}
}
