<aside *ngIf="reviews?.length" class="mt-6 2xs:px-3 xs:px-6 sm:px-9">
	<div
		class="mx-auto flex max-w-hero flex-wrap gap-x-6 gap-y-4"
		[ngClass]="{ 'justify-center': centerAligned }"
	>
		<ng-template #reviewContents let-review="review">
			<span
				class="h-8 w-8"
				*ngIf="['trustpilot', 'ekomi', 'google'].includes(review.name)"
			>
				<div
					*ngIf="review.name === 'trustpilot'"
					class="h-8 w-8 bg-icon-social-official-trustpilot-colored"
				></div>
				<div
					*ngIf="review.name === 'google'"
					class="h-8 w-8 bg-icon-social-circle-solid-google-reviews-colored"
				></div>
				<ui-ekomi-logo
					*ngIf="review.name === 'ekomi'"
					class="h-8 w-8"
					[score]="review.review.value"
				></ui-ekomi-logo>
			</span>
			<span class="ml-1 flex items-end gap-0.5 font-bold">
				<span class="text-3xl leading-none text-reviews">{{
					review.review.value
				}}</span>
				<span class="text-lg">/5.0</span>
			</span>
			<span class="mx-2 block h-full border-r border-r-ui-moderate"></span>
			<span class="flex flex-col gap-0.5 font-bold leading-none">
				<span *ngIf="review.name === 'google'">{{
					'components.reviews.partner-name-google' | translate
				}}</span>

				<span *ngIf="review.name === 'ekomi'">{{
					'components.reviews.partner-name-ekomi' | translate
				}}</span>

				<span *ngIf="review.name === 'trustpilot'">{{
					'components.reviews.partner-name-trustpilot' | translate
				}}</span>
				<span
					*ngIf="!['trustpilot', 'ekomi', 'google'].includes(review.name)"
					>{{ review.name[0].toUpperCase() + review.name.slice(1) }}</span
				>

				<span class="text-sm leading-none opacity-60"
					>{{ review.review.amount | number: '2.0-0' : localeHelper.locale }}
					{{ 'components.reviews.reviews' | translate }}</span
				>
			</span>
		</ng-template>
		<ng-container *ngFor="let review of reviews">
			<a
				*ngIf="review.review.profileURL"
				class="flex items-center rounded-full bg-ui-low px-4 py-2"
				[href]="review.review.profileURL"
				target="_blank"
			>
				<ng-container
					*ngTemplateOutlet="
						reviewContents;
						context: {
							review: review
						}
					"
				></ng-container>
			</a>
			<div
				*ngIf="!review.review.profileURL"
				class="flex items-center rounded-full bg-ui-low px-4 py-2"
			>
				<ng-container
					*ngTemplateOutlet="
						reviewContents;
						context: {
							review: review
						}
					"
				></ng-container>
			</div>
		</ng-container>
	</div>
</aside>
