import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'imageQuery',
	pure: true,
	standalone: true,
})
export class ImageQueryPipe implements PipeTransform {
	transform(imageUrl: string, size: { height: number; width: number }): string {
		if (!imageUrl) {
			return '';
		} else if (imageUrl.includes('sig/plain')) {
			const image = imageUrl.split('sig/plain');
			return `${image[0]}sig/rs:auto:${size.width}:${size.height}:1:1/plain${image[1]}@webp`;
		}
		return `${imageUrl}`;
	}
}
