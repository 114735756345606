import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { ContactDetailsFacade } from '@sunny-cars/data-access-contact-details';
import { SourceType } from '@sunny-cars/util-global';
import { EnvironmentService } from '@sunny-cars/util-global/lib/services/environment/environment.service';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import { filter, first } from 'rxjs/operators';

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

export const format = 'DD-MMM-YYYY-HH:mm:ss';

@Injectable()
export class ContactDetailsStateService {
	readonly affiliateKey: number =
		this.environmentService.getDomainConfig().affiliateKey;
	readonly source: SourceType =
		this.environmentService.getDomainConfig().source;

	constructor(
		@Inject(PLATFORM_ID) private readonly platformId: string,
		private readonly contactDetailsStore: ContactDetailsFacade,
		private readonly environmentService: EnvironmentService,
	) {}

	init() {
		this.initialLoadContactDetails();

		if (isPlatformBrowser(this.platformId)) {
			this.reloadContactDetails();
		}
	}

	private initialLoadContactDetails(): void {
		this.contactDetailsStore.contactDetails$
			.pipe(
				filter((contactDetails) => {
					return !contactDetails;
				}),
				first(),
			)
			.subscribe(() => this.dispatchGetContactDetails());
	}

	private reloadContactDetails(): void {
		this.contactDetailsStore.expires$.subscribe((expires) => {
			if (!expires) {
				return;
			}

			const now = dayjs().tz('CET');
			const expirationDate = this.getDayjsFromHeader(expires);
			const minuteGracePeriodInMs = 60000;
			const differenceInMs = expirationDate.diff(now) + minuteGracePeriodInMs;

			if (differenceInMs > 0) {
				setTimeout(() => {
					this.dispatchGetContactDetails();
				}, differenceInMs);
			} else {
				this.dispatchGetContactDetails();
			}
		});
	}

	private dispatchGetContactDetails(): void {
		this.contactDetailsStore.getContactDetails({
			affiliatekey: this.affiliateKey,
			source: this.source,
		});
	}

	/**
	 * getDayjsFromHeader
	 * @param {string} expires - in the following format: "Tue, 20 Dec 2022 23:00:00 GMT"
	 * @returns dayjs.Dayjs
	 */
	private getDayjsFromHeader(expires: string): dayjs.Dayjs {
		return dayjs.tz(expires);
	}
}
