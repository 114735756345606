import { marker } from '@colsen1991/ngx-translate-extract-marker';
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/nl';

marker('global.months.month');
marker('global.months.january');
marker('global.months.february');
marker('global.months.march');
marker('global.months.april');
marker('global.months.may');
marker('global.months.june');
marker('global.months.july');
marker('global.months.august');
marker('global.months.september');
marker('global.months.october');
marker('global.months.november');
marker('global.months.december');

/**
 * Predefined date/time format options
 */
export enum DateTimeFormats {
	ISO_WITHOUT_ZONE = 'YYYY-MM-DD[T]HH:mm:ss',
	DATE_TIME = 'dd-MM-YYYY HH:mm',
	DATE_TIME_SHORT = 'ddd D MMM YYYY, HH:mm',
	DATE_TIME_LONG = 'dddd D MMMM YYYY, HH:mm',
	DATE = 'DD-MM-YYYY',
	DATE_DOT_SEPERATED = 'DD.MM.YYYY',
	DATE_SHORT = 'ddd D MMM YYYY',
	DATE_LONG = 'D MMMM YYYY',
	DATE_WEEKDAY = 'dddd',
	TIME = 'HH:mm',
	DAY_DATE_LONG = 'dddd D MMMM YYYY',
}

export class DateTimeHelper {
	static convertISOToFormat(
		isoDateString: string,
		format?: string,
		language?: string,
	): string {
		if (!format) {
			format = DateTimeFormats.DATE_TIME_SHORT;
		}
		return dayjs(isoDateString).format(
			DateTimeHelper.getCorrectedFormat(format, language),
		);
	}

	/* Adds custom dot notation after day (`D.` instead of `D`) */
	static getCorrectedFormat(format: string, language?: string): string {
		if (
			format &&
			language === 'de' &&
			format !== DateTimeFormats.DATE_WEEKDAY
		) {
			// Day of month should be suffixed by dot
			// Day of week should be suffixed by comma
			return format
				.replace(/(\s|^)(D+)\s/, '$1$2. ')
				.replace(/(\s|^)(d+)\s/, '$1$2, ');
		}
		return format;
	}

	/**
	 * Formats the date to the format we use in the API
	 * @param {Date} date - DateTime object
	 * @param {boolean} appendDay - Whether or not the day should be appended to the eventual string
	 * @returns {string} YYYY-MM-DD
	 */
	static dateToString(date: Date, appendDay: boolean): string {
		if (appendDay) {
			return dayjs(date).format('YYYY-MM-DD');
		} else {
			return dayjs(date).format('YYYY-MM');
		}
	}

	/**
	 * Checks if the date is valid date instance
	 * @param {Date} date - DateTime object
	 * @returns {boolean} YYYY-MM-DD
	 */
	static isValidDate(date: Date): boolean {
		return date instanceof Date && !isNaN(date.getTime());
	}

	static getTodaysIndex(): number {
		let dayOfWeekIndex: number = dayjs().day() - 1;
		if (dayOfWeekIndex < 0) {
			dayOfWeekIndex = 6;
		}
		return dayOfWeekIndex;
	}

	static getMonthOptions(): (
		| { value: string; label: string }
		| { value: number; label: string }
	)[] {
		const months = [
			{ value: '', label: 'global.months.month' },
			{ value: 0, label: 'global.months.january' },
			{ value: 1, label: 'global.months.february' },
			{ value: 2, label: 'global.months.march' },
			{ value: 3, label: 'global.months.april' },
			{ value: 4, label: 'global.months.may' },
			{ value: 5, label: 'global.months.june' },
			{ value: 6, label: 'global.months.july' },
			{ value: 7, label: 'global.months.august' },
			{ value: 8, label: 'global.months.september' },
			{ value: 9, label: 'global.months.october' },
			{ value: 10, label: 'global.months.november' },
			{ value: 11, label: 'global.months.december' },
		];

		return months;
	}

	static getTimeOptions(): string[] {
		const options: string[] = [];
		for (let i = 0; i < 24; i++) {
			options.push(`${i < 10 ? '0' : ''}${i}:00`);
			options.push(`${i < 10 ? '0' : ''}${i}:30`);
		}
		return options;
	}
}
