import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { ValidationErrors } from '@angular/forms';
import { FieldWrapper, FormlyModule } from '@ngx-formly/core';
import { TranslateModule } from '@ngx-translate/core';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {
	DialogComponent,
	DialogTypes,
} from '@sunny-cars/ui/lib/dialog/dialog.component';

@Component({
	selector: 'formly-wrapper-label',
	template: `
		<div class="form-group">
			<label
				[attr.for]="field.key"
				*ngIf="props['label'] && !props['hideLabel']"
			>
				{{ props['translate'] ? (props['label'] | translate) : props['label'] }}
			</label>
			<div>
				<ng-container #fieldComponent></ng-container>
			</div>
			<div
				*ngIf="props['labelExtras'] && props['labelExtras']['helperText']"
				class="text-text-tertiary"
			>
				{{ props['labelExtras']['helperText'] | translate }}
			</div>
			<ng-container
				*ngIf="showError && getErrorData(formControl.errors) as errorItem"
			>
				<ui-dialog
					*ngIf="errorItem['type'] === 'dialog'; else defaultError"
					styleModifiers="mt-2"
					[isPadded]="false"
					[type]="dialogTypes.INFO"
					[title]="
						errorItem['translate']
							? (errorItem['message'] | translate)
							: errorItem['message']
					"
				>
					<span *ngIf="errorItem['supportingText']">
						{{
							errorItem['translate']
								? (errorItem['supportingText'] | translate)
								: errorItem['supportingText']
						}}
					</span>
				</ui-dialog>
			</ng-container>
			<ng-template #defaultError>
				<div class="text-error-high" *ngIf="showError">
					<formly-validation-message
						#errorMessage
						[field]="field"
					></formly-validation-message>
					<div *ngIf="!(errorMessage?.errorMessage$ | async)">
						{{
							'global.formly.error.invalid'
								| translate
									: {
											field: field.props?.label,
											value: field.formControl?.value
									  }
						}}
					</div>
				</div>
			</ng-template>
		</div>
	`,
	standalone: true,
	imports: [CommonModule, DialogComponent, FormlyModule, TranslateModule],
})
export class LabelWrapperComponent extends FieldWrapper {
	readonly dialogTypes = DialogTypes;

	getErrorData(errors: ValidationErrors | null): ValidationErrors | undefined {
		if (!errors) {
			return undefined;
		}
		return Object.values(errors as object)[0];
	}
}
