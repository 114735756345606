<nav
	*ngIf="positionOnPage === positions.TOP; else BottomBreadcrumbs"
	class="relative text-sm shadow-nav-menu-inner lg:bg-secondary lg:px-8 lg:py-3 lg:text-white"
>
	<a
		*ngFor="let crumb of breadcrumbs?.slice(0, -1)"
		class="hidden bg-icon-chevron-left-white bg-2 bg-left-center bg-no-repeat pl-2.5 first:ml-0 hover:underline lg:ml-4 lg:inline-block"
		[routerLink]="crumb.path"
		[queryParams]="crumb.params"
	>
		{{ crumb.title }}
	</a>
	<a
		class="btn btn-secondary absolute right-2 top-0 z-10 rounded-t-none bg-icon-chevron-left-bold-white bg-2 bg-left-3-center bg-no-repeat py-1 pl-6 font-semibold text-white xs:right-8 lg:hidden"
		[routerLink]="routerLink"
		type="button"
	>
		{{ 'global.back-action' | translate }}
	</a>
</nav>
<ng-template #BottomBreadcrumbs>
	<nav class="relative flex flex-row flex-wrap gap-4 gap-y-1 text-base">
		<ng-container *ngFor="let crumb of breadcrumbs; let lastCrumb = last">
			<a
				*ngIf="!lastCrumb"
				class="inline-block bg-icon-chevron-left-bold-secondary bg-2 bg-left-center bg-no-repeat pl-2.5 font-bold text-secondary first:bg-none first:pl-0 hover:underline"
				[routerLink]="crumb.path"
				[queryParams]="crumb.params"
			>
				{{ crumb.title }}
			</a>
			<span *ngIf="lastCrumb" class="inline-flex flex-wrap gap-1">
				<span class="font-bold">{{
					'components.breadcrumbs.current-location' | translate
				}}</span>
				<span class="text-text-secondary">{{ crumb.title }}</span>
			</span>
		</ng-container>
	</nav>
</ng-template>
