import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { LocaleHelper } from '../../helpers/locale/locale.helper';

/**
 * Convert value to currency string
 */
@Pipe({
	name: 'sc_currency',
	pure: false,
	standalone: true,
})
export class SunnyCurrencyPipe implements PipeTransform {
	constructor(
		private readonly currencyPipe: CurrencyPipe,
		private readonly localeHelper: LocaleHelper,
	) {}

	/**
	 * Convert value to a currency string
	 * - returns integer values without digits
	 * - returns float values with two digits
	 * @param {number | string | undefined} value
	 * @param {string} currency
	 * @param {string} locale
	 * @returns {string}
	 */
	transform(
		value: number | string | undefined,
		currency = 'EUR',
		locale: string = this.localeHelper.locale,
	): string | null {
		let correctedLocale: string = locale;
		let digitsInfo = '1.0-0';
		let type = 'code';

		if (['de-CH', 'fr-CH'].includes(locale)) {
			correctedLocale = 'ch';
		}
		if (typeof value === 'string') {
			value = parseFloat(value);
		}
		if (value && value % 1 > 0) {
			digitsInfo = '1.2-2';
		}
		if (['EUR', 'USD'].includes(currency)) {
			type = 'symbol';
		}

		return this.currencyPipe.transform(
			value,
			currency,
			type,
			digitsInfo,
			correctedLocale,
		);
	}
}
