import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconComponent } from '../icon/icon.component';

export interface AccordionItem {
	text: string;
	title: string;
	isOpen?: boolean;
	category?: string;
}

@Component({
	selector: 'ui-accordion-item',
	templateUrl: './accordion-item.component.html',
	standalone: true,
	imports: [CommonModule, IconComponent],
})
export class AccordionItemComponent {
	@Input() title = '';
	@Input() isOpen = false;
	@Input() detailStyleModifiers = '';
	@Input() summaryStyleModifiers = '';

	@Output() toggle: EventEmitter<boolean> = new EventEmitter(this.isOpen);

	setIsOpen(event: Event): void {
		event.preventDefault();
		this.isOpen = !this.isOpen;
		this.toggle.emit(this.isOpen);
	}
}
