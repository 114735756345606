import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { ContactPersonInterface } from '@sunny-cars/util-global/lib/interfaces/content/contact-person.interface';
import { HeadingLevels } from '@sunny-cars/util-global/lib/interfaces/heading-levels.interface';
import { ImageComponent } from '../image/image.component';
import { TextBlockComponent } from '../text-block/text-block.component';

@Component({
	selector: 'ui-contact-person',
	templateUrl: './contact-person.component.html',
	standalone: true,
	imports: [CommonModule, TextBlockComponent, ImageComponent],
})
export class ContactPersonComponent {
	@Input() heading = '';
	@Input() headingType: HeadingLevels = HeadingLevels.H1;
	@Input() subheading = '';
	@Input() content = '';
	@Input() contactPerson?: ContactPersonInterface;
}
