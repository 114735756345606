import { CommonModule } from '@angular/common';
import {
	Component,
	EventEmitter,
	Input,
	Output,
	computed,
	signal,
} from '@angular/core';
import { ContrastTextDirective } from '../contrast-text/contrast-text.directive';
import {
	ButtonSizeClasses,
	ButtonSizes,
	ButtonStyleTypes,
	ButtonTypes,
	StyleClasses,
} from './button.component.data';

@Component({
	selector: 'ui-button',
	templateUrl: './button.component.html',
	standalone: true,
	imports: [CommonModule, ContrastTextDirective],
})
export class ButtonComponent {
	buttonSizes = ButtonSizes;
	buttonStyleTypes = ButtonStyleTypes;
	buttonTypes = ButtonTypes;

	@Input() set styleType(value: ButtonStyleTypes) {
		this.typeSig.set(value);
	}
	typeSig = signal<ButtonStyleTypes>(ButtonStyleTypes.PRIMARY_SOLID);
	styleClassesComputed = computed<string>(() => {
		return (
			StyleClasses[this.typeSig()] ||
			StyleClasses[ButtonStyleTypes.PRIMARY_SOLID]
		);
	});

	@Input() set size(value: ButtonSizes) {
		this.sizeSig.set(value);
	}
	sizeSig = signal<ButtonSizes>(this.size);
	sizeClassesComputed = computed<string>(() => {
		return (
			ButtonSizeClasses[this.sizeSig()] || ButtonSizeClasses[ButtonSizes.M]
		);
	});

	@Input() set isSubmit(value: boolean) {
		this.isSubmitButtonSig.set(value);
	}
	isSubmitButtonSig = signal<boolean>(this.isSubmit);
	buttonTypeComputed = computed<ButtonTypes>(() => {
		return this.isSubmitButtonSig()
			? this.buttonTypes.SUBMIT
			: this.buttonTypes.BUTTON;
	});

	@Input() isDisabled: boolean | null = false;
	@Input() isRoundedFull = false;
	@Input() isWidthFull = false;
	@Input() styleModifiers = '';
	@Input() form = '';

	@Output() buttonClick: EventEmitter<void> = new EventEmitter<void>();

	public handleClick(): void {
		this.buttonClick.emit();
	}
}
